import { HYDRATE } from "next-redux-wrapper";
import { ACTION_TYPE } from "../../../src/action-constants/my-account-constants";

const initialState = {
  myOrders: {},
  isLoading: false,
  isDeleted: false,
  paymentStatus: {
    isLoading: false,
    data: {},
  },
  faq: "",
  senderData: {
    mobileCountryCode: "",
    senderCountry: "",
  },
};

/**
 * reducer for address book
 *
 * @param {object} state - initial redux state
 * @param {object} action - action object for action type and payload
 * @returns {object} - updated state
 */
function addressBookReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.addressBook,
      };
    case ACTION_TYPE.GET_MY_ORDER_LIST_REQUESTED:
      return {
        ...state,
        isLoading: true,
        isDeleted: false,
      };
    case ACTION_TYPE.GET_MY_ORDER_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        myOrders: payload,
      };
    case ACTION_TYPE.GET_MY_ORDER_LIST_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_TYPE.GET_COUNTRY_NAME_REQUESTED:
      return {
        ...state,
      };
    case ACTION_TYPE.GET_COUNTRY_NAME_SUCCESS:
      return {
        ...state,
        senderData: payload,
      };
    case ACTION_TYPE.GET_COUNTRY_NAME_FAIL:
      return {
        ...state,
      };
    case ACTION_TYPE.CHECK_PAYMENT_STATUS_REQUESTED:
      return {
        ...state,
        paymentStatus: { ...state.paymentStatus, isLoading: true, data: action.payload },
      };
    case ACTION_TYPE.CHECK_PAYMENT_STATUS_SUCCESS:
      return {
        ...state,
        paymentStatus: { ...state.paymentStatus, isLoading: false, data: action.payload },
      };
    case ACTION_TYPE.CHECK_PAYMENT_STATUS_FAIL:
      return {
        ...state,
        paymentStatus: { ...state.paymentStatus, isLoading: false, data: {} },
      };
    case ACTION_TYPE.DELETE_EXPIRED_PRODUCT_SUCCESS:
      return {
        ...state,
        isDeleted: true,
      };
    case ACTION_TYPE.GET_FAQ_DETAILS:
      return { ...state, faq: payload };

    default:
      return state;
  }
}

export default addressBookReducer;
