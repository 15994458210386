import { HYDRATE } from "next-redux-wrapper";
import ListingPageConstants from "../../src/action-constants/listing-contants";

const initialState = {
  searchInsteadList: [],
};

/**
 * @param {object} state - state
 * @param {string} action - action
 * @returns {object} new state
 */
function searchInsteadReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.searchInstead,
      };
    case ListingPageConstants.SET_SEARCH_INSTEAD_DATA:
      return {
        ...state,
        searchInsteadList: [...payload],
      };
    default:
      return state;
  }
}

export default searchInsteadReducer;
