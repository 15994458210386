import { put, call, takeLatest } from "redux-saga/effects";
import GiftFinderConstants from "../../src/action-constants/gift-finder-constants";
import {
  getFacetsForGiftFinder,
  getCityNameBasedOnLocation,
  getPincodeBasedOnLocation,
} from "../../src/services/api/giftFinder/giftFinder";
import {
  setFacetsData,
  setFacetsError,
  setCityData,
  setCityError,
  setLocationata,
} from "../../src/actions/gift-finder-actions";
/**
 * This Saga handles gift finder facets data
 *
 * @param {object} action contains type and payload
 */
function* getFacetsData(action) {
  try {
    const response = yield call(getFacetsForGiftFinder, action.payload);
    if (response) {
      yield put(setFacetsData(response));
    } else {
      yield put(setFacetsError(""));
    }
  } catch (error) {
    yield put(setFacetsError(error));
  }
}

/**
 * This Saga handles gift finder city data
 *
 * @param {object} action contains type and payload
 */
function* getCityData(action) {
  try {
    const { latitude, longitude, locality, geoLocality, faddress, lpincode, laddress } = action.payload;

    let pincode;
    if (lpincode) {
      pincode = lpincode;
    } else {
      pincode = yield call(getPincodeBasedOnLocation, { latitude, longitude });
    }

    const response = yield call(getCityNameBasedOnLocation, { locality, geoLocality, pincode });

    if (response) {
      yield put(setCityData(response));
      if (lpincode) {
        yield put(
          setLocationata({
            pincode,
            faddress: laddress,
          }),
        );
      } else {
        yield put(
          setLocationata({
            pincode,
            faddress,
          }),
        );
      }
    } else {
      yield put(setCityError(""));
    }
  } catch (error) {
    yield put(setCityError(error));
  }
}
/**
 * Watcher saga for home page
 */
function* watchGiftFinderPageRequest() {
  yield takeLatest(GiftFinderConstants.REQUEST_FACET_DATA, getFacetsData);
  yield takeLatest(GiftFinderConstants.REQUEST_CITY_DATA, getCityData);
}

export default watchGiftFinderPageRequest;
