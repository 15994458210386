import endsWith from "lodash/endsWith";
import get from "lodash/get";
import isUndefined from "lodash/isUndefined";
import omit from "lodash/omit";
import startCase from "lodash/startCase";
import Router from "next/router";
import { parseCookies } from "nookies";
import React from "react";
import HTMLReactParser from "html-react-parser";
import errorLogger from "../../app_configs/logger-service";
import { WIDGET_TYPES } from "../../components/common/constants";
import { productTypes } from "./pdp-constants";
import geoDefault from "../../geo_configurations/default";

const catelogueCountries = [
  "uae",
  "usa",
  "uk",
  "australia",
  "canada",
  "singapore",
  "philippines",
  "germany",
  "new-zealand",
  "argentina",
  "austria",
  "bahrain",
  "bangladesh",
  "belgium",
  "brazil",
  "bulgaria",
  "chile",
  "china",
  "croatia",
  "denmark",
  "egypt",
  "finland",
  "france",
  "greece",
  "haiti",
  "hong-kong",
  "hungary",
  "indonesia",
  "ireland",
  "israel",
  "italy",
  "japan",
  "jordan",
  "kenya",
  "korea",
  "kuwait",
  "lebanon",
  "macau",
  "malaysia",
  "mauritius",
  "mexico",
  "morocco",
  "nepal",
  "netherlands",
  "norway",
  "oman",
  "pakistan",
  "poland",
  "portugal",
  "qatar",
  "romania",
  "russia",
  "saudi-arabia",
  "south-africa",
  "spain",
  "sri-lanka",
  "sweden",
  "switzerland",
  "taiwan",
  "thailand",
  "turkey",
  "ukraine",
  "vietnam",
  "zimbabwe",
];

const pageTypeVarName = "fnpPageType";
const isMicroSiteVarName = "isMicroSite";

export const catelogueCountriesRedirections = [
  { name: "uae", redirects: "/uae/gifts?promo=mob_countryList" },
  { name: "usa", redirects: "/usa/gifts?promo=mob_countryList" },
  { name: "uk", redirects: "/uk/gifts?promo=mob_countryList" },
  { name: "australia", redirects: "/australia/gifts?promo=mob_countryList" },
  { name: "canada", redirects: "/canada/gifts?promo=mob_countryList" },
  { name: "singapore", redirects: "/singapore/gifts?promo=mob_countryList" },
  { name: "philippines", redirects: "/philippines/gifts?promo=mob_countryList" },
  { name: "germany", redirects: "/germany/gifts?promo=mob_countryList" },
  { name: "new-zealand", redirects: "/new-zealand/gifts?promo=mob_countryList" },
  { name: "argentina", redirects: "/argentina/gifts?promo=mob_countryList" },
  { name: "austria", redirects: "/austria/gifts?promo=mob_countryList" },
  { name: "bahrain", redirects: "/bahrain/gifts?promo=mob_countryList" },
  { name: "bangladesh", redirects: "/bangladesh/gifts?promo=mob_countryList" },
  { name: "belgium", redirects: "/belgium/gifts?promo=mob_countryList" },
  { name: "brazil", redirects: "/brazil/gifts?promo=mob_countryList" },
  { name: "bulgaria", redirects: "/bulgaria/gifts?promo=mob_countryList" },
  { name: "chile", redirects: "/chile/gifts?promo=mob_countryList" },
  { name: "china", redirects: "/china/gifts?promo=mob_countryList" },
  { name: "croatia", redirects: "/croatia/gifts?promo=mob_countryList" },
  { name: "denmark", redirects: "/denmark/gifts?promo=mob_countryList" },
  { name: "egypt", redirects: "/egypt/gifts?promo=mob_countryList" },
  { name: "finland", redirects: "/finland/gifts?promo=mob_countryList" },
  { name: "france", redirects: "/france/gifts?promo=mob_countryList" },
  { name: "greece", redirects: "/greece/gifts?promo=mob_countryList" },
  { name: "haiti", redirects: "/haiti/gifts?promo=mob_countryList" },
  { name: "hong-kong", redirects: "/hong-kong/gifts?promo=mob_countryList" },
  { name: "hungary", redirects: "/hungary/gifts?promo=mob_countryList" },
  { name: "indonesia", redirects: "/indonesia/gifts?promo=mob_countryList" },
  { name: "ireland", redirects: "/ireland/gifts?promo=mob_countryList" },
  { name: "israel", redirects: "/israel/gifts?promo=mob_countryList" },
  { name: "italy", redirects: "/italy/gifts?promo=mob_countryList" },
  { name: "japan", redirects: "/japan/gifts?promo=mob_countryList" },
  { name: "jordan", redirects: "/jordan/gifts?promo=mob_countryList" },
  { name: "kenya", redirects: "/kenya/gifts?promo=mob_countryList" },
  { name: "korea", redirects: "/korea/gifts?promo=mob_countryList" },
  { name: "kuwait", redirects: "/kuwait/gifts?promo=mob_countryList" },
  { name: "lebanon", redirects: "/lebanon/gifts?promo=mob_countryList" },
  { name: "macau", redirects: "/macau/gifts?promo=mob_countryList" },
  { name: "malaysia", redirects: "/malaysia/gifts?promo=mob_countryList" },
  { name: "mauritius", redirects: "/mauritius/gifts?promo=mob_countryList" },
  { name: "mexico", redirects: "/mexico/gifts?promo=mob_countryList" },
  { name: "morocco", redirects: "/morocco/gifts?promo=mob_countryList" },
  { name: "nepal", redirects: "/nepal/gifts?promo=mob_countryList" },
  { name: "netherlands", redirects: "/netherlands/gifts?promo=mob_countryList" },
  { name: "norway", redirects: "/norway/gifts?promo=mob_countryList" },
  { name: "oman", redirects: "/oman/gifts?promo=mob_countryList" },
  { name: "pakistan", redirects: "/pakistan/gifts?promo=mob_countryList" },
  { name: "poland", redirects: "/poland/gifts?promo=mob_countryList" },
  { name: "portugal", redirects: "/portugal/gifts?promo=mob_countryList" },
  { name: "qatar", redirects: "/qatar/gifts?promo=mob_countryList" },
  { name: "romania", redirects: "/romania/gifts?promo=mob_countryList" },
  { name: "russia", redirects: "/russia/gifts?promo=mob_countryList" },
  { name: "saudi-arabia", redirects: "/saudi-arabia/gifts?promo=mob_countryList" },
  { name: "south-africa", redirects: "/south-africa/gifts?promo=mob_countryList" },
  { name: "spain", redirects: "/spain/gifts?promo=mob_countryList" },
  { name: "sri-lanka", redirects: "/sri-lanka/gifts?promo=mob_countryList" },
  { name: "sweden", redirects: "/sweden/gifts?promo=mob_countryList" },
  { name: "switzerland", redirects: "/switzerland/gifts?promo=mob_countryList" },
  { name: "taiwan", redirects: "/taiwan/gifts?promo=mob_countryList" },
  { name: "thailand", redirects: "/thailand/gifts?promo=mob_countryList" },
  { name: "turkey", redirects: "/turkey/gifts?promo=mob_countryList" },
  { name: "ukraine", redirects: "/ukraine/gifts?promo=mob_countryList" },
  { name: "vietnam", redirects: "/vietnam/gifts?promo=mob_countryList" },
  { name: "zimbabwe", redirects: "/zimbabwe/gifts?promo=mob_countryList" },
];
export const MONTH_NAMES = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
export const DAY = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const DAY_FULL_NAME = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
export const MONTH_FULL_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const currentYear = new Date().getFullYear();
export const YEARS = Array.from(new Array(70), (val, index) => currentYear - index);

export const GIFT_FINDER_COUNTRY_MAP = {
  india: "IND",
  argentina: "ARG",
  australia: "AUS",
  austria: "AUT",
  bahrain: "BAH",
  bangladesh: "BAN",
  belgium: "BEL",
  brazil: "BRA",
  bulgaria: "BUL",
  canada: "CAN",
  chile: "CHL",
  china: "CHI",
  colombia: "COL",
  croatia: "CRO",
  denmark: "DEN",
  egypt: "EGY",
  finland: "FIN",
  france: "FRA",
  germany: "GER",
  greece: "GRE",
  greenland: "GRL",
  guam: "GUM",
  guyana: "GUY",
  haiti: "HTI",
  "hong-kong": "HKG",
  hungary: "HUN",
  indonesia: "IDN",
  ireland: "IRL",
  israel: "ISR",
  italy: "ITA",
  japan: "JAP",
  jordan: "JOR",
  kenya: "KEN",
  korea: "KOR",
  kuwait: "KWT",
  lebanon: "LBN",
  macau: "MAC",
  malaysia: "MAL",
  mauritius: "MUS",
  mexico: "MEX",
  morocco: "MAR",
  nepal: "NPL",
  netherlands: "NET",
  "new-zealand": "NEZ",
  norway: "NOR",
  oman: "OMA",
  pakistan: "PAK",
  philippines: "PHI",
  poland: "POL",
  portugal: "PRT",
  qatar: "QAT",
  romania: "ROU",
  russia: "RUS",
  "saudi-arabia": "SAU",
  singapore: "SIN",
  "south-africa": "SOA",
  spain: "ESP",
  "sri-lanka": "LKA",
  sweden: "SWE",
  switzerland: "CHE",
  taiwan: "TWN",
  thailand: "THA",
  turkey: "TUR",
  uae: "UAE",
  uk: "UK",
  ukraine: "UKR",
  usa: "USA",
  vietnam: "VNM",
  zambia: "ZMB",
  zimbabwe: "ZWE",
};

export const CATALOG_COUNTRIES = [
  {
    catalogID: "india",
    name: "India",
  },
  {
    catalogID: "usa",
    name: "USA",
  },
  {
    catalogID: "uk",
    name: "UK",
  },
  {
    catalogID: "uae",
    name: "UAE",
  },
  {
    catalogID: "australia",
    name: "Australia",
  },
  {
    catalogID: "canada",
    name: "Canada",
  },
  {
    catalogID: "singapore",
    name: "Singapore",
  },
  {
    catalogID: "philippines",
    name: "Philippines",
  },
  {
    catalogID: "germany",
    name: "Germany",
  },
  {
    catalogID: "new-zealand",
    name: "New Zealand",
  },
  {
    catalogID: "argentina",
    name: "Argentina",
  },
  {
    catalogID: "austria",
    name: "Austria",
  },
  {
    catalogID: "bahrain",
    name: "Bahrain",
  },
  {
    catalogID: "bangladesh",
    name: "Bangladesh",
  },
  {
    catalogID: "belgium",
    name: "Belgium",
  },
  {
    catalogID: "brazil",
    name: "Brazil",
  },
  {
    catalogID: "bulgaria",
    name: "Bulgaria",
  },
  {
    catalogID: "chile",
    name: "Chile",
  },
  {
    catalogID: "china",
    name: "China",
  },
  {
    catalogID: "colombia",
    name: "Colombia",
  },
  {
    catalogID: "croatia",
    name: "Croatia",
  },
  {
    catalogID: "denmark",
    name: "Denmark",
  },
  {
    catalogID: "egypt",
    name: "Egypt",
  },
  {
    catalogID: "finland",
    name: "Finland",
  },
  {
    catalogID: "france",
    name: "France",
  },
  {
    catalogID: "greece",
    name: "Greece",
  },
  {
    catalogID: "greenland",
    name: "Greenland",
  },
  {
    catalogID: "guam",
    name: "Guam",
  },
  {
    catalogID: "guyana",
    name: "Guyana",
  },
  {
    catalogID: "haiti",
    name: "Haiti",
  },
  {
    catalogID: "hong-kong",
    name: "Hong Kong",
  },
  {
    catalogID: "hungary",
    name: "Hungary",
  },
  {
    catalogID: "indonesia",
    name: "Indonesia",
  },
  {
    catalogID: "ireland",
    name: "Ireland",
  },
  {
    catalogID: "israel",
    name: "Israel",
  },
  {
    catalogID: "italy",
    name: "Italy",
  },
  {
    catalogID: "japan",
    name: "Japan",
  },
  {
    catalogID: "jordan",
    name: "Jordan",
  },
  {
    catalogID: "kenya",
    name: "Kenya",
  },
  {
    catalogID: "korea",
    name: "Korea",
  },
  {
    catalogID: "kuwait",
    name: "Kuwait",
  },
  {
    catalogID: "lebanon",
    name: "Lebanon",
  },
  {
    catalogID: "macau",
    name: "Macau",
  },
  {
    catalogID: "malaysia",
    name: "Malaysia",
  },
  {
    catalogID: "mauritius",
    name: "Mauritius",
  },
  {
    catalogID: "mexico",
    name: "Mexico",
  },
  {
    catalogID: "morocco",
    name: "Morocco",
  },
  {
    catalogID: "nepal",
    name: "Nepal",
  },
  {
    catalogID: "netherlands",
    name: "Netherlands",
  },
  {
    catalogID: "norway",
    name: "Norway",
  },
  {
    catalogID: "oman",
    name: "Oman",
  },
  {
    catalogID: "pakistan",
    name: "Pakistan",
  },
  {
    catalogID: "poland",
    name: "Poland",
  },
  {
    catalogID: "portugal",
    name: "Portugal",
  },
  {
    catalogID: "qatar",
    name: "Qatar",
  },
  {
    catalogID: "romania",
    name: "Romania",
  },
  {
    catalogID: "russia",
    name: "Russia",
  },
  {
    catalogID: "saudi-arabia",
    name: "Saudi Arabia",
  },
  {
    catalogID: "south-africa",
    name: "South Africa",
  },
  {
    catalogID: "spain",
    name: "Spain",
  },
  {
    catalogID: "sri-lanka",
    name: "Sri Lanka",
  },
  {
    catalogID: "sweden",
    name: "Sweden",
  },
  {
    catalogID: "switzerland",
    name: "Switzerland",
  },
  {
    catalogID: "taiwan",
    name: "Taiwan",
  },
  {
    catalogID: "thailand",
    name: "Thailand",
  },
  {
    catalogID: "turkey",
    name: "Turkey",
  },
  {
    catalogID: "ukraine",
    name: "Ukraine",
  },
  {
    catalogID: "vietnam",
    name: "Vietnam",
  },
  {
    catalogID: "zambia",
    name: "Zambia",
  },
  {
    catalogID: "zimbabwe",
    name: "Zimbabwe",
  },
];

/**
 * This method returns the title of the web page dynamically using routes
 *
 * @returns { string } Title of the application.
 */
export const getDynamicTitle = () => {
  const router = Router?.router;
  const pathname = startCase(router?.pathname).replace("Desktop ", "");
  return router?.query ? `${pathname} ${startCase(router?.query?.slug1)} - FNP` : "";
};

/**
 * This method returns the title of the web page
 * Takes the config object as parameter which provides the default
 * configuration and the platform based configuration which helps determine the
 * title.
 *
 * @param {object} configs configuration object of the application.
 * @returns { string } Title of the application.
 */
export const getTitle = (configs) => {
  const defaultConfigs = configs?.default;
  return getDynamicTitle() || get(defaultConfigs, "title") || "Ferns N Petals";
};

/**
 * This method takes path names as input and returns the corresponding PageName
 *
 * @param {string} pathname .
 * @returns {string} page name
 */
export const getPageName = (pathname) => {
  const pageNameObject = {
    "/home": "HomePage",
    "/": "HomePage",
    "/listing": "PlpPage",
    "/desktop-listing": "PlpPage",
    "/mobile-pdp": "pdpPage",
    "/desktop-pdp": "pdpPage",
    "/account": "AccountPage",
    "/checkout": "checkout",
    "/giftfinder": "GiftFinder",
    "/microSite": "MicroSite",
    "/cartpreview": "cartPreview",
    "/addons": "AddOns",
    "/_error": "ServerError",
    "/testimonials": "testimonials",
    "/notfound": "NotFound",
    "/experiences": "Experiences",
  };
  return pageNameObject[pathname] || "PageNotFound";
};

export const FnpPageType = {
  HOME: "home",
  CATEGORY: "category",
  SEARCH: "search",
  CART_PREVIEW: "cartPreview",
  GIFT_FINDER: "giftfinder",
  INFO: "info",
  PLP_MICRO_SITE: "plp_micro_site",
  PLP: "plp",
  PRODUCT: "product",
  MY_ACCOUNT: "myaccount",
  ERROR_404: "error404",
  CHECKOUT: "checkout",
  PAYMENT_OPTIONS: "payment-options",
  MESSAGE_ON_CARD: "message-on-card",
  TESTIMONIAL: "testimonials",
  CONFIRM_ORDER: "confirmorder",
  ORDER_NOT_CONFIRM: "ordernotconfirm",
  EXPERIENCES: "Experiences",
};

export const GOOGLE_KEYS = ["AIzaSyDyubnG8Oavl7JiQ_Saj56tFuL823vphUo", "AIzaSyAV9qAXl2Y_Kh0dl_4PXLTZuPoQMKdcw3M"];
/**
 * This method gets the current pageType in the window
 *
 * @returns {string} - returns the FnpPageType variable inside window
 */
export function getFnpPageType() {
  return typeof window !== "undefined" ? window[pageTypeVarName] : "";
}

/**
 * This function sets the PageType in window
 *
 * @param {string} pageType - pageType of the current page
 */
export function setFnpPageType(pageType) {
  window[pageTypeVarName] = pageType;
  // eslint-disable-next-line no-undef
  if (typeof newrelic !== "undefined" && newrelic.setCustomAttribute) {
    // eslint-disable-next-line no-undef
    newrelic.setCustomAttribute("fnpPageType", pageType);
  }
}

/**
 * This function sets the Listing page type based whether product is searched or it is a category
 *
 * @param {string} categoryId - specifies the categoryId of the products in plp page
 */
export function setListingPageInfo(categoryId) {
  if (categoryId === "search") {
    setFnpPageType(FnpPageType.SEARCH);
  } else if (categoryId === "product") {
    setFnpPageType(FnpPageType.PRODUCT);
  } else {
    setFnpPageType(FnpPageType.CATEGORY);
  }
}

/**
 * This method finds out the currency based on the platform or using the default configs
 *
 * @param {object} configs configuration object.
 * @returns {string} currency string.
 */
export const getCurrency = (configs) => {
  const defaultConfigs = configs.default;
  const platformConfigs = configs.mobile || configs.desktop;
  return get(platformConfigs, "currency") || get(defaultConfigs, "currency") || "INR";
};

/**
 * This method finds out the domain based on the platform or using the default configs
 *
 * @param {object} configs configuration object.
 * @returns {string} domain string.
 */
export const getDomain = (configs) => {
  const defaultConfigs = configs.default;
  const platformConfigs = configs.mobile || configs.desktop;
  return get(platformConfigs, "cookieDomain") || get(defaultConfigs, "cookieDomain") || ".fnp.com";
};

/**
 * This method takes cookie name as input and finds out its value from the
 * cookies list from the array of cookie store in the DOM.
 *
 * @param {string} cname cookie name eg: currency.
 * @returns {string} cookie value.
 */
export function getCookie(cname) {
  const name = `${cname}=`;
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
/**
 * This method checks if the request is from desktop or msite based on the context object received. It returns true if it is Msite else false.
 *
 * @param {object} ctx Request context object
 * @returns {boolean} Returns true if it is mobile else false
 */
export const isMobileDevice = (ctx) => {
  const headers = ctx?.req?.headers;
  if (headers && (headers["X-isMobile"] || headers["x-ismobile"])) {
    return true;
  }
  if (typeof window !== "undefined") {
    return !window.dSite;
  }
  return false;
};

/**
 * This method is used to return website id ie FNP or FNPMobile based on the
 * device from which thw request has been made.
 *
 * @param {object} ctx context object.
 * @returns {string} website id.
 */
export function getWebSiteId(ctx) {
  return isMobileDevice(ctx) ? "FNPMobile" : "FNP";
}

/**
 * Utility method to get ordinals
 *
 * @param {object} date date value
 * @returns {string} ordinal.
 */
export function getSuffix(date) {
  const s = ["th", "st", "nd", "rd"];
  const v = date % 100;
  return s[(v - 20) % 10] || s[v] || s[0];
}

/**
 * This method takes the "-" separated date eg: 13-11-2020 and finds out its delivery day
 * that can be either Today or Tomorrow.
 *
 * @param { string } deliveryDate .
 * @param {boolean} isWithDateOrdinal flag to enable date
 * @returns { string } String in delivery date Today and Tomorrow.
 */
export function getEarliestDeliveryDateString(deliveryDate, isWithDateOrdinal) {
  const inputDate = deliveryDate?.split("-");
  const day = Number(inputDate && inputDate[0]);
  const month = Number(inputDate && inputDate[1]);
  const year = Number(inputDate && inputDate[2]);
  const today = window && window.servertime ? window.servertime : new Date();
  const tomorrow = new Date(today?.getTime() + 24 * 60 * 60 * 1000);
  if (day === today?.getDate() && month === today?.getMonth() + 1 && year === today?.getFullYear()) return "Today";
  if (day === tomorrow?.getDate() && month === tomorrow?.getMonth() + 1 && year === tomorrow?.getFullYear())
    return "Tomorrow";
  if (isWithDateOrdinal) {
    return `${day}${getSuffix(day)} ${MONTH_NAMES[month - 1]}`;
  }
  return deliveryDate;
}
/**
 * This function handles date format for input date string and returns a desired format
 *
 * @param {string} dateString date string in the format dd-mmm-yyyy
 * @param {string} format desired format for the date string
 * @returns {string} returns new date string as per the format param defaults to DD-MMM
 */
export function getFormattedDate(dateString, format = "DD-MMM") {
  if (!dateString) return "";
  const dateToConvertToDate = new Date(dateString.split("-").reverse().join("-"));
  return format && `${dateToConvertToDate.getDate()} ${MONTH_NAMES[dateToConvertToDate.getMonth()]}`;
}

/**
 * This method retrieves the header menu which should be presented on left side bar as per the login status
 *
 * @param {object} headerMenu - header menu from config file
 * @param {boolean} isLoggedIn - specifies if user is logged in or not
 * @returns {Array} headerMenu which should be shown on left menu on the basis of login status
 */
export const getHeaderMenu = (headerMenu, isLoggedIn) => {
  const updatedHeaderMenu = headerMenu;
  const accountRoute = `/account/my-account`;
  if (Array.isArray(updatedHeaderMenu) && updatedHeaderMenu.length && typeof window !== "undefined") {
    if (isLoggedIn) {
      updatedHeaderMenu[0].name = "My Account";
      updatedHeaderMenu[0].href = accountRoute;
    } else {
      updatedHeaderMenu[0].name = "Login";
      updatedHeaderMenu[0].href = `${accountRoute}?from=${window.location.pathname}${window.location.search}`;
    }
    return updatedHeaderMenu;
  }
  return [];
};

/**
 * This method retrieves the header menu which should be presented on left side bar as per the login status
 *
 * @param {object} footerMenu - footer menu from config file
 * @param {boolean} isLoggedIn - specifies if user is logged in or not
 * @returns {Array} footerMenu which should be shown on left menu on the basis of login status
 */
export const getFooterMenu = (footerMenu, isLoggedIn) => {
  const updatedFooterMenu = footerMenu;
  if (Array.isArray(updatedFooterMenu) && updatedFooterMenu.length) {
    if (isLoggedIn) {
      return updatedFooterMenu;
    }
    return updatedFooterMenu.slice(0, updatedFooterMenu.length - 1);
  }
  return [];
};

/**
 * Utility function that will convert query string into javascript object.
 *
 * @param {string} query Input query string. Example: "category_id=roses&sortFields=price_ASC".
 * @returns {object} Key value pairs. Example: {category_id:"roses", sortFields:price_ASC}.
 */
export const getJsonFromQueryString = (query) => {
  if (!query) {
    return {};
  }
  const result = {};
  query.split("&").forEach((part) => {
    if (!part) return;
    const splittedPart = part.split("+").join(" ");
    const eq = splittedPart.indexOf("=");
    let key = eq > -1 ? splittedPart.substr(0, eq) : splittedPart;
    const val = eq > -1 ? decodeURIComponent(splittedPart.substr(eq + 1)) : "";
    const from = key.indexOf("[");
    if (from === -1) result[decodeURIComponent(key)] = val;
    else {
      const to = key.indexOf("]", from);
      const index = decodeURIComponent(key.substring(from + 1, to));
      key = decodeURIComponent(key.substring(0, from));
      if (!result[key]) result[key] = [];
      if (!index) result[key].push(val);
      else result[key][index] = val;
    }
  });
  return result;
};

/**
 * method removes particular query param from the string
 *
 * @param {string} url - url string
 * @param {string} parameter - parameter to be removed
 * @returns {string} final url
 */
export const removeURLParameter = (url, parameter) => {
  const destinationURL = new URL(url);
  destinationURL.searchParams.delete(parameter);
  return destinationURL.href;
};

/**
 * method removes particular hashvalue from the url
 *
 * @param {string} url - url string
 * @param {Array} parametersList - list of parameters to be removed from hash
 * @returns {string} final url
 */
export const removeHashParameterFromURL = (url, parametersList) => {
  const regex = new RegExp(`[#&]\\b(?:${parametersList.join("|")})\\b[^&#]*(#.*)?$`);
  return url.replace(regex, "");
};

/**
 * method removes hash from the url
 *
 * @param {string} url - url string
 * @returns {string} final url
 */
export const removeHashFromURL = (url) => {
  const destinationURL = new URL(url);
  destinationURL.hash = "";
  return destinationURL.href;
};

/**
 * This method generates the query from the current url
 *
 * @param {string} productCategoryId takes the categoryId and splits it up to find out the different slugs
 * @returns {object} query.
 */
export const generateQueryFromUrl = (productCategoryId) => {
  const query = {};
  productCategoryId.split("/").forEach((param, i) => {
    query[`slug${i + 1}`] = param;
  });
  return query;
};

/** Utility function to get formatted currency
 *
 * @param {Array} currencyList list of currencies
 * @param {string} currencyUom currency id
 * @returns {*} currency value
 */
function getCurrencyFormat(currencyList, currencyUom) {
  let currencyFormat;
  currencyList.forEach((item) => {
    if (item?.currency?.id === currencyUom) {
      currencyFormat = item.currency.value;
    }
  });
  return currencyFormat;
}

/**
 * Utility method to convert price of product from INR to local currency
 *
 * @param {*} price value of price in INR
 * @param {*} conversionFactor value of conversion factor of selected currency
 * @returns {*} local currency
 */
function convertPriceFromINR(price, conversionFactor) {
  try {
    const conversionFactorFormatted = conversionFactor.toString().replace(/,/g, "");
    let priceFormatted = price.toString().replace(/,/g, "");
    priceFormatted = (parseFloat(priceFormatted) / parseFloat(1.0)).toFixed(2);
    const local = (parseFloat(priceFormatted) * parseFloat(conversionFactorFormatted)).toFixed(2);
    return local;
  } catch (e) {
    return null;
  }
}

/**
 *
 * @param {*} currencyList list of currencies
 *
 * @param {*} currencyUom currency uom
 * @param {*} actualPrice actual price in INR
 * @returns {object} convert price
 */
export function convertPrice(currencyList, currencyUom, actualPrice) {
  const currencyFormat = getCurrencyFormat(currencyList, currencyUom);
  let currencySymbol;
  try {
    if (typeof currencyFormat !== "undefined") {
      let conversionFactor = currencyFormat.replace(/^[^\d]*/, "");
      currencySymbol = currencyFormat.replace(conversionFactor, "");
      conversionFactor = conversionFactor.replace(",", "");
      let localPrice = convertPriceFromINR(actualPrice, conversionFactor);
      let currencyClass = `curr-${currencyUom.toLowerCase()}`;
      if (currencyUom === "INR") {
        localPrice = Math.round(localPrice);
        currencyClass = "rupee";
      }
      return { price: localPrice, symbol: currencySymbol, currencyClass };
    }
    return {};
  } catch (e) {
    return {};
  }
}

/**
 * Utility function to format product details
 *
 * @param {object} item Product details
 * @returns {object} formatted product details
 */
function getMainProductDetails(item) {
  return {
    pincode: item.pincode,
    quantity: item.quantity,
    productId: item.productId,
    personalizedImage: item.personalizedImage,
    primaryProductCategoryId: item.primaryProductCategoryId,
    personalizedText: item.personalizedText || "",
    isProductPersonalizable: item.isProductPersonalizable,
    productName: item.productName,
    variantType: item.variantType,
    productImage: item.productImage,
    isVariant: item.isVariant,
    price: item.price,
    absoluteImageUrl: {
      alt: item.productName,
    },
  };
}

/**
 *  It is a utility function to format api response object of view cart api
 *
 * @param {object} resp response of view cart api
 * @returns {object} formatted api response
 */
export function updateViewCartJSON(resp) {
  const giftItems = [];
  const cartData = {};
  let baseItemTotal = 0;
  let addonsTotal = 0;
  let noOfAddons = 0;
  if (resp.items && resp.items.length > 0) {
    resp.items.forEach((product, index) => {
      const item = { ...product };
      if (item.addon === "N") {
        item.addons = [];
        item.giftIndex = giftItems.length + 1;
        item.mainProduct = getMainProductDetails(item);
        item.cartItemIndex = index;
        item.isMultiOrderProduct = !!item.deliveryDatesList;
        giftItems.push(item);
        baseItemTotal += item.price;
        if (item.isProductPersonalizable || !!item.messageOnCake) {
          item.personalize = {
            isPersnlImg: item.isPersonalizedImageAvailable,
            isPersnlTxt: item.isPersonalizedTextAvailable,
            personalizedImage: item.personalizedImage,
            personalizedText: item.personalizedText,
            messageOnCake: item.messageOnCake,
          };
        }
      } else if (item.addon === "Y") {
        const giftLength = giftItems.length;
        giftItems[giftLength - 1].addons.push(item);
        addonsTotal += item.price;
        noOfAddons += item.quantity;
      }
    });
  }
  cartData.paymentInfo = {
    baseItemTotal,
    addonsTotal,
    noOfAddons,
    noOfBaseItems: giftItems.length,
    totalShipping: resp.totalShipping,
    subTotal: resp.subTotal,
    total: resp.total,
    donationAmount: resp.donationAmount,
  };
  cartData.items = giftItems;
  return { ...cartData };
}
/** Utility function that takes new Date as an arg and gives desired format .
 *
 * @param { number } date date
 * @returns { string } String in Desired time format.
 */
export const formatDateFullDateMonth = (date) => {
  const allMonth = MONTH_NAMES;
  const allDays = DAY;
  const day = allDays[date.getDay()] ? allDays[date.getDay()] : "Not a valid day";
  const month = allMonth[date.getMonth()] ? allMonth[date.getMonth()] : "Not a valid month";
  let gDate = date.getDate();
  gDate = gDate < 10 ? `0${gDate}` : gDate;
  const year = date.getFullYear().toString().substr(-2);
  const fullDate = `${day} , ${gDate} ${month} ${year}`;
  return fullDate;
};

/**
 * Utility function that takes new Date as an arg and gives desired format .
 *
 * @param { number } date date
 * @returns { string } String in Desired time format.
 */
export const formatTime = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  hours %= 24;
  hours = hours || 24;
  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  const strTime = `${hours}:${minutes} hrs`;
  return strTime;
};

/**
 * Utility function that will convert EPOCH timestamp into this format (day, dd mm yy, hh:mm hrs).
 *
 * @param { number } dt EPOCH time stamp
 * @returns { string } String in Desired time format.
 */
export const dateAndTimeFormat = (dt) => {
  const d = new Date(dt);
  const fDate = formatDateFullDateMonth(d);
  const fTime = formatTime(d);
  const fullDateTime = `${fDate} , ${fTime}`;
  return fullDateTime;
};

/**
 *This method is used to add a watcher for the container which loads when the data is received from API call
 *
 * @param {*} selector HTML Element selector
 * @returns {object} .
 */
export function waitForElm(selector) {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      resolve(document.querySelector(selector));
      return;
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}

/**
 *
 * @param {string} selectedPinCode selected pincode
 * @returns {Promise} Promise object that calls getWidget api
 */
export const callWidgetApiForPincode = (selectedPinCode) => {
  return new Promise((resolve) => {
    if (window.widgetData) {
      const widData = Object.keys(window.widgetData);
      const { TRENDING_NOW, BEST_SELLERS } = WIDGET_TYPES;
      const widgetTypesToReload = [TRENDING_NOW, BEST_SELLERS];
      widData.forEach((widget) => {
        if (widgetTypesToReload.includes(widget)) {
          window[window.widgetData.renderFunctionName](window.widgetData[widget], selectedPinCode);
        }
      });
    }
    resolve(true);
  });
};

/**
 * Helper method to check if userAgent matches with any of the configs in the list.
 *
 * @returns {boolean} Returns true if userAgents exists else false
 */
export const isMiniAppUserAgent = () => {
  let isAgentExists = false;
  const userAgent = typeof window !== "undefined" ? window.navigator.userAgent : "server";
  const agentsListToCheck = ["phonepe", "paytm", "AppContainer"];
  agentsListToCheck.forEach((element) => {
    if (userAgent.indexOf(element) > -1) {
      isAgentExists = true;
    }
  });
  return isAgentExists;
};

/**
 * This method will check the fnpci and fnpli in the cookie.
 *
 * @returns {boolean} true if cookies exists otherwise false.
 */
export function checkCartAndLoginCookie() {
  let isCookieExists = false;
  const { fnpci, fnpli } = parseCookies();
  if (fnpci === "t" || fnpli === "t") {
    isCookieExists = true;
  }
  return isCookieExists;
}

export const RoutePageConstants = {
  HOME: "HomePage",
  PLP_PAGE: "PlpPage",
  PDP_PAGE: "PdpPage",
  SEARCH: "Search",
  CART_PREVIEW: "CartPreview",
  CHECKOUT_PAGE: "Checkout",
  MICROSITE: "MicroSite",
  ACCOUNT_PAGE: "Account",
  GIFT_FINDER_PAGE: "GiftFinder",
  TRACKORDER_PAGE: "TrackOrder",
  TESTIMONIAL: "Testimonials",
  NOT_FOUND: "NotFound",
  SERVER_ERROR: "ServerError",
  ADDONS: "Addons",
  EXPERIENCES_PAGE: "Experiences",
};

export const PathPageConstants = {
  COUNTRY_LIST: "countries-list",
  PLP_FILTER_VIEW: "PlpFilterView",
  SELECT_COUNTRY: "SelectCountry",
  MULTI_IMAGE_UPLOAD: "MultiImageUpload",
  MOBILE_SHIPPING_VIEW: "MobileShippingView",
  RECIPIENT_ADDRESS: "RecipientAddress",
};
/**
 * This method takes path names as input and returns the corresponding PageName
 *
 * @param {string} pathname .
 * @returns {string} page name
 */
export const getRoutePageName = (pathname) => {
  const pageNameObject = {
    "/home": RoutePageConstants.HOME,
    "/desktop-home": RoutePageConstants.HOME,
    "/": RoutePageConstants.HOME,
    "/listing": RoutePageConstants.PLP_PAGE,
    "/desktop-listing": RoutePageConstants.PLP_PAGE,
    "/desktop-pdp": RoutePageConstants.PDP_PAGE,
    "/mobile-pdp": RoutePageConstants.PDP_PAGE,
    "/account": RoutePageConstants.ACCOUNT_PAGE,
    "/desktop-account": RoutePageConstants.ACCOUNT_PAGE,
    "/checkout": RoutePageConstants.CHECKOUT_PAGE,
    "/giftfinder": RoutePageConstants.GIFT_FINDER_PAGE,
    "/microSite": RoutePageConstants.MICROSITE,
    "/desktop-microsite": RoutePageConstants.MICROSITE,
    "/track-order": RoutePageConstants.TRACKORDER_PAGE,
    "/cartpreview": RoutePageConstants.CART_PREVIEW,
    "/search": RoutePageConstants.SEARCH,
    "/testimonials": RoutePageConstants.TESTIMONIAL,
    "/desktop-testimonials": RoutePageConstants.TESTIMONIAL,
    "/notfound": RoutePageConstants.NOT_FOUND,
    "/addons": RoutePageConstants.ADDONS,
    "/_error": RoutePageConstants.SERVER_ERROR,
    "/experiences": RoutePageConstants.EXPERIENCES_PAGE,
  };
  return pageNameObject[pathname] || "";
};

/**
 * This function returns an array of large images for the slick image slider for Desktop Product  card
 *
 * @param {string} url contains product image url
 * @param {number} imageCount number of images of particular product
 * @returns {Array} returns an array of multiple images
 */
export const getProductLargeImgList = (url, imageCount) => {
  if (!url) return ["/assets/images/image_placeholder-bb.png"];
  if (!imageCount) return [`${url.replace("/m/", "/l/")}`];

  const productImages = [];
  for (let index = 1; index <= imageCount; index += 1) {
    let imageUrl = url.replace("/m/", "/l/").split(".jpg")[0];
    const hasExtra = endsWith(imageUrl, "_1");
    if (hasExtra) {
      imageUrl = imageUrl.slice(0, -2);
    }
    productImages.push(`${imageUrl}_${index}.jpg`);
  }

  return productImages;
};

/**
 * This function returns a custom alt attribute value for images of desktop product card
 * For SEO purpose we are adding the logic for first ten products by using breadcrumbs value
 *
 * @param {object} product contains product details
 * @param {Array} breadCrumbs contains breadcrumbs of the category page
 * @param {string} productCategoryName contains product name
 * @param {object} itemIndex contains current product index
 * @returns {string} returns a description of string
 */
export const getProductImgAltValue = (product, breadCrumbs, productCategoryName, itemIndex) => {
  let altMsg = product?.productImage?.altText;
  const intIndex = parseInt(itemIndex, 10);
  if (breadCrumbs.length && productCategoryName && intIndex <= 10) {
    const lastIndex = breadCrumbs.length - 1;
    const breadcrumbVal = breadCrumbs[lastIndex].label;
    altMsg =
      intIndex % 2 === 0
        ? `${product?.productImage?.altText}:${breadcrumbVal?.replace("Online", "").trim()}`
        : `${product?.productImage?.altText}:${productCategoryName?.replace("Online", "").trim()}`;
  }
  return altMsg;
};
/**
 * This method fetches the catalog and category based on the path and country.
 *
 * @param {object} ctx page context
 * @param {string} country Eg: IN or UAE.
 *
 * @returns {object} catalog and country.
 */
export const getCatalogueAndCategory = (ctx, country) => {
  const path = ctx.asPath ? ctx.asPath : ctx;
  const actualPath = path?.split("?");
  let catalogue = "india";
  let category = "";
  if (actualPath?.length > 0) {
    const catalogueCategoryPath = actualPath[0]?.split("/");
    const selectedCountry = catalogueCategoryPath[1];
    const isInternational = catelogueCountries.includes(selectedCountry);
    if (isInternational) {
      catalogue = selectedCountry;
      category = [...catalogueCategoryPath[2]];
    } else if (selectedCountry === "search") {
      const { query } = ctx;
      const obj = getJsonFromQueryString(actualPath[1]);
      catalogue =
        obj?.FNP_CURRENT_CATALOG_ID ?? query?.FNP_CURRENT_CATALOG_ID
          ? query?.FNP_CURRENT_CATALOG_ID
          : geoDefault.rootCatalogId;
      category = "search";
    } else {
      category = [...catalogueCategoryPath[1]];
    }
  }
  if (country === "SG") {
    catalogue = "sgp";
  } else if (country === "UAE") {
    catalogue = "uae";
  }
  return { catalogue, category };
};

/**
 * To find hash in url and then append open status in key value pair to the url else append modal with new hash.
 *
 * @param {string} modal modal to open
 */
export const setHashToRoute = (modal) => {
  const { hash, pathname, search } = window.location;
  const pdpPincodeExceptionHash = ["#/deliveryarea", "#/places-suggestions"];
  const exceptionHash = ["#openSearch", "#lm", "#rm", "#sortm", "#selm", "#giftLocation", "#llp", "#lls"];
  const isModal = exceptionHash.includes(hash);

  if (!hash || isModal || (modal === "openSearch" && !pdpPincodeExceptionHash.includes(hash))) {
    Router.push(`${pathname + search}#${modal}`);
  } else {
    const refinedUrl = new URL(
      removeHashParameterFromURL(window.location.href, ["sortm", "selm", "lm", "rm", "giftLocation", "llp", "lls"]),
    );
    const refinedHash = refinedUrl.hash;
    Router.push(`${pathname + search + refinedHash}&${modal}=open`);
  }
};

/**
 * This method initiates the lazy loading of all images on a page.
 * When the element is in the view port the data-src attributes or data-imageurl attributes
 * values are assigned to src attributes to load the images.
 */
export const lazyLoadImgs = () => {
  const elements = document.querySelectorAll("[data-imageurl],[data-src]");
  if (elements) {
    if ("IntersectionObserver" in window) {
      const imageObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const lazyImage = entry.target;
            if (lazyImage?.dataset?.imageurl) {
              if (lazyImage?.src !== lazyImage.dataset.imageurl) {
                lazyImage.src = lazyImage.dataset.imageurl;
              }
            } else if (lazyImage?.dataset?.src) {
              if (lazyImage.src !== lazyImage.dataset.src) {
                lazyImage.src = lazyImage.dataset.src;
              }
            }
          }
        });
      });
      elements.forEach((v) => imageObserver.observe(v));
    } else {
      elements.forEach((elem) => {
        const element = elem;
        if (element?.dataset?.src) element.src = elem.dataset.src;
        else if (element?.dataset?.imageurl) element.src = elem.dataset.imageurl;
      });
    }
  }
};
/**
 * This method capitalizes the first letter of all the strings
 * in an array or even a single string
 *
 * @param {string} category selected catgory url path,
 * @returns {string} formatted string.
 */
export const capitalize = (category) => {
  const categories = category.split("-");
  categories.forEach((str, index, arr) => {
    // eslint-disable-next-line no-param-reassign
    arr[index] = str.charAt(0).toUpperCase() + str.slice(1);
  });
  const formattedStr = categories.join(" ");
  return formattedStr;
};

/**
 * This function sets the isMicroSite var in window
 *
 * @param {boolean} flag - isMicroSite bool value
 */
export function setIsMicroSite(flag) {
  if (!isUndefined(window)) {
    window[isMicroSiteVarName] = flag;
  }
}
/**
 * Utility method to get formatted date
 *
 * @param {object} date date object
 * @returns {string} date
 */
export function formatDate(date) {
  const allMonth = MONTH_NAMES;
  const month = allMonth[date.getMonth()] ? allMonth[date.getMonth()] : "Not a valid month";
  let gDate = date.getDate();
  gDate = gDate < 10 ? `0${gDate}` : gDate;
  const year = date.getFullYear().toString().substr(-2);
  const fullDate = `${gDate} ${month}' ${year}`;
  return fullDate;
}

/**
 * Utility method to get formatted time with meridian
 *
 * @param {object} date date object
 * @param {boolean} isUTC isUTC format
 * @returns {string} date
 */
export function formatTimeWithMeridian(date, isUTC = false) {
  let meridianTime = "AM";
  let hours = isUTC ? date.getUTCHours() : date.getHours();
  let minutes = isUTC ? date.getUTCMinutes() : date.getMinutes();
  if (hours >= 12 && minutes > 0) {
    meridianTime = "PM";
  }
  hours %= 12;
  hours = hours || 12;
  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  const strTime = `${hours}:${minutes} ${meridianTime}`;
  return strTime;
}

/**
 * Utility method to get formatted date and time without day
 *
 * @param {string} dt date in ms
 * @returns {string} date and time
 */
export function dateAndTimeFormatWithoutDay(dt) {
  if (!dt) {
    return "";
  }
  const d = new Date(dt);
  const fDate = formatDate(d);
  const fTime = formatTimeWithMeridian(d);
  const fullDateTime = `${fDate}, ${fTime}`;
  return fullDateTime;
}

/**
 * This method is used to calculate percentage off product
 *
 * @param {number} listPrice list price.
 * @param {number} price price.
 * @returns {number} percentage
 */
export function calDiscountPercentage(listPrice, price) {
  const priceDifference = parseFloat(listPrice) - parseFloat(price);
  const percentage = (parseFloat(priceDifference) / parseFloat(listPrice)) * 100;
  return Math.floor(percentage);
}

/**
 * This method is used to calculate amount off on the product
 *
 * @param {number} listPrice This is the initial decided price.
 * @param {number} price offer price.
 * @returns {number} returns the amount which has been saved.
 */
export function calDiscountAmount(listPrice, price) {
  return (parseFloat(listPrice) - parseFloat(price)).toFixed(2);
}

/**
 * This method is used to calculate percentage off product
 *
 * @param {string} dateTimeStr date time string.
 * @returns {object} date object
 */
export function convertTimestampStringToDate(dateTimeStr) {
  let date = 0;
  if (dateTimeStr) {
    let dateTimeParts = dateTimeStr.split(" ");
    const timePart = dateTimeParts[1].split(":");
    dateTimeParts = dateTimeParts[0].split("-");
    date = new Date(dateTimeParts[2], parseInt(dateTimeParts[1], 10) - 1, dateTimeParts[0], timePart[0], timePart[1]);
  }
  return date;
}

/**
 * This method is used to create date object from date string
 *
 * @param {string} dateStr date string.
 * @returns {object} date object
 */
export function sanitizeDateStringToDate(dateStr) {
  if (dateStr) {
    const dateParts = dateStr.split("-");
    const [day, month, year] = dateParts;
    return new Date(`${year}/${month}/${day}`);
  }
  return dateStr;
}

/**
 * Converts HH:MM:SS to h:mm AM/PM. 23:59:00 becomes 11:59PM
 *
 * @param {string} ts Time stamp
 * @param {string} tz Time zone, default en
 * @returns {string} formatted timestamp
 */
export function shippingSlotHumanReadable(ts, tz = "en") {
  try {
    return new Date(`${new Date().toDateString()} ${ts}`)
      .toLocaleTimeString(tz, { hour: "2-digit", minute: "2-digit" })
      .toUpperCase();
  } catch (e) {
    /* eslint no-console: ["error", { allow: ["error"] }] */
    const errorLogStatement = `${new Date().toUTCString()} ::::Response log:::: Trace Id: Not Required, Forwarded for: Not Required, Url: Not Required, Status: Not Required, Response message: Unable to format ${ts}. Error: ${
      e.message
    }, Response body: Not Required, Error Object:  Not Required`;
    console.error(errorLogStatement);
    return ts;
  }
}

/**
 * Converts HH:MM:SS to h:mm AM/PM. 23:59:00 becomes 11:59PM
 *
 * @param {string} ts Time stamp
 * @param {string} tz Time zone, default en
 * @returns {string} formatted timestamp
 */
export function shippingSlotHoursHumanReadable(ts, tz = "en") {
  try {
    const [slot, shiftType] = new Date(`${new Date().toDateString()} ${ts}`)
      .toLocaleTimeString(tz, { hour: "2-digit" })
      .toUpperCase()
      .split(" ");
    return `${+slot} ${shiftType}`;
  } catch (e) {
    errorLogger(e);
    return ts;
  }
}
/**
 * This method is used to exclude keys from the object
 *
 * @param {object} object object the that will be modified.
 * @param {Array} keys array of ignored keys.
 * @returns {object} modified object
 */
export function omitObjectKeys(object, keys) {
  return omit(object, keys);
}

/**
 * This method is used to calculate current delivery day
 *
 * @param {object} currentDate current date object.
 * @param {object} deliveryDate deliveryDate Object.
 * @returns {string} modified object
 */
export function getDeliveryDay(currentDate, deliveryDate) {
  const MONTH_NAMES_CAPS = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
  let day;
  const s = new Date(currentDate.getTime() + 1000 * 60 * 60 * 24);

  if (
    parseInt(deliveryDate.getDate(), 10) === currentDate.getDate() &&
    parseInt(deliveryDate.getMonth(), 10) + 1 === currentDate.getMonth() + 1 &&
    parseInt(deliveryDate.getFullYear(), 10) === currentDate.getFullYear()
  ) {
    day = "Today";
  } else if (
    parseInt(deliveryDate.getDate(), 10) === s.getDate() &&
    parseInt(deliveryDate.getMonth(), 10) + 1 === s.getMonth() + 1 &&
    parseInt(deliveryDate.getFullYear(), 10) === s.getFullYear()
  ) {
    day = "Tomorrow";
  } else {
    day = `${deliveryDate.getDate()}, ${MONTH_NAMES_CAPS[parseInt(deliveryDate.getMonth(), 10) + 1 - 1]}`;
  }
  return day;
}

/**
 * This method is used display time lapse clock
 *
 * @param {number} timer timestamp.
 * @returns {string} modified object
 */
export function clock(timer) {
  const delta = Math.floor(timer / 1000);
  const hours = Math.floor(delta / 3600);
  const minutes = Math.floor((delta - 3600 * hours) / 60);
  const seconds = delta - 3600 * hours - 60 * minutes;
  const displayTime = `${(hours < 10 ? "0" : "") + hours}:${minutes < 10 ? "0" : ""}${minutes}:${
    seconds < 10 ? "0" : ""
  }${seconds}`;
  return displayTime;
}

/**
 * This method is used to format the date
 *
 * @param {object} date date object.
 * @param {string} separator format seperator.
 * @returns {string} modified object
 */
export function dateToDMY(date, separator = "-") {
  if (!date) return "";
  const d = date.getDate();
  const m = date.getMonth() + 1;
  const y = date.getFullYear();
  return `${d <= 9 ? `0${d}` : d}${separator}${m <= 9 ? `0${m}` : m}${separator}${y}`;
}

/**
 * Checks if the given date (`selectedDate`) is today or tomorrow.
 *
 * @param {Date} selectedDate - The date that needs to be checked.
 * @param {Date} [today=new Date()] - (Optional) The reference date for "today". Defaults to the current date if not provided.
 *
 * @returns {Object} - Returns an object with two boolean properties:
 *    - `isToday`: `true` if `selectedDate` is today's date, otherwise `false`.
 *    - `isTomorrow`: `true` if `selectedDate` is tomorrow's date, otherwise `false`.
 */
export function isDateTodayOrTomorrow(selectedDate, today = new Date()) {
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  const isDateToday = dateToDMY(today) === dateToDMY(selectedDate);
  const isDateTomorrow = dateToDMY(tomorrow) === dateToDMY(selectedDate);

  return { isDateToday, isDateTomorrow };
}

/**
 * This method is used to generate an array of dates between 2 dates
 *
 * @param {object} startDate starting date of the array
 * @param {object} endDate ending date of the array
 * @returns {Array} array of dates between start date and end date
 */
export const generateDatesArray = (startDate, endDate) => {
  const date = new Date(startDate);
  const dates = [];
  while (date <= endDate) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return dates;
};

/**
 * This method returns a full date string
 *
 * @param {string} timestamp - Date timestamp
 * @param {string} format - Date timestamp
 * @returns {string} a string with details of date
 */
export const getFullDateString = (timestamp, format = "WW, DD MM YY") => {
  const fullDate = new Date(timestamp);
  const weekday = DAY[fullDate.getDay()];
  const date = fullDate.getDate();
  const month = MONTH_NAMES[fullDate.getMonth()];
  const fullMonth = MONTH_FULL_NAMES[fullDate.getMonth()];
  const year = fullDate.getFullYear().toString().slice(-2);
  const fullYear = fullDate.getFullYear();
  const hours = fullDate.getUTCHours();
  const minutes = fullDate.getMinutes();

  switch (format) {
    case "WW, DD MM YY":
      return `${weekday}, ${date} ${month} ${year}`;
    case "DD MM YY":
      return `${date} ${month} ${year}`;
    case "DD, MM":
      return `${date} ${month}`;
    case "DD MM HH:MMM":
      return `${date} ${month} ${hours}:${minutes}`;
    case "DD Month YYYY":
      return `${date} ${fullMonth} ${fullYear}`;
    default:
      return "";
  }
};

/**
 * This method is used to generate an array of dates between 2 dates
 *
 * @param {object} startDate starting date of the array
 * @param {number} daysCount future date-month count
 * @returns {Array} array of dates between start date and end date
 */
export const getDeliveryDates = (startDate, daysCount) => {
  const date = new Date(startDate);
  const endDate = new Date(date.setDate(date.getDate() + daysCount));
  let dateList = generateDatesArray(startDate, endDate);
  dateList = dateList.map((v) => dateToDMY(v));
  return dateList;
};

/**
 * This method is used to generate random character with specified length
 *
 * @param {number} size length of random characters.
 * @returns {string} return random characters
 */
export const generateRandomCharacters = (size) => {
  let generatedOutput = "";
  const storedCharacters = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const totalCharacterSize = storedCharacters.length;
  for (let index = 0; index < size; index += 1) {
    generatedOutput += storedCharacters.charAt(Math.floor(Math.random() * totalCharacterSize));
  }
  return generatedOutput;
};

/**
 * This method is used to lead number with zero
 *
 * @param {number} number number.
 * @returns {string} return modified number
 */
export const leadingZero = (number) => {
  return number <= 9 ? `0${number}` : number;
};

/**
 * This method is used to get foldername of date
 *
 * @returns {string} return date folder
 */
export const getFolderDirectory = () => {
  const currentDate = new Date();
  const date = currentDate.getDate();
  const month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();
  return `${year}${leadingZero(month)}${leadingZero(date)}`;
};

/**
 * This method is used to get supported extensions
 *
 * @param {string} allowedMediaType string.
 * @returns {string} supported extensions.
 */
export const getExtensions = (allowedMediaType) => {
  const data = allowedMediaType.split(",");
  const extensions = [];
  data.forEach((element) => {
    const imageParts = element.split("/");
    const [, extension] = imageParts;
    extensions.push(extension.toUpperCase());
  });
  return extensions.join(", ");
};

/**
 * This method is used to upsert array
 *
 * @param {Array} array array.
 * @param {object} item item that will add or update.
 * @returns {Array} return merged array.
 */
export function upsertArray(array, item) {
  const arrayValues = array;
  const i = arrayValues.findIndex((_item) => _item.id === item.id);
  if (i > -1) arrayValues[i] = item;
  else array.push(item);
  return array;
}

/**
 * This method is used to validate extension
 *
 * @param {string} allowedMediaType comma separated allowed media types.
 * @param {string} currentMediaType current media type.
 * @returns {boolean} return valid or false.
 */
export function validateExtension(allowedMediaType, currentMediaType) {
  const mediaTypeToArray = allowedMediaType.split(", ");
  return mediaTypeToArray.indexOf(currentMediaType) > -1;
}

/**
 * This method is used to check presses key is number or not
 *
 * @param {event} event key press event.
 * @returns {boolean} return valid or false.
 */
export function isNumberPressed(event) {
  const evt = event || window.event;
  const charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
}

/**
 * This method is used to compare two dates check month is passed or not
 *
 * @param {object} firstDate past date.
 * @param {object} secondDate current date.
 * @returns {boolean} return true if it is in past otherwise returns false
 */
export const checkMonthIsPassed = (firstDate, secondDate) => {
  if (firstDate.getMonth() < secondDate.getMonth() && firstDate.getFullYear() === secondDate.getFullYear()) {
    return true;
  }

  return false;
};

/**
 * This method is used to compare two dates check month is passed or not
 *
 * @param {object} date selected date.
 * @param {number} xDays number of days to future.
 * @returns {boolean} return true if it is in past otherwise returns false
 */
export const getNextDates = (date, xDays) => {
  const dates = [];
  if (date instanceof Date && typeof xDays === "number") {
    // eslint-disable-next-line no-plusplus
    for (let I = 0; I < Math.abs(xDays); I++) {
      dates.push(new Date(new Date(date).getTime() - (xDays >= 0 ? I : I - I - I) * 24 * 60 * 60 * 1000));
    }
  }

  return dates;
};

/**
 * Utility method to get formatted date and time without day
 *
 * @param {string} ms date in ms
 * @returns {string} date
 */
export function getDateFromMilliseconds(ms) {
  if (!ms) {
    return "";
  }
  const date = new Date(ms);
  const fDate = formatDate(date);
  return fDate;
}

/**
 *
 * @param {string} time time in 24 hours format
 * @returns {string} in 12 hours format
 */
export const convert24To12Hours = (time) => {
  if (!time) {
    return "";
  }
  // Check correct time format and split into components
  const convertedTime = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
  if (time.length > 1) {
    // If time format correct
    convertedTime.shift(); // remove first element
    convertedTime.pop(); // remove last element
    convertedTime[3] = +convertedTime[0] < 12 ? " AM" : " PM"; // Set AM/PM
    convertedTime[0] = +convertedTime[0] % 12 || 12; // Adjust hoursgetFullDateString
  }
  return convertedTime.join(""); // return adjusted time or original string
};
/**
 *
 * @param {string} time time in 24 hours format
 * @returns {string} in 12 hours format
 */
export function convertTime24Hours(time) {
  if (!time) {
    return "";
  }
  let meridianTime = "AM";
  const splittedTime = time.split(":");
  let hours = splittedTime[0];
  const minutes = splittedTime[1];
  if (hours >= 12 && minutes > 0) {
    meridianTime = "PM";
  }
  hours %= 12;
  hours = hours || 12;
  hours = hours < 10 ? `0${hours}` : hours;
  const strTime = `${hours}:${minutes} ${meridianTime}`;
  return strTime;
}

/**
 * fetches delivery time slot
 *
 * @param {object} itemShippingDetails - date object
 * @param {string} format delivery time format
 * @returns {string} Delivery Time
 */
export const getDeliveryTimeSlot = (itemShippingDetails, format = "") => {
  const hoursFrom = itemShippingDetails?.deliveryTimeSlot.slice(0, 5);
  const hoursTo = itemShippingDetails?.deliveryTimeSlot.slice(8, 13);
  const fromText = convert24To12Hours(hoursFrom);
  const toText = convert24To12Hours(hoursTo);
  if (format === "12hrsFormat") {
    return `${fromText} - ${toText}`;
  }

  return `${fromText} - ${toText} Hrs`;
};

/**
 *
 * @param {string} pathParam path of url
 * @returns {string} category name
 */
export function getMicrositeCategoryName(pathParam) {
  return pathParam
    .toLowerCase()
    .replace(/[-/]/g, " ")
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    .join(" ");
}

/**
 * @param {number} currYear current year
 * @param {number} lastNYears array length required
 * @returns {Array} category name
 */
export function getLastNYears(currYear, lastNYears) {
  const years = [];
  for (let i = 0; i <= lastNYears; i += 1) {
    const year = currYear - i;
    years.push({ year });
  }
  return years;
}

/**
 * Utility method to get formatted date
 *
 * @param {object} date date object
 * @returns {string} date
 */
export function getFormattedDateMonthAndYear(date) {
  const allMonth = MONTH_NAMES;
  const month = allMonth[date.getMonth()] ? allMonth[date.getMonth()] : "Not a valid month";
  let gDate = date.getDate();
  gDate = gDate < 10 ? `0${gDate}` : gDate;
  const year = date.getFullYear().toString().substr(-2);

  return { date: gDate, year, month };
}

/**
 * this function filters the personalized options and returns the option for multi image uploader
 *
 * @param {Array} personalizedData array of personalized input options
 * @returns {object} object of multi image personalized input
 */
export const getMultiImageUploadOption = (personalizedData) => {
  return personalizedData.find((option) => {
    if (option.contentType === "IMAGE" && option.maxInputRequired > 1) {
      return option;
    }
    return {};
  });
};

const basicHeaders = {
  "Access-Control-Allow-Credentials": "true",
  "Access-Control-Allow-Headers": "Content-Type",
  "Content-Type": "text/html;charset=UTF-8",
  "X-made-with": "Made with <3 in India",
};

/**
 * @param {object} cacheHeaders response headers from api
 * @returns {object} headers to be set
 */
function getCacheControlHeaders(cacheHeaders) {
  const cacheHeaderArr = cacheHeaders.split(",");

  const updatedCacheHeaderArr = cacheHeaderArr?.map((item) => (item?.includes("max-age") ? "max-age=0" : item));

  return updatedCacheHeaderArr?.join(",") || cacheHeaders;
}

/**
 * @param {object} resHeaders response headers from api
 * @returns {object} headers to be set
 */
export const getCacheHeaders = (resHeaders) => {
  const headersToSet = {
    ...basicHeaders,
    "cache-control": getCacheControlHeaders(resHeaders["cache-control"]),
    "edge-control": resHeaders["edge-control"],
    "edge-cache-tag": resHeaders["edge-cache-tag"],
    expires: resHeaders.expires || resHeaders.Expires,
  };
  return headersToSet;
};

/**
 * @param {string} selectedDay contains date object
 * @param {Array} deliveryDatesList contains available date list
 * @returns {object} contains daterange info
 */
export const getDatesAround = (selectedDay, deliveryDatesList = []) => {
  const index = deliveryDatesList.indexOf(selectedDay);
  if (index === -1) return {};
  const dateRangeData = {
    endRangeDate: deliveryDatesList[index + 1],
    startRangeDate: deliveryDatesList[index > 0 ? index - 1 : index],
    selectedDate: deliveryDatesList[index],
  };

  return dateRangeData;
};

/**
 * This function returns formatted date string
 *
 * @param {number} DMY contains DMY formatted date string
 * @returns {string} returns formatted string value
 */
export const getDateRangeShortMonth = (DMY) => {
  const sanitizedDate = sanitizeDateStringToDate(DMY);
  const day = sanitizedDate.getDay();
  const month = sanitizedDate.getMonth();
  const date = sanitizedDate.getDate();
  return `${DAY[day]}, ${date} ${MONTH_NAMES[month]}`;
};

/**
 * This function returns formatted date string
 *
 * @param {number} userSelectedDate user selected date
 * @param {number} dateList available dates
 * @returns {object} returns formatted string value
 */
export const getCourierDateRange = (userSelectedDate, dateList) => {
  const { endRangeDate, startRangeDate } = getDatesAround(userSelectedDate, dateList);
  const startDate = startRangeDate && getDateRangeShortMonth(startRangeDate);
  const endDate = endRangeDate && getDateRangeShortMonth(endRangeDate);
  return { startDate, endDate };
};

/**
 * This function will return date and time in millisecods without hours.
 *
 * @param {string} DMY contains the formatted date string.
 * @returns {object} contains the date and time.
 */
export const getDateRangeTimeWithoutHours = (DMY) => {
  const date = sanitizeDateStringToDate(DMY);
  date.setHours(0, 0, 0, 0);
  return { day: date?.getDay(), date: date?.getDate(), timeInMS: date.getTime(), month: date.getMonth() };
};

/**
 * This function will return the no.of days in the range.
 *
 * @param {number} date1 first date
 * @param {number} date2 second date
 * @returns {number} returns no.of days in gap.
 */
export const getDayCountInRange = (date1, date2) => {
  const gap = date1 > date2 ? date1 - date2 : date2 - date1;
  return gap / (1000 * 60 * 60 * 24);
};

/**
 * This function returns formatted date string
 *
 * @param {number} timeStamp user selected timeStamp
 * @returns {string} returns formatted string value
 */
export const getDateStringFromTimeStamp = (timeStamp) => {
  const fullDate = new Date(timeStamp);
  return `${fullDate.getDate() < 10 ? `0${fullDate.getDate()}` : fullDate.getDate()}-${
    fullDate.getMonth() + 1 < 10 ? `0${fullDate.getMonth() + 1}` : fullDate.getMonth() + 1
  }-${fullDate.getFullYear()}`;
};

/**
 * This function returns next dynamic slot
 *
 * @param {number} interval time interval
 * @param {number} leadTime lead time
 * @returns {Array} returns start and end date of dynamic slot
 */
export const calculateNextCycleSlot = (interval, leadTime = 1) => {
  const timeStampCurrentOrOldDate = Date.now();
  const timeStampStartOfDay = new Date().setHours(0, 0, 0, 0);
  const timeDiff = timeStampCurrentOrOldDate - timeStampStartOfDay;
  const mod = Math.ceil(timeDiff / interval);
  const roundedTime = new Date(timeStampStartOfDay + mod * interval);
  const startTime = roundedTime.toLocaleTimeString([], { hour12: false, hour: "2-digit", minute: "2-digit" });
  roundedTime.setHours(roundedTime.getHours() + leadTime);
  const endTime = roundedTime.toLocaleTimeString([], { hour12: false, hour: "2-digit", minute: "2-digit" });
  return [startTime, endTime];
};

/**
 * This function returns a flag, the lead time is more than 24 hrs or not
 *
 * @param {object} shippingDetails holds shipping details info
 * @returns {boolean} returns lead time flag.
 */
export const isLeadtimeMoreThan24 = (shippingDetails) => {
  const timeSlots = shippingDetails?.COURIER?.timeSlots;
  if (timeSlots?.length) {
    return timeSlots[0]?.isShowDateRange;
  }
  return false;
};

/**
 * This function returns date range
 *
 * @param {object} dateArray dateArray
 * @returns {object} returns date object.
 */
export const getCourierDateRangeFromDates = (dateArray) => {
  const dateRangeData = {};
  if (dateArray?.length > 1) {
    dateRangeData.startDate = getDateRangeShortMonth(dateArray[0]);
    dateRangeData.endDate = getDateRangeShortMonth(dateArray[1]);
    return dateRangeData;
  }
  return null;
};

/**
 *  To check whether its DSITE or MSITE
 *
 * @returns {string} DSITE or MSITE
 */
export const getPlatform = () => {
  if (window.dSite) {
    return "DSITE";
  }
  return "MSITE";
};

/**
 * To check if passed string is a json string
 * Function to format deliver date from cookie
 *
 * @param {string} date date in the cookie
 * @returns {string} returns formatted date.
 */
export const formatDeliveryDate = (date) => {
  const newDate = new Date(date);
  return `${DAY_FULL_NAME[newDate.getDay()]}, ${newDate.getDate()} ${
    MONTH_FULL_NAMES[newDate.getMonth()]
  } ${newDate.getFullYear()}`;
};

/**
 * Function to format deliver date from cookie for multi order products
 *
 * @param {string} date date in the cookie
 * @param {number} noOfDays date in the cookie
 * @returns {string} returns formatted date.
 */
export const formatMultiOrderDeliveryDate = (date, noOfDays) => {
  const newDate = new Date(date);
  const formattedStartDate = `${newDate.getDate()} ${MONTH_NAMES[newDate.getMonth()]}`;

  const timeStamp = newDate.setDate(newDate.getDate() + noOfDays);
  const endDate = new Date(timeStamp);
  const formattedEndDate = `${endDate.getDate()} ${MONTH_NAMES[endDate.getMonth()]}`;
  return `${formattedStartDate} - ${formattedEndDate}`;
};

/**
 *
 * @param {Date} startDate date from which delivery will start
 * @param {number} noOfDays for how long delivery should continue
 * @param {string} type frequency tye e.g daily
 * @returns {Array} array for multi order date range
 */
export const getMultiOrderDateRange = (startDate, noOfDays, type) => {
  if (!noOfDays || !startDate) return [];
  const MultiOrderDateRange = [];
  let daysCount = noOfDays;
  if (type === "DAILY") {
    const date = new Date(startDate);
    while (daysCount > 0) {
      MultiOrderDateRange.push(dateToDMY(new Date(date)));
      date.setDate(date.getDate() + 1);
      daysCount -= 1;
    }
  }
  return MultiOrderDateRange;
};

/** To check if passed string is a json string
 *
 * @param {string} str json string
 *
 * @returns {boolean} true or false depending upon if string can be parsed
 */
export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

/**
 * Function to check if passed string has number only.
 *
 * @param {string} str string we get on onchange of input field.
 * @returns {boolean} is number or not.
 */
export const hasOnlyNumbers = (str) => {
  if (str && !/^\d+$/.test(str)) {
    return false;
  }
  return true;
};

/**
 * Function to get filtered city list
 *
 * @param {string} value value of input.
 * @param {Array} cityListData citylist
 * @returns {Array} filtered list of cities.
 */
export const getFilteredCitiesList = (value, cityListData) => {
  let filteredCitiesInclude = [];
  if (!value) {
    return filteredCitiesInclude;
  }
  const filteredCitiesStartsWith = cityListData?.filter((city) =>
    city.cityName.toLowerCase().startsWith(value.toLowerCase()),
  );

  if (filteredCitiesStartsWith.length < 5) {
    filteredCitiesInclude = cityListData?.filter((city) => city.cityName.toLowerCase().includes(value.toLowerCase()));
  }
  return [...filteredCitiesStartsWith, ...filteredCitiesInclude].reduce((unique, cityObj) => {
    if (!unique.some((obj) => obj.cityId === cityObj.cityId && obj.cityName === cityObj.cityName)) {
      unique.push(cityObj);
    }
    return unique;
  }, []);
};

/**
 * utility function to covert json in array for gift finder fields
 *
 * @param {Array} arr json string
 *
 * @returns {Array} converted array for gift finder fields
 */
export const convertJson = (arr) => {
  const convertedArray = [];
  arr?.forEach((item) => {
    convertedArray.push(item.value);
  });
  return convertedArray;
};

/**
 * utility function to covert json into  object
 *
 * @param {Array} arr json string
 * @returns {object} true or false depending upon if string can be parsed
 */
export const convertPriceTag = (arr) => {
  const prices = {};
  arr?.forEach((item) => {
    prices[item.displayName] = {
      startPrice: Number(item.priceFrom),
      endPrice: Number(item.priceTo),
      available: item.count > 0,
    };
  });
  return prices;
};

/**
 * This function returns final price range.
 *
 * @param {string} price is the price range
 * @param {number} highestPrice is the highest price coming from api response
 * @returns {string} returns price range string
 */
export const sortedPriceRange = (price, highestPrice) => {
  if (price === "") return "";
  const [currentMin, currentMax] = price.split(" TO ");

  return currentMax <= highestPrice
    ? `${Math.trunc(currentMin).toFixed(1)} TO ${Math.trunc(currentMax).toFixed(1)}`
    : `${Math.trunc(currentMin).toFixed(1)} TO *`;
};

/**
 * This function converts if prices are above thresholdPrice as covert that into "Above"
 *
 * @param {string} priceRange is the price range
 * @param {number} thresholdPrice is the maximum price available
 * @returns {string} returns priceRange
 */
export const transformInput = (priceRange, thresholdPrice) => {
  const pattern = /(\d+)\s*:\s*(\d+)/;
  const match = priceRange.match(pattern);
  if (match) {
    if (match[1] >= thresholdPrice) {
      return `${match[1]} : Above`;
    }

    return priceRange;
  }

  return priceRange;
};

/**
 * This method is used to generate jsx along with date value to be shown in date field
 *
 * @param  {object} selectedDeliveryDate select delivery date
 * @param {boolean} isMultiOrderProduct true if multiOrder product
 * @param {Array} multiOrderDateRange date range array for multi order product e.g valentine week product
 * @returns {React.element} array of dates between start date and end date
 */
export const updatedDeliveryDate = (selectedDeliveryDate, isMultiOrderProduct, multiOrderDateRange) => {
  if (!selectedDeliveryDate) return null;
  const updatedSelectedDeliveryDate = getFullDateString(selectedDeliveryDate, "DD Month YYYY");
  const dateArray = updatedSelectedDeliveryDate.split(" ");
  const suffix = getSuffix(dateArray[0]);
  if (isMultiOrderProduct && multiOrderDateRange?.length > 0) {
    const deliveryDatesRange = multiOrderDateRange.map((date) => new Date(sanitizeDateStringToDate(date)));
    const startDay = deliveryDatesRange[0].getDate();
    const startMonth = deliveryDatesRange[0].toLocaleString("default", {
      month: "short",
    });
    const lastIndex = deliveryDatesRange.length - 1;
    const endDay = deliveryDatesRange[lastIndex].getDate();
    const endMonth = deliveryDatesRange[lastIndex].toLocaleString("default", {
      month: "short",
    });

    return <span style={{ color: "#222222" }}>{`${startDay} ${startMonth} - ${endDay} ${endMonth}`}</span>;
  }
  return (
    <span style={{ color: "#222222" }}>
      {dateArray[0]}
      <sup>{suffix}</sup>
      &nbsp;
      {dateArray[1]}
      &nbsp;
      {dateArray[2]}
    </span>
  );
};

/**
 * This method calculates wallet remaining balance, used balance.
 *
 * @param {number}initialAmount amount in cart
 * @param {number}walletAmount amount in wallet
 * @returns {object} -list of dependencies
 */
export const iswalletCalculate = (initialAmount, walletAmount) => {
  let amountUsed;
  let availableBalance;

  if (initialAmount <= walletAmount) {
    amountUsed = initialAmount;
    availableBalance = walletAmount - initialAmount;
  } else {
    amountUsed = walletAmount;
    availableBalance = 0;
  }

  return {
    amountUsed,
    availableBalance,
  };
};

/**
 * This function is used for redirection from pdp in case of View available gifts CTA comes for a pincode entered.
 *
 * @param {string}redirectToPlp redirectToPlp.
 * @param {string}redirectToGiftsLp redirectToPlp.
 * @returns {string} path.
 */
export const redirectFromPdp = (redirectToPlp, redirectToGiftsLp) => {
  if (redirectToPlp.includes("-lp")) {
    return redirectToPlp;
  }

  return redirectToGiftsLp;
};

/**
 * Function to filter out null, undefined, and empty string values from an object
 *
 * @param {object} obj contains object to be filtered
 * @returns {object} filtered object
 */
export const removeEmptyNullAndUndefinedFromObject = (obj) => {
  return Object.fromEntries(
    // eslint-disable-next-line no-unused-vars
    Object.entries(obj).filter(([_, value]) => value !== null && value !== undefined && value !== ""),
  );
};

/**
 * Determines the delivery type based on the primary category ID.
 *
 * @param {string} primaryCategoryId The primary category ID of the product.
 * @returns {string} The delivery type ('express' or 'courier').
 */
export const getProductDeliveryType = (primaryCategoryId) => {
  if (primaryCategoryId === productTypes.EXPRESS || primaryCategoryId === productTypes.PERSONALIZED) {
    return productTypes.EXPRESS;
  }
  return productTypes.COURIER;
};

export const OneTapLoginConstants = {
  CLIENT_ID: "746155480363-625uap16ecpl7u9s2i14uqg1tiri9nch.apps.googleusercontent.com",
  REDIRECT_PATH: "/control/googleResponse",
};

/**
 * Checks whether the provided HTML string contains an <img> tag.
 *
 * This function parses the given HTML string using the HTMLReactParser library,
 * and searches for the presence of an <img> tag within the content. It uses a
 * callback function to inspect each element as the string is parsed. If an <img>
 * tag is found, a flag (`hasImage`) is set to true. The function returns this flag,
 * indicating whether or not an <img> tag exists in the provided HTML.
 *
 * @param {string} htmlString - The HTML content as a string.
 * @returns {boolean} - Returns true if an <img> tag is found in the HTML string, otherwise false.
 */
export const containsImageTag = (htmlString) => {
  let hasImage = false;

  HTMLReactParser(htmlString, {
    replace: ({ name }) => {
      if (name === "img") {
        hasImage = true;
      }
    },
  });

  return hasImage;
};

/**
 * This function calculates the time left until a given end date.
 *
 * @param {string} endDate - The end date.
 *  @returns {object} An object with the time left in hours, minutes, and seconds.
 */
export const calculateTimeLeft = (endDate) => {
  const now = new Date();
  const end = new Date(endDate);
  const difference = end - now;

  let timeLeft = { hours: 0, minutes: 0, seconds: 0 };

  if (difference > 0) {
    const hours = Math.floor(difference / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    timeLeft = {
      hours,
      minutes,
      seconds,
    };
  }

  return timeLeft;
};

/**
 * Determines the delivery date.
 *
 * @param {string} deliveryDates The dateString of the product.
 * @returns {Date} The delivery Date.
 */
export const processDeliveryDate = (deliveryDates) => {
  let dateObj = new Date(0);
  if (deliveryDates && typeof deliveryDates === "string") {
    let dateParts;
    if (deliveryDates.includes("-")) {
      dateParts = deliveryDates.split("-");
    } else if (deliveryDates.includes("/")) {
      dateParts = deliveryDates.split("/");
    } else {
      return dateObj;
    }
    if (dateParts.length === 3) {
      dateObj = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
      return dateObj;
    }
    return dateObj;
  }
  return dateObj;
};
