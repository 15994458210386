import HomeActionsConstants from "../action-constants/home-actions-constants";

/**
 * Action for requesting home page body data
 *
 * @param {object} payload contains content options
 * @returns {object} returns action
 */
export const requestHomePageBodyData = (payload) => ({
  type: HomeActionsConstants.SERVER_REQUEST_MOBILE_HOME_CONTENT_DATA,
  payload,
});

/**
 * Action to set home page body data
 *
 * @param {string} payload contains content data
 * @returns {object} returns action
 */
export const setHomePageBodyData = (payload) => ({
  type: HomeActionsConstants.SERVER_SET_MOBILE_HOME_CONTENT_DATA,
  payload,
});

/**
 * Action to set home page content error
 *
 * @param {string} payload contains error data
 * @returns {object} returns action
 */
export const errorHomePageBodyData = (payload) => ({
  type: HomeActionsConstants.SERVER_ERROR_MOBILE_HOME_CONTENT_DATA,
  payload,
});

/**
 * Action for requesting home page accordion data
 *
 * @param {string} payload contains accordion endpoint
 * @returns {object} returns action
 */
export const requestHomePageAccordionData = (payload) => ({
  type: HomeActionsConstants.SERVER_REQUEST_MOBILE_HOME_ACCORDION_DATA,
  payload,
});

/**
 * Action to set home page accordion data
 *
 * @param {string} payload contains accordion data
 * @returns {object} returns action
 */
export const setHomePageAccordionData = (payload) => ({
  type: HomeActionsConstants.SERVER_SET_MOBILE_HOME_ACCORDION_DATA,
  payload,
});

/**
 * Action to set home page accordion error
 *
 * @param {string} payload contains error data
 * @returns {object} returns action
 */
export const errorHomePageAccordionData = (payload) => ({
  type: HomeActionsConstants.SERVER_ERROR_MOBILE_HOME_ACCORDION_DATA,
  payload,
});

/**
 * Action for requesting home page footer data
 *
 * @param {string} payload contains footer contentId
 * @returns {object} returns action
 */
export const requestHomePageFooterData = (payload) => ({
  type: HomeActionsConstants.SERVER_REQUEST_DESKTOP_HOME_FOOTER_DATA,
  payload,
});

/**
 * Action to set home page footer data
 *
 * @param {string} payload contains footer data
 * @returns {object} returns action
 */
export const setHomePageFooterData = (payload) => ({
  type: HomeActionsConstants.SERVER_SET_DESKTOP_HOME_FOOTER_DATA,
  payload,
});

/**
 * Action to set home page footer error
 *
 * @param {string} payload contains error data
 * @returns {object} returns action
 */
export const errorHomePageFooterData = (payload) => ({
  type: HomeActionsConstants.SERVER_ERROR_DESKTOP_HOME_FOOTER_DATA,
  payload,
});

/**
 * Action to set home page error message
 *
 * @param {string} payload contains error data
 * @returns {object} returns action
 */
export const errorMessageHomePage = (payload) => ({
  type: HomeActionsConstants.SERVER_SET_ERROR_MESSAGE,
  payload,
});

/**
 * Action to set home page error message
 *
 * @param {string} payload contains error data
 * @returns {object} returns action
 */
export const setTrendingNowWidget = (payload) => ({
  type: HomeActionsConstants.SERVER_SET_TRENDING_NOW_WIDGET,
  payload,
});
