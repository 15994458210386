import https from "https";
import getConfig from "next/config";
import axios from "./index";

const { serverRuntimeConfig } = getConfig();
const apiUrl = serverRuntimeConfig.API_URL;

const agent = new https.Agent({
  rejectUnauthorized: false,
});

export default axios({
  baseURL: apiUrl,
  withCredentials: true,
  httpsAgent: agent,
});
