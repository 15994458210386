import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import { InputBase, InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  inputStyle: {
    border: "1px solid",
    borderColor: theme.color.lightBlack,
    borderRadius: "4px",
    padding: "10px",
    height: "40px",
    width: "100%",
    fontSize: "14px",
    color: theme.color.darkBlack,
    "&.Mui-focused": {
      borderColor: theme.color.orange,
    },
    "&::placeholder": {
      color: theme.color.lightGrey,
    },
  },
  mInputStyle: {
    height: "32px",
    width: "180px",
    fontSize: "12px",
    marginTop: "10px",
  },
  searchIcon: {
    color: theme.color.lightGrey,
    fontSize: "24px",
    marginRight: "10px",
  },
}));
/**
 * This functional component is reusable search component for filter.
 *
 * @param {object} props - Component props
 * @param {object} props.filterName - placeHolderName
 * @param {string} props.searchTerm - filter value
 * @param {boolean} props.isMobile - device type
 * @param {Function} props.changeSearchTerm - changeSearchTerm
 * @returns {React.component} return input box.
 */
const SearchFilter = ({ filterName, searchTerm, changeSearchTerm, isMobile = false }) => {
  const classes = useStyles();
  return (
    <>
      <InputBase
        className={`${classes.inputStyle} ${isMobile ? classes.mInputStyle : ""}`}
        aria-label="filter-search-box"
        placeholder={filterName}
        value={searchTerm}
        startAdornment={(() => (
          <InputAdornment>
            <SearchIcon className={classes.searchIcon} />
          </InputAdornment>
        ))()}
        onChange={(e) => {
          changeSearchTerm(e.target.value);
        }}
      />
    </>
  );
};
SearchFilter.propTypes = {
  filterName: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired,
  changeSearchTerm: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};
SearchFilter.defaultProps = {
  isMobile: false,
};
export default SearchFilter;
