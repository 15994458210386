import React from "react";
import PropTypes from "prop-types";
import style from "./info-toolbar/info-toolbar.module.scss";
import dheaderStyle from "../styles/d-header.module.scss";
import { CALL_US_LABEL } from "../../../common/constants";

/**
 * Component is info strip of Dheader.
 *
 * @param {string} root .
 * @param {string} root.description the detail of has shown on the call us.
 * @param {string} root.link details of formatted number to redirect.
 * @returns {React.ReactElement} return the call us component
 */
function CallUsStrip({ description, link }) {
  return (
    <a
      className={`${style["info-links"]} ${style["phone-number"]}  ${style.black} ${dheaderStyle["align-items"]}`}
      href={`tel:${link}`}
    >
      <i className="material-icons">phone</i>
      {`${CALL_US_LABEL} ${description}`}
    </a>
  );
}

CallUsStrip.propTypes = {
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default CallUsStrip;
