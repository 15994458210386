import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import { Button, createMuiTheme, ThemeProvider } from "@material-ui/core";

const useStyles = makeStyles({
  paper: {
    margin: "0 20px 0 20px",
  },
  button: {
    padding: "10px 5px",
    border: "1px solid #999",
    borderRadius: 4,
    textAlign: "center",
    minWidth: 100,
    textTransform: "uppercase",
    margin: 10,
    cursor: "pointer",
    "&:hover": {
      background: "#666",
      color: "#fff",
    },
  },
  dialogueContentRoot: {
    paddingBottom: 13,
  },
  dialogueActionRoot: {
    paddingTop: 0,
    paddingBottom: 15,
  },
});

export const theme = createMuiTheme({
  overrides: {
    MuiButtonBase: {
      root: {
        fontWeight: 500,
      },
    },
  },
});

/**
 *
 * Component that will take confirmation before deleting item
 *
 * @param {*} param0 all the props passed to the component
 * @param {Function} param0.handleClickClose function that will be called when dialog needs to be closed.
 * @param {boolean} param0.open prop to open or close modal.
 * @param {number} param0.itemIndex index number of item you want to delete
 * @param {string} param0.textContent text to show in delete modal
 * @param {boolean} param0.isMainItemDeleted boolean flag to check if the delete button is clicked for the main  item
 * @param {string} param0.productId  contains product details for instance product id
 * @returns {React.ReactElement} jsx for the delete confirm component.
 *
 */
const Delete = ({
  handleClose,
  open,
  itemIndex,
  textContent,
  isMainItemDeleted,
  productId,
  isCdpEnabled,
  ...props
}) => {
  const classes = useStyles(props);
  const cartId = useSelector((state) => (isCdpEnabled ? state.userDetails.cartId : ""));

  useEffect(() => {
    const desktopBackDrop = document.getElementById("backdrop_wrapper");
    if (open && desktopBackDrop) {
      desktopBackDrop.style.background = "#00000000";
    }

    return () => {
      if (desktopBackDrop) {
        desktopBackDrop.style.background = "#000000cc";
      }
    };
  }, [open]);

  return (
    <div>
      <Dialog
        classes={{ paper: classes.paper }}
        style={{ backgroundColor: "rgba(0, 0, 0, 0.60)" }}
        open={open}
        onClose={handleClose}
      >
        <div
          className="pop-up-msg reveal"
          id="prod-del"
          role="dialog"
          aria-hidden="false"
          data-yeti-box="prod-del"
          data-resize="prod-del"
          data-tabindex="-1"
          data-item-index={itemIndex}
        >
          <ThemeProvider theme={theme}>
            <DialogContent className={classes.dialogueActionRoot}>
              <DialogContentText
                style={{
                  fontSize: 14,
                  color: "#222",
                  fontWeight: 400,
                  fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                  textAlign: "center",
                  lineHeight: "24px",
                }}
              >
                {textContent}
              </DialogContentText>
            </DialogContent>
            <DialogActions classes={{ root: classes.dialogueActionRoot }}>
              <Grid container justify="center">
                <Button
                  onClick={() => {
                    handleClose("Y");
                  }}
                  style={{
                    border: "1px solid #999",
                    margin: 10,
                    borderRadius: 4,
                    padding: "10px 5px",
                    minWidth: 100,
                    textAlign: "center",
                    textTransform: "uppercase",
                    cursor: "pointer",
                  }}
                  className={isMainItemDeleted ? `${classes.button} mainItemToDelete` : classes.button}
                  data-id={isMainItemDeleted ? productId : ""}
                  data-cdp={isCdpEnabled ? "removeItem" : ""}
                  data-cart={cartId}
                >
                  Yes
                </Button>

                <Button
                  onClick={() => {
                    handleClose("N");
                  }}
                  style={{
                    border: "1px solid #999",
                    margin: 10,
                    borderRadius: 4,
                    padding: "10px 5px",
                    minWidth: 100,
                    textAlign: "center",
                    textTransform: "uppercase",
                    cursor: "pointer",
                  }}
                  className={classes.button}
                >
                  No
                </Button>
              </Grid>
            </DialogActions>
          </ThemeProvider>
        </div>
      </Dialog>
    </div>
  );
};
Delete.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  itemIndex: PropTypes.number,
  textContent: PropTypes.string.isRequired,
  isMainItemDeleted: PropTypes.bool.isRequired,
  productId: PropTypes.string,
  isCdpEnabled: PropTypes.bool,
};

Delete.defaultProps = {
  productId: "",
  itemIndex: -1,
  isCdpEnabled: false,
};

export default Delete;
