import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styles from "./app-redirection-banner.module.scss";

/**
 * This component is for app redirection banner to app/play store.
 *
 * @param {object} root0  props passed to the component .
 * @param {string} root0.setShowAppBanner prop for closing the banner when click on close icon.
 * @param {string} root0.imgWidth prop for image width.
 * @param {string} root0.imgHeight prop for image height.
 * @param {string} root0.imgUrl prop for image url.
 * @param {string} root0.redirectionUrl prop for redirection url to app/store.
 * @param {Function} root0.setBannerDisappearDetect prop to set app redirect banner is in view or not.
 * @returns {React.ReactElement} main JSX for the component.
 */
const AppRedirectionBanner = ({
  setShowAppBanner,
  imgWidth,
  imgHeight,
  imgUrl,
  redirectionUrl,
  setBannerDisappearDetect,
}) => {
  const [isBannerVisible, setIsBannerVisible] = useState(true);
  const bannerRef = useRef(null);
  const paddingTopValue = (imgHeight / imgWidth) * 100;

  /**
   * This method is used to close the app redirection banner when click on cancel icon.
   */
  const handleCloseBannerClick = () => {
    setShowAppBanner(false);
    setBannerDisappearDetect(true);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        setIsBannerVisible(entry.isIntersecting);
      },
      {
        root: null,
        threshold: 0,
      },
    );

    if (bannerRef.current) {
      observer.observe(bannerRef.current);
    }

    return () => {
      if (bannerRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(bannerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!isBannerVisible) {
      setBannerDisappearDetect(true);
    } else {
      setBannerDisappearDetect(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBannerVisible]);

  return (
    <>
      <div className={`${styles.bannerContainer} `} data-testid="banner" ref={bannerRef}>
        <a href={redirectionUrl} style={{ paddingTop: `${paddingTopValue}%` }}>
          <img src={imgUrl} alt="banner" />
        </a>
        <div
          className={styles.crossIcon}
          onClick={handleCloseBannerClick}
          onKeyDown={handleCloseBannerClick}
          tabIndex={0}
          role="button"
          aria-label="Close button"
        />
      </div>
    </>
  );
};
AppRedirectionBanner.propTypes = {
  setShowAppBanner: PropTypes.func.isRequired,
  imgWidth: PropTypes.number.isRequired,
  imgHeight: PropTypes.number.isRequired,
  imgUrl: PropTypes.string.isRequired,
  redirectionUrl: PropTypes.string.isRequired,
  setBannerDisappearDetect: PropTypes.func.isRequired,
};

export default AppRedirectionBanner;
