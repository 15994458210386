import { ButtonBase, Checkbox, Typography } from "@material-ui/core";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import styles from "./styles/wallet-CheckBox.module.scss";
import { textConst } from "../../const/text-english";
import { CheckoutRoutes } from "../../src/action-constants/checkout-constants";
import { cleverTapCommonEvents } from "../../src/analytics/clevertapConstants";
import callClevertapEvent from "../../src/analytics/clevertapUtility";
import UsableCashTooltip from "./usableCashTooltip";

const FNPCashText = dynamic(() => import("./fnpCashText"), { ssr: false });

/**
 * This method displays wallet checkbox and allow check and uncheck of balance.
 *
 * @param {object} props It contains all properties passed to it.
 * @returns {React.ReactElement} jsx for the current wallet CheckBox.
 */
const WalletCheckBox = (props) => {
  const router = useRouter();
  const {
    setAvailableAmount,
    onStateChange,
    availableAmount,
    checked,
    disabled,
    CurrencyCheckBoxStyle,
    cleverTapCartCommonData,
  } = props;
  const { isMobile } = useSelector((state) => state.appConfigs);
  const [disabledState, setDisabledState] = useState(false);
  const desktopPaymentChecked =
    (!isMobile && router?.query?.slug1 === CheckoutRoutes.PAYMENT_OPTIONS) ||
    (!isMobile && router.query?.slug1 === CheckoutRoutes.RETRY_PAYMENT);
  const cashValueRX = useSelector((state) => state.walletDetails?.walletValue?.balance) || 0;
  const usableBalance = useSelector((state) => state.walletDetails?.walletUsableBalance?.usableBalance) || 0;
  /**
   * handling checkout change
   *
   * @param {object} e event.RETRY_PAYMENT
   */
  const handleChange = (e) => {
    onStateChange(e.target.checked);
  };

  /**
   * handle name card show click
   */
  const handleNameCardClick = () => {
    if (!checked) {
      const walletCleverTapData = {
        is_cart_empty: undefined,
        login_status: undefined,
        available_fnp_cash: availableAmount.availableBal,
        fnp_cash_discount: availableAmount.amountUse,
        ...cleverTapCartCommonData,
      };
      callClevertapEvent(cleverTapCommonEvents.fnpCashApplied, walletCleverTapData);
    }

    setAvailableAmount({
      ...availableAmount,
      amountUse: availableAmount?.amountUse,
      availableBal: availableAmount?.availableBal,
    });
    onStateChange(!checked);
  };

  /**
   * handle disable click on currency change
   */
  const handleDisableClick = () => {
    if (disabled) setDisabledState(true);
    setTimeout(() => {
      setDisabledState(false);
    }, textConst.checkout.priceDetails.transitionTimeout);
  };

  return (
    <>
      <div
        className={desktopPaymentChecked ? styles.desktopPaymentStyle : styles.box}
        style={{
          ...CurrencyCheckBoxStyle,
          opacity: usableBalance === 0 ? "0.5" : "1",
          pointerEvents: usableBalance === 0 ? "none" : "auto",
        }}
        onClick={handleDisableClick}
        role="presentation"
        data-Testid="wallet-checkbox"
      >
        <div className={styles.checkBoxStyle}>
          <ButtonBase
            onClick={handleNameCardClick}
            disableRipple
            className={styles["checkbox-container"]}
            disabled={usableBalance === 0}
          >
            <Checkbox
              className={styles.colorPrimary}
              checked={checked}
              onChange={handleChange}
              disabled={usableBalance === 0}
            />
          </ButtonBase>
        </div>
        <div>
          <div className={styles.textWallet}>
            <div className={styles.textLabel}>
              <Typography className={styles.text}>
                <FNPCashText />
              </Typography>
              <Typography className={styles.subHeading}>
                {textConst.common.AvailableBalance}
                {checked ? ` ₹ ${cashValueRX - usableBalance}` : ` ₹ ${cashValueRX}`}
              </Typography>
            </div>
            <div className={styles.usedWallet}>
              <Typography className={styles.usedText}>{checked ? textConst.common.YouUsed : ""}</Typography>
              <Typography className={styles.amountEntry}>{checked ? `₹ ${availableAmount?.amountUse}` : ""}</Typography>
            </div>
          </div>
          <UsableCashTooltip />
        </div>
      </div>
      {disabledState && (
        <div className={styles.disabledCurrency}>
          <FNPCashText />
          {textConst.checkout.priceDetails.disableMessage}
        </div>
      )}
    </>
  );
};
WalletCheckBox.propTypes = {
  setAvailableAmount: PropTypes.func,
  onStateChange: PropTypes.func,
  availableAmount: PropTypes.shape({
    amountUse: PropTypes.number,
    availableBal: PropTypes.number,
  }),
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  CurrencyCheckBoxStyle: PropTypes.objectOf(PropTypes.any),
  cleverTapCartCommonData: PropTypes.objectOf(PropTypes.any),
};
WalletCheckBox.defaultProps = {
  setAvailableAmount: () => {},
  onStateChange: () => {},
  availableAmount: {
    amountUse: 0,
    availableBal: 0,
  },
  checked: false,
  disabled: false,
  CurrencyCheckBoxStyle: {},
  cleverTapCartCommonData: {},
};
export default WalletCheckBox;
