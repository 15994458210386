import qs from "qs";
import getConfig from "next/config";
import axios from "../../../../app_configs/axios/base";
import errorLogger from "../../../../app_configs/logger-service";

const error = {
  config: {},
  response: {},
};

const { publicRuntimeConfig } = getConfig();
const SHOW_REQUESTS_LOGS = publicRuntimeConfig.SHOW_REQUESTS_LOGS || "Y";

/**
 * This method gets the list of all reminders
 *
 * @returns {object} - api response
 */
export const getReminders = async () => {
  try {
    const resp = await axios.get("control/getAllReminders-rj", {});
    return resp.data;
  } catch (ex) {
    error.config.url = "control/getAllReminders-rj";
    error.response.status = `An exception occurred while calling getReminders API => ${ex}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return [];
  }
};

/**
 * This method post new or edit reminders
 *
 * @param {object} params - params for api request
 * @returns {object} - api response
 */
export const updateReminder = async (params) => {
  try {
    const resp = await axios.post("control/saveReminder-rj", qs.stringify(params), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    return resp.data;
  } catch (ex) {
    error.config.url = "control/saveReminder-rj";
    error.response.status = `An exception occurred while calling saveReminders API => ${ex}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return [];
  }
};

/**
 *  This method deletes the reminder
 *
 * @param {object} params - params for api request
 * @returns {object} - api response
 */
export const deleteReminder = async (params) => {
  try {
    const resp = await axios.post("control/deleteReminder-rj", qs.stringify(params), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    return resp.data;
  } catch (ex) {
    error.config.url = "control/deleteReminder-rj";
    error.response.status = `An exception occurred while calling delete Reminders API => ${ex}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return [];
  }
};
