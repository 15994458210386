/* eslint-disable react/jsx-props-no-spreading */
import { Dialog, Slide } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";

import HeaderWithTitle from "../../../layouts/mobile/header/main/header-with-title";

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Full Screen Dialog Component
 *
 * @param {object} param0 prop from parent
 * @param {boolean} param0.open to open dialog or not
 * @param {Function} param0.handleClose to handle drawer onclose
 * @param {string} param0.headerLabel header text to show
 * @param {string} param0.pathName route path name
 * @param {Function} param0.rightComponent component to align at right of header
 * @param {object} param0.titleStyle title styles
 * @param {object} param0.children children to render
 *
 * @returns {React.ReactElement} full screen dialog
 */
const FullScreenDialog = ({ open, handleClose, headerLabel, pathName, rightComponent, titleStyle, children }) => {
  return (
    <Dialog fullScreen fullWidth scroll="paper" open={open} onClose={handleClose} TransitionComponent={Transition}>
      <HeaderWithTitle
        handleBack
        pathName={pathName}
        headerLabel={headerLabel}
        onBackPress={handleClose}
        rightComponent={rightComponent}
        titleStyle={titleStyle}
      />
      {children}
    </Dialog>
  );
};

FullScreenDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  headerLabel: PropTypes.string.isRequired,
  pathName: PropTypes.string.isRequired,
  rightComponent: PropTypes.func,
  titleStyle: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.node,
};

FullScreenDialog.defaultProps = {
  children: <></>,
  rightComponent: () => <></>,
  titleStyle: {},
};

export default FullScreenDialog;
