import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import APP_CONSTANTS from "../../../src/action-constants/app-actions";
import { setHashToRoute, RoutePageConstants, PathPageConstants } from "../../../src/utils/common";
import { LocationLockStaticConstants } from "../../common/constants";
import HeaderWithoutTitle from "./header/main/header-without-title";
import HeaderWithTitle from "./header/main/header-with-title";
import HomePrimaryHeader from "./header/main/home-primary-header";
import HomeSecondaryHeader from "./header/main/home-secondary-header";
import { setCurrentHashSelected } from "../../../src/actions/user-actions";
import { showMediaInfo } from "../../../src/actions/common-page-actions";
import { cleverTapCommonEvents } from "../../../src/analytics/clevertapConstants";
import callClevertapEvent, { getPageNameForCleverTap } from "../../../src/analytics/clevertapUtility";

const RightMenu = dynamic(() => import("../../right-menu"), { ssr: false });
const SearchBar = dynamic(() => import("./search-bar"), { ssr: false });
const LeftNavDrawer = dynamic(() => import("./navigation/left-nav-drawer"));

/**
 * This is a functional component for Mobile Header
 *
 * @param {*} param0 .
 * @returns {React.Component} main jsx for the required type of header component.
 */
function MobileHeader({ pageName, isInternationalHomepage, showSearch, bannerDisapperaDetect }) {
  const [showSecondary, setSecondary] = useState(false);
  const [openSearch, setSearchOpen] = useState(false);
  const leftMenuEnable = useSelector((state) => state.appData.leftMenuEnable);
  const [showRightMenu, setRightMenuVisibility] = useState(false);
  const appConfigs = useSelector((state) => state.appConfigs);
  const currentCatalogId = appConfigs.catalogId;
  const router = useRouter();
  const dispatch = useDispatch();
  const path = router.query?.slug1;

  const showMediaPopup = useSelector((state) => state.commonPageData.showMediaPopup);

  const haveLeftDrawer = [
    RoutePageConstants.HOME,
    RoutePageConstants.PLP_PAGE,
    RoutePageConstants.MICROSITE,
    RoutePageConstants.TESTIMONIAL,
    RoutePageConstants.NOT_FOUND,
    RoutePageConstants.SERVER_ERROR,
    RoutePageConstants.EXPERIENCES_PAGE,
  ].includes(pageName);

  /**
   * set route back
   */
  const setRouteBack = useCallback(() => {
    router.back();
  }, [router]);

  /**
   * Hide search section modal - By moving one step backward in Browser history stack.
   */
  function hideActualSearchSection() {
    setRouteBack();
    if (pageName === RoutePageConstants.PDP_PAGE) {
      setSearchOpen(false);
      document.body.style.overflow = "auto";
    }
  }

  /**
   * To open search panel .
   */
  function showActualSearchSection() {
    const pageHeaderMenuData = {
      page_name: getPageNameForCleverTap(),
      page_type: getPageNameForCleverTap(),
      menu_name: "Search",
    };
    callClevertapEvent(cleverTapCommonEvents.headerMenuClicked, pageHeaderMenuData);
    setHashToRoute("openSearch");
    dispatch(setCurrentHashSelected("openSearch"));
    setSearchOpen(true);
    document.body.style.overflow = "hidden";
  }

  /**
   * To open and close the Right / Hamburger menu .
   */
  function openRightMenu() {
    if (!showRightMenu) {
      setHashToRoute("rm");
      dispatch(setCurrentHashSelected("rm"));
      setRightMenuVisibility(true);
    } else {
      setRouteBack();
      setRightMenuVisibility(false);
    }
  }

  /**
   * To open and close the left / Kebab menu .
   */
  const openLeftMenu = useCallback(() => {
    if (!leftMenuEnable) {
      setHashToRoute("lm");
      dispatch(setCurrentHashSelected("lm"));
      dispatch({
        type: APP_CONSTANTS.LEFT_MENU_ENABLE,
        payload: true,
      });
      document.body.style.overflow = "auto";
    } else {
      setRouteBack();
      dispatch({
        type: APP_CONSTANTS.LEFT_MENU_ENABLE,
        payload: false,
      });
      document.body.style.overflow = "hidden";
    }
  }, [dispatch, setRouteBack, leftMenuEnable]);

  /**
   * It changes the status of search button and hamburger to false on clicking back button of browser.
   */
  const handleHashChange = useCallback(() => {
    if (openSearch) {
      setSearchOpen(false);
      document.body.style.overflow = "auto";
    }

    if (showRightMenu) {
      setRightMenuVisibility(false);
    }

    if (leftMenuEnable) {
      dispatch({
        type: APP_CONSTANTS.LEFT_MENU_ENABLE,
        payload: false,
      });

      if (showMediaPopup) {
        dispatch(showMediaInfo(false));
      }
    }
  }, [openSearch, showRightMenu, leftMenuEnable, dispatch, showMediaPopup]);

  useEffect(() => {
    router.events.on("hashChangeStart", handleHashChange);
    return () => {
      router.events.off("hashChangeStart", handleHashChange);
    };
  }, [router.events, handleHashChange]);

  useEffect(() => {
    /**
     * Show Home Secondary Menu based on the scroll position
     */
    function handleScroll() {
      const lastKnownScrollPosition = window.scrollY;
      if (lastKnownScrollPosition > 105 && !showSecondary) {
        setSecondary(true);
      } else if (lastKnownScrollPosition === 0 && showSecondary) {
        setSecondary(false);
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [showSecondary]);

  let renderHeader;
  if (
    [
      RoutePageConstants.CART_PREVIEW,
      RoutePageConstants.ACCOUNT_PAGE,
      RoutePageConstants.GIFT_FINDER_PAGE,
      RoutePageConstants.ADDONS,
      RoutePageConstants.CHECKOUT_PAGE,
    ].includes(pageName) ||
    path === PathPageConstants.COUNTRY_LIST
  ) {
    return (
      <>
        <HeaderWithTitle
          pageName={pageName}
          pathName={path}
          headerLabel={LocationLockStaticConstants.LL_SELECT_CITY}
          openRightMenu={openRightMenu}
          catalogId={currentCatalogId}
        />
        {showRightMenu && <RightMenu openMenu={showRightMenu} showOrHideMenu={openRightMenu} />}
      </>
    );
  }

  if (
    [
      RoutePageConstants.PLP_PAGE,
      RoutePageConstants.PDP_PAGE,
      RoutePageConstants.MICROSITE,
      RoutePageConstants.TESTIMONIAL,
      RoutePageConstants.NOT_FOUND,
      RoutePageConstants.SERVER_ERROR,
      RoutePageConstants.EXPERIENCES_PAGE,
    ].includes(pageName)
  ) {
    renderHeader = (
      <>
        <HeaderWithoutTitle
          pageName={pageName}
          openLeftMenu={openLeftMenu}
          openSearch={showActualSearchSection}
          openRightMenu={openRightMenu}
          catalogId={currentCatalogId}
          isInternationalHomepage={isInternationalHomepage}
          bannerDisapperaDetect={bannerDisapperaDetect}
        />
        {showRightMenu && <RightMenu openMenu={showRightMenu} showOrHideMenu={openRightMenu} />}
      </>
    );
  } else if (pageName === RoutePageConstants.HOME) {
    if (!showSecondary) {
      renderHeader = (
        <>
          <HomePrimaryHeader
            catalogId={currentCatalogId}
            openLeftMenu={openLeftMenu}
            openSearch={showActualSearchSection}
            openRightMenu={openRightMenu}
          />
          {showRightMenu && <RightMenu openMenu={showRightMenu} showOrHideMenu={openRightMenu} />}
        </>
      );
    }

    if (showSecondary && showSearch) {
      renderHeader = <HomeSecondaryHeader openLeftMenu={openLeftMenu} openSearch={showActualSearchSection} />;
    }
  }

  return (
    <>
      {renderHeader}
      {openSearch && <SearchBar hideActualSearchSection={hideActualSearchSection} />}
      {haveLeftDrawer && <LeftNavDrawer open={leftMenuEnable} handleClose={openLeftMenu} />}
    </>
  );
}

MobileHeader.propTypes = {
  showSearch: PropTypes.bool,
  pageName: PropTypes.string,
  isInternationalHomepage: PropTypes.bool,
  bannerDisapperaDetect: PropTypes.bool,
};

MobileHeader.defaultProps = {
  showSearch: true,
  pageName: "",
  isInternationalHomepage: false,
  bannerDisapperaDetect: true,
};

export default React.memo(MobileHeader);
