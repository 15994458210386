import { takeEvery, put, call } from "redux-saga/effects";
import { Router } from "../../routes/routes";
import APP_CONSTANTS from "../../src/action-constants/app-actions";
import { CheckoutRoutes } from "../../src/action-constants/checkout-constants";
import { initiateSocialLogin, loginApi, mobileValidation, setLoggedInPincode } from "../../src/actions/login-actions";
import { initiateFus } from "./fusSaga";
import errorLogger from "../../app_configs/logger-service";

/**
 * This method is yielding fus api response in saga that is retrieving cartinfo and loginInfo
 *
 * @param {object} action - contains the response payload information of cartInfo and loginInfo
 * after calling fus api
 */
function* loginUser(action) {
  try {
    const data = yield loginApi(action.payload);
    if (data?.data?.isSuccess) {
      yield put({ type: APP_CONSTANTS.LOGIN_USER_SUCCESS, payload: data });
      if (Router.router.pathname === `/${CheckoutRoutes.CHECKOUT}`) yield call(initiateFus);
    } else {
      yield put({ type: APP_CONSTANTS.LOGIN_USER_ERROR, error: data });
    }
  } catch (error) {
    yield put({ type: APP_CONSTANTS.LOGIN_USER_ERROR, error });
  }
}

/**
 * This method is fetching contentId
 *
 * @param {object} action - contains the response payload information of cartInfo and loginInfo
 * after calling fus api
 */
function* mobileValidationContentId(action) {
  try {
    const data = yield mobileValidation(action.payload);
    if (data.status === 200) {
      yield put({ type: APP_CONSTANTS.SET_MOBILE_VALIDATION_ENABLE, payload: data.data });
    }
  } catch (error) {
    yield put({ type: APP_CONSTANTS.SET_MOBILE_VALIDATION_ENABLE_ERROR, error });
  }
}

/**
 * This method is used to Set pincode and address to a loggenIn user
 *
 * @param {object} action - contains the response payload information of address and pincode
 */
function* setPincodeWhilelogin(action) {
  try {
    yield call(setLoggedInPincode(action.payload));
  } catch (error) {
    errorLogger(error);
  }
}
/**
 * This method is used to initiate social login
 *
 * @param {object} action - contains the query
 */
function* setSocialLogin(action) {
  try {
    yield call(initiateSocialLogin(action.payload));
  } catch (error) {
    errorLogger(error);
  }
}

/**
 * This method is used to yield success response of CHECK_LOGIN_CART_INFO action
 *
 */
function* loginSaga() {
  yield takeEvery(APP_CONSTANTS.LOGIN_USER, loginUser);
  yield takeEvery(APP_CONSTANTS.CHECK_MOBILE_VALIDATION, mobileValidationContentId);
  yield takeEvery(APP_CONSTANTS.SET_LOGGED_IN_PINCODE, setPincodeWhilelogin);
  yield takeEvery(APP_CONSTANTS.INITIATE_SOCIAL_LOGIN, setSocialLogin);
}

export default loginSaga;
