import React from "react";

import PropTypes from "prop-types";
import SubscriptionForm from "../subscribe";
import styles from "./style.module.scss";

/**
 * *This method returns desktop info footer jsx.
 *
 * @param {object}root0 The main parameter.
 * @param {Array}root0.ctaLinks links in main footer
 * @returns {React.ReactElement} the main jsx for the main footer.
 */
export default function mainFooter({ ctaLinks }) {
  const footerBodyLinks = ctaLinks.map((ctaLink) => (
    <div key={ctaLink.title}>
      <h6>{ctaLink.title}</h6>
      <ul>
        {ctaLink.links.map((link) => (
          <li key={link.linkName}>
            <a href={link.path}>{link.linkName}</a>
          </li>
        ))}
      </ul>
    </div>
  ));

  return (
    <div className={styles.mainFooter}>
      <div className={styles.cTALinks}>{footerBodyLinks}</div>
      <SubscriptionForm />
    </div>
  );
}

mainFooter.propTypes = {
  ctaLinks: PropTypes.instanceOf(Array).isRequired,
};
