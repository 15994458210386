import { HYDRATE } from "next-redux-wrapper";
import AddressBookConstants from "../../../src/action-constants/address-book-constants";

const initialState = {
  isEditAddressSuccess: false,
  isLoaded: false,
  isDeleteLoading: false,
  getEditAddressSuccess: true,
};

/**
 * reducer for address book
 *
 * @param {object} state - initial redux state
 * @param {object} action - action object for action type and payload
 * @returns {object} - updated state
 */
function addressBookReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.addressBook,
      };
    case AddressBookConstants.GET_CHECKOUT_ADDRESS_LIST_REQUESTED:
    case AddressBookConstants.GET_ACCOUNT_ADDRESS_LIST_REQUESTED:
    case AddressBookConstants.GET_CHECKOUT_EDIT_ADDRESS_DATA:
      return {
        ...state,
        isLoaded: false,
        isEditAddressSuccess: false,
        isSuccess: true,
        editAddressData: null,
      };
    case AddressBookConstants.GET_ACCOUNT_ADDRESS_LIST:
      return {
        ...state,
        addressData: payload.addressList.map((e) => {
          const obj = {
            id: e.postalAddress,
            name: e.toName,
            address: e.address,
            address2: e.address2,
            addressType: e.addressType,
            city: e.city,
            postalCode: e.postalcode,
            mobileCode: e.countryCode,
            mobileNumber: e.phone,
            countryGeoId: e.countryGeoId,
            telecomNumber: e.telecomNumber,
            email: e.attnName,
            altMobileNumber: e.altPhone,
            doorBell: e.doorBell,
          };
          if (Object.keys(e).indexOf("addressId") !== -1) {
            obj.addressId = e.addressId;
            obj.houseNo = e.houseNo;
            obj.landmark = e.landmark;
          }
          return obj;
        }),
        isLoaded: true,
      };
    case AddressBookConstants.GET_CHECKOUT_ADDRESS_LIST:
      return {
        ...state,
        addressData: payload.addressList.map((e) => {
          const obj = {
            id: e.contactMechId,
            salutation: e.salutation,
            name: e.toName,
            address: e.address1,
            address2: e.address2,
            addressType: e.addressType,
            city: e.city,
            postalCode: e.postalCode,
            mobileCode: e.teleCode,
            altMobileCode: e.altRecpTeleCode,
            mobileNumber: e.mobileNumber,
            altMobileNumber: e.altRecpNumber,
            countryGeoId: e.countryGeoId,
            email: e.email,
            doorBell: e.doorBell,
            contactMechPurposeTypeId: e.contactMechPurposeTypeId,
          };
          if (Object.keys(e).indexOf("addressId") !== -1) {
            obj.addressId = e.addressId;
            obj.houseNo = e.houseNo;
            obj.landmark = e.landmark;
          }
          return obj;
        }),
        isLoaded: true,
      };
    case AddressBookConstants.DELETE_ADDRESS_CARD:
      return {
        ...state,
        addressData: state.addressData.filter((e) => e.id !== payload.addressId),
        isDeleteLoading: false,
      };
    case AddressBookConstants.GET_EDIT_ADDRESS_DATA_SUCCESS:
      return {
        ...state,
        editAddressData: payload.addressDetails,
        isEditAddressSuccess: false,
      };
    case AddressBookConstants.EDIT_ADDRESS_CARD:
      return {
        ...state,
        isEditAddressSuccess: true,
        editAddressData: null,
        addressData: null,
      };
    case AddressBookConstants.RESET_EDIT_ADDRESS_DATA:
      return {
        ...state,
        editAddressData: null,
        addressData: null,
      };
    case AddressBookConstants.GET_ACCOUNT_ADDRESS_LIST_FAILURE:
      return {
        ...state,
        isSuccess: false,
        isLoaded: true,
      };
    case AddressBookConstants.DELETE_ADDRESS_CARD_REQUESTED:
      return {
        ...state,
        isDeleteLoading: true,
      };
    case AddressBookConstants.GET_EDIT_ADDRESS_DATA_FAILURE:
      return {
        ...state,
        getEditAddressSuccess: false,
      };
    case AddressBookConstants.SET_EDIT_CHECKOUT_ADDRESS_EMPTY:
      return {
        ...state,
        isEditAddressSuccess: false,
        editAddressData: null,
      };
    default:
      return state;
  }
}

export default addressBookReducer;
