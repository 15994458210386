import get from "lodash/get";
import getConfig from "next/config";
import { parseCookies } from "nookies";
import axiosClient from "../../app_configs/axios/base";
import errorLogger from "../../app_configs/logger-service";

const { publicRuntimeConfig } = getConfig();
const SHOW_REQUESTS_LOGS = publicRuntimeConfig.SHOW_REQUESTS_LOGS || "Y";
const error = {
  config: {},
  response: {},
};

/**
 * Populating Products List data to store as a result of sort
 *
 * @param {Array} productData response from the api call containing products details
 * @param {object} options params option of the listing.
 * @returns {object} returns data object
 */
function addProductsBasedOnResults(productData, options) {
  const results = productData ? productData.productResults : [];

  const {
    startRows,
    viewSize,
    CategoryRatingValue,
    CategoryTotalReview,
    productCategoryName,
    listSize,
    sortOptions,
    facetRangeOptions,
  } = productData;
  return {
    results,
    startRows,
    viewSize,
    CategoryRatingValue,
    CategoryTotalReview,
    productCategoryName,
    listSize,
    options,
    sortOptions,
    facetRangeOptions,
  };
}

/**
 *This function is also used to get  product lists for category, however on client side
 * either on Infinite Scroll or Sort or on Location Lock
 *
 * @param {object} options contains params of the product list
 * @returns {object} data to be dispatched to the reducer
 */
export default async function getProductListingForClient(options) {
  try {
    const { lastSelectedPincode } = parseCookies();
    const { params, ...rest } = options;
    const optionsModified = options;
    if (lastSelectedPincode && params.catalogId === "india" && params.slug1 !== "search") {
      optionsModified.params.pincode = lastSelectedPincode;
    }
    const response = await axiosClient.get("control/getProductListForPlp", {
      ...rest,
      params: { ...params, earliestDatesRequired: "N" },
    });
    const productData = get(response, "data.data");
    const data = addProductsBasedOnResults(productData, options);
    return data;
  } catch (ex) {
    error.config.url = `control/getProductListForPlp`;
    error.response.status = `An Exception occurred while fetching results => ${ex}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return [];
  }
}
