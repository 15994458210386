const endpointConst = {
  deleteCartItem: "/control/deleteItem-rj",
  updateAddOn: "/control/updateAddonQuantityProduct-rj",
  viewCart: "/control/viewcart",
  addItem: "/control/additem-rj",
  addAddon: "control/addAddonsToCart-rj",
  getCompleteCartItem: "/control/getCompleteCartItemDetails-rj",
  setCartItemValue: "/control/setCartItemValues-rj",
  login: "/control/lu-rj",
  signUp: "/control/su-rj",
  otpLogin: "/control/vc-rj",
  newPasswordLogin: "/control/up-rj",
  logout: "/control/logout?to=/",
  socialLogin: "/control/googleRedirect",
  gwb: "/control/gwb",
  thankYouPage: "/control/getThankYouPageInfo",
  configurecallandchattime: "global/static/web/configurecallandchattime",
  recentSearchItemList: "recentSearchItemList",
};

const cacheKeyConst = {
  [endpointConst.viewCart]: "Cart",
  [endpointConst.gwb]: "gwb",
  [endpointConst.configurecallandchattime]: "configurecallandchattime",
  [endpointConst.recentSearchItemList]: "recentSearchItemList",
};

export { endpointConst, cacheKeyConst };
