import getConfig from "next/config";
import axios from "../../app_configs/axios/base";
import errorLogger from "../../app_configs/logger-service";
import { execDataLayer } from "../data-layer/dataLayer";
import { endpointConst } from "../data-layer/dataLayerConstants";

const { publicRuntimeConfig } = getConfig();
const SHOW_REQUESTS_LOGS = publicRuntimeConfig.SHOW_REQUESTS_LOGS || "Y";
const error = {
  config: {},
  response: {},
};
/**
 * returns product list for add ons
 *
 * @param {string} query query parameters received from the calling component.
 * @returns {object} object containing product list for add ons
 */
export default async function getProductListForAddOns(query) {
  try {
    const res = await axios.get(`control/getAddonsInfo-rj?${query}`);
    return res.data;
  } catch (e) {
    error.config.url = "control/getAddonsInfo-rj";
    error.response.status = `An exception occurred while calling getAddonsInfo-rj => ${e}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return {};
  }
}

/**
 * adds addon products to cart
 *
 * @param {object} query request object received from calling component.
 * @returns {object} object containing response data
 */
export async function addAddonsToCart(query) {
  try {
    /**
     * @function apiFn wrapper function to execute addAddonsToCart from datalayer.
     * @returns response from datalayer
     */
    const apiFn = async () => {
      return axios.post(`${endpointConst.addAddon}`, query);
    };

    const res = await execDataLayer(apiFn, endpointConst.addAddon);
    return res.data;
  } catch (e) {
    error.config.url = "control/addAddonsToCart-rj";
    error.response.status = `An exception occurred while calling addAddonsToCart-rj => ${e}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return {};
  }
}
