import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import PriceFilterList from "../../../../desktop/plp/facet-filters/filter-options/price-filter-list";
import FilterOptionsList from "./filter-list";

const useStyles = makeStyles(() => ({
  filterOptionsContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    paddingLeft: "20px",
  },
}));

/**
 * this function creates the filter options for the filters
 *
 * @param {object} param0 props
 * @param {string} param0.activeFilterTab -current active filter tab
 * @param {Function} param0.updateFilterData - function to update the filter data in local state
 * @param {object} param0.selectedFilterOptions -current selected filter options
 * @param {object} param0.facetDetailList -current facet detail list
 * @returns {React.ReactFragment} returns jsx element for filter options
 */
const FilterOptions = ({ activeFilterTab, updateFilterData, selectedFilterOptions, facetDetailList }) => {
  const classes = useStyles();
  const renderList = facetDetailList.find((item) => item.facetFieldName === activeFilterTab);

  /**
   * This function handles the event delegated from each filter type
   *
   * @param {object} e event details
   */
  const handleFilterSelection = (e) => {
    const targetFilter = e.target;
    if (targetFilter?.type === "checkbox") {
      updateFilterData(targetFilter.name, targetFilter.value, targetFilter.checked);
    }
  };

  return (
    <Grid
      item
      role="button"
      aria-label="filter-options"
      className={classes.filterOptionsContainer}
      onClick={handleFilterSelection}
    >
      {renderList?.facetFieldName !== "listprice" ? (
        <FilterOptionsList
          facetOptions={renderList?.facetOptions}
          facetFieldName={renderList?.facetFieldName}
          defaultSelection={selectedFilterOptions[renderList?.facetFieldName]}
          facetFieldDisplayName={renderList?.facetFieldDisplayName}
          activeFilterTab={activeFilterTab}
        />
      ) : (
        <PriceFilterList
          facetOptions={renderList?.facetOptions}
          facetFieldName={renderList?.facetFieldName}
          defaultSelection={selectedFilterOptions[renderList?.facetFieldName]}
          facetFieldDisplayName={renderList?.facetFieldDisplayName}
        />
      )}
    </Grid>
  );
};
FilterOptions.propTypes = {
  activeFilterTab: PropTypes.string.isRequired,
  updateFilterData: PropTypes.func.isRequired,
  selectedFilterOptions: PropTypes.objectOf(PropTypes.any).isRequired,
  facetDetailList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default FilterOptions;
