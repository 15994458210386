import { HYDRATE } from "next-redux-wrapper";
import APP_CONSTANTS from "../../../src/action-constants/app-actions";

const initialState = {
  leftMenuEnable: false,
  selectedCurrency: null,
  errorMessages: {
    productCountError: "",
  },
  isDateLockEnabled: false,
};

/**
 * This method is the reducer for actions that are specific to App
 *
 * @param {object} state - contains initial redux State
 * @param {object} action - contains actionTypes and payload information for specific action
 * @returns {object} state - the next State which needs to be dispatched to redux
 */
function appDataReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.appData,
      };
    case APP_CONSTANTS.LEFT_MENU_ENABLE:
      return {
        ...state,
        leftMenuEnable: payload,
      };

    case APP_CONSTANTS.UPDATE_SELECTED_CURRENCY:
      return {
        ...state,
        selectedCurrency: payload,
      };
    case APP_CONSTANTS.SET_IS_DATE_LOCK_ENABLED:
      return {
        ...state,
        isDateLockEnabled: payload,
      };
    default:
      return state;
  }
}

export default appDataReducer;
