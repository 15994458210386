import { getPinCodeByLocation, getPinCode } from "../services/api/location-lock/location-lock-api";

/**
 * This function maps the pin code entered in the google suggestion with the available pin code
 *
 * @param {object} mappedPinCode mapped pincodes
 * @param {string} pin selected pin code
 *
 * @returns {string} resolved pincode
 */
const getMappedPinCode = async (mappedPinCode, pin) => {
  let mp = {};
  if (mappedPinCode && Object.keys(mappedPinCode).length === 0) {
    mp = await getPinCode();
  }

  return mp[pin] || pin;
};

/**
 * This function returns a pin code as per the lat and long
 *
 * @param {object} mappedPinCode mapped pincodes
 * @param {object} coordinates object of lat and long
 *
 * @returns {string} resolved pincode
 */
const handleGetPinCodeByLocation = async (mappedPinCode, coordinates) => {
  const data = await getPinCodeByLocation(coordinates);
  const { matchedPincode, pincode } = data;

  const resolvedPincode = await getMappedPinCode(mappedPinCode, matchedPincode || pincode);
  return resolvedPincode;
};

/**
 * Get the place details
 *
 * @param {object} placeData object of place details
 * @param {object} mappedPinCode mapped pincodes
 *
 * @returns {object} place details
 */
const getPlaceDetails = async (placeData, mappedPinCode) => {
  const { address_components: addressComponents, geometry, formatted_address: locality } = placeData;

  const postalCode = addressComponents?.find((item) => item.types.includes("postal_code"));
  const pincode = postalCode?.long_name;
  let resolvedPincode;
  if (pincode) {
    resolvedPincode = await getMappedPinCode(mappedPinCode, pincode);
  } else if (geometry) {
    const {
      location: { lat, lng },
    } = geometry;
    resolvedPincode = await handleGetPinCodeByLocation(mappedPinCode, { lat: lat(), lng: lng() });
  }

  return {
    resolvedPincode,
    locality,
  };
};

export default getPlaceDetails;

/**
 * Extracts the country code from an EdgeScape header.
 *
 * @param {string} edgeScapeHeader - The EdgeScape header containing the country code.
 * @returns {string|null} The extracted country code, or null if not found.
 */
export const extractCountryCodeFromEdgeScapeHeader = (edgeScapeHeader) => {
  const countryCode = edgeScapeHeader?.split(",")?.[0]?.split("=")?.[1];
  return countryCode;
};
