import { HYDRATE } from "next-redux-wrapper";
import APP_CONSTANTS from "../../src/action-constants/app-actions";

const initialState = {
  data: "",
  errorMessages: {
    pageBodyError: "",
  },
  testimonialData: {},
  testimonialError: "",
  dynamicMetaData: "",
};

/**
 * Reducer for Info Page
 *
 * @param {object} state state object
 * @param {object} action action object
 * @returns {object} state object
 */
const cmsMicroPageReducer = (state = initialState, action = {}) => {
  const { type } = action;
  switch (type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.cmsMicroPage,
      };
    case APP_CONSTANTS.SET_CMS_MICRO_SITE_PAGE_CONTENT:
      return {
        ...state,
        data: action.payload,
      };
    case APP_CONSTANTS.ERROR_IN_MICRO_PAGE:
      return {
        ...state,
        data: "",
        errorMessages: {
          ...state.errorMessages,
          pageBodyError: action.payload,
        },
      };
    case APP_CONSTANTS.SET_TESTIMONIAL_DATA:
      return {
        ...state,
        testimonialData: action.payload,
        testimonialError: "",
      };
    case APP_CONSTANTS.ERROR_TESTIMONIAL:
      return {
        ...state,
        testimonialError: action.payload,
        testimonialData: {},
      };
    case APP_CONSTANTS.SET_TESTIMONIAL_CMS_CONTENT:
      return {
        ...state,
        dynamicMetaData: action.payload,
      };

    default:
      return state;
  }
};

export default cmsMicroPageReducer;
