const LocationLockConstants = {
  GET_COUNTRY_LIST_REQUEST: "GET_COUNTRY_LIST_REQUEST",
  GET_COUNTRY_LIST_SUCCESS: "GET_COUNTRY_LIST_SUCCESS",
  SET_ERROR: "SET_ERROR",
  GET_PINCODE_LIST_REQUEST: "GET_PINCODE_LIST_REQUEST",
  GET_PINCODE_LIST_SUCCESS: "GET_PINCODE_LIST_SUCCESS",
  SET_SELECTED_PINCODE: "SET_SELECTED_PINCODE",
  SET_SELECTED_DELIVERY_DATE: "SET_SELECTED_DELIVERY_DATE",
  SET_ITEM_DESIRED_DELIVERY_DATE: "SET_ITEM_DESIRED_DELIVERY_DATE",
  SET_SELECTED_MAPPED_PIN_CODE: "SET_SELECTED_MAPPED_PIN_CODE",
  SET_SELECTED_COUNTRY: "SET_SELECTED_COUNTRY",
  RESET_SELECTED_PINCODE: "RESET_SELECTED_PINCODE",
  SET_DELIVERY_AS_KEY: "SET_DELIVERY_AS_KEY",
  FINAL_SELECTED_PINCODE: "FINAL_SELECTED_PINCODE",
  GET_PLACE_DETAILS_REQUEST: "GET_PLACE_DETAILS_REQUEST",
  GET_PLACE_DETAILS_SUCCESS: "GET_PLACE_DETAILS_SUCCESS",
  SET_PIN_CODE_MAPPING_REQUEST: "SET_PIN_CODE_MAPPING_REQUEST",
  SET_PIN_CODE_MAPPING: "SET_PIN_CODE_MAPPING",
  SHOW_LOCATION_LOCK_POPUP_REQUEST: "SHOW_LOCATION_LOCK_POPUP_REQUEST",
  SHOW_LOCATION_LOCK_POPUP_SUCCESS: "SHOW_LOCATION_LOCK_POPUP_SUCCESS",
  CLEAR_PIN_CODE_LIST: "CLEAR_PIN_CODE_LIST",
  SHOW_MAPPED_PIN_TOOLTIP: "SHOW_MAPPED_PIN_TOOLTIP",
  UNSET_IS_PIN_CODE_MAPPED: "UNSET_IS_PIN_CODE_MAPPED",
  SHOW_LOCATION_LOCK_MODAL: "SHOW_LOCATION_LOCK_MODAL",
  SET_SELECTED_CITY: "SET_SELECTED_CITY",
  GET_CITY_LIST_REQUEST: "GET_CITY_LIST_REQUEST",
  GET_CITY_LIST_SUCCESS: "GET_CITY_LIST_SUCCESS",
  GET_CITY_LIST_INITIATED: "GET_CITY_LIST_INITIATED",
  FINAL_SELECTED_COUNTRY: "FINAL_SELECTED_COUNTRY",
  SET_LOCK_TRIGGER: "SET_LOCK_TRIGGER",
  SHOW_LOCATION_TOOLTIP: "SHOW_LOCATION_TOOLTIP",
  GET_PINCODE_AND_DELIVERY_LOCATION_ENABLE: "GET_PINCODE_AND_DELIVERY_LOCATION_ENABLE",
  SET_PINCODE_AND_DELIVERY_LOCATION_ENABLE: "SET_PINCODE_AND_DELIVERY_LOCATION_ENABLE",
  SET_ADOBE_AUTO_MANUAL_POPUP: "SET_ADOBE_AUTO_MANUAL_POPUP",
  REQUEST_AUTO_GATE_POPUP_CONFIG_DATA: "REQUEST_AUTO_GATE_POPUP_CONFIG_DATA",
  SET_AUTO_GATE_POPUP_CONFIG_DATA: "SET_AUTO_GATE_POPUP_CONFIG_DATA",
  SET_INPUTS_ERRORS: "SET_INPUTS_ERRORS",
};
export default LocationLockConstants;
