import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import CardGiftCardIcon from "@material-ui/icons/CardGiftcard";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  addAddons: {
    backgroundColor: theme.color?.olive,
    margin: "12px 0",
    borderRadius: 3,
    padding: "10px 0",
    fontWeight: 500,
    textAlign: "center",
    color: "#fff",
    cursor: "pointer",
  },
  productIcon: {
    verticalAlign: "bottom",
    fontSize: 20,
    marginLeft: 5,
  },
  text: {
    marginLeft: 5,
  },
}));

/**
 * Component to render delivery shipping info
 *
 * @param {*} param0 props passed to component
 * @param {string} param0.giftItem object contains shipping details.
 * @param {string} param0.proceedToAddonsPage string contains selected currency.
 * @returns {React.ReactElement} jsx for the delivery delivery shipping info component.
 */
const ProceedToAddons = ({ giftItem, proceedToAddonsPage, ...props }) => {
  const classes = useStyles(props);
  return (
    <Grid
      className={classes.addAddons}
      onClick={() => {
        proceedToAddonsPage(giftItem);
      }}
    >
      <CardGiftCardIcon className={classes.productIcon} />
      <span className={classes.text}>Make it extra special</span>
    </Grid>
  );
};

const propTypes = {
  giftItem: PropTypes.objectOf(PropTypes.any).isRequired,
  proceedToAddonsPage: PropTypes.func.isRequired,
};

ProceedToAddons.propTypes = propTypes;

export default ProceedToAddons;
