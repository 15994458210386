import getConfig from "next/config";

let cleverTapInstance;

/**
 * Main function to be executed first to initiate all the setup for CleverTap
 *
 */
function initClevertapSetup() {
  const { publicRuntimeConfig } = getConfig();
  const cleverTapAccountId = publicRuntimeConfig.CLEVER_TAP_ACCOUNT_ID;
  const cleverTapRegion = publicRuntimeConfig.CLEVER_TAP_REGION;
  if (typeof window !== "undefined") {
    const cleverTap = require("clevertap-web-sdk"); // eslint-disable-line global-require
    cleverTap.init(cleverTapAccountId, cleverTapRegion);
    cleverTapInstance = cleverTap;
    cleverTap.privacy.push({ useIP: true });
  }
}

/**
 * This function returns cleverTap instance
 *
 * @returns {Function} clevertap instance
 */
function getCleverTapInstance() {
  return cleverTapInstance;
}

/**
 * CleverTap setup hook
 *
 */
export default function useClevertapSetup() {
  initClevertapSetup();
}

export { getCleverTapInstance };
