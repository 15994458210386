import { HYDRATE } from "next-redux-wrapper";
import APP_CONSTANTS from "../../../src/action-constants/app-actions";

const InitialState = {
  success: false,
  error: false,
  valid: "",
  errorMessage: "",
};

/**
 * This method is the reducer for actions that are specific to user
 *
 * @param {object} state - contains initial redux State
 * @param {object} action - contains actionTypes and payload information for specific action
 * @returns {object} state - the next State which needs to be dispatched to redux
 */
function checkLoginReducer(state = InitialState, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.checkLogin,
      };
    case APP_CONSTANTS.CHECK_LOGIN:
      return {
        ...state,
        success: false,
      };
    case APP_CONSTANTS.CHECK_LOGIN_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        valid: action.payload.data,
        errorMessage: "",
      };
    case APP_CONSTANTS.CHECK_LOGIN_ERROR:
      return {
        ...state,
        success: false,
        error: true,
        errorMessage: action.error.data.errorMessage,
      };
    case APP_CONSTANTS.CLEAR_LOGIN_ERROR_MESSAGE:
      return {
        ...state,
        error: false,
        errorMessage: "",
      };
    default:
      return state;
  }
}
export default checkLoginReducer;
