import { HYDRATE } from "next-redux-wrapper";
import passwordConstants from "../../../src/action-constants/password-constants";

const initialState = {
  fetchError: false,
  valid: false,
  isLoading: true,
};
/**
 *
 * @param {object} state - contains initial redux State
 * @param {object} action - contains actionTypes and payload information for specific action
 * @returns {object} state - the next State which needs to be dispatched to redux
 */
const changePasswordReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.changePassword,
      };
    case passwordConstants.PASSWORD_RECEIVED:
      return {
        ...state,
        pw: payload.pwd,
        isLoading: false,
      };
    case passwordConstants.PASSWORD_UPDATED:
      return {
        isLoading: false,
        isSuccess: payload.isSuccess,
        errorMsg: payload.errorMsg,
      };
    case passwordConstants.PASSWORD_WRONG:
      return {
        isLoading: false,
        valid: true,
      };
    case passwordConstants.CLEAR_ERROR_MSG:
      return {
        isLoading: true,
        valid: false,
        isSuccess: false,
        errorMsg: false,
      };
    default:
      return state;
  }
};

export default changePasswordReducer;
