export const calendarLabels = {
  DELIVERY_NOT_AVAILABLE: "Delivery is not available on this date",
  PRIOR_OR_AFTER_DATE: "Gift may be delivered prior or after the chosen date.",
  TIMEZONE_DIFFERENCE_DELIVERY:
    "Due to timezone difference, an update regarding delivery will be available only after 2 business days",
  DELIVERY: "",
  ACROSS_INDIA: "",
  FREE: "",
};
export const productTypes = {
  COURIER: "COURIER",
  EXPRESS: "EXPRESS",
  CAKE: "CAKE",
  INTERNATIONAL: "INTERNATIONAL",
  PERSONALIZED: "PERSONALIZED",
  DIGITAL_GOOD: "DIGITAL_GOOD",
};
export const locationConstants = {
  LAST_SELECTED_PINCODE: "lastSelectedPincode",
  LAST_SELECTED_ADDRESS: "lastSelectedAddress",
  LAST_SELECTED_LOCATION: "lastSelectedIntlLoc",
  LAST_SELECTED_CITY: "lastSelectedIntlCity",
  SET_LOGGED_IN_PINCODE: "SET_LOGGED_IN_PINCODE",
};

export const CAKE_TYPE = {
  EGG_LESS: "EGGLESS",
  WITH_EGG: "WITH EGG",
};

export const offerConstant = {
  FNPMOBILE_OFFER_MODULE: "FNPMOBILE_OFFER_MODULE",
  FNP_OFFER_MODULES: "FNP_OFFER_MODULES",
};

export const MULTIPLE_IMAGE_UPLOAD = {
  UPLOAD_PHOTOS: "Upload Photos",
  CUSTOMIZE_PRODUCT: "Customize Product",
  FILE_SIZE: "File size between100KB - 10MB only JPG, JPEG, PNG",
  UPLOAD_DETAILS: "Upload Photos and fill other details",
  UPLOADING_TOAST: "Please wait, uploading photos",
  UPLOAD_VALIDATION_MSG: "Please upload photos to continue.",
};

export const errorMessages = {
  AREA_DOES_NOT_EXIST: "Area does not exist. Please enter correct area name.",
};

export const CHOOSE_LOCATION_HINT = `"Example: For Country USA - type "New York"`;

export const FEATURE_SET = {
  INGREDIENTS: "INGREDIENTS",
  STEMS: "STEMS",
  PIECES: "PIECES",
  FLAVOUR: "FLAVOUR",
};

export const DELIVERY_TYPE = {
  EXPRESS: "hand-delivery",
  COURIER: "courier-delivery",
};

export const ACTION_BUTTONS = {
  BUY_NOW: "Buy Now",
  ADD_TO_CART: "Add to cart",
};

export const PDP_HASH = {
  PRODUCT_PAGE: "#/product-page",
  ADDONS: "#/addons",
  CALENDAR: "#/calendar",
  DELIVERY_AREA: "#/deliveryarea",
  PLACES_SUGGESTIONS: "#/places-suggestions",
  SHIPPING_METHODS: "#/shippingmethods",
  COUNTRIES_LIST: "#/countries-list",
  INTL_SHIPPING_METHOD: "#/international-shippingmethods",
  IMAGE_POPUP: "#/image-pop-up",
};

export const REGEX_PATTERN = {
  onlySplCharPattern: /[^(A-Za-z0-9 )]/,
  NotAlphaPattern: /[(A-Za-z)]/g,
  NotNumPattern: /\d/g,
};
