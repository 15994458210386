import { HYDRATE } from "next-redux-wrapper";
import APP_CONSTANTS from "../../../src/action-constants/app-actions";

const InitialState = {
  success: false,
  error: false,
  userDetail: "",
  name: "",
  mobile: "",
  password: "",
  showPassword: false,
  salution: "Mr.",
  code: "+91 IND",
  isValidating: false,
  selectedCode: "91",
};

/**
 * This method is the reducer for actions that are specific to user
 *
 * @param {object} state - contains initial redux State
 * @param {object} action - contains actionTypes and payload information for specific action
 * @returns {object} state - the next State which needs to be dispatched to redux
 */
function createUserReducer(state = InitialState, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.createUser,
      };
    case APP_CONSTANTS.CREATE_USER:
      return {
        ...state,
        success: false,
        error: false,
        isValidating: true,
      };
    case APP_CONSTANTS.CREATE_USER_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        userDetail: action.payload.data,
        isValidating: false,
      };
    case APP_CONSTANTS.CREATE_USER_ERROR:
      return {
        ...state,
        success: false,
        error: true,
        isValidating: false,
      };
    case APP_CONSTANTS.SET_CREATE_USER_DETAIL:
      return {
        ...state,
        name: action.payload.name,
        mobile: action.payload.mobile,
        password: action.payload.password,
        showPassword: action.payload.showPassword,
        salution: action.payload.salution,
        code: action.payload.code,
        selectedCode: action.payload.selectedCode,
      };
    default:
      return state;
  }
}
export default createUserReducer;
