import GiftFinderConstants from "../action-constants/gift-finder-constants";

/**
 * This method returns facet request data action object
 *
 * @param {string} payload payload for the action object.
 * @returns {object} action object.
 */
export const requestFacetData = (payload) => ({
  type: GiftFinderConstants.REQUEST_FACET_DATA,
  payload,
});

/**
 * This method returns city request data action object
 *
 * @param {string} payload payload for the action object.
 * @returns {object} action object.
 */
export const requestCityData = (payload) => ({
  type: GiftFinderConstants.REQUEST_CITY_DATA,
  payload,
});

/**
 * This method returns facet store request data action object
 *
 * @param {object} payload payload for the action object.
 * @returns {object} action object.
 */
export const setFacetsData = (payload) => ({
  type: GiftFinderConstants.SET_FACET_DATA,
  payload: { facetData: payload },
});

/**
 * This method returns facet store request error data action object
 *
 * @param {string} payload payload for the action object.
 * @returns {object} action object.
 */
export const setFacetsError = (payload) => ({
  type: GiftFinderConstants.SET_FACET_ERROR,
  payload,
});

/**
 * This method returns city store request data action object
 *
 * @param {object} payload payload for the action object.
 * @returns {object} action object.
 */
export const setCityData = (payload) => {
  return {
    type: GiftFinderConstants.SET_CITY_DATA,
    payload: { city: payload.data.city },
  };
};

/**
 * This method returns city store request error action object
 *
 * @param {string} payload payload for the action object.
 * @returns {object} action object.
 */
export const setCityError = (payload) => ({
  type: GiftFinderConstants.SET_CITY_ERROR,
  payload,
});

/**
 * This method returns initial city action object
 *
 * @returns {object} action object.
 */
export const setInitialCity = () => ({
  type: GiftFinderConstants.INITIATE_SET_CITY_DATA,
});

/**
 * This method returns action to clear city data
 *
 * @returns {void} returns action object.
 */
export const clearCityData = () => ({
  type: GiftFinderConstants.CLEAR_CITY_DATA,
});

/**
 * This method sets location data
 *
 * @param {object} payload payload for the action object.
 * @returns {object} action object.
 */
export const setLocationata = (payload) => {
  return {
    type: GiftFinderConstants.SET_LOCATION_DATA,
    payload,
  };
};

/**
 * This method clears location data
 *
 * @returns {void} returns action object.
 */
export const clearLocationData = () => ({
  type: GiftFinderConstants.CLEAR_LOCATION_DATA,
});

/**
 * This method set flag for location reset
 *
 * @returns {void} returns action object.
 */
export const clearedLocationField = () => ({
  type: GiftFinderConstants.CLEARED_LOCATION_FIELD,
});
