import { call, put, takeLatest } from "redux-saga/effects";
import getConfig from "next/config";
import APP_CONSTANTS from "../../src/action-constants/app-actions";
import SubscribeNewUser from "../../src/actions/subscribeEmailFooter";
import errorLogger from "../../app_configs/logger-service";

const { publicRuntimeConfig } = getConfig();
const SHOW_REQUESTS_LOGS = publicRuntimeConfig.SHOW_REQUESTS_LOGS || "Y";
const error = {
  config: {},
  response: {},
};
/**
 * action to trigger subscribe email
 *
 * @param {object} action - action object
 */
export function* getFooterSubscription(action) {
  try {
    const res = yield call(SubscribeNewUser, action.payload);

    yield put({
      type: APP_CONSTANTS.SET_FOOTER_SUBSCRIPTION,
      payload: res,
    });
  } catch (e) {
    error.config.url = "subscribeNewUser";
    error.response.status = `An exception occurred while calling subscribeNewUser => ${e}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
  }
}

/**
 * sagas for fetching email for subscription in desktop footer
 *
 */
function* watchFooterSubscriptionRequest() {
  yield takeLatest(APP_CONSTANTS.DESKTOP_FOOTER_SUBSCRIPTION, getFooterSubscription);
}
export default watchFooterSubscriptionRequest;
