import get from "lodash/get";
import { takeEvery, put } from "redux-saga/effects";
import APP_CONSTANTS from "../../src/action-constants/app-actions";
import { checkLoginApi } from "../../src/actions/login-actions";

/**
 * This method is yielding fus api response in saga that is retrieving cartinfo and loginInfo
 *
 * @param {object} action - contains the response payload information of cartInfo and loginInfo
 * after calling fus api
 *
 * @returns {object} valid or not
 */
function* checkLoginId(action) {
  try {
    const data = yield checkLoginApi(action.payload);
    if (data.data.valid) {
      yield put({ type: APP_CONSTANTS.CHECK_LOGIN_SUCCESS, payload: data });
    } else {
      yield put({ type: APP_CONSTANTS.CHECK_LOGIN_ERROR, error: data });
    }
    return get(data, "data", "");
  } catch (error) {
    yield put({ type: APP_CONSTANTS.CHECK_LOGIN_ERROR, error });
    return "";
  }
}

/**
 * This method is used to yield success response of CHECK_LOGIN_CART_INFO action
 *
 */
function* checkLoginSaga() {
  yield takeEvery(APP_CONSTANTS.CHECK_LOGIN, checkLoginId);
}

export default checkLoginSaga;
