import getConfig from "next/config";
import axios from "../../../../app_configs/axios/base";
import errorLogger from "../../../../app_configs/logger-service";

const { publicRuntimeConfig } = getConfig();
const SHOW_REQUESTS_LOGS = publicRuntimeConfig.SHOW_REQUESTS_LOGS || "Y";

const error = {
  config: {},
  response: {},
};
/**
 * This method calls the api for fetching all the occasions
 *
 * @param {string} catalogId it's Id of the catalog
 * @returns {Array} response of the  occasions api - array of occasions
 */
async function getOccasions(catalogId) {
  try {
    const res = await axios.get(`productapi/api/rest/v1.3/occasionList?catalogId=${catalogId}`, {
      baseURL: publicRuntimeConfig.PRODUCT_API_URL,
    });
    return res.data.data;
  } catch (ex) {
    error.config.url = "/productapi/api/rest/v1.3/webAnalytics";
    error.response.status = `An Exception occurred while fetching results => ${ex}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return [];
  }
}
export default getOccasions;
