import { call, put, takeLatest } from "redux-saga/effects";
import appConstants from "../../src/action-constants/app-actions";
import getPlpLeftNavHtml from "../../src/actions/filter-action";
import { setFilterSuccess } from "../../src/actions/plp-actions";
/**
 * action to trigger filter content
 *
 * @param {object} action - action object
 */
export function* getFilterData(action) {
  const res = yield call(getPlpLeftNavHtml, action.payload);
  yield put(setFilterSuccess(res));
}

/**
 * sagas for fetching filter content
 *
 */
function* watchFilterDataRequest() {
  yield takeLatest(appConstants.GET_FILTER_DATA_REQUEST, getFilterData);
}

export default watchFilterDataRequest;
