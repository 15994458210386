import { call, put, takeEvery } from "redux-saga/effects";
import profileConstants from "../../src/action-constants/profile-constants";
import APP_CONSTANTS from "../../src/action-constants/app-actions";
import { getCustomerDetails, updateCustomerDetails } from "../../src/actions/profile-actions";

/**
 * Fetching customer details
 *
 */
export function* getCustomerData() {
  try {
    const response = yield call(getCustomerDetails);
    if (response?.data?.isSuccess) {
      yield put({
        type: profileConstants.DETAILS_RECEIVED,
        payload: response.data,
      });
    } else {
      yield put({
        type: profileConstants.ERROR_MESSAGE,
      });
    }
  } catch (e) {
    yield put({
      type: profileConstants.ERROR_MESSAGE,
    });
  }
}

/**
 *
 * @param {object} payload data sent to up-rj api
 * @returns {object} data to be updated in reducer as per the get customer detail response
 */
const getMappedData = (payload) => {
  return {
    customerName: payload.name,
    countryCode: payload.countryCode,
    phoneNum: payload.phone,
    gender: payload.gender,
    emailAddress: payload.email,
    address: payload.address,
    postalCode: payload.postalcode,
    customerId: payload.partyId,
    postalAddress: payload.postalAddress,
    countryGeoId: payload.countryGeoId,
    dateOfBirth: payload.birthDay,
    anniversaryDate: payload.annivDate,
    city: payload.city,
    telecomNumber: payload.telecomNumber,
  };
};

/**
 * Updating customer details entered by user
 *
 * @param {*} details - customer data
 */
export function* updateDetail(details) {
  try {
    const res = yield call(updateCustomerDetails, details.payload);
    if (res.data.isSuccess === "success") {
      const dataToSendInReducer = getMappedData(details.payload);
      yield put({ type: profileConstants.UPDATE_DETAIL_SUCCESS, payload: dataToSendInReducer });
      yield put({ type: APP_CONSTANTS.SET_USER, payload: { name: details?.payload?.name } });
    } else {
      yield put({
        type: profileConstants.ERROR_MESSAGE,
      });
    }
  } catch (e) {
    yield put({
      type: profileConstants.ERROR_MESSAGE,
    });
  }
}

/**
 * sagas for fetching currencies list
 *
 */
function* watchFetchCustomerDetails() {
  yield takeEvery(profileConstants.GET_DETAILS, getCustomerData);
  yield takeEvery(profileConstants.UPDATE_DETAILS, updateDetail);
}

export default watchFetchCustomerDetails;
