import React from "react";
import TooltipPdp from "../../desktop/pdp/tooltip-pdp/tooltip-pdp";
import styles from "../styles/date-range-info.module.scss";
import { calendarLabels } from "../../../src/utils/pdp-constants";
/**
 * This functional component represents date range info.
 *
 * @returns {React.Fragment} selected shipping date range info.
 */
function DateRangeInfo() {
  return (
    <TooltipPdp
      isForDateRange
      title={(() => (
        <div>
          <span className={styles["date-range-tooltip-font"]}>{calendarLabels.PRIOR_OR_AFTER_DATE}</span>
        </div>
      ))()}
    />
  );
}

export default DateRangeInfo;
