import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Image from "next/image";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import { Grid, makeStyles } from "@material-ui/core";
import dynamic from "next/dynamic";
import APP_CONSTANTS from "../../../src/action-constants/app-actions";
import styles from "./styles/cart-preview.module.scss";
import CustomButton from "../../common/customButton";
import { AccountRoutes } from "../../../src/action-constants/my-account-constants";
import checkPaytmLogin from "../../../src/utils/paytmMiniAppLogin";
import { isMiniAppUserAgent } from "../../../src/utils/common";
import { setGaClickEventInteraction } from "../../../src/ga/gaEvents";

const RecentlyViewed = dynamic(() => import("../../mobile/pdp/recently-viewed"), { ssr: false });
const RetryPaymentCard = dynamic(() => import("./retryPaymentCard"), { ssr: false });

const useStyles = makeStyles({
  emptyCartIcon: {
    position: "absolute",
    top: 0,
  },
});
/**
 * This function build the empty cart component for the cart preview page
 *
 * @param {object} props required for this component
 * @param {boolean} props.loggedIn flag for user login
 * @param {boolean} props.isMobile flag to check what device it is
 * @returns {React.ReactElement} jsx for the empty cart.
 */
function EmptyCart({ loggedIn, isMobile }) {
  const router = useRouter();
  const [disableLoginBtn, setDisableLoginBtn] = useState(false);
  const [disableShoppingBtn, setDisableShoppingBtn] = useState(false);
  const { pncCartData: pncCartDetails } = useSelector((state) => state.cartDetails);
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMiniApp = isMiniAppUserAgent();
  useEffect(() => {
    if (loggedIn) dispatch({ type: APP_CONSTANTS.GET_RETRY_PAYMENT_INFO_REQUEST });
  }, [loggedIn, dispatch]);
  /**
   * This function handles the continue shopping button
   */
  const handleContinueShopping = () => {
    setDisableShoppingBtn(true);
    setGaClickEventInteraction(
      "pdp_add_to_cart_confirmation",
      "pdp_interaction",
      "add_to_cart_confirmation",
      "continue_shopping",
    );
    window.location.href = "/";
    if (!isMobile) dispatch({ type: APP_CONSTANTS.SHOW_BACKDROP_REQUEST, payload: false });
  };
  /**
   * This function handles the login button
   */
  const handleLogin = () => {
    window.location.href = `/${AccountRoutes.ACCOUNT}/${AccountRoutes.ACCOUNT_HOME}?from=${router.asPath}`;
    setDisableLoginBtn(true);
  };

  /**
   * This function handles the login button for paytm mini app
   */
  const handlePaytmLogin = () => {
    checkPaytmLogin(`/cartpreview`);
  };

  return (
    <>
      <div
        className={loggedIn && pncCartDetails.length ? styles["pnc-modules-container"] : styles["empty-cart-container"]}
        style={isMobile ? { minHeight: "100vh" } : {}}
      >
        {loggedIn && pncCartDetails.length ? (
          <Grid>
            <RetryPaymentCard pncCartDetails={pncCartDetails} isMobile={isMobile} />
          </Grid>
        ) : (
          <>
            <div className={styles["empty-cart"]}>
              <div className={styles["empty-cart-details"]}>
                <Image
                  className={!isMobile ? classes.emptyCartIcon : {}}
                  src="/assets/images/empty-cart-icon.png"
                  alt="cart_image"
                  width={150}
                  height={113}
                  unoptimized
                />
                <p className={styles.missing}>
                  {loggedIn ? "Your Cart is empty" : "Missing Cart Item?"}
                  <span className={styles.login_msg}>
                    {loggedIn ? "But it doesn't have to be." : "Login to see items you added previously."}
                  </span>
                </p>
              </div>
              {!loggedIn ? (
                <CustomButton
                  data-test="loginButton"
                  showTransition={!!disableLoginBtn}
                  disableBtn={disableLoginBtn}
                  onClick={isMiniApp ? handlePaytmLogin : handleLogin}
                  buttonText="login"
                  buttonType="primary"
                  buttonStyle={{ boxShadow: "none" }}
                />
              ) : (
                ""
              )}
              <div className={loggedIn ? styles.LoggedIn : styles.notLoggedIn}>
                <CustomButton
                  data-test="trackButton"
                  showTransition={!!(loggedIn && disableShoppingBtn)}
                  disableBtn={disableShoppingBtn}
                  onClick={handleContinueShopping}
                  buttonText="Continue shopping"
                  buttonType={loggedIn ? "primary" : "secondary"}
                  buttonStyle={loggedIn ? { boxShadow: "none" } : {}}
                />
              </div>
            </div>
            {isMobile && <RecentlyViewed title="Recently Viewed" styleName="cart-preview" cidAttribute="CART_RVBY" />}
          </>
        )}
      </div>
    </>
  );
}
export default EmptyCart;
EmptyCart.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
};
