export const ACTION_TYPE = {
  GET_MY_ORDER_LIST_REQUESTED: "GET_MY_ORDER_LIST_REQUESTED",
  GET_MY_ORDER_LIST_SUCCESS: "GET_MY_ORDER_LIST_SUCCESS",
  GET_MY_ORDER_LIST_FAIL: "GET_MY_ORDER_LIST_FAIL",
  DELETE_EXPIRED_PRODUCT: "DELETE_EXPIRED_PRODUCT",
  DELETE_EXPIRED_PRODUCT_SUCCESS: "DELETE_EXPIRED_PRODUCT_SUCCESS",
  LOGOUT_REQUESTED: "LOGOUT_REQUESTED",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  GET_FAQ_DETAILS: "GET_FAQ_DETAILS",
  LOGOUT_FAIL: "LOGOUT_FAIL",
  CHECK_PAYMENT_STATUS_REQUESTED: "CHECK_PAYMENT_STATUS_REQUESTED",
  CHECK_PAYMENT_STATUS_SUCCESS: "CHECK_PAYMENT_STATUS_SUCCESS",
  CHECK_PAYMENT_STATUS_FAIL: "CHECK_PAYMENT_STATUS_FAIL",
  GET_COUNTRY_NAME_REQUESTED: "GET_COUNTRY_NAME_REQUESTED",
  GET_COUNTRY_NAME_SUCCESS: "GET_COUNTRY_NAME_SUCCESS",
  GET_COUNTRY_NAME_FAIL: "GET_COUNTRY_NAME_FAIL",
};

export const ORDER_STATUS = {
  WAITING: { label: "Waiting", color: "#e03131", icon: "history", index: -1 },
  CONFIRMED: { label: "Confirmed", color: "#7d8035", icon: "check_circle", index: 0 },
  CANCELLED: { label: "Cancelled", color: "#e03131", icon: "check_circle", index: -1 },
  DELIVERY_ATTEMPTED: { label: "Delivery Attempted", color: "#7d8035", icon: "check_circle", index: 3 },
  DISPATCHED: { label: "Dispatched", color: "#7d8035", icon: "check_circle", index: 2 },
  DELIVERED_ON: { label: "Delivered on", color: "#7d8035", icon: "local_shipping", index: 3 },
  DELIVERED: { label: "Delivered", color: "#7d8035", icon: "check_circle", index: 3 },
  LOST_IN_TRANSIT: { label: "Lost In Transit", color: "#e03131", icon: "history", index: 2 },
  RETURNED: { label: "Returned", color: "#e03131", icon: "history", index: -1 },
  HOLD: { label: "Hold", color: "#e03131", icon: "history", index: -1 },
  EXPIRED: { label: "Expired", color: "#e03131", icon: "history", index: -1 },
  READY_TO_BE_SHIPPED: { label: "Ready to be Shipped", color: "#7d8035", icon: "local_shipping", index: 2 },
  SHIPPED: { label: "Shipped", color: "#7d8035", icon: "local_shipping", index: 2 },
};

export const AccountRoutes = {
  ACCOUNT: "account",
  DESKTOP_ACCOUNT: "desktop-account",
  MY_ORDERS: "my-orders",
  MY_ADDRESS_BOOK: "myaddressbook",
  MY_ADDRESS_BOOK_EDIT: "myaddressbook-edit",
  MY_ADDRESS_BOOK_ADD: "myaddressbook-add",
  MY_TICKETS: "my-tickets",
  UPDATE_TICKET: "update-ticket",
  CREATE_TICKET: "create-ticket",
  VIEW_DETAILS: "view-details",
  CHANGE_PASSWORD: "changepassword",
  GIFT_VOUCHER: "gift-coupons",
  MY_PROFILE: "profile-edit",
  MY_REMINDERS: "reminder",
  ACCOUNT_HOME: "my-account",
  TRACK_ORDER: "track-order",
  SELF_HELP: "self-help",
};

export const accountPagesTitle = {
  [AccountRoutes.MY_ADDRESS_BOOK]: "Saved Addresses",
  [AccountRoutes.MY_ADDRESS_BOOK_EDIT]: "Edit Address",
  [AccountRoutes.MY_TICKETS]: "Ticket History",
  [AccountRoutes.UPDATE_TICKET]: "Ticket History",
  [AccountRoutes.CREATE_TICKET]: "Raise Query",
  [AccountRoutes.MY_REMINDERS]: "My Reminders",
  [AccountRoutes.MY_PROFILE]: "My Profile",
  [AccountRoutes.MY_ORDERS]: "My Orders",
  [AccountRoutes.GIFT_VOUCHER]: "My Gift Vouchers",
  [AccountRoutes.CHANGE_PASSWORD]: "Change Password",
  [AccountRoutes.VIEW_DETAILS]: "My Orders",
  [AccountRoutes.TRACK_ORDER]: "Track Order",
  [AccountRoutes.ACCOUNT_HOME]: "My Account",
};

const myProfile = {
  label: "MY PROFILE",
  icon: "person",
  route: `/account/${AccountRoutes.MY_PROFILE}`,
  slug1: AccountRoutes.MY_PROFILE,
  basePage: "account",
  color: "#2179d0",
};
const myOrders = {
  label: "MY ORDERS",
  icon: "redeem",
  route: `/account/${AccountRoutes.MY_ORDERS}`,
  slug1: AccountRoutes.MY_ORDERS,
  basePage: "account",
  color: "#f64b56",
  subText: "Order related Issues, Track Order & download invoice",
};
const giftVouchers = {
  label: "GIFT VOUCHERS",
  icon: "local_offer",
  route: `/account/${AccountRoutes.GIFT_VOUCHER}`,
  slug1: AccountRoutes.GIFT_VOUCHER,
  basePage: "account",
  color: "#ffa200",
};
const reminders = {
  label: "MY REMINDERS",
  icon: "notifications_active",
  route: `/account/${AccountRoutes.MY_REMINDERS}`,
  slug1: AccountRoutes.MY_REMINDERS,
  basePage: "account",
  color: "#976866",
  subText: "Manage your reminders & add a new reminder",
};
const savedAddress = {
  label: "SAVED ADDRESSES",
  icon: "folder_shared",
  route: `/account/${AccountRoutes.MY_ADDRESS_BOOK}`,
  slug1: AccountRoutes.MY_ADDRESS_BOOK,
  basePage: "account",
  color: "#009850",
  subText: "Save address for a hassle-free checkout",
};
const changePassword = {
  label: "CHANGE PASSWORD",
  icon: "lock",
  route: `/account/${AccountRoutes.CHANGE_PASSWORD}`,
  slug1: AccountRoutes.CHANGE_PASSWORD,
  basePage: "account",
  subText: "change your password",
};
const logout = {
  label: "LOGOUT",
  icon: "power_settings_new",
  route: "/control/logout?to=/",
  slug1: "",
  basePage: "home",
  subText: "Stay logged in for faster checkout",
};
const privacyPolicy = {
  label: "PRIVACY POLICY",
  icon: "description",
  route: "/info/privacy-policy",
  slug1: "",
  basePage: "",
  subText: "The security of your personal information is important to us",
};

export const HOMEPAGE_LIST = [myOrders, reminders, savedAddress, privacyPolicy, changePassword, logout];

export const SIDEBAR_LIST = [myOrders, myProfile, reminders, savedAddress, giftVouchers, changePassword, logout];

export const COUNTRY_CODE = {
  CANADA: "CAN",
  USA: "USA",
  AUSTRALIA: "AUS",
  UK: "GBR",
  INDIA: "IND",
};

export const TOLLFREE_NUMBER = {
  CAN_NUMBER: "+1 18338320102",
  USA_NUMBER: "+1 18338320109",
  AUS_NUMBER: "+61 1800571258",
  UK_NUMBER: "+44 8000489423",
  IND_NUMBER: "+91 9212422000",
  IND_NUMBER_DESKTOP: "+91 9212422000 / 9755248248",
};
