import { combineReducers } from "redux";
import configurationsReducer from "./appConfigs/configurationsReducer";
import userReducer from "./user/userReducer";
import productsListsReducer from "./productList/productListReducer";
import currenciesReducer from "./currencyReducer";
import appDataReducer from "./appData/appDataReducer";
import cmsMicroPageReducer from "./cmsMicroPageReducer";
import homePageDataReducer from "./home/homePageDataReducer";
import giftFinderReducer from "./giftFinder/giftFinderReducer";
import checkoutReducer from "./checkoutReducer";
import paymentsReducer from "./paymentsReducer";
import cartReducer from "./cartReducer";
import dFooterReducer from "./footer/dFooterReducer";
import locationLockReducer from "./location-lockReducer";
import productDescReducer from "./desktopProductReducer";
import addressBookReducer from "./addressBook/addressBookReducer";
import profileReducer from "./profileReducer";
import myAccountReducer from "./myAccount/myAccountReducer";
import ticketsReducer from "./ticketsReducer/ticketsReducer";
import changePasswordReducer from "./changePassword/changePasswordReducer";
import reminderReducer from "./reminder/reminderReducer";
import giftVoucherReducer from "./giftVoucher/giftVoucherReducer";
import yearsReducer from "./calender/yearsReducer";
import searchInsteadReducer from "./searchInsteadReducer";
import addOnsReducer from "./addOns/addOnsReducer";
import filterReducer from "./dplpFilterReducer";
import validLoginIdReducer from "./user/validIdReducer";
import checkLoginReducer from "./user/checkLogin";
import createUserReducer from "./user/createUserReducer";
import getCsrfReducer from "./user/getCsrfTokensReducer";
import loginReducer from "./user/loginReducer";
import validOtpReducer from "./user/validateOtpReducer";
import sendOtpReducer from "./user/sendOtpReducer";
import setPasswordReducer from "./user/setPasswordReducer";
import productDetailReducer from "./pdp/productDetailReducer";
import pdpContextReducer from "./pdp/pdpContextReducer";
import commonPageDataReducer from "./common/common-page-data-reducer";
import searchDataReducer from "./searchdata/search-data-reducer";
import dateRangeReducer from "./dateRangeReducer";
import walletReducer from "./user/walletReducer";

export default combineReducers({
  appConfigs: configurationsReducer,
  userDetails: userReducer,
  productsLists: productsListsReducer,
  currenciesList: currenciesReducer,
  appData: appDataReducer,
  cmsMicroPage: cmsMicroPageReducer,
  homePageData: homePageDataReducer,
  giftFinder: giftFinderReducer,
  checkout: checkoutReducer,
  payments: paymentsReducer,
  cartDetails: cartReducer,
  dFooter: dFooterReducer,
  locationLockLists: locationLockReducer,
  productDescription: productDescReducer,
  addressBook: addressBookReducer,
  profile: profileReducer,
  myAccount: myAccountReducer,
  tickets: ticketsReducer,
  changePassword: changePasswordReducer,
  reminder: reminderReducer,
  giftVoucher: giftVoucherReducer,
  totalYears: yearsReducer,
  searchInstead: searchInsteadReducer,
  addOnDetails: addOnsReducer,
  plpFilter: filterReducer,
  validID: validLoginIdReducer,
  checkLogin: checkLoginReducer,
  createUser: createUserReducer,
  csrfToken: getCsrfReducer,
  login: loginReducer,
  validOtp: validOtpReducer,
  sendOtp: sendOtpReducer,
  setPassword: setPasswordReducer,
  productDetail: productDetailReducer,
  pdpContext: pdpContextReducer,
  commonPageData: commonPageDataReducer,
  searchData: searchDataReducer,
  dateRangeSwitch: dateRangeReducer,
  walletDetails: walletReducer,
});
