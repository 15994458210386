import { call, put, takeEvery } from "redux-saga/effects";
import APP_CONSTANTS from "../../src/action-constants/app-actions";
import {
  errorInMicroSitePage,
  setCMSMicroSitePageData,
  setTestimonialData,
  errorTestimonial,
  setTestimonialCmsContent,
} from "../../src/actions/cms-microSite-actions";
import fetchTestimonialsContent from "../../src/services/api/micro-site/cms-api";
import commonPageSagaActions from "../../src/actions/common-page-saga-actions";
import { fetchContentFromCMS } from "../../src/services/api/common/common-api";

/**
 * This is a worker saga function that calls another worker saga setCMSCommonData
 * which in turn returns the main content
 *
 * @param {object} action - contains the response payload information of svn content
 */
function* getCMSMicroSiteData(action) {
  try {
    const { mainContent, errorStatus } = yield call(commonPageSagaActions, action);
    if (errorStatus) {
      const ctxRes = action.payload.ctx.res;
      ctxRes.statusCode = errorStatus;
    }
    if (mainContent) {
      yield put(setCMSMicroSitePageData(mainContent));
    }
  } catch (error) {
    yield put(errorInMicroSitePage(error.message));
  }
}

/**
 * This is a worker saga function that calls the get content for svn and sets the data in reducer
 *
 * @param {object} action - contains the response payload information of svn content
 */
function* getTestimonialData(action) {
  try {
    const res = yield call(fetchTestimonialsContent, action.payload);
    if (res.error) {
      yield put(errorTestimonial(res.error));
    } else {
      yield put(setTestimonialData(res));
    }
  } catch (error) {
    yield put(errorTestimonial(error.message));
  }
}

/**
 * This is a worker saga function that calls the get content for svn and sets the data in reducer
 *
 * @param {object} action - contains the response payload information of svn content
 */
function* getTestimonialCMSContentData(action) {
  try {
    const { bottomAnalytics, headAnalytics, metaContent, status } = yield call(fetchContentFromCMS, action.payload);
    if (status === 200) {
      yield put(setTestimonialCmsContent({ bottomAnalytics, headAnalytics, metaContent }));
    }
  } catch (error) {
    yield put(errorInMicroSitePage(error.message));
  }
}

/**
 * Watcher saga for SVN pages
 */
function* watchSVNPageDataSaga() {
  yield takeEvery(APP_CONSTANTS.REQUEST_CMS_MICRO_SITE_PAGE_DATA, getCMSMicroSiteData);
  yield takeEvery(APP_CONSTANTS.REQUEST_TESTIMONIAL_DATA, getTestimonialData);
  yield takeEvery(APP_CONSTANTS.REQUEST_TESTIMONIAL_CMS_CONTENT, getTestimonialCMSContentData);
}

export default watchSVNPageDataSaga;
