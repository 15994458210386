import { call, fork, put, takeLatest } from "redux-saga/effects";
import APP_CONSTANTS from "../../src/action-constants/app-actions";
import { getSystemPropertyValue } from "../../src/actions/common-actions";
import { setIsInternationalEnable, setIsInternationalEnableError } from "../../src/actions/pdp-actions";

/**
 * handle IsInternationalEnable
 *
 */
function* handleIsInternationalEnable() {
  try {
    const payload = { resource: "fnp", name: "isEnableITNTimeSlot" };
    const res = yield call(getSystemPropertyValue, payload);
    if (res?.value) yield put(setIsInternationalEnable(res.value));
  } catch (error) {
    yield put(setIsInternationalEnableError(error.message));
  }
}

/**
 * for handling is international enable
 */
function* watchIsInternationalEnableRequest() {
  yield takeLatest(APP_CONSTANTS.GET_IS_INTERNATIONAL_ENABLE, handleIsInternationalEnable);
}

/** This method is used to listen to respective dispatched action */
export default function* internationalEnableSaga() {
  yield fork(watchIsInternationalEnableRequest);
}
