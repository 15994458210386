const AddressBookConstants = {
  GET_ACCOUNT_ADDRESS_LIST_REQUESTED: "GET_ACCOUNT_ADDRESS_LIST_REQUESTED",
  GET_CHECKOUT_ADDRESS_LIST_REQUESTED: "GET_CHECKOUT_ADDRESS_LIST_REQUESTED",
  GET_ACCOUNT_ADDRESS_LIST: "GET_ACCOUNT_ADDRESS_LIST",
  GET_CHECKOUT_ADDRESS_LIST: "GET_CHECKOUT_ADDRESS_LIST",
  GET_ACCOUNT_EDIT_ADDRESS_DATA: "GET_ACCOUNT_EDIT_ADDRESS_DATA",
  GET_CHECKOUT_EDIT_ADDRESS_DATA: "GET_CHECKOUT_EDIT_ADDRESS_DATA",
  GET_EDIT_ADDRESS_DATA_SUCCESS: "GET_EDIT_ADDRESS_DATA_SUCCESS",
  SET_EDIT_ACCOUNT_ADDRESS_REQUESTED: "SET_EDIT_ACCOUNT_ADDRESS_REQUESTED",
  SET_EDIT_CHECKOUT_ADDRESS_REQUESTED: "SET_EDIT_CHECKOUT_ADDRESS_REQUESTED",
  EDIT_ADDRESS_CARD: "EDIT_ADDRESS_CARD",
  DELETE_ADDRESS_CARD_REQUESTED: "DELETE_ADDRESS_CARD_REQUESTED",
  DELETE_ADDRESS_CARD: "DELETE_ADDRESS_CARD",
  GET_ACCOUNT_ADDRESS_LIST_FAILURE: "GET_ACCOUNT_ADDRESS_LIST_FAILURE",
  GET_EDIT_ADDRESS_DATA_FAILURE: "GET_EDIT_ADDRESS_DATA_FAILURE",
  SET_EDIT_CHECKOUT_ADDRESS_EMPTY: "SET_EDIT_CHECKOUT_ADDRESS_EMPTY",
  RESET_EDIT_ADDRESS_DATA: "RESET_EDIT_ADDRESS_DATA",
};

export default AddressBookConstants;
