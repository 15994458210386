import React, { useState } from "react";
import PropTypes from "prop-types";

import dynamic from "next/dynamic";
import Dropdown from "../../hoc/dropdown/index";
import style from "./more-info-dropdown.module.scss";
import dropDownstyle from "../../hoc/dropdown/drop-down.module.scss";
import { EMPTY_VIEW_LABEL, MORE } from "../../../../../common/constants";

const MediaEnquiryDialog = dynamic(() => import("./MediaEnquiryDialog"), { ssr: false });

/**
 * Component is More Info Dropdown
 *
 * @param {object} props - contains moreInfoDropDown props.
 * @param {object} props.moreInfoDropDown Array of links of more info section.
 * @returns {React.ReactElement} retuns the More Info Dropdown to the Dheader.
 */
function MoreInfoDropdown({ moreInfoDropDown }) {
  const [showMediaDialog, setShowMediaDialog] = useState(false);

  /**
   * Returns the MoreInfo Sections
   *
   * @param {Array} items more info list
   * @returns {React.ReactElement} retuns the more info dection dropdown if items are not empty else retuns empty string
   */
  const renderMenu = (items) => {
    const EmptyView = <div className="account-dropdown__header">{EMPTY_VIEW_LABEL}</div>;

    const ListView = <ul className="menu">{items}</ul>;

    const showEmptyView = items.length === 0;

    return (
      <div
        className={`${dropDownstyle["menu-container"]} ${style["menu-width"]} ${dropDownstyle["more-info"]}`}
        isScroll={false}
        flow={false}
      >
        {showEmptyView ? EmptyView : ListView}
      </div>
    );
  };

  /**
   * This method has the switch statement based on the function name respective function will executed
   *
   * @param {string} fnToExec functionName to be invoke.
   */
  const handleOnClick = (fnToExec) => {
    if (fnToExec === "Media") {
      setShowMediaDialog((state) => !state);
    }
  };

  /**
   * More info detail
   *
   * @param {object} item  more info detail
   * @returns {React.ReactElement} retuns the more info item like Help center, Frenchise enquiry etc.
   */
  const renderItem = (item) => (
    <li className={dropDownstyle["menu-list"]} key={item.label}>
      <a
        href={item.href}
        onClick={() => {
          handleOnClick(item.label);
        }}
        className={style["more-info-items"]}
      >
        <i className="material-icons">{item.icon}</i>
        <span>{item.label}</span>
      </a>
    </li>
  );

  return (
    <>
      <Dropdown
        items={moreInfoDropDown}
        renderItem={renderItem}
        renderMenu={renderMenu}
        getItemValue={() => {}}
        arrow="more-info-arrow"
      >
        <div className={`${dropDownstyle["info-toolbar-dropdown-placeholder"]} ${style["more-info-strip"]}`}>
          <b className={style["bold-text"]}>{MORE}</b>
          <span>
            <i className="material-icons">expand_more</i>
          </span>
        </div>
      </Dropdown>
      <MediaEnquiryDialog open={showMediaDialog} handleCloseIconClick={handleOnClick} />
    </>
  );
}

MoreInfoDropdown.propTypes = {
  moreInfoDropDown: PropTypes.arrayOf(PropTypes.object).isRequired,
};
export default MoreInfoDropdown;
