import axios from "../../app_configs/axios/base";

/**
 * This method will fetch clientID to send to paytm servers
 *
 * @returns {object} data to be sent to paytm backend
 */
const fetchClientID = async () => {
  const { data } = await axios
    .get(`/control/getPaytmConfigs`)
    .then((response) => {
      if (response.data.isSuccess) {
        return response;
      }
      return { status: "fail", message: "Error at backend" };
    })
    .catch(() => {
      return { status: "fail", message: "Backend authontication fail" };
    });
  return data;
};
/**
 * This method will send authId and openId to backend
 *
 * @param {object} data  authId and OpenId sent by paytm.
 * @returns {object} if the login inside paytm mini app was successful from backend
 */
const getPaytmResponse = async (data) => {
  const loginResponse = await axios
    .get(`/control/paytmMiniAppLoginResponse?authId=${data.authId}&openId=${data.openId}`)
    .then((result) => {
      if (result?.data?.isSuccess) {
        return result;
      }
      return { status: "fail", message: "Error at backend" };
    })
    .catch(() => {
      return { status: "fail", message: "Backend authontication fail" };
    });
  return loginResponse;
};

/**
 * This method will check if user can login inside paytm mini app and get the data from paytm backend required for user to login
 *
 * @param {*} redirectionPath - path
 * @returns {object} to check if user can log in or not
 */
const checkPaytmLogin = async (redirectionPath) => {
  const clientIdData = await fetchClientID();
  const isPaytmMiniApp = /AppContainer/i.test(window.navigator.userAgent);
  let paytmAuthResponse;
  if (isPaytmMiniApp && typeof JSBridge !== "undefined") {
    paytmAuthResponse = new Promise((resolve) => {
      if (clientIdData.isSuccess) {
        if (clientIdData.clientId) {
          // This function works only inside paytmMiniApp
          // eslint-disable-next-line no-undef
          JSBridge.call(
            "paytmFetchAuthCode",
            {
              clientId: clientIdData.clientId,
            },
            // will only be calling this inside PaytmMiniApp for user authentication
            // eslint-disable-next-line func-names
            function (result) {
              if (result.error === -2 || result.error === -1) {
                resolve({ status: "loginDenied", message: result.errorMessage });
              } else if ([0, 2, -3, -4, -5, -6, -7, -8, -9, -10, -11, -12, -13, -14].indexOf(result.error) !== -1) {
                resolve({ status: "error", message: "Error from Paytm" });
              }
              const data = {
                authId: result?.data?.authId,
                openId: result?.data?.openId,
              };
              if (result && result.data && result.data.authId) {
                const response = getPaytmResponse(data).then((res) => {
                  if (res?.data?.isSuccess) {
                    window.location.href = redirectionPath;
                  }
                });
                resolve(response);
              } else {
                resolve({ status: "fail", message: "unauthorised" });
              }
            },
          );
        } else {
          //  If we fail to get clientId
          //  eslint-disable-next-line no-alert
          alert("Unable to fetch Client-Id");
          // This function works only inside paytmMiniApp
          // eslint-disable-next-line no-undef
          JSBridge.call("popWindow");
        }
      }
    });
  }
  return paytmAuthResponse;
};
export default checkPaytmLogin;
