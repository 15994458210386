import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import APP_CONSTANTS from "../../../../../src/action-constants/app-actions";
import styles from "./style.module.scss";
import { EMAIL_REQUIRED_LABEL, SUBMIT_LABEL, SUBSCRIBE_LABEL } from "../../../../common/constants";
/** returns Desktop Footer Email Suscription Form
 *
 *@returns {React.ReactElement} - React component
 */
function SubscriptionForm() {
  const [isSubmitted, setSubmitted] = useState(false);
  const [isValid, setValid] = useState(false);
  const [isdisable, setDisable] = useState(true);
  const [color, updateColor] = useState("#555");
  const [email, updateEmail] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const subscribe = useSelector((state) => state.dFooter.subscribe);
  const dispatch = useDispatch();
  /**
   * Method used for form input validations
   *
   * @param {object}e form input
   */
  const handleFormSubmit = (e) => {
    e.preventDefault();
    setDisable(true);

    if (email.length > 0) {
      setSubmitted(true);

      const reg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (reg.test(email)) {
        dispatch({ type: APP_CONSTANTS.SET_FOOTER_SUBSCRIPTION, payload: "" });
        dispatch({ type: APP_CONSTANTS.DESKTOP_FOOTER_SUBSCRIPTION, payload: email });
        setValid(true);
        updateColor("#009943");
      } else {
        dispatch({ type: APP_CONSTANTS.FOOTER_SUBSCRIPTION_ERROR });
        setValid(false);
        updateColor("#e53333");
      }
    } else {
      setShowMessage(true);
      setSubmitted(false);
      setDisable(true);
    }
  };
  /**
   * Method used to get the links to be passed to head
   *
   * @param {object}e form submit
   */
  const handleChange = (e) => {
    setShowMessage(false);
    setDisable(false);
    updateEmail(e.target.value);
  };

  return (
    <div className={styles.subscribe}>
      <h6>{SUBSCRIBE_LABEL}</h6>
      <form>
        <i className="material-icons">email_icon</i>
        <input id="subscribe-input" type="email" value={email} placeholder="Email" onChange={(e) => handleChange(e)} />
        <button
          className={isdisable && subscribe.loading ? styles.subscribeBtn : styles.subscribeBtn1}
          onClick={(e) => handleFormSubmit(e)}
          type="submit"
        >
          {SUBMIT_LABEL}
        </button>
      </form>
      {showMessage ? (
        <p className={showMessage ? styles.errorMsg : styles.errorMsg1}>{EMAIL_REQUIRED_LABEL}</p>
      ) : (
        <p className={styles.subscribeSuccesfull} style={{ color }}>
          {!isValid && isSubmitted && !subscribe.loading && (
            <span className={styles.icon}>
              <i className="material-icons">info</i>
            </span>
          )}
          {isValid && isSubmitted && !subscribe.loading && (
            <span className={styles.icon}>
              <i className="material-icons">check</i>
            </span>
          )}
          {subscribe.message}
        </p>
      )}
    </div>
  );
}

export default SubscriptionForm;
