import PDP_CONSTANTS from "../action-constants/pdp-constants";

/**
 * set user personalizations
 *
 * @param {object} data payload data
 * @returns {object} action
 */
export const setPdpUserSelections = (data) => ({
  type: PDP_CONSTANTS.SET_PDP_USER_SELECTIONS,
  payload: data,
});

/**
 * set selected type or quantity for the product
 *
 * @param {string} data payload data
 * @returns {object} action
 */
export const setSelectedType = (data) => ({
  type: PDP_CONSTANTS.SET_SELECTED_TYPE,
  payload: data,
});

/**
 * set selected flavour
 *
 * @param {string} data payload data
 * @returns {object} action
 */
export const setSelectedFlavour = (data) => ({
  type: PDP_CONSTANTS.SET_SELECTED_FLAVOUR,
  payload: data,
});

/**
 * set selected flavour
 *
 * @param {string} data payload data
 * @returns {object} action
 */
export const setFlavours = (data) => ({
  type: PDP_CONSTANTS.SET_FLAVOURS,
  payload: data,
});

/**
 * set selected variant
 *
 * @param {object} data payload data
 * @returns {object} action
 */
export const setSelectedVariant = (data) => ({
  type: PDP_CONSTANTS.SET_SELECTED_VARIANT,
  payload: data,
});

/**
 * set user personalizations
 *
 * @param {Array} data payload data
 * @returns {object} action
 */
export const setUserPersonalization = (data) => ({
  type: PDP_CONSTANTS.SET_USER_PERSONALIZATIONS,
  payload: data,
});

/**
 * set variant list
 *
 * @param {Array} data payload data
 * @returns {object} action
 */
export const setVariantList = (data) => ({
  type: PDP_CONSTANTS.SET_VARIANT_LIST,
  payload: data,
});

/**
 * set type or quantity list for the selected product
 *
 * @param {Array} data payload data
 * @returns {object} dispatcher object contains quantity or cake types list
 */
export const setQuantityList = (data) => ({
  type: PDP_CONSTANTS.SET_QUANTITY_OR_TYPE_LIST,
  payload: data,
});

/**
 * set delivery type
 *
 * @param {Array} data payload data
 * @returns {object} action
 */
export const setDeliveryType = (data) => ({
  type: PDP_CONSTANTS.SET_DELIVERY_TYPE,
  payload: data,
});

/**
 * set image configs
 *
 * @param {Array} data payload data
 * @returns {object} action
 */
export const setImageConfigs = (data) => ({
  type: PDP_CONSTANTS.SET_IMAGE_CONFIGS,
  payload: data,
});

/**
 * set upload status of ongoing multi image upload
 *
 * @param {boolean} data flag for upload status
 * @returns {object} returns the action dispatched
 */
export const setUploadProgress = (data) => ({
  type: PDP_CONSTANTS.SET_MULTI_IMAGE_UPLOAD_COMPLETED,
  payload: data,
});

/**
 * set delivery type
 *
 * @param {Array} data payload data
 * @returns {object} action
 */
export const setUserSelectedDelivery = (data) => ({
  type: PDP_CONSTANTS.SET_USER_SELECTED_DELIVERY,
  payload: data,
});
