const nextRoutes = require("next-routes");

const routes = nextRoutes()
  .add({
    name: "home",
    pattern: "/",
    page: "home/index",
  })
  .add({
    name: "desktop-home",
    pattern: "/",
    page: "desktop-home/index",
  })
  .add({
    name: "account",
    pattern: "/account/:slug1?/:slug2?",
    page: "account/index",
  })
  .add({
    name: "desktop-account",
    pattern: "/account/:slug1?/:slug2?",
    page: "desktop-account/index",
  })
  .add({
    name: "giftFinder",
    pattern: "/giftfinder",
    page: "giftfinder/index",
  })
  .add({
    name: "checkout",
    pattern: "/checkout/:slug1?/:slug2?",
    page: "checkout/index",
  })
  .add({
    name: "addOns",
    pattern: "/addons",
    page: "addons/index",
  })
  .add({
    name: "cartPreview",
    pattern: "/cartpreview",
    page: "cartpreview/index",
  })
  .add({
    name: "notfound",
    pattern: "/notfound",
    page: "notfound/index",
  })
  .add({
    name: "giftvoucher",
    pattern: "/giftvoucher",
    page: "giftvoucher/index",
  })
  .add({
    name: "experiences",
    pattern: "/experiences/:slug1?/:slug2?/:slug3?/:slug4?",
    page: "experiences/index",
  })
  .add({
    name: "testimonials",
    pattern: "/info/testimonials/:slug1?/:slug2?/:slug3?/:slug4?/:slug5?",
    page: "testimonials/index",
  })
  .add({
    name: "desktop-testimonials",
    pattern: "/info/testimonials/:slug1?/:slug2?/:slug3?/:slug4?/:slug5?",
    page: "desktop-testimonials/index",
  })
  .add({
    name: "microSite",
    pattern: "/(article|info|infographic|offers|quotes|services|shayari|ideas)/:slug1?/:slug2?/:slug3?/:slug4?/:slug5?",
    page: "microSite/index",
  })
  .add({
    name: "desktop-microsite",
    pattern: "/(article|info|infographic|offers|quotes|services|shayari|ideas)/:slug1?/:slug2?/:slug3?/:slug4?/:slug5?",
    page: "desktop-microsite/index",
  })
  .add({
    name: "mobile-pdp",
    pattern: "/gift/:slug1?/:slug2?/:slug3?/:slug4?",
    page: "mobile-pdp/index",
  })
  .add({
    name: "desktop-pdp",
    pattern: "/gift/:slug1?/:slug2?/:slug3?/:slug4?",
    page: "desktop-pdp/index",
  })
  .add({
    name: "mobile-pdpInternational",
    pattern: "/:slug1/gift/:slug2?/:slug3?/:slug4?/:slug5?",
    page: "mobile-pdp/index",
  })
  .add({
    name: "desktop-pdpInternational",
    pattern: "/:slug1/gift/:slug2?/:slug3?/:slug4?/:slug5?",
    page: "desktop-pdp/index",
  })
  .add({
    name: "categories",
    pattern: "/:slug1/:slug2?/:slug3?/:slug4?/:slug5?",
    page: "listing/index",
  })
  .add({
    name: "desktop-categories",
    pattern: "/:slug1/:slug2?/:slug3?/:slug4?/:slug5?",
    page: "desktop-listing/index",
  })
  .add({
    name: "search",
    pattern: "/search",
    page: "listing/index",
  });

module.exports = routes;
