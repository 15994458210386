import { HYDRATE } from "next-redux-wrapper";
import APP_CONSTANTS from "../../../src/action-constants/app-actions";

const InitialState = {
  success: false,
  error: false,
  tokens: {},
};

/**
 * This method is the reducer for actions that are specific to user
 *
 * @param {object} state - contains initial redux State
 * @param {object} action - contains actionTypes and payload information for specific action
 * @returns {object} state - the next State which needs to be dispatched to redux
 */
function getCsrfReducer(state = InitialState, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.csrfToken,
      };
    case APP_CONSTANTS.GET_CSRFTOKENS:
      return {
        ...state,
        success: false,
        error: false,
      };
    case APP_CONSTANTS.GET_CSRFTOKENS_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        tokens: action.payload.data,
      };
    case APP_CONSTANTS.GET_CSRFTOKENS_ERROR:
      return {
        ...state,
        success: false,
        error: true,
      };
    default:
      return state;
  }
}
export default getCsrfReducer;
