import { HYDRATE } from "next-redux-wrapper";
import geoDefault from "../../../geo_configurations/default";
import ListingPageConstants from "../../../src/action-constants/listing-contants";

const initialState = {
  productsList: [],
  size: geoDefault.size,
  totalPages: 0,
  total: 0,
  page: 0,
  categoryRating: 0,
  categoryReviews: 0,
  productCategoryName: "",
  searchOptions: {},
  breadCrumbs: [],
  categoryDesc: {},
  longDescription: "",
  shortDescription: "",
  categoryReviewsCollection: [],
  bannerDetails: "",
  isFetching: false,
  errMsg: "",
  sortOptions: [],
  htmlDOM: "",
  aggregateRating: {},
  uspData: "",
  productAttributeData: {},
  widgets: "",
  facetDetailList: [],
  isServiceable: false,
  loadingProducts: false,
  isLoading: false,
  widgetUrls: [],
  redirectUrl: "",
  geoId: "india",
  plpPromotionalBanners: [],
};

/**
 * This function is a reducer for all the product lists.
 * It includes data like productList, listsize etc.
 *
 * @param {object} state it represents the current state object
 * @param {object} action it represents the new action object which consists of the new payload.
 * @returns {object} Updated state object with the action object.
 */
function productsListsReducer(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.productsLists,
      };
    case ListingPageConstants.SET_PRODUCTS_LISTING:
      return {
        ...state,
        productsList: [...action.payload.results],
        categoryRating: action.payload.categoryRating,
        categoryReviews: action.payload.categoryReviews,
        productCategoryName: action.payload.productCategoryName,
        searchOptions: action.payload.options,
        isFetching: false,
        sortOptions: action.payload.sortOptions,
        htmlDOM: "",
        errMsg: "",
        productCategoryId: action.payload.productCategoryId,
        facetDetailList: action.payload.facetDetailList,
        totalPages: action.payload.totalPages,
        total: action.payload.total,
        page: action.payload.page,
        isServiceable: action.payload.serviceable,
        loadingProducts: false,
        geoId: action.payload?.geoId,
      };
    case ListingPageConstants.SET_CATEGORY_REVIEWS:
      return {
        ...state,
        categoryReviewsCollection: [...action.payload],
      };
    case ListingPageConstants.SET_BANNER_DETAILS:
      return {
        ...state,
        bannerDetails: action.payload,
      };
    case ListingPageConstants.SORTING_PRODUCTS_LIST:
      return {
        ...state,
        isFetching: true,
      };
    case ListingPageConstants.SORTING_PRODUCTS_FAILED:
      return {
        ...state,
        isFetching: false,
      };
    case ListingPageConstants.CURRENCY_CHANGES_DONE:
      return {
        ...state,
        productsList: action.payload.productList,
      };
    case ListingPageConstants.CLEAR_PRODUCTS_LISTING:
      return initialState;
    case ListingPageConstants.SET_HTML_MICRO_SITE_DATA:
      return {
        ...state,
        productsList: [],
        searchOptions: {},
        currentPage: action.payload.currentPage,
        total: action.payload.total,
        totalPages: action.payload.totalPages,
        categoryRatingValue: action.payload.categoryRatingValue,
        categoryTotalReview: action.payload.categoryTotalReview,
        productCategoryId: action.payload.productCategoryId,
        productCategoryName: action.payload.productCategoryName,
        htmlDOM: action.payload.htmlDOM,
        bestRating: action.payload.bestRating,
      };
    case ListingPageConstants.PRODUCT_LISTING_ERROR_MSG:
      return {
        ...state,
        errMsg: action.payload,
      };

    case ListingPageConstants.SET_PLP_USP:
      return {
        ...state,
        uspData: action.payload,
      };
    case ListingPageConstants.SET_PLP_LONG_DESCRIPTION:
      return {
        ...state,
        longDescription: action.payload,
      };
    case ListingPageConstants.SET_PLP_SHORT_DESCRIPTION:
      return {
        ...state,
        shortDescription: action.payload,
      };
    case ListingPageConstants.SET_PRODUCT_ATTRIBUTE_DATA:
      return {
        ...state,
        productAttributeData: action.payload,
      };
    case ListingPageConstants.SET_PLP_MICRO_SITE_WIDGETS:
      return {
        ...state,
        widgets: action.payload,
      };
    case ListingPageConstants.REQUEST_PRODUCTS_ON_PLP:
      return {
        ...state,
        loadingProducts: true,
      };
    case ListingPageConstants.SET_PRODUCT_LISTING_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case ListingPageConstants.SET_WIDGET_DATA:
      return {
        ...state,
        widgetUrls: action.payload,
      };
    case ListingPageConstants.SET_SEARCH_REDIRECT_URL:
      return {
        ...state,
        redirectUrl: action.payload.redirectUrl,
      };
    case ListingPageConstants.SET_PLP_PROMOTIONAL_BANNERS:
      return {
        ...state,
        plpPromotionalBanners: action.payload,
      };

    default:
      return state;
  }
}

export default productsListsReducer;
