import { takeEvery, put } from "redux-saga/effects";
import APP_CONSTANTS from "../../src/action-constants/app-actions";
import { validIdApi } from "../../src/actions/login-actions";

/**
 * This method is yielding fus api response in saga that is retrieving cartinfo and loginInfo
 *
 * @param {object} action - contains the response payload information of cartInfo and loginInfo
 * after calling fus api
 */
function* validLoginID(action) {
  try {
    const data = yield validIdApi(action.payload.emailData);
    yield put({ type: APP_CONSTANTS.VALID_ID_SUCCESS, payload: { data, email: action.payload.email } });
  } catch (error) {
    yield put({ type: APP_CONSTANTS.VALID_ID_FAILURE, error });
  }
}

/**
 * This method is yielding fus api response in saga that is retrieving cartinfo and loginInfo
 *
 * @param {object} action - contains the response payload information of cartInfo and loginInfo
 * after calling fus api
 */
function* updateValidUser(action) {
  yield put({ type: APP_CONSTANTS.SET_VALID_USER_EXISTS, payload: action.payload.value });
}

/**
 * This method is used to yield success response of CHECK_LOGIN_CART_INFO action
 *
 */
function* validLoginIdSaga() {
  yield takeEvery(APP_CONSTANTS.VALID_ID, validLoginID);
  yield takeEvery(APP_CONSTANTS.SET_VALID_USER, updateValidUser);
}

export default validLoginIdSaga;
