import React from "react";
import PropTypes from "prop-types";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  deliveryLocation: { marginLeft: 5, color: theme.color?.darkBlack },
  deliveryDateLabel: {
    padding: "5px 2px",
    display: "block",
  },
  deliveryLocationInfo: {
    background: "#f8f8f8",
    padding: 10,
    borderRadius: 4,
    margin: "5px 0px",
  },
  locationPinIcon: {
    color: theme.color?.red,
    fontSize: 18,
    verticalAlign: "sub",
  },
}));

/**
 * Component to render delivery location info
 *
 * @param {*} param0 props passed to component
 * @param {string} param0.deliveryLocation string contains delivery location info.
 * @returns {React.ReactElement} jsx for the delivery location info component.
 */
const DeliveryLocationInfo = ({ deliveryLocation, ...props }) => {
  const classes = useStyles(props);
  return (
    <div>
      <span className={classes.deliveryDateLabel}> Deliver To </span>
      <div className={classes.deliveryLocationInfo}>
        <LocationOnIcon className={classes.locationPinIcon} />
        <span className={classes.deliveryLocation}>{`${deliveryLocation}`}</span>
      </div>
    </div>
  );
};

const propTypes = {
  deliveryLocation: PropTypes.string.isRequired,
};

DeliveryLocationInfo.propTypes = propTypes;

export default DeliveryLocationInfo;
