import React from "react";
import MenuIcon from "@material-ui/icons/Menu";
import { IconButton } from "@material-ui/core";
import PropTypes from "prop-types";

/**
 * This component is used to show left nav menu icon in the m-site header
 *
 * @param {object} root0 props from parent
 * @param {Function} root0.openLeftMenu onclick handler to open left nav menu
 *
 * @returns {React.ReactElement} - renders left-menu-icon-btn component
 */
const LeftMenuIconBtn = ({ openLeftMenu }) => {
  return (
    <IconButton disableRipple color="inherit" onClick={openLeftMenu} aria-label="Left Menu Icon">
      <MenuIcon />
    </IconButton>
  );
};

LeftMenuIconBtn.propTypes = {
  openLeftMenu: PropTypes.func,
};

LeftMenuIconBtn.defaultProps = {
  openLeftMenu: () => {},
};

export default LeftMenuIconBtn;
