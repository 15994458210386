import { call, put, takeLatest } from "redux-saga/effects";
import APP_CONSTANTS from "../../src/action-constants/app-actions";
import { getRetryPaymentDetails } from "../../src/actions/cartPreviewActions";

/**
 * dispatches the action with payload data for reducer
 */
export function* getRetryPaymentInfo() {
  try {
    const retryPayment = yield call(getRetryPaymentDetails);
    yield put({
      type: APP_CONSTANTS.GET_RETRY_PAYMENT_INFO_SUCCESS,
      payload: retryPayment,
    });
  } catch (error) {
    yield put({ type: APP_CONSTANTS.SET_CART_ERROR_MESSAGE, payload: error });
  }
}
/**
 * saga for fetching Retry Payment Info
 *
 */
function* watchRetryPayments() {
  yield takeLatest(APP_CONSTANTS.GET_RETRY_PAYMENT_INFO_REQUEST, getRetryPaymentInfo);
}
export default watchRetryPayments;
