import { call, put, takeEvery } from "redux-saga/effects";
import HomeActionsConstants from "../../src/action-constants/home-actions-constants";
import {
  errorHomePageBodyData,
  errorMessageHomePage,
  setHomePageBodyData,
  setTrendingNowWidget,
  setHomePageAccordionData,
  setHomePageFooterData,
} from "../../src/actions/home-actions";
import { SOMETHING_WENT_WRONG } from "../../src/constants/common/errorConstants";
import commonPageSagaActions from "../../src/actions/common-page-saga-actions";
import { setLockTrigger } from "../../src/actions/location-lock-actions";

/**
 * Fetches home page content data
 *
 * @param {object} action contains type and payload
 */
function* getAndSetPageContentData(action) {
  try {
    const { mainContent, trendingNowWidget, footerSEOContent, footer, errorStatus, triggerGateLock } = yield call(
      commonPageSagaActions,
      action,
    );
    if (errorStatus) {
      const ctxRes = action.payload.ctx.res;
      ctxRes.statusCode = errorStatus;
    }
    if (mainContent) {
      yield put(setHomePageBodyData(mainContent));
    }
    if (trendingNowWidget) {
      yield put(setTrendingNowWidget(trendingNowWidget));
    }
    if (footerSEOContent) {
      yield put(setHomePageAccordionData(footerSEOContent));
    }
    if (footer) {
      yield put(setHomePageFooterData(footer));
    }
    if (triggerGateLock) {
      const value = parseInt(triggerGateLock, 10) || 0;
      yield put(setLockTrigger(value));
    }
  } catch (error) {
    yield put(errorMessageHomePage(SOMETHING_WENT_WRONG));
    yield put(errorHomePageBodyData(error.message));
  }
}

/**
 * Watcher saga for home page
 */
function* watchHomePageDataSaga() {
  yield takeEvery(HomeActionsConstants.SERVER_REQUEST_MOBILE_HOME_CONTENT_DATA, getAndSetPageContentData);
}

export default watchHomePageDataSaga;
