import loadINDIAConfig from "./IN/index";
/* const loadUAEConfig = require("./UAE/index");
const loadSINGAPOREConfig = require("./SG/index"); */

/**
 *
 * @param {boolean} isMobile request user agent is mobile or not
 * @param {string} country request is from which domain
 * @returns {object} return domain specific configs
 */
const getAppGeoConfig = (isMobile, country) => {
  switch (country) {
    /* case "UAE":
      return loadUAEConfig(isMobile);
    case "SG":
      return loadSINGAPOREConfig(isMobile); */
    default:
      return loadINDIAConfig(isMobile);
  }
};

export default getAppGeoConfig;
