import { HYDRATE } from "next-redux-wrapper";
import APP_CONSTANTS from "../../src/action-constants/app-actions";

const initialState = {
  cartSummary: {},
  priceDetails: {},
  errorMsg: "",
  pncCartData: [],
  isLoadingCartDetails: false,
  isLoadingPncCard: true,
  isLoadingAddonUpdate: false,
  isLoadingDeleteItem: false,
  showBackDrop: false,
  firstTimeLoad: true,
  showAddItemToCart: false,
};

/**
 * This method is the reducer for actions that are specific to App
 *
 * @param {object} state - contains initial redux State
 * @param {object} action - contains actionTypes and payload information for specific action
 * @returns {object} state - the next State which needs to be dispatched to redux
 */
function cartReducer(state = initialState, action) {
  const { type, payload } = action;
  let viewCounts = null;
  switch (type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.cartDetails,
      };
    case APP_CONSTANTS.GET_CART_SUMMARY_INFO_REQUEST:
      return {
        ...state,
        isLoadingCartDetails: true,
      };
    case APP_CONSTANTS.GET_CART_SUMMARY_INFO_SUCCESS:
      if (payload?.items) {
        viewCounts = {
          laxt: new Date().getTime(),
        };
        payload.items.forEach((item) => {
          const key = item.isVariant ? item.baseProductId : item.productId;
          viewCounts[key] = true;
        });
      }
      window?.localStorage.setItem("vc", JSON.stringify(viewCounts));
      return {
        ...state,
        cartSummary: payload,
        isLoadingCartDetails: false,
        isLoadingAddonUpdate: false,
        isLoadingDeleteItem: false,
      };
    case APP_CONSTANTS.GET_PRICE_DETAILS_SUCCESS:
      return {
        ...state,
        priceDetails: payload,
        isLoadingCartDetails: false,
        isLoadingPncCard: false,
      };
    case APP_CONSTANTS.SET_CART_ERROR_MESSAGE:
      return {
        ...state,
        errorMsg: payload.errorMessage,
        isLoadingPncCard: true,
      };
    case APP_CONSTANTS.GET_RETRY_PAYMENT_INFO_SUCCESS:
      return {
        ...state,
        pncCartData: payload,
        isLoadingPncCard: false,
      };
    case APP_CONSTANTS.SHOW_LOADER:
    case APP_CONSTANTS.UPDATE_ADDON_QUANTITY_REQUEST:
      return {
        ...state,
        isLoadingAddonUpdate: true,
      };

    case APP_CONSTANTS.DELETE_CART_ITEM_REQUEST:
      return {
        ...state,
        isLoadingDeleteItem: true,
      };
    case APP_CONSTANTS.SHOW_BACKDROP_REQUEST:
      return {
        ...state,
        showBackDrop: payload,
      };
    case APP_CONSTANTS.GET_CART_DETAILS_FIRST_TIME:
      return {
        ...state,
        firstTimeLoad: payload,
      };
    case APP_CONSTANTS.SHOW_ITEM_NOTIFICATION:
      return {
        ...state,
        showAddItemToCart: payload,
      };

    default:
      return state;
  }
}
export default cartReducer;
