import getConfig from "next/config";
import gateway from "../../../../app_configs/axios/gateway";
import errorLogger from "../../../../app_configs/logger-service";

const { publicRuntimeConfig } = getConfig();
const showRequestLogs = publicRuntimeConfig.SHOW_REQUESTS_LOGS;
const moodyAPIVersion = publicRuntimeConfig.MOODY_API_VERSION;
const error = {
  config: {},
  response: {},
};

/**
 * Method to fetch content from SVN
 *
 * @param {object} options .
 * @param {string} options.pageType pageType .
 * @param {string} options.categoryId category id for page
 * @param {string} options.productId id for page
 * @param {string} options.domainId domain id for page
 * @param {string} options.month current month
 * @param {string} options.year current year
 * @param {number} options.current page .
 * @param {number} options.size total testimonials
 * @param {string} options.requestType request type
 * @param {object} options.reviewSummaryCSR reviews
 * @returns {string} returns html file data
 */
export default async function fetchTestimonialsContent(options) {
  const endPoint = `${moodyAPIVersion}testimonials`;
  const {
    pageType = "dateRange",
    categoryId = "",
    productId = "",
    domainId,
    month = "",
    year = "",
    page = 0,
    size = 20,
    requestType = "",
    reviewSummaryCSR = {},
  } = options;
  let reviewSummary = {};

  if (requestType === "ssr" && (pageType === "category" || pageType === "product")) {
    const categoryEndPoint = `${moodyAPIVersion}category/ratings`;
    const productEndPoint = `${moodyAPIVersion}product/rating`;
    try {
      let params = {};
      let requestEndPoint = "";
      if (pageType === "category") {
        params = {
          categoryId,
          domainId,
        };
        requestEndPoint = categoryEndPoint;
      }
      if (pageType === "product") {
        params = {
          productId,
          domainId,
        };
        requestEndPoint = productEndPoint;
      }
      const response = await gateway.get(requestEndPoint, {
        params,
      });
      reviewSummary = response.data;
    } catch (err) {
      error.config.url = endPoint;
      error.response.status = `An Exception occurred while calling rel alt API => ${err}`;
      errorLogger(err, showRequestLogs);
      throw new Error(err);
    }
  }

  try {
    const response = await gateway.get(`${endPoint}`, {
      params: {
        pageType,
        categoryId,
        productId,
        month,
        year,
        domainId,
        page,
        size,
      },
    });

    if (requestType === "csr") {
      response.data.reviewSummary = reviewSummaryCSR;
    } else {
      response.data.reviewSummary = reviewSummary;
    }
    return response.data;
  } catch (err) {
    error.config.url = endPoint;
    error.response.status = `An Exception occurred while fetching results => ${err}`;
    errorLogger(error, showRequestLogs);
    return {
      error: err.message,
    };
  }
}
