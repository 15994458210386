import { takeEvery, put, call } from "redux-saga/effects";
import APP_CONSTANTS from "../../src/action-constants/app-actions";
import { createUserApi } from "../../src/actions/login-actions";
import { CheckoutRoutes } from "../../src/action-constants/checkout-constants";
import { Router } from "../../routes/routes";
import { initiateFus } from "./fusSaga";

/**
 * This method is yielding fus api response in saga that is retrieving cartinfo and loginInfo
 *
 * @param {object} action - contains the response payload information of cartInfo and loginInfo
 * after calling fus api
 */
function* createUser(action) {
  try {
    const data = yield createUserApi(action.payload);
    if (data.data.isSuccess) {
      yield put({ type: APP_CONSTANTS.CREATE_USER_SUCCESS, payload: data });
      if (Router.router.pathname === `/${CheckoutRoutes.CHECKOUT}`) yield call(initiateFus);
    } else {
      yield put({ type: APP_CONSTANTS.CREATE_USER_ERROR, error: data.data.errorMsg });
    }
  } catch (error) {
    yield put({ type: APP_CONSTANTS.CREATE_USER_ERROR, error });
  }
}

/**
 * This method is used to yield success response of CHECK_LOGIN_CART_INFO action
 *
 */
function* createUserSaga() {
  yield takeEvery(APP_CONSTANTS.CREATE_USER, createUser);
}

export default createUserSaga;
