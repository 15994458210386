import { createWrapper } from "next-redux-wrapper";
import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import getConfig from "next/config";
import rootReducer from "../reducers/index";
import rootSaga from "../sagas/rootSaga";

const { publicRuntimeConfig } = getConfig();

/**
 *
 * @param {Array} middleware accepts the redux middleware
 * @returns {object} store with the supplied middleware
 */
const bindMiddleware = (middleware) => {
  if (publicRuntimeConfig.NODE_ENV !== "production") {
    // eslint-disable-next-line global-require
    const { composeWithDevTools } = require("redux-devtools-extension");
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

/**
 *
 * @returns {object} redux store
 */
const makeStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(rootReducer, bindMiddleware([sagaMiddleware]));
  store.sagaTask = sagaMiddleware.run(rootSaga);
  return store;
};

const wrapper = createWrapper(makeStore, {
  debug: false,
});

export default wrapper;
