import React from "react";
import PropTypes from "prop-types";
import Modal from "@material-ui/core/Modal";

import { isMobileDevice } from "../../../src/utils/common";
import styles from "./loader.module.scss";

/**
 * @param {object} root .
 * @param {boolean} root.showLoading to enable disable loader
 * @param {string} root.altText alternative text
 * @returns {React.ReactElement} jsx for full loader
 */
function FullScreenLoader({ showLoading, altText }) {
  const isMobile = isMobileDevice();

  return (
    <div>
      {showLoading ? (
        <Modal open disableRestoreFocus className={styles.modalLoader}>
          <div className={styles.loaderWrapper}>
            <img
              src={isMobile ? "/assets/images/full_page_loader-bb.gif" : "/assets/images/full_page_loader.gif"}
              alt={altText}
            />
          </div>
        </Modal>
      ) : null}
    </div>
  );
}

FullScreenLoader.defaultProps = {
  altText: "Loading...",
};

FullScreenLoader.propTypes = {
  showLoading: PropTypes.bool.isRequired,
  altText: PropTypes.string,
};

export default FullScreenLoader;
