import getConfig from "next/config";
import axios from "../../../../app_configs/axios/base";
import gateway from "../../../../app_configs/axios/gateway";

const { publicRuntimeConfig } = getConfig();
const columbusPath = publicRuntimeConfig.COLUMBUS_API_VERSION || "/";

/**
 * This function returns facets for gift finder
 *
 * @param {object} options params for api
 * @returns {Array} object containing gift finder facet data
 */
export const getFacetsForGiftFinder = async (options) => {
  const { params } = options;

  try {
    const res = await gateway.get(`${columbusPath}productList/facet`, { params: { ...params } });
    return res?.data?.facetDetailList;
  } catch (e) {
    throw new Error(e);
  }
};

/**
 * This function gets pin code for a provided location
 *
 * @param {object} payload input payload.
 * @returns {void} returns nothing
 */
export async function getPincodeBasedOnLocation(payload) {
  try {
    const { latitude, longitude } = payload;
    const pinCodeResponse = await axios.get(
      `control/getPinCodeByPostalAddress?latitude=${latitude}&longitude=${longitude}`,
    );
    const pinCode = pinCodeResponse.data.pincode || pinCodeResponse.data.matchedPincode;

    return pinCode;
  } catch (e) {
    throw new Error(e);
  }
}

/**
 * This function gets pin code for a provided location
 *
 * @param {object} payload input payload.
 * @returns {void} returns nothing
 */
export async function getCityNameBasedOnLocation(payload) {
  try {
    const { locality, geoLocality, pincode } = payload;
    const cityResponse = await axios.get(
      `control/getCityForPostalAddress?pincode=${pincode}&locality=${locality}&geolevel2=${geoLocality}`,
    );
    return cityResponse;
  } catch (e) {
    throw new Error(e);
  }
}

/**
 * This function provides redirection URL for giftFinder
 *
 * @param {object} options input payload to call the find-gifts api.
 * @returns {object} returns response object
 */
export const fetchRedirectApi = async (options) => {
  const { params } = options;
  try {
    const response = await gateway.get(`${columbusPath}find-gifts`, {
      params: { ...params },
    });
    return response;
  } catch (err) {
    throw new Error(err);
  }
};
