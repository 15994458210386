export const DEFAULT_CURRENCY = "INR";

/**
 * To get user local currency based on the akamai response header
 *
 * @param {string} edgeHeader next context object
 * @param {Array} currencyList list of currencies
 *
 * @returns {string} user local currency
 */
const getUserLocalCurrency = (edgeHeader, currencyList) => {
  const countryCode = edgeHeader?.split(",")[0].split("=")[1];
  if (countryCode === "IN") return DEFAULT_CURRENCY;

  const userCountry = currencyList?.find((item) => item.currency.geoCode.includes(countryCode));
  return userCountry?.currency?.currencyCode || DEFAULT_CURRENCY;
};

export default getUserLocalCurrency;
