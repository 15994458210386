import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import CustomCheckBox from "../../../../common/checkbox/customCheckbox";
import SearchFilter from "../../../../common/searchFilter/searchFilter";
import CheckboxLabel from "../../../../desktop/plp/facet-filters/checkboxLabel";
import { PLP } from "../../../../common/constants";

/**
 * This function renders the facet filters options list
 *
 * @param {object} root0 - props
 * @param {Array} root0.facetOptions - filter options
 * @param {string} root0.facetFieldName - filter type name
 * @param {string} root0.facetFieldDisplayName - display name
 * @param {object} root0.defaultSelection - default filter selection
 * @returns {React.ReactElement} nsx for filter options
 */
const FilterOptionsList = ({ facetOptions, facetFieldName, facetFieldDisplayName, defaultSelection }) => {
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setSearchTerm("");
  }, [facetFieldName]);

  /**
   * This function handles the search filter
   *
   * @param {string} changedTerm text typed in search box
   */
  const changeSearchTerm = (changedTerm) => {
    setSearchTerm(changedTerm);
  };

  return (
    <>
      <div aria-label="facet-options-list">
        {facetOptions?.length > 20 && (
          <SearchFilter
            searchTerm={searchTerm}
            filterName={`${PLP.SEARCH} ${facetFieldDisplayName}`}
            changeSearchTerm={changeSearchTerm}
            isMobile
          />
        )}
        {facetOptions
          ?.filter((options) => options.displayName.toLowerCase().includes(searchTerm.toLowerCase()))
          .map((options) => (
            <CustomCheckBox
              key={options.displayName}
              facetOption={options}
              facetFieldName={facetFieldName}
              facetFieldDisplayName={facetFieldDisplayName}
              defaultSelection={defaultSelection}
              isMobile
              checkBoxLabel={<CheckboxLabel facetOption={options} facetFieldName={facetFieldName} isMobile />}
            />
          ))}
      </div>
    </>
  );
};

FilterOptionsList.propTypes = {
  facetOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  facetFieldName: PropTypes.string.isRequired,
  facetFieldDisplayName: PropTypes.string.isRequired,
  defaultSelection: PropTypes.objectOf(PropTypes.any),
};
FilterOptionsList.defaultProps = {
  defaultSelection: {},
};
export default FilterOptionsList;
