import axios from "../../../app_configs/axios/base";
import { execDataLayer } from "../../data-layer/dataLayer";
import { endpointConst } from "../../data-layer/dataLayerConstants";

/**
 * This method fetches all orders
 *
 * @returns {object} all orders
 */
export async function getMyOrders() {
  try {
    const res = await axios.get("/control/getUserSubOrderDetails?allDetails=Y&viewSize=40&viewIndex=0&selfHelp=Y");
    return res?.data || {};
  } catch (ex) {
    return ex;
  }
}

/**
 * This method deletes exp products
 *
 * @param {string} orderId orderId of the order.
 * @returns {object} response
 */
export async function deleteExpiredProduct(orderId) {
  try {
    const res = await axios.get(`/control/remove-myorder?orderId=${orderId}`);
    return res?.data || {};
  } catch (ex) {
    throw new Error(ex);
  }
}

/**
 * This method logs out user from app
 *
 * @returns {object} api response
 */
export async function logout() {
  /**
   * @function apiFn wrapper function to execute logout redirection  from datalayer.
   */
  function apiFn() {
    window.location.href = endpointConst.logout;
  }
  execDataLayer(apiFn, endpointConst.logout);
}

/**
 * This method fetches payment status
 *
 * @param {string} orderId orderId of the order.
 * @returns {object} response
 */
export async function getOrderPaymentPreferenceStatus(orderId) {
  try {
    const res = await axios.get(`/control/getOrderPaymentPreferenceStatus?orderId=${orderId}`);
    return res?.data || {};
  } catch (ex) {
    return ex;
  }
}

/**
 * This method fetches Country code and Country name.
 *
 * @param {string} subOrderId subOrderId of the order.
 * @returns {object} response
 */
export async function getSenderMobileCountryCodeAndCity(subOrderId) {
  try {
    const res = await axios.get(`control/getSenderMobileCountryCodeAndCity?subOrderId=${subOrderId}`);
    return res?.data || {};
  } catch (ex) {
    return ex;
  }
}
