export const POPULAR_SEARCH_LOWERCASE = "popular search";
export const SEARCH_SUGGESTIONS = "search suggestions";
export const SEARCH_LIST_HEADING_LABEL = "Popular Searches";
export const POPULATED_SEARCH_LIST_HEADING_LABEL = "Your Personalized Category Suggestions";
export const CALL_US_LABEL = "Call Us";
export const HI_GUEST_LABEL = "Hi Guest";
export const EMPTY_VIEW_LABEL = "Empty View";
export const SEARCH_BOX_PLACE_HOLDER = "Search flowers, cakes, gifts, etc.";
export const NEED_TODAY_LABEL = "Need Today?";
export const HELP_CENTER_LABEL = "Help Center";
export const EMAIL_REQUIRED_LABEL = "Email is Required";
export const SUBMIT_LABEL = "SUBMIT";
export const SUBSCRIBE_LABEL = "SUBSCRIBE";
export const KEEP_IN_TOUCH_LABEL = "Keep in touch";
export const COPYRIGHT_LABEL = "fnp.com.All rights reserved.";
export const OUT_OF_STOCK = "Out of Stock";
export const FREE_SHIPPING = "free-shipping";
export const MY_CART = "My Cart";
export const MAKE_IT_EXTRA_SPECIAL = "Add on something to make it extra special!";
export const WHAT_CUSTOMERS_SAYING = "What Customers Are Saying";
export const SOMETHING_WENT_WRONG = "Something went wrong";
export const WHAT_USERS_SAY = "What our users say about us";
export const NO_REVIEWS = "No reviews available";
export const TIMEOUT_PERIOD_500 = 500;
export const INR = "INR";
export const INR_CURRENCY_SYMBOL = "₹";
export const DOMAIN = "domain";
export const PAGE_URL = "pageUrl";
export const DEVICE_TYPE = "deviceType";
export const PAGE_TYPE = "pageType";
export const D_NAME = "dname";
export const PIN_CODE = "pincode";
export const MORE = "More";
export const COUNTRIES = {
  INDIA: "india",
};

export const COUNTRIES_CODE = {
  INDIA: "IN",
};

export const READ_MORE = "Read More...";
export const READ_LESS = "Read Less";
export const FOOTER_DESCRIPTION_LENGTH = 800;
export const DELIVERY_DATE_LABEL = "Select Delivery Date";
export const LocationLockStaticConstants = {
  SET_LOGGED_IN_PINCODE: "SET_LOGGED_IN_PINCODE",
  WITHIN_INDIA: "Within India",
  OUTSIDE_INDIA: "Outside India",
  CATALOG_ID_INDIA: "india",
  DELIVER_TO: "Deliver To",
  SELECT_DELIVERY_LOCATION: "Select Delivery Location",
  SELECT_DELIVERY_LOCATION_DATE: "Select Delivery Location & Date",
  CHOOSE_DELIVERY_LOCATION: "Choose Delivery Location",
  CHOOSE_DELIVERY_LOCATION_DATE: "Choose Delivery Location & Date",
  CHOOSE_YOUR_DELIVERY_LOCATION: "Select Delivery Location",
  WHERE_WOULD_YOU_LIKE_TO_GET_THE_PRODUCT_DELIVERED:
    "Please enter the correct delivery location or pincode to view available products and delivery options",
  CONTINUE: "Continue Shopping",
  BTN: "BTN",
  ICON: "ICON",
  DONT_KNOW_PINCODE: "Don't Know Pincode?",
  DONT_SEE_YOUR_PINCODE: "Don't see your Pincode?",
  ENTER_CORRECT_PINCODE_FOR_HASSLE_FREE_TIMELY_DELIVERY: " Enter correct Pincode for hassle free timely delivery.",
  ENTER_PINCODE_OR_LOCALITY: "* Enter delivery location or pincode or area",
  ENTER_PINCODE: "Enter Delivery Pincode",
  PINCODE: "Pincode",
  ENTER_DELIVERY_AREA: "Enter delivery location or pincode or area",
  ENTER_AREA_OR_LOCALITY_TO_GET_THE_PINCODE: "Enter area or locality to get the Pincode.",
  PF: "pf",
  LAST_SELECTED_PINCODE: "lastSelectedPincode",
  LAST_SELECTED_ADDRESS: "lastSelectedAddress",
  LAST_SELECTED_DELIVERY_DATE: "lastSelectedDeliveryDate",
  ALL_SELECTED_COUNTRIES: "selectedCountries",
  COUNTRY_CODE_INDIA: "IND",
  LLP: "llp",
  DELIVERY_TO: "Delivery to",
  DELIVERY: "Delivery",
  TO: "to",
  LL_DRAWER_SUB_HEADING:
    "Please enter the correct delivery location or pincode to view available products and delivery options",
  LL_DRAWER_BTN_TEXT: "Enter delivery location or pincode or area",
  LL_SELECT_COUNTRY: "Select Country",
  LL_SELECT_CITY: "Select City",
  LL_Location_Suggestion: "*Enter Delivery Area or Locality",
  LL_HEADER_DL: "Select Delivery Location",
  AUTO_TRIGGER_LOCK: "autoTriggerLock",
  LL_AREA_DOES_NOT_EXIST: "Area does not exist. Please enter correct area name.",
  NO_CITY_FOUND: "No city found",
  SEARCH_COUNTRY: "Search Country",
  SELECT_COUNTRIES: "Select Country",
  SELECT_DELIVERY_CITY: "Select Delivery City",
  ENTER_DELIVERY_CITY: "Enter Delivery City",
  NATIVE_EVENT_INPUT_TYPE: "deleteContentBackward",
  PINCODE_CHANGE_METHOD_TYPE: "type",
  LAST_SELECTED_LOCATION: "lastSelectedIntlLoc",
  LAST_SELECTED_CITY: "lastSelectedIntlCity",
  LL_NOT_INDIA_DELIVERY: "Not India Delivery?",
  SELECTED_INTERNATIONAL_CITY: "selectedIntlCity",
  SELECT_DELIVERY_DATE_AFTER_PINCODE_SELECT: "You can select date of delivery after providing valid pincode",
  OUTSIDE: "OUTSIDE",
  YOU_WILL_BE_REDIRECTED: "You will be redirected to our",
  PERSONALIZE_YOUR_EXPERIENCE: "Let's Personalize Your Experience!",
  FIND_PERFECT_GIFT_FOR_YOU: "Find the perfect gifts for you or your loved ones – it's like magic!",
};
export const OOPS_PAGE = {
  CONTACT_US_NUMBER: "+91 92124 22 000",
  CALL_US_ON: "Call us on",
  NEED_HELP: "Need Help ?",
  FOUR_MILLION: "4 Million",
  PEOPLE_TRUST_US: "People Trust Us",
};
export const PLP = {
  NO_PRODUCT_MATCH_FOUND: "No product match found.",
  CHANGE_DELIVERY_PINCODE_OR_AREA_AND_TRY_AGAIN: "Change delivery pincode or area and try again.",
  CHANGE_DELIVERY_PINCODE_AREA: "CHANGE DELIVERY PINCODE/AREA",
  LESS_THAN: "Less than",
  TO: "to",
  AND_ABOVE: "and above",
  AND_BEYOND: "& above",
  NO_PRODUCT_FOUND: "No Products Found",
  NO_PRODUCT_IN_THIS_CATEGORY_FOR_YOUR_LOCATION: "No products in this category for your location",
  VIEW_AVAILABLE_GIFT_FOR: "View available gifts for",
  NOT_SERVICEABLE: "Not Serviceable",
  WE_ARE_SORRY: "We are sorry! We do not deliver in this location for now",
  CHANGE_DELIVERY_PINCODE: "Change Delivery Pincode/Area",
  WE_COULD_NOT_FIND_ANY_PRODUCTS: "We could not find any products for your selected criteria",
  RESET_FILTERS: "RESET FILTERS",
  FILTERS: "Filters",
  CLEAR: "Clear",
  CLEAR_ALL: "Clear All",
  SEARCH: "Search",
  SHOW_MORE: "Show More",
  SHOW_LESS: "Show Less",
  mSIZE: 40,
  mFILTER_HASH: "#selm",
  NOT_SERVICABLE_CATEGORY: "No products found in this category",
  VIEW_AVAILABLE_GIFT: "View available gifts",
  ABOVE: "ABOVE",
  SHOW_RESULTS_FOR: "Show results for",
};

export const FILTER = {
  DISCARD_MODAL_HEADING: "Discard Changes?",
  DISCARD_MODAL_SUB_TEXT: "You applied some filters. What would you like to do with these changes?",
  DISCARD_MODAL_ACTION_APPLY: "APPLY",
  DISCARD_MODAL_ACTION_DISCARD: "DISCARD",
};

export const FACET_FIELD_NAME = {
  PRODUCT_TYPE_TAGS_RELATION: "product_type_tags_relation",
};
export const SUPPRESSION_MESSAGE =
  "Products count might change after applying filters as we intend to show you the most relevant results";
export const SUPPRESSION_TIMER = 6000;
export const FEATURE_NAME = {
  DATE_LOCK: "date_lock",
};

export const DEVICE_TYPES = {
  DESKTOP: "Desktop",
  MOBILE: "Mobile",
};

export const WIDGET_TYPES = {
  RECENTLY_VIEWED: "recentlyViewedByYouWidget",
  TRENDING_NOW: "trendingNowWidget",
  BEST_SELLERS: "bestSellersWidget",
};

export const GA4_EVENTS = {
  PINCODE_ENTERED: "pincode_entered",
  PINCODE_AVAILABLE: "pincode_available",
  VIEW_ITEM_LIST: "view_item_list",
  FILTER_CLICKED: "filter_clicked",
  SELECT_ITEM: "select_item",
  SEARCH: "search",
};

export const APP_REDIRECTION_BANNER_ENDPOINT = "global/static/web/mobile/fnp_app_redirect";

export const WALLET_CONST = {
  FNP_CASH: "FNP Cash",
};
