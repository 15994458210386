import { HYDRATE } from "next-redux-wrapper";
import profileConstants from "../../src/action-constants/profile-constants";

const initialState = {
  customerData: {},
  isSuccess: false,
  isUpdating: false,
};
/**
 *
 * @param {object} state - initial state
 * @param {object} action - action
 * @returns {object} state - the next State which needs to be dispatched to redux
 */
const profileReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.profile,
      };
    case profileConstants.DETAILS_RECEIVED:
      return {
        ...state,
        customerData: payload.customer,
      };
    case profileConstants.UPDATE_DETAILS:
      return {
        ...state,
        isUpdating: true,
      };
    case profileConstants.UPDATE_DETAIL_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isUpdating: false,
      };
    case profileConstants.RESET_SUCCESS_FLAG:
      return {
        ...state,
        isSuccess: false,
        customerData: {},
      };
    case profileConstants.ERROR_MESSAGE:
      return {
        ...state,
        showError: true,
        isUpdating: false,
      };
    case profileConstants.GET_DETAILS:
      return {
        ...state,
        showError: false,
      };
    default:
      return state;
  }
};
export default profileReducer;
