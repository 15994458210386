import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";

/**
 * This component is used to show search icon in the m-site header
 *
 * @param {object} root0 props from parent
 * @param {Function} root0.openSearch onclick handler to open search bar
 *
 * @returns {React.ReactElement} - renders search-icon-btn component
 */
const SearchIconBtn = ({ openSearch }) => {
  return (
    <IconButton color="inherit" disableRipple onClick={openSearch} aria-label="Search Icon">
      <SearchIcon />
    </IconButton>
  );
};

SearchIconBtn.propTypes = {
  openSearch: PropTypes.func,
};

SearchIconBtn.defaultProps = {
  openSearch: () => {},
};

export default SearchIconBtn;
