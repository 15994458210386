// TODO: refactor and remove baseUrl from API's

import get from "lodash/get";
import queryString from "query-string";
import getConfig from "next/config";
import fetchCurrencies from "./common-actions";
import axios from "../../app_configs/axios/base";
import errorLogger from "../../app_configs/logger-service";
import PDP_CONSTANTS from "../action-constants/pdp-constants";
import { fetchClientSideContentDetails } from "../services/api/plp/product-listing-api";
import APP_CONSTANTS from "../action-constants/app-actions";
import { omitObjectKeys } from "../utils/common";
import availablePinCodes from "../helpers/available-pincodes";
import { execDataLayer } from "../data-layer/dataLayer";
import { endpointConst } from "../data-layer/dataLayerConstants";

const { publicRuntimeConfig } = getConfig();
const BASE_URL = publicRuntimeConfig.BASE_URL || "/";
const SHOW_REQUESTS_LOGS = publicRuntimeConfig.SHOW_REQUESTS_LOGS || "Y";

const error = {
  config: {},
  response: {},
};

/**
 * update product variant info
 *
 * @param {object} productInfo  product info
 * @returns {object} action
 */
export const updateProductVariant = (productInfo) => ({
  type: PDP_CONSTANTS.UPDATE_PRODUCT_VARIANT,
  payload: productInfo,
});

/**
 * update product PriceDetailsByDate
 *
 * @param {object} payload product PriceDetailsByDate
 * @returns {object} action
 */
export const updatePriceDetailsByDate = (payload) => ({
  type: PDP_CONSTANTS.SET_PRICE_DETAILS_BY_DATE,
  payload,
});

/**
 * This function fetches product data
 *
 * @param {object} params .
 * @returns {object} rel alternate data on success and empty on exception.
 */
export async function getProductDetails(params) {
  try {
    const res = await axios.get(`control/getProductDetails-rj?${queryString.stringify(params)}`);
    return get(res, "data", {});
  } catch (ex) {
    error.config.url = "/control/getProductDetails-rj";
    error.response.status = `An exception occurred while fetching product details => ${ex}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return {};
  }
}

/**
 * Action for setting delivery time left
 *
 * @param {object} payload payload for the action object
 * @returns {object} action
 */
export const setDeliveryTimeLeft = (payload) => ({
  type: PDP_CONSTANTS.SET_DELIVERY_TIME_LEFT,
  payload,
});

/**
 * Action for getting delivery time left
 *
 * @param {object} payload payload for the action object
 * @returns {object} action
 */
export const getDeliveryTimeLeft = (payload) => ({
  type: PDP_CONSTANTS.GET_DELIVERY_TIME_LEFT,
  payload,
});

/**
 * Fetch contentID for offers available data
 *
 * @param {object} payload contains offers available data
 * @returns {object} action
 */
export const getContentById = (payload) => ({
  type: PDP_CONSTANTS.FETCH_CONTENT_ID,
  payload,
});

/** DELIVERY ON STARTS */
/**
 * This function fetches shipping dates and methods
 *
 * @param {string} pincode pincode
 * @param {string} productID product ID
 * @param {Function} dispatch dispatcher
 * @param {string} assocProductId assocProductId (optional)
 * @param {boolean} isInternational assocProductId (optional)
 * @returns {object} rel alternate data on success and empty on exception.
 */
export async function getDatesAndShippingMethods(pincode, productID, dispatch, assocProductId = "", isInternational) {
  let productId = productID;
  if (assocProductId) {
    productId = `${productID},${assocProductId}`;
  }

  try {
    let params = {
      pinCode: pincode,
      productId,
    };
    if (isInternational) {
      params = {
        geoId: pincode,
        productId,
      };
    }

    dispatch({ type: PDP_CONSTANTS.SHIPPING_FETCH_START });

    const res = await axios.get(`control/getDatesAndShippingMethods-rj?${queryString.stringify(params)}`);
    let { data } = res;
    if (assocProductId) {
      data = { ...data, ...data[productID] };
      const deliveryDates = {
        [productID]: data[productID].deliveryDates[0] || "_NA_",
        [assocProductId]: data[assocProductId].deliveryDates[0] || "_NA_",
      };
      dispatch({
        type: PDP_CONSTANTS.SET_EARLIEST_DELIVERY_DATES,
        payload: deliveryDates,
      });
    }
    dispatch({ type: PDP_CONSTANTS.SET_DATES_AND_SHIPPING_METHODS, payload: data });
    return data;
  } catch (ex) {
    throw Error(ex);
  }
}

/**
 * This function fetches shipping details
 *
 * @param {object} root0 it includes object from parent component
 * @param {string} root0.productId product Id
 * @param {string} root0.pinCode pincode
 * @param {string} root0.deliveryDate delivery date
 * @param {string} root0.countryGeoId country Geo Id
 * @param {Function} dispatch dispatcher
 * @param {boolean} isInternational isInternational
 * @returns {object} rel alternate data on success and empty on exception.
 */
export async function getShippingDetails(
  { productId, pinCode, deliveryDate, countryGeoId },
  dispatch,
  isInternational,
) {
  try {
    let params = {
      productId,
      pinCode,
      deliveryDate,
      countryGeoId,
    };

    if (isInternational) {
      params = {
        productId,
        geoId: pinCode,
        deliveryDate,
        countryGeoId,
      };
    }

    dispatch({ type: PDP_CONSTANTS.SHIPPING_DETAILS_FETCH_START });
    const res = await axios.get(`control/getShippingDetails-rj?${queryString.stringify(params)}`);
    dispatch({ type: PDP_CONSTANTS.SET_SHIPPING_DETAILS, payload: res.data });
    return get(res, "data", {});
  } catch (ex) {
    dispatch({ type: PDP_CONSTANTS.SHIPPING_DETAILS_FETCH_ERROR });
    error.config.url = "/control/getShippingDetails-rj";
    error.response.status = `An exception occurred while fetching shipping details => ${ex}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return {};
  }
}

/**
 * This function fetches blocked date info
 *
 * @param {string} pincode pincode
 * @param {string} productId product Id
 * @param {Function} dispatch dispatcher
 * @returns {object} rel alternate data on success and empty on exception.
 */
export async function getBlockedDates(pincode, productId, dispatch) {
  try {
    const params = {
      format: "json",
      pincode,
      productId,
    };
    const res = await axios.get(`runtime/control/getBlockedDates?${queryString.stringify(params)}`);
    dispatch({ type: PDP_CONSTANTS.SET_BLOCKED_DATES, payload: res.data });
    return get(res, "data", {});
  } catch (ex) {
    error.config.url = "runtime/control/getBlockedDates";
    error.response.status = `An exception occurred while fetching blocked dates => ${ex}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return {};
  }
}

/**
 * This function fetches price surge info
 *
 * @param {Function} dispatch dispatcher
 * @param {string} productId productId
 * @returns {object} rel alternate data on success and empty on exception.
 */
export async function getPriceSurgeDetails(dispatch, productId) {
  try {
    const res = await axios.get(`control/getPriceSurgeForProductId?productId=${productId}`);
    dispatch({ type: PDP_CONSTANTS.SET_PRICE_SURGE, payload: res.data });
    return get(res, "data", {});
  } catch (ex) {
    error.config.url = `/control/getPriceSurgeForProductId?productId=${productId}`;
    error.response.status = `An exception occurred while fetching price surge details => ${ex}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return {};
  }
}

/** DELIVERY ON ENDED */

/**
 * This function fetches pincode list by using the first two digit of pincode
 *
 * @param {*} pincode  it refers to first two digit of pincode.
 * @param {Function} dispatch dispatcher
 * @returns {Array} rel alternate data on success and empty on exception.
 */
export async function getPincodes(pincode, dispatch) {
  if (availablePinCodes.indexOf(pincode) > -1) {
    try {
      const res = await axios.get(`assets/js/pincodes/${pincode}.json`, {
        withCredentials: false,
      });

      const sanitizedPinCodes = res.data.map((row) => {
        return {
          pincode: Object.keys(row)[0],
          location: row[Object.keys(row)[0]],
        };
      });

      const uniquePinCodes = [];
      sanitizedPinCodes.forEach((value) => {
        if (!uniquePinCodes.find((o) => o.pincode === value.pincode)) {
          uniquePinCodes.push(value);
        }
      });

      dispatch({ type: PDP_CONSTANTS.SET_PINCODES, payload: uniquePinCodes });
      return get(res, "data", []);
    } catch (ex) {
      error.config.url = `assets/js/pincodes/${pincode}.json`;
      error.response.status = `An exception occurred while fetching pincode JSON => ${ex}`;
      errorLogger(error, SHOW_REQUESTS_LOGS);
      return [];
    }
  } else {
    return [];
  }
}

/**
 * Populating PDP offers data to the store
 *
 * @param {object} productData offer data
 * @returns {object} offer data on success
 */
export const populateOffersData = (productData) => ({
  type: PDP_CONSTANTS.SET_OFFERS,
  payload: typeof productData !== "string" ? "" : productData,
});

/**
 * Populating same sku content
 *
 * @param {object} content content
 * @param {Function} dispatch dispatcher
 */
const populateSameSkuContent = (content, dispatch) => {
  dispatch({
    type: PDP_CONSTANTS.SET_SAME_SKU_CONTENT,
    payload: content,
  });
};

/**
 * update product PriceDetailsByPincode
 *
 * @param {object} payload product PriceDetailsByPincode
 * @param {object} dispatch dispatch for store
 */
export const updatePriceDetailsByPincode = (payload, dispatch) => {
  dispatch({
    type: PDP_CONSTANTS.SET_PRICE_DETAILS_BY_PINCODE,
    payload,
  });
};

/**
 * This function fetches you may also like products
 *
 * @param {string} catalogId catalog Id
 * @param {string} productId product Id
 * @param {Function} dispatch dispatcher
 * @returns {object} rel alternate data on success and empty on exception.
 */
export async function getYouMayAlsoLike(catalogId, productId, dispatch) {
  try {
    const params = {
      FNP_CURRENT_CATALOG_ID: catalogId,
      productId,
    };
    const res = await axios.get(`/control/youmayalsolike-rj?${queryString.stringify(params)}`);
    const data = get(res, "data", null);
    let productList = [];
    if (data) {
      productList = data.data.productResults ? data.data.productResults : [];
    }

    dispatch({ type: PDP_CONSTANTS.SET_YOU_MAY_ALSO_LIKE_PRODUCTS, payload: productList });
    return get(res, "data", []);
  } catch (ex) {
    return {};
  }
}

/**
 * This function fetches you may also like products
 *
 * @param {string} catalogId  product catalog id like india etc.
 * @param {string} pageType  page type.
 * @param {Array} productIds  product Ids.
 * @returns {object} rel alternate data on success and empty on exception.
 */
export async function getProductListing(catalogId, pageType, productIds) {
  try {
    const params = {
      catalogId,
      pageType,
      productIds,
      FNP_CURRENT_CATALOG_ID: catalogId || "india",
    };
    const res = await axios.get(`/control/getProductListing-rj?${queryString.stringify(params)}`);
    return get(res, "data", {});
  } catch (ex) {
    return {};
  }
}

/**
 * Populating WOAV offers to add what others are viewing data to the store
 *
 * @param {Array} productData product data
 * @param {Function} dispatch dispatcher
 */
const populateWoavData = (productData, dispatch) => {
  dispatch({
    type: PDP_CONSTANTS.SET_WOAV_DATA,
    payload: productData,
  });
};
/**
 * Function to get random 10 woav products
 *
 * @param {Array} products products
 * @param {number} showNumOfProducts number of products to be shown
 * @returns {Array} randomProducts
 */
function getRandomWoavProducts(products, showNumOfProducts) {
  const randomProduct = [...products].sort(() => 0.5 - Math.random());
  return randomProduct.slice(0, showNumOfProducts).toString();
}

/**
 * This function fetch what others are viewing product ids then get product list by using these product ids and sets that data to the store
 *
 * @param {string} catalogId  product catalog id like india etc.
 * @param {Function} dispatch dispatcher
 * @param {string} productId  productId
 * @param {string} isRakhiProduct  page type.
 * @returns {object} rel alternate data on success and empty on exception.
 */
export async function getWhatOthersAreViewingData(catalogId, dispatch, productId, isRakhiProduct = "N") {
  try {
    let productIds;
    const pageType = "woav";

    const params = {
      catalogId,
      isRakhiProduct,
    };
    const res = await axios.get(`/control/whatOthersAreViewing?${queryString.stringify(params)}`);
    const data = get(res, "data", {});
    if (data.responseStatus === "success") {
      productIds = data.data.productIds;
      const filterProduct = productIds.filter((item) => item !== productId);
      const shuffledProducts = getRandomWoavProducts(filterProduct, 10);
      productIds = productIds.length > 0 ? shuffledProducts : "";
    }

    let woavProductsList = [];
    const woavProducts = await getProductListing(catalogId, pageType, productIds);
    if (woavProducts.isSuccess) {
      woavProductsList = woavProducts.data.productResults;
      populateWoavData(woavProducts.data.productResults, dispatch);
    }
    return woavProductsList;
  } catch (ex) {
    return {};
  }
}

/**
 * Populating recently viewed products and add listing to the store
 *
 * @param {Array} productData product data
 * @param {Function} dispatch dispatcher
 */
export const populateRecentViewedProducts = (productData, dispatch) => {
  dispatch({
    type: PDP_CONSTANTS.SET_RECENTLY_VIEWED_PRODUCTS,
    payload: productData,
  });
};

/**
 * This function fetches recently viewed products
 *
 * @param {string} catalogId  product catalog id like india etc.
 * @param {string} pageType  page type.
 * @param {Array} productIds  product Ids.
 * @param {Function} dispatch dispatcher
 * @returns {object} rel alternate data on success and empty on exception.
 */
export async function recentlyViewedData(catalogId, pageType, productIds, dispatch) {
  try {
    let recentlyViewedProducts = [];
    const recentProducts = await getProductListing(catalogId, pageType, productIds);
    if (recentProducts.isSuccess) {
      recentlyViewedProducts = recentProducts.data.productResults;
      populateRecentViewedProducts(recentProducts.data.productResults, dispatch);
    }
    return recentlyViewedProducts;
  } catch (ex) {
    return {};
  }
}

/**
 * This function fetches product add ons
 *
 * @param {object} params  request payload for getting addons.
 * @param {Function} dispatch dispatcher
 * @returns {object} rel alternate data on success and empty on exception.
 */
export async function getAddonsInfo(params, dispatch) {
  try {
    const res = await axios.get(`/control/getAddonsInfo-rj?${queryString.stringify(params)}`);
    const data = get(res, "data", null);
    if (data && data.isSuccess) {
      dispatch({
        type: PDP_CONSTANTS.SET_ADDONS,
        payload: data,
      });
    }
    return get(res, "data", {});
  } catch (ex) {
    error.config.url = "/control/getAddonsInfo-rj";
    error.response.status = `An exception occurred while fetching addons => ${ex}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return {};
  }
}
/**
 * This function is used to upload file to pre signed url
 *
 * @param {string} presignedUrl  request payload for getting pre signed url.
 * @param {object} file  request payload for getting pre signed url.
 * @param {Function} dispatch dispatcher
 * @returns {object} rel alternate data on success and empty on exception.
 */
const uploadWithPresignedUrl = async (presignedUrl, file, dispatch) => {
  dispatch({
    type: PDP_CONSTANTS.SET_UPLOAD_PROGRESS_BAR,
    payload: {
      totalSize: "",
      sizeRemaining: "",
      percentComplete: "",
    },
  });
  const result = await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", presignedUrl);
    xhr.upload.onprogress = (e) => {
      if (e.lengthComputable) {
        const percentComplete = Math.ceil((e.loaded / e.total) * 100);
        const bytesInMegabyte = 1048576;
        const totalSize = e.total / bytesInMegabyte;
        const sizeRemaining = e.loaded / bytesInMegabyte;
        dispatch({
          type: PDP_CONSTANTS.SET_UPLOAD_PROGRESS_BAR,
          payload: {
            totalSize: totalSize.toFixed(1),
            sizeRemaining: sizeRemaining.toFixed(1),
            percentComplete,
          },
        });
      }
    };
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.response);
      } else {
        reject(
          new Error({
            status: xhr.status,
            statusText: xhr.statusText,
          }),
        );
      }
    };
    xhr.onerror = () => {
      reject(
        new Error({
          status: xhr.status,
          statusText: xhr.statusText,
        }),
      );
    };
    xhr.send(file);
  });
  return result;
};

/**
 * This function is used to get AWS Pre Signed URL
 *
 * @param {object} params  request payload for getting pre signed url.
 * @param {object} file  request payload for getting pre signed url.
 * @param {Function} dispatch dispatcher
 * @returns {object} rel alternate data on success and empty on exception.
 */
export async function uploadToS3(params, file, dispatch) {
  try {
    // get pre signed url
    const res = await axios.get(`/control/getAWSPreSignedUrl-rj?${queryString.stringify(params)}`);
    const response = get(res, "data", {});
    if (response.isSuccess) {
      const { presignedUrl } = response.data;
      await uploadWithPresignedUrl(presignedUrl, file, dispatch);
    }
    return get(res, "data", {});
  } catch (ex) {
    error.config.url = "/control/getAWSPreSignedUrl-rj";
    error.response.status = `An exception occurred while uploading to S3 => ${ex}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return {};
  }
}

/**
 * This function is used to get same sku contents and sets that data to the store
 *
 * @param {Function} dispatch dispatcher
 */
export async function getSameSKUContent(dispatch) {
  const data = await fetchClientSideContentDetails("know_more");
  if (data) {
    populateSameSkuContent(data, dispatch);
  }
}

/**
 * This function fetches earliest delivery dates
 *
 * @param {string} productIds  list of product ids
 * @param {string} catalogId catalogId
 * @param {Function} dispatch dispatcher
 * @returns {object} rel alternate data on success and empty on exception.
 */
export async function getEarliestDeliveryDays(productIds, catalogId, dispatch) {
  const params = {
    productIds,
    FNP_CURRENT_CATALOG_ID: catalogId || "india",
  };
  try {
    const res = await axios.get(`/control/getEarliestDeliveryDays-rj?${queryString.stringify(params)}`);
    const data = get(res, "data", null);
    if (data) {
      dispatch({
        type: PDP_CONSTANTS.SET_EARLIEST_DELIVERY_DATES,
        payload: data?.deliveryDates,
      });
    }
    return get(res, "data", {});
  } catch (ex) {
    error.config.url = "/control/getEarliestDeliveryDays-rj";
    error.response.status = `An exception occurred while fetching earliest delivery days => ${ex}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return {};
  }
}

/**
 * This function  adds item into the cart
 *
 * @param {object} params  request payload for adding item to cart.
 * @returns {object} rel alternate data on success and empty on exception.
 */
export async function addItemToCart(params) {
  try {
    /**
     * @function apiFn wrapper function to execute add item from datalayer.
     * @returns response from datalayer
     */
    const apiFn = async () => {
      return axios.post(`${endpointConst.addItem}`, queryString.stringify(params), {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      });
    };
    const res = await execDataLayer(apiFn, endpointConst.addItem);
    return get(res, "data", {});
  } catch (ex) {
    error.config.url = "/control/additem-rj";
    error.response.status = `An exception occurred while adding item to cart => ${ex}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return {};
  }
}

/**
 * clear already set pincode
 *
 * @param {Function} dispatch dispatcher
 */
export const clearPincodes = (dispatch) => {
  dispatch({
    type: PDP_CONSTANTS.CLEAR_PINCODES,
    payload: [],
  });
};

/**
 * This function is used to get new product details and update store
 *
 * @param {object} requestPayload info to fetch product details
 * @param {Function} dispatch dispatcher
 * @returns {object} product info
 */
export async function updateProductDetails(requestPayload, dispatch) {
  const productData = await getProductDetails(requestPayload);
  if (productData && productData.isSuccess) {
    dispatch({
      type: PDP_CONSTANTS.SET_PRODUCT,
      payload: {
        results: productData.data,
      },
    });
  }
  return productData;
}

/**
 * This function is used to set AWS Pre Signed URL
 *
 * @param {object} params  request payload for getting pre signed url.
 * @param {object} file  request payload for getting pre signed url.
 * @param {Function} dispatch dispatcher
 * @returns {object} rel alternate data on success and empty on exception.
 */
export async function setPersonalizedImage(params, file, dispatch) {
  const data = await uploadToS3(params, file, dispatch);
  if (data && data.isSuccess) {
    dispatch({
      type: PDP_CONSTANTS.SET_PERSONALIZED_IMAGE,
      payload: data.data.presignedUrl,
    });
  }
  return data;
}

/**
 * This function fetches earliest delivery dates
 *
 * @param {object} params  request payload for getting product price by date.
 * @param {boolean} isPincodeLevel a flag to check, price surge is pincode level or not
 * @param {object} dispatch dispatch object
 * @returns {object} rel alternate data on success and empty on exception.
 */
export async function getProductPriceByDeliveryDate(params, isPincodeLevel, dispatch) {
  try {
    dispatch({ type: PDP_CONSTANTS.FETCH_PRICE_DETAILS_BY_DATE_START });
    const res = await axios.get(`/control/getProductPriceByDeliveryDate-rj?${queryString.stringify(params)}`);
    const data = get(res, "data", null);
    if (data && data.isSuccess) {
      if (isPincodeLevel) {
        updatePriceDetailsByPincode(data, dispatch);
      } else {
        dispatch(updatePriceDetailsByDate(data));
      }
    }
    return get(res, "data", {});
  } catch (ex) {
    dispatch({ type: PDP_CONSTANTS.FETCH_PRICE_DETAILS_BY_DATE_ERROR });
    throw Error(ex);
  }
}

/**
 * This method is used to set pdp currency
 *
 * @param {string} currency Its the currencies of different products which changes bases on Country.
 * @param {Array} currencyList currency list
 * @param {Function} dispatch dispatcher
 * @param {boolean} isMobile isMobile flag
 */
export const setCurrency = async (currency, currencyList, dispatch, isMobile) => {
  let currencies = currencyList || [];

  if (currencies.length < 1 && isMobile) {
    const resp = await fetchCurrencies();
    currencies = resp.data?.currencies || [];
    dispatch({
      type: APP_CONSTANTS.GET_CURRENCIES_LIST,
      payload: currencies,
    });
  }
  if (currencies.length > 0) {
    const selectedCurrencyDetails = currencies.filter((x) => x.currency.id === currency);
    if (selectedCurrencyDetails && selectedCurrencyDetails.length > 0) {
      const currencyFactor = selectedCurrencyDetails[0].currency;
      dispatch({
        type: PDP_CONSTANTS.SET_PDP_CURRENCY,
        payload: {
          currencyCode: currencyFactor.currencyCode,
          currencyFactor: currencyFactor.factor,
        },
      });
    }
  }
};

/**
 * This function is used to fetch cities
 *
 * @param {object} params  request payload for getting product price by date.
 * @param {Function} dispatch dispatcher
 * @returns {object} rel alternate data on success and empty on exception.
 */
export async function getCityForIntlProducts(params, dispatch) {
  try {
    const res = await axios.get(`/control/getCityForIntlProducts-rj?${queryString.stringify(params)}`);
    const data = get(res, "data", null);
    if (data && data.isSuccess) {
      dispatch({
        type: PDP_CONSTANTS.SET_INTERNATIONAL_CITIES,
        payload: data.cities,
      });
    }
    return get(res, "data", {});
  } catch (ex) {
    error.config.url = "/control/getCityForIntlProducts-rj";
    error.response.status = `An exception occurred while fetching international city => ${ex}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return {};
  }
}

/**
 * This function is used to fetch shipment types for international products
 *
 * @param {object} params  request payload for getting product price by date.
 * @param {Function} dispatch dispatcher
 * @returns {object} rel alternate data on success and empty on exception.
 */
export async function getEarliestShipmentTypesForINTL(params, dispatch) {
  try {
    const res = await axios.get(`/control/getEarliestShipmentTypesForINTL-rj?${queryString.stringify(params)}`);
    const data = get(res, "data", null);
    if (data && data.isSuccess) {
      dispatch({
        type: PDP_CONSTANTS.SET_INTERNATIONAL_SHIPPING_DETAILS,
        payload: data,
      });
    }
    return get(res, "data", {});
  } catch (ex) {
    error.config.url = "/control/getEarliestShipmentTypesForINTL-rj";
    error.response.status = `An exception occurred while fetching international shipping types => ${ex}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return {};
  }
}

/**
 * This function is used to fetch shipment types for international products
 *
 * @param {object} params  request payload for getting product price by date.
 * @param {Function} dispatch dispatcher
 * @returns {object} rel alternate data on success and empty on exception.
 */
export async function getDeliverDatesForINTL(params, dispatch) {
  try {
    dispatch({
      type: PDP_CONSTANTS.DELIVERY_DATES_FETCH_START,
    });
    const res = await axios.get(`/control/getDeliverDatesForINTL-rj?${queryString.stringify(params)}`);
    const data = get(res, "data", null);
    if (data && data.isSuccess) {
      dispatch({
        type: PDP_CONSTANTS.SET_INTERNATIONAL_DELIVERY_DATES,
        payload: data,
      });
    }
    return get(res, "data", {});
  } catch (ex) {
    dispatch({
      type: PDP_CONSTANTS.DELIVERY_DATES_FETCH_FAILED,
    });
    error.config.url = "/control/getDeliverDatesForINTL-rj";
    error.response.status = `An exception occurred while fetching international deliver dates => ${ex}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return {};
  }
}

/**
 * This function gets pin code for a provided location
 *
 * @param {number} latitude this is the latitude of the location selected through google map
 * @param {number} longitude this is the longitude of the location selected through google map
 * @returns {void} returns nothing
 */
export async function getCityPincodeBasedOnLocation(latitude, longitude) {
  try {
    const pinCodeResponse = await axios.get(
      `control/getPinCodeByPostalAddress?latitude=${latitude}&longitude=${longitude}`,
    );
    if (pinCodeResponse.data.isPinCodeFound) {
      return pinCodeResponse.data;
    }
    return {};
  } catch (e) {
    error.config.url = "control/getPinCodeByPostalAddress";
    error.response.status = `An exception occurred while calling fetching city name => ${e}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return {};
  }
}

/* -------------------------------------------------------------------------- */
/*                      UAE PRODUCT HANDLING                                  */
/* -------------------------------------------------------------------------- */

const UAE_DOMAIN = "https://www.fnp.ae";
/**
 * This function fetches localities of current catalog
 *
 * @param {string} catalogId .
 * @param {Function} dispatch dispatcher
 * @returns {object} rel alternate data on success and empty on exception.
 */
export async function getCatalogLocalities(catalogId, dispatch) {
  try {
    const params = {
      FNP_CURRENT_CATALOG_ID: catalogId,
    };
    const endpoint = `${UAE_DOMAIN}/control/getLocalities?${queryString.stringify(params)}`;
    const res = await axios.get(`${endpoint}`, {
      withCredentials: false,
    });
    const data = get(res, "data", null);

    dispatch({ type: PDP_CONSTANTS.SET_CATALOG_LOCALITIES, payload: data });
    return get(res, "data", []);
  } catch (ex) {
    return {};
  }
}

/**
 * This function fetches delivery dates for UAE
 *
 * @param {string} pinCode .
 * @param {string} productId .
 * @param {Function} dispatch dispatcher
 * @returns {object} rel alternate data on success and empty on exception.
 */
export async function getPinCodeAndDeliveryDates(pinCode, productId, dispatch) {
  try {
    const params = {
      productId,
      pinCode,
    };
    const endpoint = `${UAE_DOMAIN}/control/getPinCodeAndDeliveryDates?${queryString.stringify(params)}`;
    const res = await axios.get(`${endpoint}`, {
      withCredentials: false,
    });
    const data = get(res, "data", null);

    dispatch({ type: PDP_CONSTANTS.SET_DATES_AND_SHIPPING_METHODS, payload: data });
    return get(res, "data", []);
  } catch (ex) {
    return {};
  }
}

/**
 * This function is used to get svn personalization content for the desktop pdp
 *
 * @param {string} url .
 * @returns {object} rel alternate data on success and empty on exception.
 */
export async function getContentByUrl(url) {
  try {
    const res = await axios.get(url, {
      withCredentials: false,
      baseURL: BASE_URL,
    });

    return get(res, "data", null);
  } catch (ex) {
    return {};
  }
}

/**
 * Requesting PDP ProductDetails
 *
 * @param {object} payload payload params
 * @returns {object} action
 */
export const requestProductDetails = (payload) => ({
  type: PDP_CONSTANTS.SERVER_REQUEST_PRODUCT_DETAILS,
  payload,
});

/**
 * Set PDP Products data to the store
 *
 * @param {object} productData product data
 * @returns {object} action
 */
export const setProductData = (productData) => ({
  type: PDP_CONSTANTS.SET_PRODUCT,
  payload: {
    results: productData,
  },
});

/**
 * set shipping details
 *
 * @param {object} shippingDetails shipping Details
 * @returns {object} action
 */
export const setShippingDetails = (shippingDetails) => ({
  type: PDP_CONSTANTS.SET_SHIPPING_DETAILS,
  payload: shippingDetails,
});

/**
 * Action for PDP error msg
 *
 * @param {string} msg contains error msg
 * @returns {object} returns action
 */
export const setPDPError = (msg) => ({
  type: PDP_CONSTANTS.PDP_ERROR_MSG,
  payload: msg,
});

/**
 * Populating PDP Breadcrumb
 *
 * @param {object} data data
 * @returns {object} action
 */
export const populateProductBreadCrumb = (data) => ({
  type: PDP_CONSTANTS.SET_PRODUCT_BREADCRUMBS,
  payload: data,
});

/** This function sets product expiry info
 *
 * @param {object} payload set product expiry info
 * @returns {object} action
 */
export const setProductExpiryInfo = (payload) => ({
  type: PDP_CONSTANTS.SET_PRODUCT_EXPIRY_INFO,
  payload,
});

/** This function fetches product expiry info
 *
 * @param {object} payload get product expiry info
 * @returns {object} action
 */
export const fetchProductExpiryInfo = (payload) => ({
  type: PDP_CONSTANTS.FETCH_PRODUCT_EXPIRY_INFO,
  payload,
});

/**
 * Action for fetches product review by the product id
 *
 * @param {string} payload contains payload
 * @returns {object} returns action
 */
export const setProductReviews = (payload) => ({
  type: PDP_CONSTANTS.SET_REVIEWS,
  payload,
});

/**
 * Action for update product review by the product id
 *
 * @param {string} payload contains payload
 * @returns {object} returns action
 */
export const requestUpdateProductReviews = (payload) => ({
  type: PDP_CONSTANTS.UPDATE_SET_REVIEWS,
  payload,
});

/**
 * @param {object} variantInfo - variant info
 * @returns {object} filtered info
 */
export const filterVariantInfo = (variantInfo) =>
  omitObjectKeys(variantInfo, [
    "featureTypes",
    "variantProducts",
    "productId",
    "isVirtual",
    "featureSet",
    "showOffers",
    "productReviews",
    "productReviewsAndRatings",
    "defaultFeature",
    "defaultProductId",
  ]);

/**
 * Action for setting availability of product
 *
 * @param {string} payload contains payload
 * @returns {object} returns action
 */
export const setIsProductNotDeliverable = (payload) => ({
  type: PDP_CONSTANTS.SET_IS_PRODUCT_NOT_DELIVERABLE,
  payload,
});

/**
 * Action for date range enable
 *
 * @returns {object} returns action
 */
export const requestDateRange = () => ({
  type: APP_CONSTANTS.GET_DATE_RANGE_ENABLE,
});

/**
 * Action to set date range enable flag
 *
 * @param {string} payload contains payload
 * @returns {object} returns action
 */
export const setDateRange = (payload) => ({
  type: APP_CONSTANTS.SET_DATE_RANGE_ENABLE,
  payload,
});

/**
 * Action to set date range enable flag
 *
 * @param {string} payload contains payload
 * @returns {object} returns action
 */
export const setDateRangeError = (payload) => ({
  type: APP_CONSTANTS.SET_ERROR_IN_DATE_RANGE_ENABLE,
  payload,
});

/**
 * Action to set date range enable flag
 *
 * @param {string} payload contains payload
 * @returns {object} returns action
 */
export const setIsInternationalEnable = (payload) => ({
  type: PDP_CONSTANTS.SET_IS_INTERNATIONAL_ENABLE,
  payload,
});

/**
 * Action to set date range enable flag
 *
 * @param {string} payload contains payload
 * @returns {object} returns action
 */
export const setIsInternationalEnableError = (payload) => ({
  type: PDP_CONSTANTS.SET_ERROR_IN_IS_INTERNATIONAL_ENABLE,
  payload,
});

/**
 * Action to check whether time slot is required or not
 *
 * @param {string} payload contains payload
 * @returns {object} returns action
 */
export const initTimeSlot = (payload) => ({
  type: PDP_CONSTANTS.INIT_TIME_SLOT,
  payload,
});

/**
 * Action to dispatch after completion of init add to cart functionality
 *
 * @param {string} payload contains payload
 * @returns {object} returns action
 */
export const initAddToCartCompletion = (payload) => ({
  type: PDP_CONSTANTS.INIT_ADD_TO_CART_COMPLETION,
  payload,
});

/**
 * Action to set date range enable flag
 *
 * @param {string} payload contains payload
 * @returns {object} returns action
 */
export const initTimeSlotCompletion = (payload) => ({
  type: PDP_CONSTANTS.INIT_TIME_SLOT_COMPLETION,
  payload,
});

/**
 * Action to init add to cart functionality
 *
 * @param {string} payload contains payload
 * @returns {object} returns action
 */
export const initAddToCart = (payload) => ({
  type: PDP_CONSTANTS.INIT_ADD_TO_CART,
  payload,
});
