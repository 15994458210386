const profileConstants = {
  GET_DETAILS: "GET_DETAILS",
  DETAILS_RECEIVED: "DETAILS_RECEIVED",
  SET_DETAILS: "SET_DETAILS",
  UPDATE_DETAILS: "UPDATE_DETAILS",
  ERROR_MESSAGE: "ERROR_MESSAGE",
  UPDATE_DETAIL_SUCCESS: "UPDATE_DETAIL_SUCCESS",
  RESET_SUCCESS_FLAG: "RESET_SUCCESS_FLAG",
};
export default profileConstants;
