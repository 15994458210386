import { put, takeLatest } from "redux-saga/effects";
import passwordConstants from "../../src/action-constants/password-constants";

/**
 * Saga for getting password
 */
export async function* getPasswordData() {
  yield put({
    type: passwordConstants.PASSWORD_RECEIVED,
  });
}
/**
 *
 */
function* watchChangePassword() {
  yield takeLatest(passwordConstants.GET_PASSWORD, getPasswordData);
}
export default watchChangePassword;
