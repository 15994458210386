const TicketsConstants = {
  GET_TICKETS_LIST_REQUESTED: "GET_TICKETS_LIST_REQUESTED",
  GET_TICKETS_DATA: "GET_TICKETS_DATA",
  GET_TICKET_DETAIL: "GET_TICKET_DETAIL",
  GET_UPDATE_TICKET_DETAIL_REQUESTED: "GET_UPDATE_TICKET_DETAIL_REQUESTED",
  GET_UPDATE_TICKET_DETAIL: "GET_UPDATE_TICKET_DETAIL",
  GET_ALL_ORDERS_DETAIL_REQUESTED: "GET_ALL_ORDERS_DETAIL_REQUESTED",
  GET_ALL_ORDERS_DETAIL: "GET_ALL_ORDERS_DETAIL",
  UPDATE_TICKET_REQUESTED: "UPDATE_TICKET_REQUESTED",
  UPDATE_TICKET_SUCCESS: "UPDATE_TICKET_SUCCESS",
  UPDATE_TICKET_ALERT_SUCCESS: "UPDATE_TICKET_ALERT_SUCCESS",
  UPLOAD_IMAGE_REQUESTED: "UPLOAD_IMAGE_REQUESTED",
  UPLOAD_IMAGE_SUCCESS: "UPLOAD_IMAGE_SUCCESS",
  CREATE_TICKET_DROPDOWN_REQUESTED: "CREATE_TICKET_DROPDOWN_REQUESTED",
  CREATE_TICKET_DROPDOWN_DATA: "CREATE_TICKET_DROPDOWN_DATA",
  SUBMIT_TICKET_REQUESTED: "SUBMIT_TICKET_REQUESTED",
  SUBMIT_TICKET_SUCCESS: "SUBMIT_TICKET_SUCCESS",
  GET_TICKETS_DATA_FAILURE: "GET_TICKETS_DATA_FAILURE",
};
export default TicketsConstants;
