const MS_PER_DAY = 1000 * 60 * 60 * 24;

/**
 * a and b are javascript Date objects
 *
 * @param {object} currDate current date
 * @param {object} selectedDate selected date
 * @returns {string} returns diff in days
 */
const dateDiffInDays = (currDate, selectedDate) => {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(currDate.getFullYear(), currDate.getMonth(), currDate.getDate());
  const utc2 = Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate());

  return Math.floor((utc2 - utc1) / MS_PER_DAY);
};
export default dateDiffInDays;
