import get from "lodash/get";
import getConfig from "next/config";
import qs from "qs";
import axios from "../../app_configs/axios/base";
import axiosControl from "../../app_configs/axios/control";
import serverControl from "../../app_configs/axios/servercontrol";
import errorLogger from "../../app_configs/logger-service";
import gateway from "../../app_configs/axios/gateway";

const { publicRuntimeConfig } = getConfig();
const SHOW_REQUESTS_LOGS = publicRuntimeConfig.SHOW_REQUESTS_LOGS || "Y";
const cmsAPIVersion = process.env.CMS_API_VERSION;

const error = {
  config: {},
  response: {},
};

/**
 * fetch currency list of items
 *
 * @returns {object | Array} - returns response object on success and an empty array on failure
 */
async function fetchCurrencies() {
  try {
    const resp = await axios.get("control/getCurrencies", {
      withCredentials: false,
    });
    return resp;
  } catch (ex) {
    errorLogger(ex, SHOW_REQUESTS_LOGS);
    return [];
  }
}

/**
 * This method calls the api for fetching all the third party scripts
 *
 * @param {string} webSiteId - websiteId is used to identify the m-site or d-site
 * @returns {string} response of the  webanalytics api - string of scripts
 */
export async function getWebAnalytics(webSiteId) {
  try {
    const res = await axiosControl.get(`/productapi/api/rest/v1.3/webAnalytics?webSiteId=${webSiteId}`);
    return res.data;
  } catch (ex) {
    errorLogger(ex, SHOW_REQUESTS_LOGS);
    return [];
  }
}

/**
 * This method calls the fus api which retrieves cartInfo and loginInfo
 * It also dispatch the result to redux store.
 *
 * @returns {object} response containing login and cart information.
 */
export async function checkLoginCartInfo() {
  try {
    const response = await axios.get("control/fus");
    return response;
  } catch (ex) {
    errorLogger(ex, SHOW_REQUESTS_LOGS);
    return [];
  }
}
export default fetchCurrencies;
/**
 * This function fetches Rel Alts Data for Meta of The Page
 *
 * @param {string} getAltDataQryStr .
 * @returns {object} rel alternate data on success and empty on exception.
 */
export async function getRelAltData(getAltDataQryStr) {
  try {
    const res = await serverControl.get(`control/getRelAltData?${getAltDataQryStr}`);
    return get(res, "data") || "";
  } catch (ex) {
    return "";
  }
}
/**
 * This method fetches the organization and postal address schema for a specific geo to be added on the home page.
 *
 * @param {string} country .
 * @returns {string} json for organization and postal address schema.
 */
export async function getOrgPostalSchema(country) {
  try {
    const res = await serverControl.get(`control/getContentIdDetails-rj?contentId=SCHEMA_DETAILS_${country}`);
    return res.data;
  } catch (ex) {
    return "";
  }
}

/**
 * This method fetches the store business schema for a specific geo which has to be added on all store pages.
 *
 * @param {string} country .
 * @returns {string} json for store schema.
 */
export async function getStoreSchema(country) {
  try {
    const res = await serverControl.get(`control/getContentIdDetails-rj?contentId=SCHEMA_STORE_DETAILS_${country}`);
    return res.data;
  } catch (ex) {
    return "";
  }
}

/**
 * This method fetches products count
 *
 * @returns {number} count of products.
 */
export async function fetchProductCount() {
  try {
    const resp = await serverControl.get("control/getContentIdDetails-rj?contentId=PRODUCT_COUNT");
    return get(resp, "data.totalGifts", "");
  } catch (ex) {
    errorLogger(ex, SHOW_REQUESTS_LOGS);
    throw new Error(ex);
  }
}

/**
 * This function is used to get content id details
 *
 * @param {object} options .
 * @returns {object} content id details.
 */
export async function getContentIdDetails(options) {
  try {
    const res = await serverControl.get("control/getContentIdDetails", options);
    return get(res, "data") || "";
  } catch (ex) {
    return "";
  }
}

/**
 * Method to fetch content from SVN
 *
 * @param {string} folderName represents the folder from where the file should be fetched
 * @param {string} fileName represents html file
 * @param {boolean} isMobile represent the boolean value for the request device
 * @returns {string} returns html file data
 */
export async function getContentFromSVN(folderName, fileName, isMobile) {
  const endPoint = "/productapi/api/rest/v1.3/getContentFromSVN";
  try {
    const response = await axiosControl.get(endPoint, {
      params: {
        folderName,
        fileName,
        requestSource: isMobile ? "MRPLP" : "RPLP",
      },
    });
    return response.data;
  } catch (err) {
    errorLogger(err, SHOW_REQUESTS_LOGS);
    return {
      error: err.message,
    };
  }
}
/**
 * This method gives the property value as per the property sent in params
 *
 * @param {object} params - params
 * @returns {object} returns the property value
 */
export async function getSystemPropertyValue(params) {
  try {
    const resp = await axios.get(`control/getSystemPropertyValue?${qs.stringify(params)}`, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      credentials: "same-origin",
    });
    return resp.data;
  } catch (err) {
    error.config.url = "control/getSystemPropertyValue";
    return {
      error: err.message,
    };
  }
}

/**
 * This method gives the property value as per the property sent in params
 *
 * @param {object} params - params
 * @returns {object} returns the property value
 */
export async function getSavedCardSystemPropertyValue(params) {
  try {
    const resp = await axios.get(`control/getSystemPropertyValue?${qs.stringify(params)}`, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      credentials: "same-origin",
    });
    return resp.data;
  } catch (err) {
    error.config.url = "control/getSystemPropertyValue";
    return {
      error: err.message,
    };
  }
}

/**
 * @param {string} url - the dynamic aws url to fetch the database
 * @param {string} uid - userId (email address)
 * @returns {object} returns the value of the recently viewed items for the user
 */
export async function getDataFromRecentlyViewedAWSUrl(url, uid) {
  try {
    const resp = await axios.get(`${url}?uid=${encodeURIComponent(uid)}`, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      withCredentials: false,
    });
    return resp.data;
  } catch (err) {
    errorLogger(err, SHOW_REQUESTS_LOGS);
    return {
      error: err.message,
    };
  }
}

/**
 *
 * @param {string} url - url to populate values in the DB
 * @param {object} params - request body
 * @param {boolean} isJsonRequest - checks if the request parameter is json or query string
 * @returns {object} returns the data object after populating the DB.
 */
export async function postRequestForDB(url, params, isJsonRequest = false) {
  try {
    const requestBody = isJsonRequest ? JSON.stringify(params) : qs.stringify(params);
    const res = await axios.post(url, requestBody, {
      headers: { "Content-Type": "application/x-www-form-urlencoded", accept: "application/json" },
      withCredentials: false,
    });
    return res.data;
  } catch (ex) {
    errorLogger(ex, SHOW_REQUESTS_LOGS);
    return "";
  }
}

/**
 *This method fetches customer login details
 *
 * @returns {object} - returns customer details to update password
 */
export async function getCustomerLoginDetails() {
  try {
    const res = await axios.get("/control/getCustomerDetails-rj");
    return res.data;
  } catch (ex) {
    errorLogger(ex, SHOW_REQUESTS_LOGS);
    return "";
  }
}

/**
 * This method calls productListing API
 *
 * @param {object} productParams - productIds
 * @param {string} catalogId - catalog country Eg: India
 * @param {string} pageType - pageName
 * @returns {object} response from productListing API
 */
export async function getProductDetails(productParams, catalogId, pageType) {
  try {
    const response = await axios.get("control/getProductListing-rj", {
      params: {
        productIds: productParams,
        catalogId,
        pageType,
      },
    });
    return response.data;
  } catch (ex) {
    error.config.url = "control/getProductListing-rj";
    error.response.status = `An exception occurred while calling productListing API for recentlyViewed Section => ${ex}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return [];
  }
}
/**
 * This functio return meta object from cms
 *
 * @returns {object} page relative meta object
 */
export async function fetchDynamicPageMeta() {
  try {
    const res = await gateway.get(`${cmsAPIVersion}global/static/dyanmicpagerobotsmeta`);
    return res.data;
  } catch (ex) {
    error.config.url = `${cmsAPIVersion}global/static/dyanmicpagerobotsmeta`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    throw new Error(ex);
  }
}
