import commonSearchConstants from "../action-constants/common-search-constants";

/**
 * Action of requesting popular search
 *
 * @param {object} options represents the set of params required for get content api
 * @returns {object} returns action
 */
export const requestPopularSearch = (options) => ({
  type: commonSearchConstants.GET_POPULAR_SEARCH_REQUEST,
  payload: options,
});

/**
 * Action of requesting search term suggestion
 *
 * @param {object} options represents the set of params required for get content api
 * @returns {object} returns action
 */
export const requestSearchTermSuggestion = (options) => ({
  type: commonSearchConstants.GET_SEARCH_TERM_SUGGESTION_REQUEST,
  payload: options,
});

/**
 * Action of requesting manual boost
 *
 * @param {object} options represents the set of params required for get content api
 * @returns {object} return action
 */
export const requestManualBoost = (options) => ({
  type: commonSearchConstants.GET_MANUAL_BOOST_REQUEST,
  payload: options,
});
