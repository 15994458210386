import React from "react";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  iconBtnWithTitle: {
    marginRight: theme.spacing(-3),
  },
  iconBtnWithoutTitle: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
}));

/**
 * This component is used to show right menu icon in the m-site header
 *
 * @param {object} root0 props from parent
 * @param {boolean} root0.withTitle whether page header have any title
 * @param {Function} root0.openRightMenu onclick handler to open right menu
 *
 * @returns {React.ReactElement} - renders right-menu-icon-btn component
 */
const KebabMenuIconBtn = ({ withTitle, openRightMenu }) => {
  const classes = useStyles();
  const iconStyle = withTitle ? classes.iconBtnWithTitle : classes.iconBtnWithoutTitle;

  return (
    <IconButton color="inherit" disableRipple className={iconStyle} onClick={openRightMenu} aria-label="menu">
      <MoreVertIcon />
    </IconButton>
  );
};

KebabMenuIconBtn.propTypes = {
  withTitle: PropTypes.bool,
  openRightMenu: PropTypes.func,
};

KebabMenuIconBtn.defaultProps = {
  withTitle: false,
  openRightMenu: () => {},
};

export default KebabMenuIconBtn;
