import queryString from "query-string";
import axios from "../../app_configs/axios/base";
import profileConstants from "../action-constants/profile-constants";

/**
 * Fetches customer profile details
 *
 * @returns {object} response from api
 */
export async function getCustomerDetails() {
  try {
    const res = await axios.get("/control/customerProfileDetails");
    return res;
  } catch (ex) {
    return "";
  }
}
/**
 * Updates customer profile data submitted by user
 *
 * @param  {object} params Params we need to send in post request
 * @returns {object} response from api
 */
export async function updateCustomerDetails(params) {
  try {
    const res = await axios.post("/control/profile-rj", queryString.stringify(params), {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    });
    return res;
  } catch (ex) {
    return "";
  }
}

/**
 * @returns {void} returns nothing
 *
 */
export const getDetails = () => ({
  type: profileConstants.GET_DETAILS,
});

export default getCustomerDetails;
