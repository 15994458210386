import { put, takeLatest, call, all, fork } from "redux-saga/effects";
import getConfig from "next/config";
import errorLogger from "../../app_configs/logger-service";

import PAYMENT_CONSTANTS from "../../src/action-constants/payment-constants";
import CHECKOUT_CONSTANTS from "../../src/action-constants/checkout-constants";
import paymentActions from "../../src/actions/payment-actions";
import checkoutActions from "../../src/actions/checkout-actions";
import { getSystemPropertyValue, getSavedCardSystemPropertyValue } from "../../src/actions/common-actions";
import getOccasions from "../../src/services/api/myAccount/reminder";

const { publicRuntimeConfig } = getConfig();
const SHOW_REQUESTS_LOGS = publicRuntimeConfig.SHOW_REQUESTS_LOGS || "Y";

/** This method is making Api call to fetch user saved cards
 *
 * @param {object} action - action type and payload
 */
export function* fetchSavedCardsSaga(action) {
  try {
    const response = yield call(paymentActions.getUserSavedCards, action.payload);
    if (response?.isSuccess) {
      yield put({ type: PAYMENT_CONSTANTS.FETCH_USER_SAVED_CARDS_SUCCESS, payload: response });
    } else {
      throw new Error(response?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: PAYMENT_CONSTANTS.FETCH_USER_SAVED_CARDS_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** This method is making Api call to fetch all payment associated data
 *
 * @param {object} action - object data
 */
export function* fetchPaymentMethodsSaga(action) {
  try {
    const response = yield call(paymentActions.fetchPaymentMethods, action.payload);
    if (response.paymentDetails) {
      yield* fetchSavedCardsSaga({
        payload: response.paymentDetails?.paymentOptionsData.partyId,
      });
    }
    if (response.error) {
      yield put({
        type: PAYMENT_CONSTANTS.FETCH_PAYMENT_METHODS_FAILED,
        payload: { error: response.error?.message || "Something Went Wrong!" },
      });
    } else {
      yield put({ type: PAYMENT_CONSTANTS.FETCH_PAYMENT_METHODS_SUCCESS, payload: response });
    }
  } catch (error) {
    yield put({
      type: PAYMENT_CONSTANTS.FETCH_PAYMENT_METHODS_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** This method is making Api call to fetch details of card number entered by the user
 *
 * @param {object} action - action type and payload
 */
export function* checkCardTypeSaga(action) {
  try {
    const response = yield call(paymentActions.checkCardType, action.payload);
    yield put({ type: PAYMENT_CONSTANTS.CHECK_CARD_TYPE_SUCCESS, payload: response });
  } catch (error) {
    yield put({
      type: PAYMENT_CONSTANTS.CHECK_CARD_TYPE_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/**
 * this function get the payment options related data
 *
 * @returns {object} payment page data
 */
function* fetchPaymentOptionsData() {
  const cartItems = yield call(checkoutActions.getCompleteCartItemDetails);
  const { viewCart, cartSummaryAmountDetail, encodedCustomerDetails, donationEnable, donationAmount } = yield all({
    viewCart: call(checkoutActions.getViewCart),
    cartSummaryAmountDetail: call(checkoutActions.getCartSummaryAmountDetail),
    encodedCustomerDetails: call(checkoutActions.getEncodedCustomerDetails),
    donationEnable: call(checkoutActions.getDonationEnable),
    donationAmount: call(checkoutActions.getDonationAmount),
  });

  return {
    cartItems,
    viewCart,
    cartSummaryAmountDetail,
    encodedCustomerDetails,
    donationEnable,
    donationAmount,
  };
}

/**
 * This method is initiating all the checkout Payment Api calls
 */
export function* paymentOptionsSaga() {
  try {
    const paymentOptionsResponse = yield call(fetchPaymentOptionsData);

    if (paymentOptionsResponse?.error) {
      throw new Error(paymentOptionsResponse?.error);
    }
    yield put({
      type: CHECKOUT_CONSTANTS.CART_ITEM_EXPIRED,
      payload: null,
    });
    if (paymentOptionsResponse.cartItems?.data?.orderList?.length === 0) {
      yield put({
        type: PAYMENT_CONSTANTS.FETCH_PAYMENT_OPTIONS_METHODS_SUCCESS,
        payload: {
          cartItems: [],
          cartSummaryAmountDetail: [],
          encodedCustomerDetails: [],
          donationAmount: {},
          donationEnable: {},
        },
      });
    } else {
      if (paymentOptionsResponse?.cartItems?.data?.orderList) {
        const selectedContactMechIdObj = {};
        paymentOptionsResponse?.cartItems?.data?.orderList?.map((item) => {
          selectedContactMechIdObj[item?.giftItem?.giftIndex] =
            item?.giftItem?.selectedAddressDetails?.selectedContactMechId;

          return null;
        });
        yield put({
          type: CHECKOUT_CONSTANTS.SET_GIFT_ITEM_DEFAULT_ADDRESS,
          payload: selectedContactMechIdObj,
        });
      }
      yield put({
        type: PAYMENT_CONSTANTS.FETCH_PAYMENT_OPTIONS_METHODS_SUCCESS,
        payload: {
          cartItems: paymentOptionsResponse.cartItems.data,
          cartSummaryAmountDetail: paymentOptionsResponse.cartSummaryAmountDetail.data,
          encodedCustomerDetails: paymentOptionsResponse.encodedCustomerDetails.data,
          donationAmount: paymentOptionsResponse.donationAmount.data,
          donationEnable: paymentOptionsResponse.donationEnable.data,
        },
      });
    }
  } catch (error) {
    yield put({
      type: PAYMENT_CONSTANTS.FETCH_PAYMENT_OPTIONS_METHODS_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** This method is making Api call to fetch details of card number entered by the user
 *
 * @param {object} action - action type and payload
 */
export function* getEmiPaymentValuesSaga(action) {
  try {
    const response = yield call(paymentActions.getEmiPaymentValues, action.payload);
    if (response?.isBankIlligible?.length) {
      yield put({ type: PAYMENT_CONSTANTS.FETCH_EMI_OPTIONS_SUCCESS, payload: response });
    } else {
      throw new Error(response?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: PAYMENT_CONSTANTS.FETCH_EMI_OPTIONS_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** This method is making Api call to fetch available paypal currencies
 */
export function* getPaypalCurrenciesSaga() {
  try {
    const response = yield call(paymentActions.getPaypalCurrencies);
    if (response?.isSuccess) {
      yield put({ type: PAYMENT_CONSTANTS.FETCH_PAYPAL_CURRENCIES_SUCCESS, payload: response });
    } else {
      throw new Error(response?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: PAYMENT_CONSTANTS.FETCH_PAYPAL_CURRENCIES_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** This method is making Api call to fetch available net banking options
 */
export function* getNetBankingListSaga() {
  try {
    const response = yield call(paymentActions.getNetBankingList);
    if (response?.isSuccess) {
      yield put({ type: PAYMENT_CONSTANTS.FETCH_NET_BANKING_LIST_SUCCESS, payload: response });
    } else {
      throw new Error(response?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: PAYMENT_CONSTANTS.FETCH_NET_BANKING_LIST_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** This method is making Api call to fetch available wallet options
 */
export function* getWalletDetailsSaga() {
  try {
    const response = yield call(paymentActions.getWalletDetails);
    if (response?.isSuccess) {
      yield put({ type: PAYMENT_CONSTANTS.FETCH_WALLET_DETAILS_SUCCESS, payload: response });
    } else {
      throw new Error(response?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: PAYMENT_CONSTANTS.FETCH_WALLET_DETAILS_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** This method is making Api call to fetch payment related info
 */
export function* getPaymentOptionsDetailsSaga() {
  try {
    const response = yield call(paymentActions.getPaymentOptionsDetails);
    if (response?.isSuccess) {
      yield put({ type: PAYMENT_CONSTANTS.FETCH_PAYMENT_OPTION_DETAILS_SUCCESS, payload: response });
    } else {
      throw new Error(response?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: PAYMENT_CONSTANTS.FETCH_PAYMENT_OPTION_DETAILS_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** This method is making Api call to fetch min EMI amount
 */
export function* getMinEMIAmountSaga() {
  try {
    const response = yield call(paymentActions.getMinAmountEMI);
    if (response?.isSuccess) {
      yield put({ type: PAYMENT_CONSTANTS.FETCH_MIN_EMI_AMOUNT_SUCCESS, payload: response });
    } else {
      throw new Error(response?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: PAYMENT_CONSTANTS.FETCH_MIN_EMI_AMOUNT_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** This method is making Api call to fetch thank you page info
 *
 * @param {object} action - action type and payload
 */
export function* getThankYouPageInfoSaga(action) {
  try {
    const response = yield call(paymentActions.getThankYouPageInfo, action.payload);
    if (response?.isSuccess) {
      yield put({ type: PAYMENT_CONSTANTS.FETCH_THANK_YOU_PAGE_INFO_SUCCESS, payload: response?.result });
    } else if (response?.isSuccess === false) {
      yield put({ type: PAYMENT_CONSTANTS.FETCH_THANK_YOU_PAGE_INFO_SUCCESS, payload: { invalidOrder: true } });
    } else {
      throw new Error(response?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: PAYMENT_CONSTANTS.FETCH_THANK_YOU_PAGE_INFO_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** This method is making Api call to fetch all reminders
 *
 * @param {object} action - action type and payload
 */
export function* getAllRemindersSaga(action) {
  try {
    const { occasionId = "", upcoming = false } = action.payload;
    const response = yield call(paymentActions.getAllReminders, occasionId, upcoming);
    if (response?.isSuccess) {
      yield put({ type: PAYMENT_CONSTANTS.FETCH_REMINDERS_SUCCESS, payload: response });
    } else {
      throw new Error(response?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: PAYMENT_CONSTANTS.FETCH_REMINDERS_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** This method is making Api call to update reminders
 *
 * @param {object} action - action type and payload
 */
export function* saveRemindersSaga(action) {
  try {
    const response = yield call(paymentActions.updateReminders, action.payload);
    if (response?.isSuccess) {
      yield put({
        type: PAYMENT_CONSTANTS.SAVE_REMINDERS_SUCCESS,
        payload: action.payload,
        reminderId: response?.occasionReminderId,
      });
    } else {
      throw new Error(response?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: PAYMENT_CONSTANTS.SAVE_REMINDERS_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** This method is making Api call to delete reminders
 *
 * @param {object} action - action type and payload
 */
export function* deleteRemindersSaga(action) {
  try {
    const response = yield call(paymentActions.deleteReminders, action.payload);
    if (response?.isSuccess) {
      yield put({ type: PAYMENT_CONSTANTS.DELETE_REMINDERS_SUCCESS, payload: action.payload });
    } else {
      throw new Error(response?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: PAYMENT_CONSTANTS.DELETE_REMINDERS_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** This method is making Api call to delete saved cards
 *
 * @param {object} action - action type and payload
 */
export function* deleteSavedCardSaga(action) {
  try {
    const { partyId, cardToken } = action.payload;
    const response = yield call(paymentActions.deleteSavedCard, partyId, cardToken);
    if (response?.responseMessage === "success") {
      yield put({ type: PAYMENT_CONSTANTS.DELETE_SAVED_CARDS_SUCCESS, payload: action.payload });
    } else {
      throw new Error(response?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: PAYMENT_CONSTANTS.DELETE_SAVED_CARDS_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** This method is making Api call to fetch thank you page detail
 *
 */
export function* thankYouPageContentDetail() {
  try {
    const response = yield call(paymentActions.getThankYouPageContentDetail);
    if (response) {
      yield put({
        type: PAYMENT_CONSTANTS.FETCH_THANK_YOU_PAGE_DETAIL_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    yield put({
      type: PAYMENT_CONSTANTS.FETCH_THANK_YOU_PAGE_DETAIL_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** This method is making Api call to fetch all the net banking options data
 *
 * @param {object} action - object data
 */
export function* fetchNetBankingOptionsSaga(action) {
  try {
    const response = yield call(paymentActions.getNetBankingList, action.payload);
    if (response?.isSuccess) {
      yield put({ type: PAYMENT_CONSTANTS.FETCH_NET_BANKING_OPTIONS_SUCCESS, payload: response });
    } else {
      throw new Error(response?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: PAYMENT_CONSTANTS.FETCH_NET_BANKING_OPTIONS_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/**
 * This method is making Api call to save user feedback on profile completion
 *
 * @param {object} action - action object for action type and payload
 */
export function* saveUserFeedbackSaga(action) {
  try {
    const { data } = yield call(paymentActions.saveUserFeedback, action.payload);
    if (data?.isSuccess) {
      yield put({ type: PAYMENT_CONSTANTS.SAVE_USER_FEEDBACK_SUCCESS });
    }
  } catch (error) {
    errorLogger(error, SHOW_REQUESTS_LOGS);
  }
}

/**
 * This method is making Api call to get system property value
 *
 * @param {object} action - action object for action type and payload
 *
 */
export function* getSystemProperty(action) {
  try {
    const response = yield call(getSystemPropertyValue, action.payload);
    if (response) {
      yield put({
        type: PAYMENT_CONSTANTS.GET_SYSTEM_PROPERTY_SUCCESS,
        payload: response.value,
      });
    }
  } catch (error) {
    errorLogger(error, SHOW_REQUESTS_LOGS);
  }
}

/**
 * This method is making Api call to get system property value for saved card
 *
 * @param {object} action - action object for action type and payload
 *
 */
export function* getSavedCardSystemProperty(action) {
  try {
    const response = yield call(getSavedCardSystemPropertyValue, action.payload);
    if (response) {
      yield put({
        type: PAYMENT_CONSTANTS.GET_SAVED_CARD_SYSTEM_PROPERTY_SUCCESS,
        payload: JSON.parse(response.value),
      });
    }
  } catch (error) {
    errorLogger(error, SHOW_REQUESTS_LOGS);
  }
}

/** This method is making Api call to fetch occasion list
 *
 * @param {object} action - object data
 *
 */
export function* getOccasionContentDetail(action) {
  try {
    const response = yield call(getOccasions, action.payload.catalogId);
    if (response) {
      yield put({
        type: PAYMENT_CONSTANTS.FETCH_OCCASION_LIST_SUCCESS,
        payload: { occasionList: response.occasionList },
      });
    }
  } catch (error) {
    errorLogger(error, SHOW_REQUESTS_LOGS);
  }
}

/** This method is used to yield success response of FETCH_PAYMENT_METHODS action */
function* watchFetchPaymentMethodsRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.FETCH_PAYMENT_METHODS, fetchPaymentMethodsSaga);
}

/** This method is used to yield success response of FETCH_PAYMENT_OPTIONS_METHODS action */
function* watchPaymentOptionsRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.FETCH_PAYMENT_OPTIONS_METHODS, paymentOptionsSaga);
}

/** This method is used to yield success response of FETCH_USER_SAVED_CARDS action */
function* watchFetchSavedCardsRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.FETCH_USER_SAVED_CARDS, fetchSavedCardsSaga);
}

/** This method is used to yield success response of CHECK_CARD_TYPE action */
function* watchCheckCardTypeRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.CHECK_CARD_TYPE, checkCardTypeSaga);
}

/** This method is used to yield success response of FETCH_EMI_OPTIONS action */
function* watchEmiPaymentValuesRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.FETCH_EMI_OPTIONS, getEmiPaymentValuesSaga);
}

/** This method is used to yield success response of FETCH_PAYPAL_CURRENCIES action */
function* watchGetPaypalCurrenciesRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.FETCH_PAYPAL_CURRENCIES, getPaypalCurrenciesSaga);
}

/** This method is used to yield success response of FETCH_NET_BANKING_LIST action */
function* watchGetNetBankingListRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.FETCH_NET_BANKING_LIST, getNetBankingListSaga);
}

/** This method is used to yield success response of FETCH_WALLET_DETAILS action */
function* watchGetWalletDetailsRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.FETCH_WALLET_DETAILS, getWalletDetailsSaga);
}
/** This method is used to yield success response of FETCH_PAYMENT_OPTION_DETAILS action */
function* watchGetPaymentOptionsDetailsRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.FETCH_PAYMENT_OPTION_DETAILS, getPaymentOptionsDetailsSaga);
}
/** This method is used to yield success response of FETCH_MIN_EMI_AMOUNT action */
function* watchGetMinEMIAmountRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.FETCH_MIN_EMI_AMOUNT, getMinEMIAmountSaga);
}

/** This method is used to yield success response of FETCH_THANK_YOU_PAGE_INFO action */
function* watchGetThankYouPageInfoRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.FETCH_THANK_YOU_PAGE_INFO, getThankYouPageInfoSaga);
}

/** This method is used to yield success response of FETCH_REMINDERS action */
function* watchGetAllRemindersRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.FETCH_REMINDERS, getAllRemindersSaga);
}

/** This method is used to yield success response of SAVE_REMINDERS action */
function* watchSaveRemindersRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.SAVE_REMINDERS, saveRemindersSaga);
}

/** This method is used to yield success response of DELETE_REMINDERS action */
function* watchDeleteRemindersRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.DELETE_REMINDERS, deleteRemindersSaga);
}

/** This method is used to yield success response of DELETE_SAVED_CARDS action */
function* watchDeleteSavedCardRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.DELETE_SAVED_CARDS, deleteSavedCardSaga);
}

/** This method is used to yield success response of FETCH_THANK_YOU_PAGE_DETAIL action */
function* watchThankYouPageContentDetailRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.FETCH_THANK_YOU_PAGE_DETAIL, thankYouPageContentDetail);
}

/** This method is used to yield success response of FETCH_PAYMENT_METHODS action */
function* watchFetchNetBankingOptions() {
  yield takeLatest(PAYMENT_CONSTANTS.FETCH_NET_BANKING_OPTIONS, fetchNetBankingOptionsSaga);
}

/** This method is used to yield success response of FETCH_PAYMENT_METHODS action */
function* watchSaveUserFeedback() {
  yield takeLatest(PAYMENT_CONSTANTS.SAVE_USER_FEEDBACK, saveUserFeedbackSaga);
}

/** This method is used to yield success response of GET_SYSTEM_PROPERTY_REQUESTED action */
function* watchSystemPropertyRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.GET_SYSTEM_PROPERTY_REQUESTED, getSystemProperty);
}

/** This method is used to yield success response of GET_SAVED_CARD_SYSTEM_PROPERTY_REQUESTED action */
function* watchSavedCardSystemPropertyRequest() {
  yield takeLatest(PAYMENT_CONSTANTS.GET_SAVED_CARD_SYSTEM_PROPERTY_REQUESTED, getSavedCardSystemProperty);
}

/** This method is used to yield success response of FETCH_OCCASION_LIST_REQUESTED action */
function* watchGetOccasionContentDetail() {
  yield takeLatest(PAYMENT_CONSTANTS.FETCH_OCCASION_LIST_REQUESTED, getOccasionContentDetail);
}

/** This method is used to listen to respective dispatched action */
export default function* paymentSaga() {
  yield fork(watchFetchPaymentMethodsRequest);
  yield fork(watchFetchSavedCardsRequest);
  yield fork(watchCheckCardTypeRequest);
  yield fork(watchEmiPaymentValuesRequest);
  yield fork(watchGetPaypalCurrenciesRequest);
  yield fork(watchGetNetBankingListRequest);
  yield fork(watchGetWalletDetailsRequest);
  yield fork(watchPaymentOptionsRequest);
  yield fork(watchGetPaymentOptionsDetailsRequest);
  yield fork(watchGetMinEMIAmountRequest);
  yield fork(watchGetThankYouPageInfoRequest);
  yield fork(watchGetAllRemindersRequest);
  yield fork(watchSaveRemindersRequest);
  yield fork(watchDeleteRemindersRequest);
  yield fork(watchDeleteSavedCardRequest);
  yield fork(watchThankYouPageContentDetailRequest);
  yield fork(watchFetchNetBankingOptions);
  yield fork(watchSaveUserFeedback);
  yield fork(watchSystemPropertyRequest);
  yield fork(watchSavedCardSystemPropertyRequest);
  yield fork(watchGetOccasionContentDetail);
}
