import { HYDRATE } from "next-redux-wrapper";
import voucherConstants from "../../../src/action-constants/gift-voucher-constants";

const initialState = {
  usedCoupons: [],
  unusedCoupons: [],
};
/**
 * This method is the reducer for used and unused gift coupons
 *
 * @param {*} state - contains initial redux State
 * @param {*} action - contains actionTypes and payload information for specific action
 * @returns {object} state - the next state which needs to be dispatched to redux
 */
const giftVoucherReducer = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.giftVoucher,
      };
    case voucherConstants.CODE_RECEIVED:
      return {
        ...state,
        loading: true,
        usedCoupons: action.payload.usedCoupons,
        unusedCoupons: action.payload.unusedCoupons,
      };
    case voucherConstants.ERROR_MESSAGE:
      return {
        ...state,
        showError: true,
      };
    case voucherConstants.GET_CODE:
      return {
        ...state,
        showError: false,
      };
    default:
      return state;
  }
};
export default giftVoucherReducer;
