import React, { useEffect } from "react";
import dynamic from "next/dynamic";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import style from "../style/modal-fullscreen-animated.module.scss";
import Loader from "../../plp/loader";
import { RoutePageConstants, PathPageConstants } from "../../../../src/utils/common";
import HeaderWithoutTitle from "../../../layouts/mobile/header/main/header-without-title";

const HeaderWithTitle = dynamic(() => import("../../../layouts/mobile/header/main/header-with-title"), {
  ssr: false,
});

/**
 * This is a functional component representing an animated Modal component to render delivery screens.
 *
 * @param {object} props .
 * @returns {React.ReactElement} the JSX for the component
 */
function ModalFullscreenAnimated(props) {
  const { children, handleHeaderBack, animated, screen, headerTitle, className } = props;
  const { isLoading } = useSelector((state) => state.productDetail);
  let header;

  useEffect(() => {
    // this dom manipulation is required for removing scroll from the modal
    document.body.classList.add("no-scroll");

    // this cleanup will add the scroll back in pdp page
    return () => document.body.classList.remove("no-scroll");
  }, []);

  switch (screen) {
    case "addon":
      header = <HeaderWithTitle pageName={RoutePageConstants.ADDONS} handleBack onBackPress={handleHeaderBack} />;
      break;
    case "country":
      header = (
        <HeaderWithTitle
          pathName={PathPageConstants.SELECT_COUNTRY}
          handleBack
          onBackPress={handleHeaderBack}
          headerLabel="Select Country"
        />
      );
      break;
    case "customizeProduct":
      header = (
        <HeaderWithTitle
          pathName={PathPageConstants.MULTI_IMAGE_UPLOAD}
          handleBack
          onBackPress={handleHeaderBack}
          headerLabel={headerTitle}
        />
      );
      break;
    case PathPageConstants.MOBILE_SHIPPING_VIEW:
      header = (
        <HeaderWithTitle
          pathName={PathPageConstants.MOBILE_SHIPPING_VIEW}
          handleBack
          onBackPress={handleHeaderBack}
          headerLabel={headerTitle}
        />
      );
      break;
    default:
      header = <HeaderWithoutTitle pageName={RoutePageConstants.PDP_PAGE} handleBack onBackPress={handleHeaderBack} />;
      break;
  }
  return (
    <>
      {header}
      <div className={`${style["modal-container"]} ${animated ? style.animated : ""} ${className || ""}`}>
        <div className={style["modal-container-inner"]}>
          <div
            className={`${style.modalBody} modal-body-mobile ${screen === "country" && style.modalBodySelectCountry}`}
          >
            {isLoading && (
              <div className={style.mobileDeliveryModalSpinner}>
                <Loader />
              </div>
            )}
            {children}
          </div>
        </div>
      </div>
    </>
  );
}

ModalFullscreenAnimated.propTypes = {
  children: PropTypes.node.isRequired,
  handleHeaderBack: PropTypes.func.isRequired,
  animated: PropTypes.bool.isRequired,
  screen: PropTypes.string,
  headerTitle: PropTypes.string,
  className: PropTypes.string,
};
ModalFullscreenAnimated.defaultProps = {
  screen: "default",
  headerTitle: "",
  className: "",
};

export default ModalFullscreenAnimated;
