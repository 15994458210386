import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import CurrencyDetails from "./currencyDetails";

const useStyles = makeStyles((theme) => ({
  deliveryDateLabel: {
    padding: "5px 2px",
    display: "block",
  },
  deliveryLocationInfo: {
    background: "#f8f8f8",
    padding: 10,
    borderRadius: "4px",
    margin: "5px 0px",
    display: "inline-block",
    position: "relative",
    width: "100%",
    color: theme.color?.lightGrey,
    fontWeight: 400,
    fontSize: "16px",
    boxSizing: "border-box",
    lineHeight: 1.2,
  },
  selectedDateRange: {
    fontSize: "17px",
    fontWeight: 500,
    color: "#000",
  },
  selectedTimeSlot: {
    fontSize: "14px",
    color: "#444",
    marginLeft: "10px",
  },
  selectedDeliveryMethodTime: {
    fontSize: "14px",
    color: "#444",
    float: "left",
    width: "70%",
  },
  shippingPriceFree: {
    color: theme.color?.green,
    fontWeight: 400,
  },
}));
/**
 * Component to render    color: "#e53333",
    fontWeight: 500, delivery shipping info
 *
 * @param {*} param0 props passed to component
 * @param {string} param0.shippingDetails object contains shipping details.
 * @param {string} param0.selectedCurrency string contains selected currency.
 * @param {string} param0.currencies list of currencies.
 * @returns {React.ReactElement} jsx for the delivery delivery shipping info component.
 */
const MultiOrderShippingInfo = ({ shippingDetails, selectedCurrency, currencies }) => {
  const classes = useStyles();
  const { deliveryDatesList, shippingMethodName, deliveryTimeSlot, shippingPrice } = shippingDetails;
  const { deliveryStartDate, deliveryEndDate } = deliveryDatesList;
  return (
    <div>
      <span className={classes.deliveryDateLabel}>Delivery Date</span>
      <div className={classes.deliveryLocationInfo}>
        <div>
          <span className={classes.selectedDateRange}>
            {`${deliveryStartDate.date} ${deliveryStartDate.month} - ${deliveryEndDate.date} ${deliveryEndDate.month}`}
          </span>
          <span className={classes.selectedTimeSlot}>{deliveryTimeSlot}</span>
        </div>
        <div>
          <span className={classes.selectedDeliveryMethodTime}>
            {`${shippingMethodName}: `}
            {shippingPrice ? (
              <CurrencyDetails
                price={shippingPrice}
                selectedCurrency={selectedCurrency}
                currencies={currencies}
                classes="fnp-red"
                priceStyle={{
                  color: "#222222",
                  fontWeight: 500,
                  fontsize: "16px",
                }}
              />
            ) : (
              <span className={classes.shippingPriceFree}>Free</span>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

const propTypes = {
  shippingDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedCurrency: PropTypes.string.isRequired,
  currencies: PropTypes.arrayOf(PropTypes.object).isRequired,
};

MultiOrderShippingInfo.propTypes = propTypes;

export default MultiOrderShippingInfo;
