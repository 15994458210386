/* eslint-disable no-case-declarations */
import isEmpty from "lodash/isEmpty";
import { HYDRATE } from "next-redux-wrapper";
import PAYMENT_CONSTANTS from "../../src/action-constants/payment-constants";

const initialState = {
  paypalCurrencies: { data: { items: [] } },
  netBankingData: {},
  walletDetails: {},
  emiAmount: {},
  userSavedCards: { savedCards: [] },
  paymentDetails: { paymentOptionsData: { paymentOptionsSeq: "" } },
  cardData: {},
  emiOptions: {},
  loadingInitPayments: false,
  thankYouPageData: {},
  remindersData: {
    occassionRemindersList: [],
  },
  currencyLoader: false,
  isUserFeedbackSaved: false,
  currencySelected: "INR",
  savedCardConfig: {},
  savedCardLoader: false,
};

/**
 * This method is the reducer for actions for payments
 *
 * @param {object} state - contains initial redux State
 * @param {object} action - contains actionTypes and payload information for specific action
 * @returns {object} state - the next State which needs to be dispatched to redux
 */
function paymentsReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.payments,
      };
    case PAYMENT_CONSTANTS.FETCH_PAYMENT_METHODS:
    case PAYMENT_CONSTANTS.FETCH_USER_SAVED_CARDS:
    case PAYMENT_CONSTANTS.FETCH_NET_BANKING_OPTIONS:
      return {
        ...state,
        loadingInitPayments: true,
      };
    case PAYMENT_CONSTANTS.FETCH_PAYMENT_METHODS_FAILED:
    case PAYMENT_CONSTANTS.FETCH_USER_SAVED_CARDS_FAILED:
    case PAYMENT_CONSTANTS.FETCH_NET_BANKING_OPTIONS_FAILED:
      return {
        ...state,
        loadingInitPayments: false,
        cardData: {},
      };
    case PAYMENT_CONSTANTS.FETCH_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        ...payload,
        cardData: {},
        loadingInitPayments: false,
      };
    case PAYMENT_CONSTANTS.FETCH_NET_BANKING_OPTIONS_SUCCESS:
      return {
        ...state,
        netBankingData: payload,
        loadingInitPayments: false,
      };
    case PAYMENT_CONSTANTS.FETCH_USER_SAVED_CARDS_SUCCESS:
      return {
        ...state,
        userSavedCards: payload,
        loadingInitPayments: false,
      };
    case PAYMENT_CONSTANTS.CHECK_CARD_TYPE:
      return {
        ...state,
        currencyLoader: true,
      };
    case PAYMENT_CONSTANTS.CHECK_CARD_TYPE_SUCCESS:
      return {
        ...state,
        cardData: payload,
        currencyLoader: false,
      };
    case PAYMENT_CONSTANTS.CHECK_CARD_TYPE_FAILED:
      return {
        ...state,
        currencyLoader: false,
      };
    case PAYMENT_CONSTANTS.CLEAR_CHECK_CARD_TYPE:
      return {
        ...state,
        cardData: initialState.cardData,
      };
    case PAYMENT_CONSTANTS.FETCH_EMI_OPTIONS_SUCCESS:
      return {
        ...state,
        emiOptions: payload,
      };
    case PAYMENT_CONSTANTS.FETCH_PAYMENT_OPTION_DETAILS_SUCCESS:
      return {
        ...state,
        paymentDetails: payload,
      };
    case PAYMENT_CONSTANTS.FETCH_MIN_EMI_AMOUNT_SUCCESS:
      return {
        ...state,
        emiAmount: payload,
      };
    case PAYMENT_CONSTANTS.FETCH_NET_BANKING_LIST_SUCCESS:
      return {
        ...state,
        netBankingData: payload,
      };
    case PAYMENT_CONSTANTS.FETCH_PAYPAL_CURRENCIES_SUCCESS:
      return {
        ...state,
        paypalCurrencies: payload,
      };
    case PAYMENT_CONSTANTS.FETCH_WALLET_DETAILS_SUCCESS:
      return {
        ...state,
        walletDetails: payload,
      };
    case PAYMENT_CONSTANTS.FETCH_THANK_YOU_PAGE_INFO_SUCCESS:
      return {
        ...state,
        thankYouPageData: payload,
      };
    case PAYMENT_CONSTANTS.FETCH_REMINDERS_SUCCESS:
      return {
        ...state,
        remindersData: payload,
      };
    case PAYMENT_CONSTANTS.DELETE_REMINDERS_SUCCESS:
      const updatedRemindersList = state.remindersData.occassionRemindersList;
      const deletedReminderId = action.payload;
      const removedReminderIndex = state.remindersData.occassionRemindersList.findIndex(
        (item) => item.occasionReminderId === deletedReminderId,
      );
      if (removedReminderIndex !== -1) {
        updatedRemindersList.splice(removedReminderIndex, 1);
      }
      return {
        ...state,
        remindersData: {
          ...state.remindersData,
          occassionRemindersList: updatedRemindersList,
        },
      };
    case PAYMENT_CONSTANTS.SAVE_REMINDERS_SUCCESS:
      let updateRemindersList = state.remindersData.occassionRemindersList;
      const updatedReminderId = action.payload.occasionReminderId;
      if (updatedReminderId) {
        const updatedReminderIndex = state.remindersData?.occassionRemindersList?.findIndex(
          (item) => item.occasionReminderId === updatedReminderId,
        );
        if (updatedReminderIndex !== -1) {
          updateRemindersList = Object.assign([...updateRemindersList], {
            [updatedReminderIndex]: action.payload,
          });
        }
      } else {
        updateRemindersList.unshift({ ...action.payload, occasionReminderId: action.reminderId });
      }
      return {
        ...state,
        remindersData: {
          ...state.remindersData,
          occassionRemindersList: updateRemindersList,
        },
      };
    case PAYMENT_CONSTANTS.DELETE_SAVED_CARDS_SUCCESS:
      const updatedCardsList = { ...state.userSavedCards.savedCards[0] };
      const deletedCardToken = action.payload.cardToken;
      delete updatedCardsList[deletedCardToken];
      return {
        ...state,
        userSavedCards: {
          ...state.userSavedCards,
          savedCards: isEmpty(updatedCardsList) ? [] : [updatedCardsList],
        },
      };
    case PAYMENT_CONSTANTS.FETCH_THANK_YOU_PAGE_DETAIL_SUCCESS:
      return {
        ...state,
        thankYouPageContentDetail: payload,
      };
    case PAYMENT_CONSTANTS.UPDATE_REDIRECT_PAGE:
      return {
        ...state,
        paymentDetails: {
          ...state.paymentDetails,
          paymentOptionsData: { ...state.paymentDetails.paymentOptionsData, redirectPage: "" },
        },
      };
    case PAYMENT_CONSTANTS.SAVE_USER_FEEDBACK:
      return {
        ...state,
        isUserFeedbackSaved: false,
      };
    case PAYMENT_CONSTANTS.SAVE_USER_FEEDBACK_SUCCESS:
      return {
        ...state,
        isUserFeedbackSaved: true,
      };
    case PAYMENT_CONSTANTS.GET_SYSTEM_PROPERTY_SUCCESS:
      return { ...state, envValue: action.payload };
    case PAYMENT_CONSTANTS.GET_SAVED_CARD_SYSTEM_PROPERTY_SUCCESS:
      return { ...state, savedCardConfig: action.payload };
    case PAYMENT_CONSTANTS.FETCH_OCCASION_LIST_SUCCESS:
      return {
        ...state,
        occasionList: action.payload.occasionList,
      };
    case PAYMENT_CONSTANTS.SET_CURRENCY_SELECTED:
      return { ...state, currencySelected: action.payload };
    default:
      return state;
  }
}

export default paymentsReducer;
