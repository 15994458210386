import { HYDRATE } from "next-redux-wrapper";
import TicketsConstants from "../../../src/action-constants/tickets-constants";

const initialState = {
  ticketsDetails: [],
  updateTicketDetails: {},
  orderDetails: {},
  imageUploaded: true,
  ticketUpdated: false,
  path: [],
  isCreateTicketSuccess: false,
};

/**
 * reducer for All Tickets
 *
 * @param {object} state - initial redux state
 * @param {object} action - action object for action type and payload
 * @returns {object} - updated state
 */
function ticketsReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.tickets,
      };
    case TicketsConstants.GET_TICKETS_DATA:
      return {
        ...state,
        isCreateTicketSuccess: false,
        ticketUpdated: false,
        ticketsDetails: payload.ticketsDetails,
        totalPages: payload.totalPages,
        totalTickets: payload.totalTickets,
        isLoaded: true,
      };
    case TicketsConstants.GET_TICKET_DETAIL:
      return {
        ...state,
        updateTicketDetails: state.ticketsDetails.find((e) => e.seqId === payload.id),
        isCreateTicketSuccess: false,
      };
    case TicketsConstants.GET_UPDATE_TICKET_DETAIL:
      return {
        ...state,
        updateTicketDetails: payload.ticketDetail,
        isCreateTicketSuccess: false,
      };
    case TicketsConstants.GET_ALL_ORDERS_DETAIL:
      return {
        ...state,
        orderDetails: payload.orderDetails,
      };
    case TicketsConstants.UPDATE_TICKET_SUCCESS:
      return {
        ...state,
        ticketUpdated: true,
        updateTicketDetails: payload.ticketDetail,
      };
    case TicketsConstants.UPDATE_TICKET_ALERT_SUCCESS:
      return {
        ...state,
        ticketUpdated: false,
      };
    case TicketsConstants.UPLOAD_IMAGE_REQUESTED:
      return {
        ...state,
        imageUploaded: false,
        path: [],
      };
    case TicketsConstants.UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        imageUploaded: true,
        path: [...state.path, payload.path],
      };
    case TicketsConstants.CREATE_TICKET_DROPDOWN_DATA:
      return {
        ...state,
        ticketTypes: payload.ticketTypes,
      };
    case TicketsConstants.SUBMIT_TICKET_SUCCESS:
      return {
        ...state,
        isCreateTicketSuccess: true,
        submitMessage: payload.submitMessage,
        createdId: payload.createdId,
      };
    case TicketsConstants.GET_TICKETS_LIST_REQUESTED:
      return {
        ...state,
        isSuccess: true,
      };
    case TicketsConstants.GET_TICKETS_DATA_FAILURE:
      return {
        ...state,
        isSuccess: false,
        isLoaded: true,
      };
    default:
      return state;
  }
}

export default ticketsReducer;
