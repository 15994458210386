import { takeEvery, put } from "redux-saga/effects";
import APP_CONSTANTS from "../../src/action-constants/app-actions";
import { validateOtpApi } from "../../src/actions/login-actions";

/**
 * This method is yielding fus api response in saga that is retrieving cartinfo and loginInfo
 *
 * @param {object} action - contains the response payload information of cartInfo and loginInfo
 * after calling fus api
 */
function* validateOtp(action) {
  try {
    const data = yield validateOtpApi(action.payload);
    if (data.data.response === "success") {
      yield put({ type: APP_CONSTANTS.VALIDATE_OTP_SUCCESS, payload: data });
    } else {
      yield put({ type: APP_CONSTANTS.VALIDATE_OTP_ERROR, error: data.data.msg });
    }
  } catch (error) {
    yield put({ type: APP_CONSTANTS.VALIDATE_OTP_ERROR, error });
  }
}

/**
 * This method is used to yield success response of CHECK_LOGIN_CART_INFO action
 *
 */
function* validateOtpSaga() {
  yield takeEvery(APP_CONSTANTS.VALIDATE_OTP, validateOtp);
}

export default validateOtpSaga;
