import { HYDRATE } from "next-redux-wrapper";
import GiftFinderConstants from "../../../src/action-constants/gift-finder-constants";

const initialState = {
  fetchError: false,
  loading: false,
  inputCleared: false,
  facetOptions: [],
};

/**
 * This method is the reducer for actions that are specific to gift finder
 *
 * @param {object} state - contains initial redux State
 * @param {object} action - contains actionTypes and payload information for specific action
 * @returns {object} state - the next State which needs to be dispatched to redux
 */
function giftFinderReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.giftFinder,
      };
    case GiftFinderConstants.SET_FACET_DATA:
      return {
        ...state,
        facetOptions: payload.facetData,
      };
    case GiftFinderConstants.SET_FACET_ERROR:
      return {
        ...state,
        fetchError: true,
      };
    case GiftFinderConstants.INITIATE_SET_CITY_DATA:
      return {
        ...state,
        loading: true,
      };
    case GiftFinderConstants.SET_CITY_DATA:
      return {
        ...state,
        city: payload.city,
        loading: false,
        inputCleared: false,
      };
    case GiftFinderConstants.CLEAR_CITY_DATA:
      return {
        ...state,
        city: "",
      };
    case GiftFinderConstants.SET_LOCATION_DATA:
      return {
        ...state,
        faddress: payload.faddress,
        pincode: payload.pincode,
      };
    case GiftFinderConstants.CLEAR_LOCATION_DATA:
      return {
        ...state,
        faddress: "",
        pincode: "",
      };
    case GiftFinderConstants.CLEARED_LOCATION_FIELD:
      return {
        ...state,
        inputCleared: true,
      };
    default:
      return state;
  }
}

export default giftFinderReducer;
