import { fetchContentFromCMSOnClient } from "../../src/services/api/common/common-api";
import { SEARCH_BOX_PLACE_HOLDER } from "./constants";

let placeholders = [];
let i = 0;
let timer;
let animation = true;

/**
 * This method fetches the organization
 *
 * @param {HTMLElement} searchInputId input search
 */
export async function getApiData(searchInputId) {
  const res = await fetchContentFromCMSOnClient("global/static/web/appdynamicsearchtextsuggestions");
  if (res?.appDynamicSearchSuggestionsEnabled === "Y") {
    searchInputId.setAttribute("placeholder", "");
    placeholders = res?.searchTerm;
    // eslint-disable-next-line no-use-before-define
    typingEffect(searchInputId);
  }
}
/**
 * @param {event} e event
 */
export const stopAnimation = (e) => {
  animation = false;
  e.target.setAttribute("placeholder", SEARCH_BOX_PLACE_HOLDER);
};
/**
 * @param {HTMLElement} searchInputId input search
 */
function typingEffect(searchInputId) {
  const word = placeholders.length && placeholders[i].split("");
  /**
   *
   * @returns {boolean} return boolian value
   */
  // eslint-disable-next-line consistent-return, require-jsdoc
  const loopTyping = () => {
    if (word.length > 0 && animation) {
      searchInputId.setAttribute("placeholder", searchInputId.getAttribute("placeholder") + word.shift());
    } else {
      // eslint-disable-next-line no-use-before-define
      deletingEffect(searchInputId);
      return false;
    }
    timer = setTimeout(loopTyping, 100);
  };
  loopTyping();
}
/**
 * @param {HTMLElement} searchInputId input search
 */
function deletingEffect(searchInputId) {
  const word = placeholders.length && placeholders[i].split("");
  /**
   *
   * @returns {boolean} return boolian value
   */
  // eslint-disable-next-line consistent-return, require-jsdoc
  const loopDeleting = () => {
    if (word.length > 0 && animation) {
      word.pop();
      searchInputId.setAttribute("placeholder", word.join(""));
    } else {
      if (placeholders.length > i + 1) {
        // eslint-disable-next-line no-plusplus
        i++;
      } else {
        animation = false;
        searchInputId.setAttribute("placeholder", SEARCH_BOX_PLACE_HOLDER);
        return false;
      }
      typingEffect(searchInputId);
      return false;
    }
    // eslint-disable-next-line no-unused-vars
    timer = setTimeout(loopDeleting, 100);
  };
  loopDeleting();
}
