import { getEarliestDeliveryDateString } from "./common";
import ListingPageConstants from "../action-constants/listing-contants";
/**
 * Populating Products List data to store
 *
 * @param {object} response - response object consisting of new products fetched from API
 * @param {object} options - sort options, etc.
 * @returns {object} returns data object
 */
export const getProductListingData = (response, options) => {
  const results = response?.data || [];

  const {
    categoryRatingValue,
    categoryTotalReview,
    sortOptions,
    productCategoryId,
    productCategoryName,
    facetDetailList,
    totalPages,
    total,
    currentPage,
    serviceable,
    geoId,
  } = response || {};

  return {
    results,
    categoryRating: categoryRatingValue,
    categoryReviews: categoryTotalReview,
    productCategoryName,
    options,
    sortOptions,
    facetDetailList,
    totalPages,
    total,
    page: currentPage,
    productCategoryId,
    size: options?.params?.size,
    serviceable,
    geoId,
  };
};

/**
 * Updating Category Products Data in case on Infinite Scroll
 *
 * @param {object} response - response object consisting of new products fetched after a scroll
 * @param {object} options - sort options, etc.
 * @param {object} productList - productList from store
 * @returns {object} returns data object
 */
export const getUpdatedProductListingData = (response, options, productList) => {
  const productListingData = response.data || [];
  const alreadyPopulated = productList?.productsList || [];
  const { params } = options;

  return {
    results: [...alreadyPopulated, ...productListingData],
    page: params.page,
    size: params.size,
    productCategoryName: productList.productCategoryName,
    categoryRating: productList.categoryRating,
    categoryReviews: productList.categoryReviews,
    options,
    sortOptions: productList.sortOptions,
    facetDetailList: productList.facetDetailList,
    totalPages: productList.totalPages,
    total: productList.total,
    productCategoryId: productList.productCategoryId,
    serviceable: response.serviceable,
  };
};

/**
 * Populating Products Earliest Delivery Data To The Store
 *
 * @param {object} deliveryDates This is dictionary which contains the delivery date
 * with respect to product id.
 * @returns {object} returns an objecy of products with updated earliest delivery dates.
 */
export const populateEarliestDeliveries = (deliveryDates) => {
  const modifiedDates = Object.keys(deliveryDates).reduce((acc, productId) => {
    acc[productId] =
      deliveryDates[productId] === ListingPageConstants.NOT_APPLICABLE
        ? ListingPageConstants.NOT_APPLICABLE
        : getEarliestDeliveryDateString(deliveryDates[productId]);
    return acc;
  }, {});
  return modifiedDates;
};

/**
 * Receiving the list of products and returning their ids.
 *
 * @param {Array} allProducts list of products in store.
 * @param {number} viewSize size of list on scroll.
 * @returns {Array} returns an array of product ids.
 */
export const getProductIds = (allProducts, viewSize) => {
  const allProductsIds = [];
  const startIndex = allProducts.length >= viewSize ? allProducts.length - viewSize : 0;
  allProducts.slice(startIndex).forEach((product) => {
    if (!product.earliestDeliveryDate) {
      allProductsIds.push(product.productId);
    }
  });
  return allProductsIds;
};

/**
 * This method provides a polyfill for the smooth scroll, This gets called only when the native smooth scroll is not
 * present in the Browser
 *
 * @param {*} to element offset from top.
 * @param {*} duration duration for scroll.
 */
export const smoothScrollPolyfill = (to, duration) => {
  const element = document.scrollingElement || document.documentElement;
  const start = element.scrollTop;
  const change = to - start;
  const startDate = +new Date();

  /**
   * This method calculates the time for ease in and out
   *
   * @param {number} t current time.
   * @param {number} b start value.
   * @param {number} c change in value.
   * @param {number} d duration.
   * @returns {number} ease in out quadrant.
   */
  const easeInOutQuad = (t, b, c, d) => {
    let time = t;
    time /= d / 2;
    if (time < 1) return (c / 2) * time * time + b;
    time -= 1;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };
  /**
   * This performs the animation to the scroll.
   */
  const animateScroll = () => {
    const currentDate = +new Date();
    const currentTime = currentDate - startDate;
    element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration), 10);
    if (currentTime < duration) {
      requestAnimationFrame(animateScroll);
    } else {
      element.scrollTop = to;
    }
  };
  animateScroll();
};
/**
 * This method provides a scroll behavior on click of the global Review Component which in scrolls down to the What customers are saying component
 *
 * @param {string} scrollId - div Id
 * @param {number} offsetValue - offset value
 */
export const reviewSection = (scrollId, offsetValue) => {
  const supportsNativeSmoothScroll = "scrollBehavior" in document.documentElement.style;
  if (document.getElementById(scrollId)) {
    const eleOffsetTop = document.getElementById(scrollId).offsetTop - offsetValue;
    if (supportsNativeSmoothScroll) {
      window.scroll({ top: eleOffsetTop, behavior: "instant" });
    } else {
      smoothScrollPolyfill(eleOffsetTop, 600);
    }
  }
};

/**
 * This utility will check and returns the default sort.
 *
 * @param {Array} sortOptions list of sort options
 * @param {string} selectedSort current selected sort
 * @returns {object} contains default sort option data
 */
export const getDefaultSortOptionData = (sortOptions, selectedSort = null) => {
  const defaultSortOption = selectedSort || (sortOptions && sortOptions.length && sortOptions[0].value);
  return defaultSortOption;
};
/**
 *This function takes the product list and returns the productID's for product spotlight
 *
 * @param {Array} productsList list of products received from the api
 * @param {string} page current page
 * @param {string} size total size of the product list
 * @returns {Array} array of productId
 */
export const getProductIdsForSpotlight = (productsList, page, size) => {
  const products = page === 0 ? productsList : productsList.slice(page * size, productsList?.length);
  const allProductIds = products.map((ele) => ele.productId);
  return allProductIds;
};

/**
 * This is a  debounce function
 *
 * @param {Function} cb Call back to be debounced
 * @param {number} delay timer to delay the call
 * @returns {Function} return a debounce function
 */
export const deBounceMe = (cb, delay) => {
  let currentEvent;
  const thisContext = this;
  return (...args) => {
    if (currentEvent) clearTimeout(currentEvent);
    currentEvent = setTimeout(() => {
      cb.apply(thisContext, args);
    }, delay);
  };
};

/**
 *This function handles the removal of specific search param from the url
 *
 * @param {object} urlParams url params
 * @param {string} paramName param name that needs to be removed
 * @param {string} paramValue param
 * @returns {string} updated url string
 */
export const deleteURLParamsByNameAndValue = (urlParams, paramName, paramValue) => {
  const updatedParam = new URLSearchParams();
  urlParams.forEach((v, k) => {
    if (k.slice(3) !== paramName || decodeURIComponent(v) !== paramValue) {
      updatedParam.append(k, encodeURIComponent(decodeURIComponent(v)));
    }
  });
  return updatedParam.toString();
};

/**
 * This function validates weather the given input is present in the filter list or not
 *
 * @param {object} keyList object of filter list
 * @param {string} key current key send to check wether valid or not
 * @returns {boolean} wether the key exits or not
 */
export const isValidFilterType = (keyList, key) => keyList.find((data) => data.facetFieldName === key);

/**
 * This function updated the filter data(local state) from the url
 *
 * @param {string} urlString current url query param string
 * @returns {Map} map of current or previously selected filters
 */
export const updateFilterDataFromUrl = (urlString) => {
  const resFilter = {};
  if (!urlString) return resFilter;
  const urlParams = new URLSearchParams(urlString);
  urlParams.forEach((v, prevKey) => {
    const prevValue = decodeURIComponent(v);
    if (prevKey.slice(0, 3) === "fq-") {
      let map = {};
      if (!resFilter[prevKey.slice(3)]) {
        map[prevValue] = true;
      } else {
        map = { ...resFilter[prevKey.slice(3)] };
        if (!Object.prototype.hasOwnProperty.call(map, prevValue)) {
          map[prevValue] = true;
        }
      }
      resFilter[prevKey.slice(3)] = map;
    }
  });
  return resFilter;
};

/**
 * This function converts selected filters in url into array of selected filters for the api call
 *
 * @param {object} searchParam searchOptions for the params
 * @returns {Array} array of selected filters
 */
export const generateFqValuesFromURL = (searchParam) => {
  const fqRes = [];
  searchParam.forEach((v, prevKey) => {
    const prevValue = decodeURIComponent(v);
    if (prevKey.slice(0, 3) === "fq-") {
      fqRes.push(`${prevKey.slice(3)}:${prevValue}`);
    }
  });
  return fqRes;
};
/**
 * This function is delete specific filterType
 *
 * @param {object} urlParams url params
 * @param {string} filterType param name that needs to be removed
 * @returns {string} updated url string
 */
export const deleteByFilterType = (urlParams, filterType) => {
  const updatedParam = new URLSearchParams();
  urlParams.forEach((v, k) => {
    if (k.slice(3) !== filterType) {
      updatedParam.append(k, v);
    }
  });
  return updatedParam;
};

/**
 * This function is the url having keys with fq
 *
 * @param {object} urlParam searchOptions for the params
 * @returns {string} url with fq
 */
export const clearAllFilter = (urlParam) => {
  const updatedParam = new URLSearchParams();
  urlParam.forEach((v, k) => {
    if (k.slice(0, 3) !== "fq-") updatedParam.append(k, v);
  });
  return updatedParam;
};

/**
 * Function to create PLP Request for Filters
 *
 * @param {Map} searchParam  update url params
 * @param {object}  searchOptionsParam params in search options
 *
 * @returns {object} request params
 */
export const createPLPRequestForAppliedFilter = (searchParam, searchOptionsParam) => {
  const searchOptionsParams = { params: { ...searchOptionsParam } };
  searchOptionsParams.params.page = 0;
  if (searchParam.toString().length) {
    searchOptionsParams.params.page = 0;
    const fqRes = generateFqValuesFromURL(searchParam);
    if (fqRes.length) {
      searchOptionsParams.params.fq = fqRes;
    } else {
      delete searchOptionsParams.params?.fq;
    }
    if (searchParam.has("sortOption")) {
      searchOptionsParams.params.sortOption = decodeURI(searchParam.get("sortOption"));
    } else {
      delete searchOptionsParams.params.sortOption;
    }
  } else {
    delete searchOptionsParams.params?.fq;
    delete searchOptionsParams.params.sortOption;
  }
  return searchOptionsParams;
};
