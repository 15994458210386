import { put, takeLatest, call } from "redux-saga/effects";
import ReminderConstants from "../../src/action-constants/reminder-constants";

import { getReminders, updateReminder, deleteReminder } from "../../src/services/api/myAccount/reminderApi";

/**
 * fetches list of occasion reminders
 *
 */
function* getOccasionRemindersList() {
  const resp = yield call(getReminders);
  if (resp.isSuccess) {
    yield put({
      type: ReminderConstants.GET_REMINDERS_LIST_SUCCESS,
      payload: { remindersList: resp.occassionRemindersList },
    });
  } else {
    yield put({
      type: ReminderConstants.GET_REMINDERS_LIST_FAILURE,
    });
  }
}

/**
 * This Method make a API call when reminder should be saved.
 *
 * @param {object} action - action payload dispatched.
 */
function* saveOccasionReminder(action) {
  const resp = yield call(updateReminder, action.payload);
  if (resp.isSuccess) {
    yield put({ type: ReminderConstants.GET_REMINDERS_LIST });
  }
}

/**
 * This Method make a API call when reminder should be deleted.
 *
 * @param {object} action - action payload dispatched.
 */
function* deleteOccasionReminder(action) {
  const resp = yield call(deleteReminder, action.payload);
  if (resp.isSuccess) {
    yield put({ type: ReminderConstants.GET_REMINDERS_LIST });
  }
}

/**
 * watcher saga for reminders
 */
function* watchRemindersRequest() {
  yield takeLatest(ReminderConstants.GET_REMINDERS_LIST, getOccasionRemindersList);
  yield takeLatest(ReminderConstants.SAVE_REMINDER, saveOccasionReminder);
  yield takeLatest(ReminderConstants.DELETE_REMINDER, deleteOccasionReminder);
}

export default watchRemindersRequest;
