import { HYDRATE } from "next-redux-wrapper";
import APP_CONSTANTS from "../../../src/action-constants/app-actions";

const InitialState = {
  success: false,
  error: false,
  loginUser: "",
  mobileValidation: {},
};

/**
 * This method is the reducer for actions that are specific to user
 *
 * @param {object} state - contains initial redux State
 * @param {object} action - contains actionTypes and payload information for specific action
 * @returns {object} state - the next State which needs to be dispatched to redux
 */
function loginReducer(state = InitialState, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.login,
      };
    case APP_CONSTANTS.LOGIN_USER:
      return {
        ...state,
        success: false,
        error: false,
      };
    case APP_CONSTANTS.LOGIN_USER_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        loginUser: action.payload.data,
      };
    case APP_CONSTANTS.LOGIN_USER_ERROR:
      return {
        ...state,
        success: false,
        error: true,
      };
    case APP_CONSTANTS.SET_MOBILE_VALIDATION_ENABLE:
      return {
        ...state,
        mobileValidation: action.payload.isMobileNumberValidationEnable,
      };
    default:
      return state;
  }
}
export default loginReducer;
