import React from "react";
import PropTypes from "prop-types";

import styles from "./styles/cart-preview.module.scss";

/**
 * This function Builds the initial  cart listing  loader
 *
 * @param {boolean} isMobile boolean for mobile or desktop device
 * @returns {React.ReactElement} jsx for the cart page.
 */
const CartListingSkeleton = ({ isMobile }) => {
  return (
    <>
      <div id="my-cart" className={isMobile ? styles.skeleton : styles.desktopSkeleton} data-testid="cart-skeleton">
        <div className={`${styles["products-s"]} ${styles["cart-skeleton"]}`}>
          <div className={styles.productPanel}>
            <div className={styles.baseProductPanel}>
              <div className={styles["product-wrapper"]} data-product-wrapper-index="1" data-itemindex="0">
                <div className={styles["timeline-wrapper"]}>
                  <div className={styles["timeline-item"]}>
                    <div className={styles["animated-background"]}>
                      <div className={`${styles["bg-masker"]} ${styles["header-top"]}`} />
                      <div className={`${styles["bg-masker"]} ${styles["header-left"]}`} />
                      <div className={`${styles["bg-masker"]} ${styles["header-right"]}`} />
                      <div className={`${styles["bg-masker"]} ${styles["header-bottom"]}`} />
                      <div className={`${styles["bg-masker"]} ${styles["subheader-left"]}`} />
                      <div className={`${styles["bg-masker"]} ${styles["subheader-right"]}`} />
                      <div className={`${styles["bg-masker"]} ${styles["subheader-bottom"]}`} />
                      <div
                        className={`${styles["bg-masker"]} ${styles["subheader-bottom"]} ${styles["cart-address-top"]}`}
                      />
                      <div
                        className={`${styles["bg-masker"]} ${styles["subheader-bottom"]} ${styles["cart-address-bottom"]}`}
                      />
                    </div>
                  </div>
                </div>
                <span className={styles["base-item-number"]} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles["cart-buttons"]}>
          <div className={styles["cart-pay-info"]}>
            <div className={styles["animated-background"]} />
          </div>
          <div className={styles["cart-to-pay"]}>
            <div className={styles["animated-background"]} />
          </div>
        </div>
      </div>
    </>
  );
};
export default CartListingSkeleton;
CartListingSkeleton.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};
