import qs from "qs";
import axios from "../../../../app_configs/axios/base";

/**
 * get list of all saved addresses
 *
 * @returns {object} - api response
 */
export const getSavedAddresses = () => {
  return axios.get("control/getSavedRecipientAddresses?viewSize=40&viewIndex=0", {});
};

/**
 * post update address details
 *
 * @param {object} params - params for api request
 * @returns {object} - api response
 */
export const updateAddress = (params) => {
  return axios.post("control/update-address-rj", qs.stringify(params), {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

/**
 *  get existing details to update address
 *
 * @param {object} params - params for api request
 * @returns {object} - api response
 */
export const getEditSavedAddress = (params) => {
  const { uniqueAddressId } = params;
  return axios.get(`control/getSavedAddressForPincode-rj?fma=Y&contactMechId=${uniqueAddressId}`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

/**
 *  get delete address details
 *
 * @param {object} params - params for api request
 * @returns {object} - api response
 */
export const deleteAddressCard = (params) => {
  const { cartIndex, contactMechId } = params;
  return axios.get(`control/deleteItemAddress-rj?cartItemIndex=${cartIndex}&contactMechId=${contactMechId}`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};
