import { call, put, select, takeLeading } from "redux-saga/effects";
import { parseCookies, setCookie } from "nookies";
import fetchCurrencies from "../../src/actions/common-actions";
import APP_CONSTANTS from "../../src/action-constants/app-actions";
import getUserLocalCurrency, { DEFAULT_CURRENCY } from "../../src/utils/userLocalCurrency";
import { extractCountryCodeFromEdgeScapeHeader } from "../../src/utils/location";

/**
 * Set valid user local currency
 *
 * @param {string} validCurrency valid local currency
 */
function* setUserLocalCurrency(validCurrency) {
  const cookieDomain = yield select((store) => store.appConfigs.configData.default.cookieDomain);

  setCookie(null, "localCurrency", validCurrency, {
    path: "/",
    domain: cookieDomain,
  });

  yield put({
    type: APP_CONSTANTS.UPDATE_SELECTED_CURRENCY,
    payload: validCurrency,
  });
}

/**
 * Validate and Set User Local Currency
 *
 * @param {object} resp response object
 */
function* validateAndSetUserLocalCurrency(resp) {
  const cookies = parseCookies();
  const header = resp?.headers["x-akamai-edgescape"] || resp?.headers["akamai-edgescape-headers"];
  const currenciesList = resp?.data?.currencies;

  const userLocalCurrency = getUserLocalCurrency(header, currenciesList);

  const filteredCurrency = currenciesList?.find((item) => cookies.localCurrency === item.currency.currencyCode);
  const validCurrency = filteredCurrency?.currency?.currencyCode || userLocalCurrency;

  const countryCode = extractCountryCodeFromEdgeScapeHeader(header);
  yield put({
    type: APP_CONSTANTS.SET_COUNTRY_CODE_FROM_HEADER,
    payload: countryCode,
  });
  yield* setUserLocalCurrency(validCurrency);
}

/**
 * generates list of currencies
 *
 */
export function* getCurrenciesList() {
  try {
    const resp = yield call(fetchCurrencies);
    yield put({
      type: APP_CONSTANTS.GET_CURRENCIES_LIST,
      payload: resp?.data?.currencies,
    });

    yield* validateAndSetUserLocalCurrency(resp);
  } catch (e) {
    yield put({ type: APP_CONSTANTS.GET_CURRENCIES_LIST, payload: null });
    yield* setUserLocalCurrency(DEFAULT_CURRENCY);
  }
}

/**
 * sagas for fetching currencies list
 *
 */
function* watchFetchCurrencies() {
  yield takeLeading(APP_CONSTANTS.FETCH_CURRENCIES_REQUESTED, getCurrenciesList);
}

export default watchFetchCurrencies;
