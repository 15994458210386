import { HYDRATE } from "next-redux-wrapper";
import APP_CONSTANTS from "../../../src/action-constants/app-actions";

const InitialState = {
  success: false,
  error: false,
  validOtp: "",
  errorMessage: "",
  isValidating: false,
};

/**
 * This method is the reducer for actions that are specific to user
 *
 * @param {object} state - contains initial redux State
 * @param {object} action - contains actionTypes and payload information for specific action
 * @returns {object} state - the next State which needs to be dispatched to redux
 */
function validOtpReducer(state = InitialState, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.validOtp,
      };
    case APP_CONSTANTS.VALIDATE_OTP:
      return {
        ...state,
        success: false,
        error: false,
        isValidating: true,
      };
    case APP_CONSTANTS.VALIDATE_OTP_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        validOtp: action.payload.data,
        isValidating: false,
      };
    case APP_CONSTANTS.VALIDATE_OTP_ERROR:
      return {
        ...state,
        success: false,
        error: true,
        errorMessage: action.error,
        isValidating: false,
      };
    case APP_CONSTANTS.CLEAR_ERR_MSG:
      return {
        ...state,
        success: false,
        error: false,
        validOtp: {},
      };
    case APP_CONSTANTS.CLEAR_OTP_ERROR:
      return {
        ...state,
        error: false,
        errorMessage: "",
      };
    default:
      return state;
  }
}
export default validOtpReducer;
