import set from "lodash/set";
import { GA4_EVENTS } from "../../components/common/constants";

const dataLayerVarName = "dataLayer";

/**
 * This methods returns the dataLayer global object.
 *
 * @returns {object} - returns the dataLayer global object
 */
function getDigitalLayer() {
  window[dataLayerVarName] = window[dataLayerVarName] || [];
  return window[dataLayerVarName];
}

/**
 * Setting the checkout event object for GA
 *
 * @param {object} cartItems cartItems data
 */
const setProducDetailsForCheckout = (cartItems) => {
  const orderItems = cartItems?.orderList;
  const products = [];
  orderItems?.forEach((item) => {
    const product = {};
    product.id = item?.giftItem?.mainproduct?.productId;
    product.sku = item?.giftItem?.mainproduct?.sku;
    product.name = item?.giftItem?.mainproduct?.productName;
    product.category = item?.giftItem?.mainproduct?.category;
    product.price = item?.giftItem?.mainproduct?.price;
    product.currency = cartItems?.otherDeliveryDetails?.currency;
    product.brand = cartItems?.otherDeliveryDetails?.brand;
    product.quantity = 1;
    products.push(product);
    if (item?.giftItem?.addons?.length > 0) {
      item?.giftItem?.addons?.forEach((addonItem) => {
        const addonProduct = {};
        addonProduct.id = addonItem?.productId;
        addonProduct.sku = addonItem?.sku;
        addonProduct.name = addonItem?.productName;
        addonProduct.category = item?.giftItem?.mainproduct?.category;
        addonProduct.price = addonItem?.price;
        addonProduct.currency = cartItems?.otherDeliveryDetails?.currency;
        addonProduct.brand = cartItems?.otherDeliveryDetails?.brand;
        addonProduct.quantity = addonItem?.quantity;
        products.push(addonProduct);
      });
    }
  });
  let gaDeliveryAddressObject;
  getDigitalLayer()?.every((item) => {
    if (
      item?.event &&
      item.event === "checkout" &&
      item?.ecommerce?.checkout?.actionField?.option === "DeliveryAddressPage"
    ) {
      gaDeliveryAddressObject = item;
      return false;
    }
    return true;
  });

  if (gaDeliveryAddressObject) {
    gaDeliveryAddressObject.ecommerce.checkout.products = products;
    set(getDigitalLayer(), "ecommerce.checkout.products", gaDeliveryAddressObject);
  } else {
    gaDeliveryAddressObject = {
      event: "checkout",
      ecommerce: {
        checkout: {
          actionField: { step: 1, option: "DeliveryAddressPage" },
          products,
        },
      },
    };
    getDigitalLayer().push(gaDeliveryAddressObject);
  }
};

/**
 * Setting the purchace event object for GA.
 *
 * @param {object} orderData Order Data
 * @param {object} customerDetails Order Data
 */
const setOrderDetailsForThankYouPage = (orderData, customerDetails) => {
  if (!orderData?.orderId || !orderData?.paymentReceived || orderData?.paymentReceived === "false") {
    return;
  }
  const products = [];
  orderData?.thankYouPageInfo?.forEach((orderItems) => {
    orderItems?.productDetails?.forEach((item) => {
      const product = {};
      product.id = item?.productId;
      product.sku = item?.sku;
      product.name = item?.productDisplayName;
      product.category = item?.category;
      product.brand = orderData?.brand;
      product.price = item?.productPrice;
      product.orderSequenceId = item?.orderSequenceId;
      product.currency = orderData?.currency;
      product.quantity = 1;
      products.push(product);
      if (Array.isArray(item?.productAddonsInfo)) {
        item?.productAddonsInfo?.forEach((addon) => {
          const addonProduct = {};
          addonProduct.id = addon?.productId;
          addonProduct.sku = addon?.sku;
          addonProduct.name = addon?.addonName;
          addonProduct.category = addon?.category;
          addonProduct.brand = orderData?.brand;
          addonProduct.price = addon?.addonPrice;
          addonProduct.orderSequenceId = addon?.orderSequenceId;
          addonProduct.currency = orderData?.currency;
          addonProduct.quantity = addon?.quantity;
          products.push(addonProduct);
        });
      }
    });
  });

  const gaPurchaceEvent = {
    event: "purchase",
    ecommerce: {
      purchase: {
        actionField: {
          id: orderData?.orderId,
          revenue: orderData?.grandTotal,
          discountAmt: orderData?.otherAdjAmount,
          tax: 0,
          shipping: orderData?.shippingAmount,
          currency: orderData?.currency,
          phone: customerDetails,
        },
        products,
      },
    },
  };
  getDigitalLayer().push(gaPurchaceEvent);
};

/**
 * @function pincodeGaEvent Setting the pincode related event object for GA.
 * @param {object} event event
 * @param {object} pincode pincode
 * @param {boolean} pageType pageType
 */
const pincodeGaEvent = (event, pincode, pageType) => {
  const gaPincodeEvent = {
    event,
    pincode,
  };
  if (event === "pincode_entered") {
    gaPincodeEvent.page_type = pageType || null;
  }
  getDigitalLayer().push(gaPincodeEvent);
};

/**
 * Setting the click event interactions .
 *
 * @param {string} event - event name
 * @param {string} eventCategory - event category
 * @param {string} eventAction - event action
 * @param {string} eventLabel - event label
 * @param {object} customDimension - custom dimension
 *
 */
const setGaClickEventInteraction = (event, eventCategory, eventAction, eventLabel = "", customDimension = {}) => {
  const gaLoginEvent = {
    event,
    eventCategory,
    eventAction,
  };

  if (eventLabel) Object.assign(gaLoginEvent, { eventLabel });

  if (Object.keys(customDimension)?.length) Object.assign(gaLoginEvent, { ...customDimension });

  getDigitalLayer().push(gaLoginEvent);
};

/**
 * @function sortvalue gettting the sort filter value and return the filer name
 * @param {string} val - filter name
 * @returns {string} filterName
 *
 */
const sortvalue = (val) => {
  const sortValues = {
    "listprice_fnp.com_INR|asc": "Low to High",
    "listprice_fnp.com_INR|desc": "High to Low",
    "created_stamp|desc": "New",
  };
  if (val && val?.includes("sequence")) {
    return "Recommended";
  }
  return val ? sortValues[val] : null;
};
/**
 * Get GA4 Viewlist Plp data
 *
 * @param {object} productList productsList
 * @param {string} pincode lastSelectec pincode
 * @param {string} currency selected currency
 * @param {boolean} isLogin isLogin
 * @param {string} categoryId category Id
 * @param {string} categoryName categoty name
 * @param {boolean} capsuleVisible capsuleVisible
 * @param {any} sort is sort filter avialable or which filter apply
 * @param {any} filter is filter avialable or which filter apply
 *
 */
const setGA4ViewItemList = (
  productList,
  pincode,
  currency,
  isLogin,
  categoryId,
  categoryName,
  capsuleVisible,
  sort,
  filter,
) => {
  const products = [];
  productList?.forEach((item, index) => {
    const product = {};
    product.index = index;
    product.item_name = item?.productName;
    product.item_id = item?.productId;
    product.price = item?.price?.listPrice;
    product.mrp = item?.price?.mrp || null;
    product.item_brand = item?.brandName || "FNP-India";
    product.item_variant = item?.variant;
    product.item_category = item?.primaryProductType || null;
    product.item_category2 = item?.productTypeId || null;
    product.item_category3 = item?.fnpProductType || null;
    products.push(product);
  });
  const ga4ViewItemList = {
    event: GA4_EVENTS.VIEW_ITEM_LIST,
    pincode: pincode || null,
    login_state: isLogin || false,
    ecommerce: {
      currency: "INR",
      selected_currency: currency || null,
      capsule_visible: capsuleVisible || null,
      item_list_id: categoryId || null,
      item_list_name: categoryName || null,
      sort: sortvalue(sort) || "Recommended",
      filter: filter || null,
      items: products,
    },
  };
  getDigitalLayer().push(ga4ViewItemList);
};

/**
 * Get GA4 filter slected
 *
 * @param {any} filter is filter avialable or which filter apply
 *
 */
const setGA4FilterSelected = (filter) => {
  const result = Array.isArray(filter);
  const ga4FilterSelected = {};
  if (result) {
    ga4FilterSelected.event = GA4_EVENTS.FILTER_CLICKED;
    ga4FilterSelected.selected_filter = filter?.[filter.length - 1] || null;
    ga4FilterSelected.active_filters = filter || null;
  } else {
    ga4FilterSelected.event = GA4_EVENTS.FILTER_CLICKED;
    ga4FilterSelected.selected_filter = sortvalue(filter);
    ga4FilterSelected.active_filters = null;
  }
  getDigitalLayer().push(ga4FilterSelected);
};

/**
 * Get GA4 Selected Product
 *
 * @param {string} itemIndex product index
 * @param {object} product Selected product
 * @param {string} pincode lastSelectec pincode
 * @param {string} currency selected currency
 * @param {boolean} isLogin isLogin
 * @param {string} categoryId category Id
 * @param {string} categoryName categoty name
 * @param {boolean} capsuleVisible capsuleVisible
 * @param {any} sort is sort filter avialable or which filter apply
 * @param {any} filter is filter avialable or which filter apply
 *
 */
const setGA4SelectedProduct = (
  itemIndex,
  product,
  pincode,
  currency,
  isLogin,
  categoryId,
  categoryName,
  capsuleVisible,
  sort,
  filter,
) => {
  const products = [];
  const productItem = {};
  productItem.index = itemIndex;
  productItem.item_name = product?.productName;
  productItem.item_id = product?.productId;
  productItem.price = product?.price?.listPrice;
  productItem.mrp = product?.price?.mrp || null;
  productItem.item_brand = product?.brandName || "FNP-India";
  productItem.item_variant = product?.varian;
  productItem.item_category = categoryId;
  productItem.item_category2 = categoryName;
  productItem.item_category3 = product?.productTypeId || null;
  productItem.item_category4 = product?.fnpProductType || null;
  products.push(productItem);
  const ga4ViewItemList = {
    event: GA4_EVENTS.SELECT_ITEM,
    login_state: isLogin || false,
    pincode: pincode || null,
    ecommerce: {
      capsule_visible: capsuleVisible || null,
      currency: "INR",
      selected_currency: currency || null,
      item_list_id: categoryId || null,
      item_list_name: categoryName || null,
      sort: sortvalue(sort) || null,
      filter: filter || null,
      items: products,
    },
  };
  getDigitalLayer().push(ga4ViewItemList);
};

/**
 * GA4 Search event
 *
 * @param {string} searchvalue search value
 */
const ga4SearchEvent = (searchvalue) => {
  const gaSearchEvent = {
    event: GA4_EVENTS.SEARCH,
    search: searchvalue || null,
  };
  getDigitalLayer().push(gaSearchEvent);
};

export {
  setProducDetailsForCheckout,
  setOrderDetailsForThankYouPage,
  setGaClickEventInteraction,
  setGA4ViewItemList,
  setGA4SelectedProduct,
  setGA4FilterSelected,
  ga4SearchEvent,
  pincodeGaEvent,
};
