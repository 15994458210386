import queryString from "query-string";
import getConfig from "next/config";
import { execDataLayer } from "../data-layer/dataLayer";
import { endpointConst } from "../data-layer/dataLayerConstants";
import axios from "../../app_configs/axios/base";
import errorLogger from "../../app_configs/logger-service";

const { publicRuntimeConfig } = getConfig();
const SHOW_REQUESTS_LOGS = publicRuntimeConfig.SHOW_REQUESTS_LOGS || "Y";
const error = {
  config: {},
  response: {},
};

/**
 * function to get the expiry details/time for items in the cart
 *
 * @returns {object} - returns object with cart expiry details
 */
async function getCartExpiry() {
  try {
    return await axios.get("/control/getCartExpiry-rj");
  } catch (err) {
    error.config.url = "/control/getCartExpiry-rj";
    error.response.status = `An exception occurred while fetching cart expiry => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * Fetches the coupon codes for the user if any
 *
 * @returns {object} - returns object with available coupons details
 */
async function getCoupons() {
  try {
    return await axios.get("/control/getCoupons-rj");
  } catch (err) {
    error.config.url = "/control/getCoupons-rj";
    error.response.status = `An exception occurred while fetching coupons => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * Fetches the cart items and their addons
 *
 * @returns {object} - returns object with cart item details
 */
async function getCompleteCartItemDetails() {
  try {
    /**
     * @function apiFn wrapper function to execute getCompleteCartItemDetails from datalayer.
     * @returns response from datalayer
     */
    const apiFn = async () => {
      return axios.post(`${endpointConst.getCompleteCartItem}`);
    };
    return await execDataLayer(apiFn, endpointConst.getCompleteCartItem);
  } catch (err) {
    error.config.url = "/control/getCompleteCartItemDetails-rj";
    error.response.status = `An exception occurred while fetching cart items => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * updates AddonQuantity for Product
 *
 * @param {object} data AddonQuantity details
 *
 * @returns {object} - returns api success info
 */
async function updateAddonQuantity(data) {
  try {
    const dataString = queryString.stringify(data);

    return await axios.post("/control/updateAddonQuantityProduct-rj", dataString);
  } catch (err) {
    error.config.url = "/control/updateAddonQuantityProduct-rj";
    error.response.status = `An exception occurred while updating AddonQuantity for Product => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * Adds and Addon to Cart
 *
 * @param {object} data Add AddonsToCart details
 *
 * @returns {object} - returns api success info
 */
async function addAddonsToCart(data) {
  try {
    const dataString = queryString.stringify(data);

    return await axios.post("/control/addAddonsToCart-rj", dataString);
  } catch (err) {
    error.config.url = "/control/addAddonsToCart-rj";
    error.response.status = `An exception occurred while adding Addons to Product => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * Fetches the current gift senders info
 *
 * @returns {object} - returns object with sender info
 */
async function getCurrentSenderInfo() {
  try {
    return await axios.get("/control/getCurrentSenderInfo-rj");
  } catch (err) {
    error.config.url = "/control/getCurrentSenderInfo-rj";
    error.response.status = `An exception occurred while fetching sender info => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * Fetches the current Salutations
 *
 * @returns {object} - returns object with Salutations
 */
async function getSalutations() {
  try {
    return await axios.get("/control/getSalutaions-rj");
  } catch (err) {
    error.config.url = "/control/getSalutaions-rj";
    error.response.status = `An exception occurred while fetching getSalutations => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * Fetches the Special countries geoIds
 *
 * @returns {object} - returns object with geoIds
 */
async function getSpecialCountriesGeoIds() {
  try {
    const params = { resource: "fnp", name: "ADDR_VALID_COUNTRY_GG", defaultValue: "N" };
    return await axios.get(`/control/getSystemPropertyValue?${queryString.stringify(params)}`);
  } catch (err) {
    error.config.url = "/control/getSystemPropertyValue";
    error.response.status = `An exception occurred while fetching specialCountriesGeoIds => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * Fetches the GetGrocery vendor URL and auth key
 *
 * @returns {object} - returns object with vendor url and auth key
 */
async function getVendorURLs() {
  try {
    const params = { contentId: "APP_GROCERY_VENDOR_DETAILS" };
    return await axios.get(`/control/getContentIdDetails-rj?${queryString.stringify(params)}`);
  } catch (err) {
    error.config.url = "control/getContentIdDetails-rj";
    error.response.status = `An exception occurred while fetching GetGrocery vendorURL => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * Fetches the Price Surge Details
 *
 * @returns {object} - returns object with Price Surge Details
 */
async function getPriceSurgeDetails() {
  try {
    return await axios.get("/control/getPriceSurgeDetails-rj");
  } catch (err) {
    error.config.url = "/control/getPriceSurgeDetails-rj";
    error.response.status = `An exception occurred while fetching PriceSurgeDetails => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * Fetches the Price Surge Details
 *
 * @param {string} productId productId
 *
 * @returns {object} - returns object with Price Surge Details
 */
async function getPriceSurgeDetailsByProductId(productId) {
  try {
    const params = {
      productId,
    };
    return await axios.get(`/control/getPriceSurgeForProductId?${queryString.stringify(params)}`);
  } catch (err) {
    error.config.url = "/control/getPriceSurgeForProductId";
    error.response.status = `An exception occurred while fetching PriceSurgeDetails => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * Fetches the Dates and shipping methods
 *
 * @param {string} pincode pincode
 * @param {string} productId productId
 * @param {boolean} isInternational assocProductId (optional)
 * @returns {object} - returns object with Dates and shipping methods
 */
async function getDatesAndShippingMethods(pincode, productId, isInternational) {
  try {
    let params = {
      pinCode: pincode,
      productId,
    };
    if (isInternational) {
      params = {
        geoId: pincode,
        productId,
      };
    }
    return await axios.get(`/control/getDatesAndShippingMethods-rj?${queryString.stringify(params)}`);
  } catch (err) {
    error.config.url = "/control/getDatesAndShippingMethods-rj";
    error.response.status = `An exception occurred while fetching dates and shipping methods => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    throw Error(err);
  }
}
/**
 * This function fetches shipping details
 *
 * @param {object} root0 it includes object from parent component
 * @param {string} root0.productId .
 * @param {string} root0.pinCode .
 * @param {string} root0.deliveryDate .
 * @param {string} root0.countryGeoId .
 * @param {boolean} isInternational isInternational
 * @returns {object} returns object with shipping methods.
 */
export async function getShippingDetails({ productId, pinCode, deliveryDate, countryGeoId }, isInternational) {
  try {
    let params = {
      productId,
      pinCode,
      deliveryDate,
      countryGeoId,
    };
    if (isInternational) {
      params = {
        productId,
        geoId: pinCode,
        deliveryDate,
        countryGeoId,
      };
    }
    return await axios.get(`/control/getShippingDetails-rj?${queryString.stringify(params)}`);
  } catch (err) {
    error.config.url = "/control/getShippingDetails-rj";
    error.response.status = `An exception occurred while fetching shipping methods => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    throw Error(err);
  }
}

/**
 * Fetches Shipment types for international
 *
 * @param {string} city  city
 * @param {string} productId productId
 * @returns {object} - returns object with Shipment types for international
 */
async function getEarliestShipmentTypesForINTL(city, productId) {
  try {
    const params = {
      city,
      productId,
    };
    return await axios.get(`/control/getEarliestShipmentTypesForINTL-rj?${queryString.stringify(params)}`);
  } catch (err) {
    error.config.url = "/control/getEarliestShipmentTypesForINTL-rj";
    error.response.status = `An exception occurred while fetching shipment types => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    throw Error(err);
  }
}

/**
 * Fetches International Dates.
 *
 * @param {string} city city.
 * @param {string} productId productId
 * @param {string} shipmentType shipmentType
 * @param {string} timeSlotId timeSlotId
 * @returns {object} - returns object with International Dates
 */
async function getIntlDeliveryDates(city, productId, shipmentType, timeSlotId) {
  try {
    const params = {
      city,
      productId,
      shipmentType,
      timeSlotId,
    };
    return await axios.get(`/control/getDeliverDatesForINTL-rj?${queryString.stringify(params)}`);
  } catch (err) {
    error.config.url = "/control/getDeliverDatesForINTL-rj";
    error.response.status = `An exception occurred while fetching delivery dates => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    throw Error(err);
  }
}
/**
 * Fetches the view cart api
 *
 * @returns {object} - returns object with cart items
 */
async function getViewCart() {
  try {
    return await axios.get("/control/viewcart?isExternal=Y");
  } catch (err) {
    error.config.url = "/control/viewcart";
    error.response.status = `An exception occurred while fetching viewcart => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * Fetches the Occasions And Template Messages
 *
 * @returns {object} - returns Occasions And Template Messages
 */
async function getOccasionsAndTemplateMsgs() {
  try {
    return await axios.get("/control/getOccasionsAndTemplateMsgs-rj");
  } catch (err) {
    error.config.url = "/control/getOccasionsAndTemplateMsgs-rj";
    error.response.status = `An exception occurred while fetching occasion templates => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * Fetches the Cart Summary Amount Detail
 *
 * @returns {object} - returns Cart Summary Amount Detail
 */
async function getCartSummaryAmountDetail() {
  try {
    return await axios.get("/control/getCartSummaryAmountDetail-rj");
  } catch (err) {
    error.config.url = "/control/getCartSummaryAmountDetail-rj";
    error.response.status = `An exception occurred while fetching Cart Summary Amount Detail => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * Fetches the Encoded Customer Details
 *
 * @returns {object} - returns Encoded Customer Details
 */
async function getEncodedCustomerDetails() {
  try {
    return await axios.get("/control/getEncodedCustomerDetails-rj");
  } catch (err) {
    error.config.url = "/control/getEncodedCustomerDetails-rj";
    error.response.status = `An exception occurred while fetching Encoded Customer Details => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * Sets the Message Card Details for mobile
 *
 * @param {object} data message card details
 *
 * @returns {object} - returns Encoded Customer Details
 */
async function setItemAttributesFromMobile(data) {
  try {
    const dataString = queryString.stringify(data);

    return await axios.post("/control/setItemAttributesFromMobile", dataString);
  } catch (err) {
    error.config.url = "/control/setItemAttributesFromMobile";
    error.response.status = `An exception occurred while Setting ItemAttributesFromMobile => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * Apply User entered coupon code
 *
 * @param {object} data coupon details
 *
 * @returns {object} - returns Coupon code response
 */
async function applyCoupon(data) {
  try {
    const dataString = queryString.stringify({
      productPromoCodeId: data,
      format: "json",
    });

    return await axios.post("/control/applyCoupon-rj", dataString);
  } catch (err) {
    error.config.url = "/control/applyCoupon-rj";
    error.response.status = `An exception occurred while applying Coupon => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * Remove User entered coupon code
 *
 * @param {object} data coupon details
 *
 * @returns {object} - returns Coupon code response
 */
async function removeCoupon(data) {
  try {
    const dataString = queryString.stringify({
      promoCode: data,
      format: "json",
    });

    return await axios.post("/control/removeCoupon-rj", dataString);
  } catch (err) {
    error.config.url = "/control/removeCoupon-rj";
    error.response.status = `An exception occurred while removing Coupon => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * Update Cart From Checkout Page
 *
 * @param {object} data cart details
 *
 * @returns {object} - returns update response
 */
async function updateCartFromCheckoutPage(data) {
  try {
    const dataString = queryString.stringify(data);

    return await axios.post("/control/updateCartFromDAPage-rj", dataString);
  } catch (err) {
    error.config.url = "/control/updateCartFromDAPage-rj";
    error.response.status = `An exception occurred while updating Cart From DAPage => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * set Item Attribute For Order Item
 *
 * @param {object} data item details
 *
 * @returns {object} - returns update response
 */
async function setItemAttributeForOrderItem(data) {
  try {
    const dataString = queryString.stringify(data);

    return await axios.post("/control/setItemAttributeForOrderItem", dataString);
  } catch (err) {
    error.config.url = "/control/setItemAttributeForOrderItem";
    error.response.status = `An exception occurred while setItemAttributeForOrderItem => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * set Address For Item
 *
 * @param {object} data item details
 *
 * @returns {object} - returns update response
 */
async function setAddressForItem(data) {
  try {
    const dataString = queryString.stringify(data);

    return await axios.post("/control/setAddressForItem-rj", dataString);
  } catch (err) {
    error.config.url = "/control/setAddressForItem-rj";
    error.response.status = `An exception occurred while set Address For Item => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * Delete Item Address
 *
 * @param {{cartItemIndex: number, contactMechId: number}} cartItemIndex cartItemIndex
 *
 * @returns {object} - returns if deleted success
 */
async function deleteItemAddress({ cartItemIndex, contactMechId }) {
  try {
    return await axios.get(
      `/control/deleteItemAddress-rj?cartItemIndex=${cartItemIndex}&contactMechId=${contactMechId}`,
    );
  } catch (err) {
    error.config.url = "/control/deleteItemAddress-rj";
    error.response.status = `An exception occurred while deleting Item Address => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * Fetches Message Card Details
 *
 * @param {number} cartItemIndex cartItemIndex
 *
 * @returns {object} - returns terms and condition
 */
async function getCardMessageDetails(cartItemIndex) {
  try {
    return await axios.get(`/control/getCardMessageDetails-rj?cartItemIndex=${cartItemIndex}`);
  } catch (err) {
    error.config.url = "/control/getCardMessageDetails";
    error.response.status = `An exception occurred while fetching getCardMessageDetails => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * Deletes the Addons or Gift Item
 *
 * @param {string} url url with all query params
 * @returns {object} - returns response
 */
async function deleteItem(url) {
  try {
    return await axios.get(url);
  } catch (err) {
    error.config.url = "/control/deleteItem-rj";
    error.response.status = `An exception occurred while deleting addon/gift Item => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * Fetches Address Details
 *
 * @param {string} url url with all query params
 * @returns {object} - returns response
 */
async function getAddressDetails(url) {
  try {
    return await axios.get(url);
  } catch (err) {
    error.config.url = "/control/getAddressDetails-rj";
    error.response.status = `An exception occurred while getting Address Details => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * Fetches Saved Address For PinCode
 *
 * @param {string} url url with all query params
 * @returns {object} - returns response
 */
async function getSavedAddressForPinCode(url) {
  try {
    return await axios.get(url);
  } catch (err) {
    error.config.url = "/control/getSavedAddressForPincode-rj";
    error.response.status = `An exception occurred while getting Saved Address For PinCode => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * Fetches the AddonsInfo
 *
 * @param {string} url url with all query params
 * @returns {object} - returns AddonsInfo
 */
async function getAddonsInfo(url) {
  try {
    return await axios.get(url);
  } catch (err) {
    error.config.url = "/control/getAddonsInfo-rj";
    error.response.status = `An exception occurred while fetching AddonsInfo => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * call all checkout page apis in parallel
 *
 * @returns {object} - returns object of promise responses for each API
 */
async function initiateCheckoutApis() {
  try {
    const response = await Promise.all([
      getCartExpiry(),
      getCoupons(),
      getCompleteCartItemDetails(),
      getCurrentSenderInfo(),
      getViewCart(),
      getOccasionsAndTemplateMsgs(),
      getCartSummaryAmountDetail(),
      getEncodedCustomerDetails(),
    ]);

    return {
      cartExpiry: response[0],
      coupons: response[1],
      cartItems: response[2],
      senderInfo: response[3],
      viewCart: response[4],
      occasionsAndTemplateMsgs: response[5],
      cartSummaryAmountDetail: response[6],
      encodedCustomerDetails: response[7],
    };
  } catch (err) {
    error.config.url = "/initiateCheckoutApis";
    error.response.status = `An exception occurred while fetching initiateCheckoutApis => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * call all payment page apis
 *
 * @returns {object} - returns object of promise responses for each API
 */
async function paymentOptionsApis() {
  try {
    const response = await Promise.all([
      getCompleteCartItemDetails(),
      getViewCart(),
      getCartSummaryAmountDetail(),
      getEncodedCustomerDetails(),
    ]);

    return {
      cartItems: response[0],
      viewCart: response[1],
      cartSummaryAmountDetail: response[2],
      encodedCustomerDetails: response[3],
    };
  } catch (err) {
    error.config.url = "/paymentOptionsApis";
    error.response.status = `An exception occurred while fetching paymentOptionsApis => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * Getting the updated cart apis
 *
 * @returns {object} - returns object of promise responses for each API
 */
async function initiateCartApis() {
  try {
    const response = await Promise.all([
      getCartExpiry(),
      getCompleteCartItemDetails(),
      getCartSummaryAmountDetail(),
      getViewCart(),
    ]);

    return {
      cartExpiry: response[0],
      cartItems: response[1],
      cartSummaryAmountDetail: response[2],
      viewCart: response[3],
    };
  } catch (err) {
    error.config.url = "/initiateCartApis";
    error.response.status = `An exception occurred while fetching initiateCheckoutApis => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * call all add new address page apis in parallel
 *
 * @param {{addressUrl: string, savedAddressForPinCodeUrl: string}} props props
 *
 * @returns {object} - returns object of promise responses for each API
 */
async function initiateAddNewAddressApis({ savedAddressForPinCodeUrl }) {
  try {
    const response = await Promise.all([
      getCompleteCartItemDetails(),
      getSavedAddressForPinCode(savedAddressForPinCodeUrl),
    ]);

    return {
      cartItems: response[0],
      savedAddressForPinCode: response[1],
    };
  } catch (err) {
    error.config.url = "/initiateAddNewAddressApis";
    error.response.status = `An exception occurred while fetching initiateAddNewAddressApis => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * call apply manual adjustment API
 *
 * @param {object} adjustmentObj adjustment amount and percent
 *
 * @returns {object} - returns object of promise responses for each API
 */
async function applyManualAdjustments(adjustmentObj) {
  try {
    const formData = {};
    formData.adjustAmount = adjustmentObj.adjustAmount;
    if (adjustmentObj.amount) {
      formData.amount = adjustmentObj.amount;
    }
    if (adjustmentObj.percent) {
      formData.percent = adjustmentObj.percent;
    }
    const response = await axios.post("/control/addManualAdjustMent-rj", queryString.stringify(formData), {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    });
    return response;
  } catch (err) {
    error.config.url = "/control/addManualAdjustMent";
    error.response.status = `An exception occurred while applying manual adjustments => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * call remove manual adjustment API
 *
 * @returns {object} - returns object of promise responses for each API
 */
async function removeManualAdjustments() {
  try {
    const response = await axios.post("/control/removeManualAdjustMent-rj");
    return response;
  } catch (err) {
    error.config.url = "/control/removeManualAdjustMent";
    error.response.status = `An exception occurred while removing manual adjustments => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * Fetches the suggested cities.
 *
 * @param {string} value cityHint.
 * @returns {object} - returns citeylist.
 */
export async function getSuggestedCities(value) {
  try {
    const response = await axios.get(`/control/getSuggestedCities?cityHint=${value}`);
    return response?.data;
  } catch (err) {
    error.config.url = "/control/getSuggestedCities";
    error.response.status = `An exception occurred while fetching sender info => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * Fetches the suggested cities.
 *
 * @param {string} countryGeoId countryGeoId.
 * @returns {object} - returns citeylist.
 */
export async function getCitiesList(countryGeoId) {
  try {
    const response = await axios.get(`/control/getCitiesList?countryGeoId=${countryGeoId}`);
    return response?.data;
  } catch (err) {
    error.config.url = "/control/getCitiesList?countryGeoId";
    error.response.status = `An exception occurred while fetching sender info => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * This function fetches shipping details
 *
 * @param {object} data reuested data object like shipping details and dates
 * @returns {object} rel alternate data on success and empty on exception.
 */
export async function updateCartItemValues(data) {
  try {
    const dataString = queryString.stringify(data);
    /**
     * @function apiFn wrapper function to execute setCartItemValues from datalayer.
     * @returns response from datalayer
     */
    const apiFn = async () => {
      return axios.post(`${endpointConst.setCartItemValue}`, dataString);
    };
    return await execDataLayer(apiFn, endpointConst.setCartItemValue);
  } catch (ex) {
    error.config.url = "/control/getShippingDetails-rj";
    error.response.status = `An exception occurred while fetching shipping details => ${ex}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    throw Error(ex);
  }
}

/**
 * add or removes the donation.
 *
 * @param {string} action donation payload.
 * @returns {object} - returns response.
 */
async function addOrRemoveDonation(action) {
  try {
    const endPoint = action.donationType === "ADD" ? "addDonationAdjustmentToCart" : "removeDonationAdjustMent";
    const response = await axios.get(`/control/${endPoint}?amount=${action.amount}`);
    return response?.data;
  } catch (err) {
    error.config.url = "/control/addDonationAdjustmentToCart?amount";
    error.response.status = `An exception occurred while adding or removing donation => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * Fetches the Donation info
 *
 * @returns {object} - returns response
 */
async function fetchDonationInfo() {
  try {
    const params = { contentId: "CRY-DONATION-INFO" };
    return await axios.get(`/control/getContentIdDetails-rj?${queryString.stringify(params)}`);
  } catch (err) {
    error.config.url = "control/getContentIdDetails-rj";
    error.response.status = `An exception occurred while fetching donation info => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * Fetches the Donation enabling or not
 *
 * @returns {object} - returns object with donation enabling
 */
async function getDonationEnable() {
  try {
    const params = { resource: "fnp", name: "enable.donation" };
    return await axios.get(`/control/getSystemPropertyValue?${queryString.stringify(params)}`);
  } catch (err) {
    error.config.url = "/control/getSystemPropertyValue";
    error.response.status = `An exception occurred while fetching donation enable => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * Fetches the Donation amount
 *
 * @returns {object} - returns object with donation amount
 */
async function getDonationAmount() {
  try {
    const params = { resource: "fnp", name: "donation.ammount" };
    return await axios.get(`/control/getSystemPropertyValue?${queryString.stringify(params)}`);
  } catch (err) {
    error.config.url = "/control/getSystemPropertyValue";
    error.response.status = `An exception occurred while fetching donation enable => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

const checkoutActions = {
  getCartExpiry,
  getCoupons,
  getCompleteCartItemDetails,
  getCurrentSenderInfo,
  getPriceSurgeDetails,
  getViewCart,
  getOccasionsAndTemplateMsgs,
  getCartSummaryAmountDetail,
  getEncodedCustomerDetails,
  getAddonsInfo,
  initiateCheckoutApis,
  getCardMessageDetails,
  setItemAttributesFromMobile,
  updateAddonQuantity,
  applyCoupon,
  removeCoupon,
  addAddonsToCart,
  deleteItem,
  paymentOptionsApis,
  getSalutations,
  getAddressDetails,
  initiateAddNewAddressApis,
  getSavedAddressForPinCode,
  updateCartFromCheckoutPage,
  setItemAttributeForOrderItem,
  setAddressForItem,
  applyManualAdjustments,
  removeManualAdjustments,
  deleteItemAddress,
  getSpecialCountriesGeoIds,
  getDatesAndShippingMethods,
  getEarliestShipmentTypesForINTL,
  getIntlDeliveryDates,
  getVendorURLs,
  initiateCartApis,
  getPriceSurgeDetailsByProductId,
  addOrRemoveDonation,
  fetchDonationInfo,
  getDonationEnable,
  getDonationAmount,
};

export default checkoutActions;
