import getConfig from "next/config";
import serverControl from "../../app_configs/axios/servercontrol";

const { publicRuntimeConfig } = getConfig();
/**
 * It will give the html for plp filters
 *
 * @param {string} options options for fetching plp filter html .
 * @returns {string} returns the html for plp filter .
 */
export default async function getPlpLeftNavHtml(options) {
  const productApiUrl = publicRuntimeConfig.PRODUCT_API_URL;
  try {
    const resData = await serverControl.get(`productapi/api/rest/v1.3/plpLeftNav?categoryId=${options}`, {
      baseURL: productApiUrl,
      headers: {
        OQS: options,
      },
    });
    const filterHtml = (resData.data && resData.data.data && resData.data.data.htmlDOM) || "";
    return filterHtml;
  } catch (error) {
    return "";
  }
}
