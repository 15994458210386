import { put, takeLatest, call, takeEvery } from "redux-saga/effects";
import getConfig from "next/config";
import TicketsConstants from "../../src/action-constants/tickets-constants";
import errorLogger from "../../app_configs/logger-service";
import {
  getMyTicketsList,
  getMyTicketDetails,
  getUserSubOrderDetails,
  updateTickets,
  getAWSPreSignedUrl,
  uploadAWSPreSignedImage,
  getTicketTypes,
  createTickets,
} from "../../src/services/api/myAccount/ticketsApi";

const { publicRuntimeConfig } = getConfig();
const SHOW_REQUESTS_LOGS = publicRuntimeConfig.SHOW_REQUESTS_LOGS || "Y";

/**
 * to fetch list of all tickets
 *
 * @param {object} action - action object for action type and payload
 */
function* getAllTicketsListData(action) {
  try {
    const response = yield call(getMyTicketsList, action.payload);
    if (response.data.data) {
      const ticketsDetails = response.data.data.map((ticket) => {
        return { ...ticket, communications: ticket.communications.reverse() };
      });
      yield put({
        type: TicketsConstants.GET_TICKETS_DATA,
        payload: {
          ticketsDetails,
          totalPages: response.data.pages,
          totalTickets: response.data.totalTickets,
        },
      });
    }
  } catch (error) {
    yield put({
      type: TicketsConstants.GET_TICKETS_DATA_FAILURE,
    });
    errorLogger(error, SHOW_REQUESTS_LOGS);
  }
}

/**
 * to fetch pre-filled details for update ticket
 *
 * @param {object} action - action object for action type and payload
 */
function* getUpdateTicketData(action) {
  try {
    const response = yield call(getMyTicketDetails, action.payload.id);
    if (response.status === 200) {
      yield put({
        type: TicketsConstants.GET_UPDATE_TICKET_DETAIL,
        payload: {
          ticketDetail: response.data,
        },
      });
    }
  } catch (error) {
    errorLogger(error, SHOW_REQUESTS_LOGS);
  }
}

/**
 * to fetch list of all orders
 *
 * @function action - action object for action type and payload
 */
function* getAllOrdersData() {
  try {
    const { data } = yield call(getUserSubOrderDetails);
    if (data.isSuccess) {
      yield put({
        type: TicketsConstants.GET_ALL_ORDERS_DETAIL,
        payload: {
          orderDetails: {
            orderList: data.OrderDetails.data.subOrdersList,
            viewSize: data.OrderDetails.data.viewSize,
            completeSize: data.OrderDetails.data.completeSize,
          },
        },
      });
    }
  } catch (error) {
    errorLogger(error, SHOW_REQUESTS_LOGS);
  }
}

/**
 * to submit form on update ticket
 *
 * @param {object} action - action object for action type and payload
 */
function* submitUpdateTicket(action) {
  try {
    const { data } = yield call(updateTickets, action.payload);
    if (data) {
      const ticketDetail = { ...data, communications: data.communications.reverse() };
      yield put({
        type: TicketsConstants.UPDATE_TICKET_SUCCESS,
        payload: {
          ticketDetail,
        },
      });
    }
  } catch (error) {
    errorLogger(error, SHOW_REQUESTS_LOGS);
  }
}

/**
 * to upload image
 *
 * @param {object} action - action object for action type and payload
 */
function* UploadImage(action) {
  try {
    const { data } = yield call(getAWSPreSignedUrl);
    if (data.isSuccess) {
      const response = yield call(uploadAWSPreSignedImage, data.data.presignedUrl, action.payload.file);
      if (response.status >= 200 && response.status < 300) {
        yield put({
          type: TicketsConstants.UPLOAD_IMAGE_SUCCESS,
          payload: { path: data.data.presignedUrl.split("?")[0] },
        });
      }
    }
  } catch (error) {
    errorLogger(error, SHOW_REQUESTS_LOGS);
  }
}

/**
 * formats query type list data
 *
 * @param {Array} list - query type list
 * @returns {Array} - formatted query type list
 */
const formatDropDownList = (list) => {
  const roots = [];
  const nodes = {};
  for (let i = 0; i < list.length; i += 1) {
    const item = list[i];
    if (!item.parent) {
      roots.push({ data: item });
    } else {
      nodes[item.parent] = nodes[item.parent] || [];
      nodes[item.parent].push({ data: item });
    }
  }
  /**
   * creates query type tree recursively
   *
   * @param {object} parent - parent node
   */
  const findChildren = (parent) => {
    const parentNode = parent;
    if (nodes[parent.data.id]) {
      parentNode.nodes = nodes[parent.data.id];
      parent.nodes.map((pNode) => findChildren(pNode));
    }
  };
  roots.map((parent) => findChildren(parent));
  return roots;
};

/**
 * get drop down list for query type
 */
function* getDropDownList() {
  try {
    const response = yield call(getTicketTypes);
    if (response.status === 200) {
      yield put({
        type: TicketsConstants.CREATE_TICKET_DROPDOWN_DATA,
        payload: { ticketTypes: formatDropDownList(JSON.parse(response.data.ticketTypes)) },
      });
    }
  } catch (error) {
    errorLogger(error, SHOW_REQUESTS_LOGS);
  }
}

/**
 * submit create ticket
 *
 * @param {object} action - action object for action type and payload
 */
function* submitTicket(action) {
  try {
    const { data } = yield call(createTickets, action.payload);
    if (data.duplicate === true) {
      yield put({
        type: TicketsConstants.SUBMIT_TICKET_SUCCESS,
        payload: {
          submitMessage: `A similar request (${data.seqId}) was found & it was updated`,
          createdId: data.seqId,
        },
      });
    } else {
      yield put({
        type: TicketsConstants.SUBMIT_TICKET_SUCCESS,
        payload: {
          submitMessage: "Ticket Created Successfully!",
          createdId: data.seqId,
        },
      });
    }
  } catch (error) {
    errorLogger(error, SHOW_REQUESTS_LOGS);
  }
}

/**
 * watcher saga for Tickets History
 */
function* watchTicketsRequest() {
  yield takeLatest(TicketsConstants.GET_TICKETS_LIST_REQUESTED, getAllTicketsListData);
  yield takeLatest(TicketsConstants.GET_UPDATE_TICKET_DETAIL_REQUESTED, getUpdateTicketData);
  yield takeLatest(TicketsConstants.GET_ALL_ORDERS_DETAIL_REQUESTED, getAllOrdersData);
  yield takeLatest(TicketsConstants.UPDATE_TICKET_REQUESTED, submitUpdateTicket);
  yield takeEvery(TicketsConstants.UPLOAD_IMAGE_REQUESTED, UploadImage);
  yield takeLatest(TicketsConstants.CREATE_TICKET_DROPDOWN_REQUESTED, getDropDownList);
  yield takeLatest(TicketsConstants.SUBMIT_TICKET_REQUESTED, submitTicket);
}

export default watchTicketsRequest;
