import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  checkboxContainer: {
    width: "100%",
    "& label": {
      margin: 0,
    },
  },
  checkboxStyle: {
    height: "16px",
    width: "16px",
    "&.Mui-checked": {
      color: theme.color.orange,
    },
  },
}));

/**
 * This functional component is reusable checkbox component for filter.
 *
 * @param {object} props - Component props
 * @param {object} props.facetOption - facetOption
 * @param {string} props.facetFieldName - name of filter
 * @param {string} props.facetFieldDisplayName - name of filter
 * @param {Node} props.checkBoxLabel --label for checkbox
 * @param {object} props.defaultSelection - current filter selections
 * @param {boolean} props.isMobile - device type
 * @returns {React.Fragment} return checkbox component.
 */
const CustomCheckBox = ({
  facetOption,
  facetFieldName,
  facetFieldDisplayName,
  checkBoxLabel,
  defaultSelection = {},
  isMobile = false,
}) => {
  const { value: val, selected } = facetOption;
  const [toggle, setToggle] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (!isMobile) {
      setToggle(selected);
    } else if (defaultSelection && Object.prototype.hasOwnProperty.call(defaultSelection, val)) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  }, [defaultSelection, isMobile, selected, val]);

  /**
   * Function that returns checkbox component
   *
   * @returns {React.ReactNode} checkbox component
   */
  const renderCheckbox = (
    <Checkbox
      checked={toggle}
      className={classes.checkboxStyle}
      value={val}
      inputProps={{
        name: facetFieldName,
        "data-testid": "checkbox",
        "data-attributename": facetFieldDisplayName,
        "data-id": "filter-data",
      }}
      onChange={() => setToggle(!toggle)}
    />
  );

  return (
    <div className={classes.checkboxContainer}>
      <FormControlLabel control={renderCheckbox} label={checkBoxLabel} />
    </div>
  );
};
CustomCheckBox.propTypes = {
  facetOption: PropTypes.shape({ value: PropTypes.string, selected: PropTypes.bool }).isRequired,
  facetFieldName: PropTypes.string.isRequired,
  facetFieldDisplayName: PropTypes.string.isRequired,
  checkBoxLabel: PropTypes.node.isRequired,
  defaultSelection: PropTypes.objectOf(PropTypes.any),
  isMobile: PropTypes.bool,
};
CustomCheckBox.defaultProps = {
  defaultSelection: {},
  isMobile: false,
};
export default CustomCheckBox;
