import React from "react";
import styles from "./styles/m-plp.module.scss";
import { isMobileDevice } from "../../../src/utils/common";

/**
 * This functional component is dedicated to render loading symbol
 *
 * @returns {React.ReactElement} .
 */
const Loader = () => {
  const isMobile = isMobileDevice();

  return (
    <div className={styles.loader} key={0}>
      <img
        src={isMobile ? "/assets/images/full_page_loader-bb.gif" : "/assets/images/full_page_loader.gif"}
        alt="fetching products"
      />
    </div>
  );
};
export default Loader;
