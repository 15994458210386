import { HYDRATE } from "next-redux-wrapper";
import ACTION_CONSTANTS from "../../../src/action-constants/app-actions";

const initialState = {
  fetchError: false,
  productList: [],
  showAddOnPopup: false,
  showPDPAddOnPopup: false,
};

/**
 * This method is the reducer for actions that are specific to add ons screen
 *
 * @param {object} state - contains initial redux State
 * @param {object} action - contains actionTypes and payload information for specific action
 * @returns {object} state - the next State which needs to be dispatched to redux
 */
function addOnsReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.addOnDetails,
      };
    case ACTION_CONSTANTS.GET_ADD_ON_PRODUCTS_SUCCESS:
      return {
        ...state,
        productList: payload.addonsList[0].products,
        fetchError: false,
      };
    case ACTION_CONSTANTS.GET_ADD_ON_PRODUCTS_ERROR:
      return {
        ...state,
        fetchError: true,
      };
    case ACTION_CONSTANTS.SHOW_ADDON_REQUEST:
      return {
        ...state,
        showAddOnPopup: payload,
      };
    case ACTION_CONSTANTS.SHOW_PDP_ADDON_REQUEST:
      return {
        ...state,
        showPDPAddOnPopup: payload,
      };
    case ACTION_CONSTANTS.CLEAR_ADDON_PRODUCT_LIST:
      return {
        ...state,
        productList: [],
      };
    default:
      return state;
  }
}

export default addOnsReducer;
