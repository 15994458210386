import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import { useSelector, useDispatch } from "react-redux";
import CallUsStrip from "../call-us-strip";
import CurrencyDropdown from "../dropdown/currency-dropdown";
import MoreInfoDropdown from "../dropdown/more-info-dropdown";
import style from "./info-toolbar.module.scss";
import dheaderStyle from "../../styles/d-header.module.scss";
import scrollStyle from "../../../styles/desktop-layout.module.scss";
import APP_CONSTANTS from "../../../../../src/action-constants/app-actions";
import { CheckoutRoutes } from "../../../../../src/action-constants/checkout-constants";
import SubInfoLinks from "../SubInfoLinks";

/**
 * Info strip component has the currency,call us,more info sections.
 *
 * @param {object} root0  props passed to the component .
 * @param {boolean} root0.showCallUs it decides whether to call us strip to display.
 * @returns {React.ReactElement} returns the info strip component.
 */
function InfoToolbarSection({ showCallUs }) {
  const dispatch = useDispatch();
  const router = useRouter();
  const { desktop } = useSelector((state) => state.appConfigs.configData);
  const { contactUs, moreInfoDropDown, moreInfoLinks } = desktop;
  const walletDetails = router.query?.slug1 === CheckoutRoutes.WALLET_DETAILS;
  const { currencies: currenciesList } = useSelector((state) => state.currenciesList);

  useEffect(() => {
    if (currenciesList.length < 1) {
      dispatch({ type: APP_CONSTANTS.FETCH_CURRENCIES_REQUESTED });
    }
  }, [currenciesList, dispatch]);

  return (
    <div className={`${style["info-toolbar"]} ${dheaderStyle["align-items"]} ${scrollStyle["scroll-bar"]}`}>
      <div className={`${style["base-container"]} ${style.container}`}>
        {!walletDetails && (
          <>
            <CurrencyDropdown />
            <span className={style.divider}>|</span>
          </>
        )}
        {showCallUs ? (
          <>
            <CallUsStrip description={contactUs?.description} link={contactUs?.link} />
            <span className={style.divider}>|</span>
          </>
        ) : null}
        {moreInfoLinks.map((item) => (
          <React.Fragment key={item.label}>
            <SubInfoLinks links={item} />
            <span className={style.divider}>|</span>
          </React.Fragment>
        ))}
        <MoreInfoDropdown moreInfoDropDown={moreInfoDropDown} />
      </div>
    </div>
  );
}

InfoToolbarSection.propTypes = {
  showCallUs: PropTypes.bool,
};

InfoToolbarSection.defaultProps = {
  showCallUs: false,
};

export default InfoToolbarSection;
