import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import Loader from "../../../mobile/plp/loader";
import styles from "../style/modal-datetime-picker.module.scss";

/**
 * This is a functional component representing an animated Modal component to render delivery screens.
 *
 * @param {object} props .
 * @returns {React.ReactElement} the JSX for the component
 */
const ModalDateTimePicker = React.forwardRef((props, ref) => {
  const { children, onClose, className, showLoader } = props;
  const { isLoading } = useSelector((state) => state.productDetail);

  return (
    <div className={styles.desktopModal}>
      <div className={`modal-date-time-picker-wrapper ${className || ""}`} ref={ref}>
        <div className="modal-date-time-picker-container">
          <div className="modal-fullscreen-container-inner">
            <div className="modal-date-time-picker-header">
              <span className="close">
                <CloseIcon
                  onClick={() => {
                    onClose();
                  }}
                />
              </span>
            </div>
            <div className="modal-fullscreen-body">
              {(isLoading || showLoader) && (
                <div className="delivery-modal-spinner">
                  <Loader />
                </div>
              )}
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

ModalDateTimePicker.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  showLoader: PropTypes.bool,
};
ModalDateTimePicker.defaultProps = {
  className: "",
  showLoader: false,
};

export default ModalDateTimePicker;
