import { shallowEqual, useSelector } from "react-redux";

/**
 * Common omniture data
 *
 * @returns {object} common omniture data needed for every page load
 */
export default function useCommonOmnitureData() {
  const rootGeoId = useSelector((state) => state.appConfigs?.rootGeoId);
  const catalogId = useSelector((state) => state.appConfigs?.catalogId);
  const isMobile = useSelector((state) => state.appConfigs?.isMobile);

  const checkLoginInfo = useSelector((state) => state.userDetails?.checkLoginInfo, shallowEqual);

  return {
    rootGeoId,
    catalogId,
    checkLoginInfo,
    isMobile,
  };
}
