import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  $primary: "#0000FF",
  $bgGrey: "#f8f8f8",
  $black: "#f8f8f8",
  $white: "#ffffff",
  $blue: "#2179d0",
  $green: "#009d43",
  $darkGreen: "#009740",
  $bgDarkGrey: "f2f2f2",
  $defaultBorderRadius: "4px",
  $darkGrey: "#555555",
  breakpoints: {
    keys: ["xs", "sm", "md", "lg"],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
    },
  },
  color: {
    olive: "#7D8035",
    lightOlive: "#707428",
    green: "#008539",
    orange: "#E87325",
    blue: "#2178CF",
    red: "#E03131",
    darkBlack: "#222222",
    darkGrey: "#3D3D3D",
    lightGrey: "#555555",
    lighterGrey: "#999999",
    white: "#ffffff",
    lightCream: "#F2F3E8",
    stripBlue: "#2179D0",
    offWhite: "#CCCCCC",
    buttonBorderWhite: "#D5D5D5",
    buttonWhite: "#F2F2F2",
    moderateGrey: "#666",
    tealBlue: "#2179d0",
    lightBlack: "#d5d5d5",
    normalGrey: "#f4f4f4",
  },
  /**
   * Commenting it for the better reach
   * Kindly use custom theme of make styles at component level avoid editing the default theme file
   */
  // palette: {
  //   primary: {
  //     main: "#0000FF",
  //   },
  //   secondary: {
  //     main: "#f37f0a",
  //   },
  //   grey: {
  //     400: "#666",
  //   },
  // },
  typography: {
    fontFamily: ["Roboto", "sans-serif"].join(","),
    fontSize: 14,
    h1: {
      fontSize: "1.6rem",
      fontWeight: 800,
    },
    h2: {
      fontSize: 36,
      fontWeight: 800,
    },
    body1: {
      fontSize: 18,
    },
  },
});

export default theme;
