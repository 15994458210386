import React, { useEffect, useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Container } from "@material-ui/core";
import HTMLReactParser from "html-react-parser";
import { parseCookies } from "nookies";
import MobileHeader from "./mobile/mobile-header";
import MobileFooter from "./mobile/mobile-footer";
import { LocationLockStaticConstants } from "../common/constants";
import APP_CONSTANTS from "../../src/action-constants/app-actions";
import { AccountRoutes } from "../../src/action-constants/my-account-constants";
import {
  checkCartAndLoginCookie,
  getPageName,
  getRoutePageName,
  setIsMicroSite,
  RoutePageConstants,
  getFnpPageType,
} from "../../src/utils/common";
import errorLogger from "../../app_configs/logger-service";
import { CDP_ERORR_MSG } from "../../src/constants/common/errorConstants";
import CommonPageActionsConstants from "../../src/action-constants/common-page-actions-constants";
import { cleverTapCommonEvents, cleverTapEvents } from "../../src/analytics/clevertapConstants";
import callClevertapEvent from "../../src/analytics/clevertapUtility";
import AppRedirectionBanner from "../common/appRedirectBanner/app-redirection-banner";

const Error = dynamic(() => import("../../pages/_error"), {
  ssr: false,
});

const LocationLockStrip = dynamic(() => import("../mobile/location-lock/location-lock-strip"), {
  loading: () => <div style={{ minHeight: "40px" }} />,
  ssr: false,
});
const hashToBeRemoved = {
  llp: "llp",
  lls: "lls",
  rm: "rm",
  lm: "lm",
  sortm: "sortm",
};

/**
 * Component for layout of mobile
 *
 * @param {object} root0  props passed to the component .
 * @param {React.ReactElement} root0.children represents children passed to MobileLayout
 * @param {string} root0.errorPageName represents error page name
 * @param {number} root0.statusCode represents error status code
 * @returns {React.ReactElement} main JSX for the MobileLayout component
 */
function MobileLayout({ children, errorPageName, statusCode }) {
  const [showAppBanner, setShowAppBanner] = useState(true);
  const [bannerDisapperaDetect, setBannerDisappearDetect] = useState(false);
  const router = useRouter();
  const dispatch = useDispatch();
  const redirectUrl = useSelector((state) => state.productsLists.redirectUrl);
  const productsList = useSelector((state) => state.productsLists.productsList);
  const appRedirectionBannerDetails = useSelector(
    (state) => state.commonPageData?.appRedirectionBannerDetails || {},
    shallowEqual,
  );
  const { lastSelectedPincode, faul, lastSelectedAddress } = parseCookies();
  const countTime = useRef(0);

  useEffect(() => {
    dispatch({ type: CommonPageActionsConstants.GET_CALL_CHAT_TIME });
  }, [dispatch]);

  useEffect(() => {
    if (window.localStorage.getItem("socialLogin") && lastSelectedPincode && lastSelectedAddress && faul) {
      dispatch({
        type: APP_CONSTANTS.SET_LOGGED_IN_PINCODE,
        payload: {
          pincode: lastSelectedPincode,
          email: faul,
          customerAddress: lastSelectedAddress,
        },
      });

      window.localStorage.removeItem("socialLogin");
    }
    if (window.sessionStorage.getItem("ctapSocialLogin")) {
      const loginAttributes = {
        Site: {
          Identity: faul,
          Email: faul,
        },
      };
      const loginSuccessEvent = {
        loginSuccess: "Login Success",
      };
      const publishCleverTapData = {
        profile: { identity: faul },
      };
      dispatch({ type: CommonPageActionsConstants.POST_DATA_IN_CLEVER_TAP, payload: publishCleverTapData });
      callClevertapEvent(cleverTapEvents.userLogin, loginAttributes, cleverTapEvents.userLogin);
      callClevertapEvent(cleverTapCommonEvents.loginSuccess, loginSuccessEvent);
      window.sessionStorage.removeItem("ctapSocialLogin");
    }
    // not required re-rendering only it executes on first render of page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    /**
     * This helps in redirect if redirect url is returned from product listing api during ssr * call as current next version used in project is not configured for ssr redirect configurations
     */

    if (productsList?.length === 0 && redirectUrl) {
      router.push(redirectUrl);
    }
    setBannerDisappearDetect(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const currentURL = new URL(window.location.href);
    const { pathname, search, hash } = currentURL;
    if (hash) {
      const beforeHashStr = hash.split("#")[1];
      const hashParams = new URLSearchParams(beforeHashStr);
      if (Object.prototype.hasOwnProperty.call(hashToBeRemoved, beforeHashStr)) {
        hashParams.delete(beforeHashStr);
        let ahr = hashParams.toString();
        if (beforeHashStr || ahr) {
          ahr = ahr ? `#${ahr}` : "";
          router.replace(
            {
              pathname: router.pathname,
              query: router.query,
              hash: ahr,
            },
            `${`${pathname + search}${ahr}`}`,
            { shallow: true },
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pageName = errorPageName || getRoutePageName(router.pathname);
  const pageType = getFnpPageType();

  const { catalogId, rootGeoId, configData } = useSelector((state) => state.appConfigs);
  const contactDetails = configData?.mobile?.contactNo;
  const showBottomSticky = pageName === RoutePageConstants.PLP_PAGE;

  const slugLength = Object.keys(router.query).filter((item) => item.match(/slug/)).length;
  const isInternationalHomepage =
    catalogId !== LocationLockStaticConstants.CATALOG_ID_INDIA &&
    router?.query?.slug2?.startsWith("gifts") &&
    slugLength === 2;
  const slug2 = router.query.slug1;

  const showLocationLockStrip =
    router.query.slug1 !== "search" &&
    [RoutePageConstants.PLP_PAGE, RoutePageConstants.HOME].includes(pageName) &&
    !router?.asPath.includes("search");

  const showErrorContent = pageName === RoutePageConstants.SERVER_ERROR;
  const showMobileFooter =
    [
      RoutePageConstants.PLP_PAGE,
      RoutePageConstants.MICROSITE,
      RoutePageConstants.HOME,
      RoutePageConstants.SERVER_ERROR,
      RoutePageConstants.TESTIMONIAL,
      RoutePageConstants.NOT_FOUND,
      RoutePageConstants.EXPERIENCES_PAGE,
    ].includes(pageName) || [AccountRoutes.MY_TICKETS, AccountRoutes.UPDATE_TICKET].includes(slug2);

  const { bodyAnalytics } = useSelector((state) => state.commonPageData.bodyAnalytics, shallowEqual);

  /**
   * This method calls the checkLoginCartInfo method to fetch the login flag and the cart information
   * of the user. Further, it takes the response and dispatch the data to the store
   */
  const getLoginCartInfo = useCallback(async () => {
    if (checkCartAndLoginCookie()) {
      dispatch({
        type: APP_CONSTANTS.INITIATE_FUS,
      });
    } else {
      dispatch({
        type: APP_CONSTANTS.LOGIN_CHECKED,
      });
    }
  }, [dispatch]);

  useEffect(() => {
    setIsMicroSite(isInternationalHomepage);
  }, [isInternationalHomepage]);

  useEffect(() => {
    getLoginCartInfo();
    router.events.on("routeChangeStart", () => {
      getLoginCartInfo();
    });
    return () => router.events.off("routeChangeStart");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    /**
     * This method invokes initial method of cdp events mapper and all dom bindings.
     */
    const initializeCdp = async () => {
      const module = await import("../../src/cdp/cdp-events-mapper");
      module.initCdpEvents(getPageName(router.pathname), "pageLoad", "");
      module.initDomBindings();
    };
    /**
     * This method waits for cdp object to be present on the dom to call
     * the callback function to initialize cdp events.
     */
    function waitForCdpObject() {
      countTime.current += 1;
      if (window.cdp) {
        initializeCdp();
      } else if (countTime.current <= 2) {
        setTimeout(() => {
          waitForCdpObject();
        }, 500);
      } else {
        errorLogger(CDP_ERORR_MSG);
      }
    }
    waitForCdpObject();
  }, [router.query.slug1, router.pathname]);

  /**
   * This helps in redirect if redirect url is returned from product listing api during ssr call as current next version used in project is not configured for ssr redirect configurations
   */
  if (productsList?.length === 0 && redirectUrl) {
    return <></>;
  }
  return (
    <>
      {showAppBanner &&
        appRedirectionBannerDetails?.isEnable &&
        (pageType === appRedirectionBannerDetails?.homePage ||
          pageType === appRedirectionBannerDetails?.plpPage ||
          pageType === appRedirectionBannerDetails?.infoPage ||
          pageType === appRedirectionBannerDetails?.pdpPage) && (
          <AppRedirectionBanner
            setShowAppBanner={setShowAppBanner}
            imgWidth={appRedirectionBannerDetails?.imgWidth}
            imgHeight={appRedirectionBannerDetails?.imgHeight}
            imgUrl={appRedirectionBannerDetails?.imageUrl}
            redirectionUrl={appRedirectionBannerDetails?.appDeepLink}
            setBannerDisappearDetect={setBannerDisappearDetect}
          />
        )}
      <Container className="mobile-main-container">
        <MobileHeader
          pageName={pageName}
          country={rootGeoId}
          contactDetails={contactDetails}
          isInternationalHomepage={isInternationalHomepage}
          bannerDisapperaDetect={bannerDisapperaDetect}
        />
        {showLocationLockStrip && <LocationLockStrip />}
        {showErrorContent ? <Error statusCode={statusCode} pageName={pageName} /> : children}
        {bodyAnalytics && HTMLReactParser(bodyAnalytics)}
        {showMobileFooter && <MobileFooter showBottomSticky={showBottomSticky} />}
      </Container>
    </>
  );
}

MobileLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  errorPageName: PropTypes.string,
  statusCode: PropTypes.number,
};

MobileLayout.defaultProps = {
  children: React.createElement("span"),
  errorPageName: "",
  statusCode: 200,
};

export default MobileLayout;
