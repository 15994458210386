import { call, put, select, takeLatest } from "redux-saga/effects";
import ListingPageConstants from "../../src/action-constants/listing-contants";
import getProductListingForClient from "../../src/actions/product-listing-actions";

/**
 * This function handles the product listing for client side call
 * It takes two parameters one optionParams and the boolean which decides to append the data or add new data
 *
 * @param {object} action payload of params object and boolean
 */
export function* getProductListing(action) {
  const { optionParams, appendProducts } = action.payload;
  try {
    let alreadyPopulatedList;
    const res = yield call(getProductListingForClient, optionParams);
    const {
      results,
      startRows,
      viewSize,
      CategoryRatingValue,
      CategoryTotalReview,
      productCategoryName,
      listSize,
      options,
      sortOptions,
      facetRangeOptions,
      serviceable,
    } = res;
    if (appendProducts) {
      alreadyPopulatedList = yield select((store) => store.productsLists.productsList);
    }
    yield put({
      type: ListingPageConstants.SET_PRODUCTS_LISTING,
      payload: {
        results: appendProducts ? [...alreadyPopulatedList, ...results] : results,
        startRows: appendProducts ? options.params.viewIndex : startRows,
        viewSize: appendProducts ? options.params.viewSize : viewSize,
        categoryRating: CategoryRatingValue,
        categoryReviews: CategoryTotalReview,
        productCategoryName,
        listSize,
        options: { ...options },
        sortOptions,
        facetRangeOptions,
        serviceable,
      },
    });
  } catch (error) {
    yield put({ type: ListingPageConstants.PRODUCT_LISTING_ERROR_MSG, payload: error });
  }
}

/**
 * Watcher saga for clientSideProductListing
 */
function* watchClientSideProductListing() {
  yield takeLatest(ListingPageConstants.GET_PRODUCT_LISTING_REQUEST, getProductListing);
}
export default watchClientSideProductListing;
