import { HYDRATE } from "next-redux-wrapper";
import LocationLockConstants from "../../src/action-constants/location-lock-constants";

const initialState = {
  countryList: null,
  cityList: [],
  err: "",
  pinCodeList: [],
  selectedPinCode: null,
  selectedCountry: null,
  selectedCity: null,
  selectedDeliveryDate: "",
  itemDesiredDeliveryDate: "",
  finalSelectedPinCode: null,
  finalSelectedCountry: {},
  placeDetails: {},
  mappedPinCode: null,
  showLocationLockModal: false,
  autoTriggerLocationLock: 0,
  isLoading: false,
  selectedCategoryId: "",
  isPincodeAndLocationMergedEnable: {},
  adobeAutoManualPopup: "",
  autoGatePopupConfig: {},
  isAutoGateConfigReceived: true,
  inputErrors: {},
};

/**
 * This method is the reducer for actions that are specific to user
 *
 * @param {object} state - contains initial redux State
 * @param {object} action - contains actionTypes and payload information for specific action
 * @returns {object} state - the next State which needs to be dispatched to redux
 */
function locationLockReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.locationLockLists,
      };
    case LocationLockConstants.GET_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        countryList: payload,
      };
    case LocationLockConstants.GET_CITY_LIST_INITIATED:
      return {
        ...state,
        cityList: [],
        isLoading: true,
      };
    case LocationLockConstants.GET_CITY_LIST_SUCCESS:
      return {
        ...state,
        cityList: payload,
        isLoading: false,
      };
    case LocationLockConstants.GET_PINCODE_LIST_SUCCESS:
      return {
        ...state,
        pinCodeList: payload,
      };
    case LocationLockConstants.RESET_SELECTED_PINCODE:
    case LocationLockConstants.SET_SELECTED_PINCODE:
      return {
        ...state,
        selectedPinCode: payload,
      };
    case LocationLockConstants.SET_SELECTED_MAPPED_PIN_CODE:
      return {
        ...state,
        selectedPinCode: payload.pinCodeSelected,
        isPinCodeMapped: payload.isPinCodeMapped,
        userTypedPinCode: payload.userTypedPinCode,
      };
    case LocationLockConstants.SET_SELECTED_COUNTRY:
      return {
        ...state,
        selectedCountry: payload,
        selectedCity: null,
      };
    case LocationLockConstants.SET_SELECTED_CITY:
      return {
        ...state,
        selectedCity: payload,
      };
    case LocationLockConstants.FINAL_SELECTED_PINCODE:
      return {
        ...state,
        finalSelectedPinCode: payload,
      };
    case LocationLockConstants.FINAL_SELECTED_COUNTRY:
      return {
        ...state,
        finalSelectedCountry: payload,
      };

    case LocationLockConstants.GET_PLACE_DETAILS_SUCCESS:
      return {
        ...state,
        placeDetails: payload,
      };
    case LocationLockConstants.SET_PIN_CODE_MAPPING:
      return {
        ...state,
        mappedPinCode: payload,
      };
    case LocationLockConstants.SET_ERROR:
      return {
        ...state,
        err: payload,
        isLoading: false,
      };
    case LocationLockConstants.SHOW_LOCATION_LOCK_POPUP_SUCCESS:
      return {
        ...state,
        showLocationLockModal: payload,
        isPinCodeMapped: payload ? false : state.isPinCodeMapped,
      };
    case LocationLockConstants.CLEAR_PIN_CODE_LIST:
      return {
        ...state,
        pinCodeList: [],
      };
    case LocationLockConstants.UNSET_IS_PIN_CODE_MAPPED:
      return {
        ...state,
        isPinCodeMapped: false,
      };
    case LocationLockConstants.SHOW_LOCATION_LOCK_MODAL:
      return {
        ...state,
        showLocationLockModal: payload,
      };
    case LocationLockConstants.SET_LOCK_TRIGGER:
      return {
        ...state,
        autoTriggerLocationLock: payload,
      };
    case LocationLockConstants.SET_SELECTED_DELIVERY_DATE:
      return {
        ...state,
        selectedDeliveryDate: payload,
      };
    case LocationLockConstants.SET_ITEM_DESIRED_DELIVERY_DATE:
      return {
        ...state,
        itemDesiredDeliveryDate: payload,
      };

    case LocationLockConstants.SHOW_LOCATION_TOOLTIP:
      return {
        ...state,
        showLocationToolTip: payload,
      };
    case LocationLockConstants.SET_PINCODE_AND_DELIVERY_LOCATION_ENABLE:
      return {
        ...state,
        isPincodeAndLocationMergedEnable: payload,
      };
    case LocationLockConstants.SET_ADOBE_AUTO_MANUAL_POPUP:
      return {
        ...state,
        adobeAutoManualPopup: payload,
      };
    case LocationLockConstants.SET_AUTO_GATE_POPUP_CONFIG_DATA:
      return { ...state, autoGatePopupConfig: payload, isAutoGateConfigReceived: false };
    case LocationLockConstants.SET_INPUTS_ERRORS:
      return {
        ...state,
        inputErrors: { ...state.inputErrors, ...payload },
      };
    default:
      return state;
  }
}

export default locationLockReducer;
