import { takeEvery, put } from "redux-saga/effects";
import axios from "../../app_configs/axios/base";
import APP_CONSTANTS from "../../src/action-constants/app-actions";

/**
 * This method is for password
 * data - return event
 *
 * @returns {void} - returns nothing
 */
function getCsrfTokenApi() {
  return axios
    .get("control/getCsrfTokens")
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
}

/**
 * This method is yielding fus api response in saga that is retrieving cartinfo and loginInfo
 *
 * @function action - contains the response payload information of cartInfo and loginInfo
 * after calling fus api
 */
function* getCsrfToken() {
  try {
    const data = yield getCsrfTokenApi();
    yield put({ type: APP_CONSTANTS.GET_CSRFTOKENS_SUCCESS, payload: data.data });
  } catch (error) {
    yield put({ type: APP_CONSTANTS.GET_CSRFTOKENS_ERROR, error });
  }
}

/**
 * This method is used to yield success response of CHECK_LOGIN_CART_INFO action
 *
 */
function* getCsrfTokenSaga() {
  yield takeEvery(APP_CONSTANTS.GET_CSRFTOKENS, getCsrfToken);
}

export default getCsrfTokenSaga;
