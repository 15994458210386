import { HYDRATE } from "next-redux-wrapper";
import ActionConstant from "../../src/action-constants/app-actions";

const initialState = {
  currencies: [],
  countryCodeFromHeader: "",
};

/**
 * generates currency list
 *
 * @param {object} state - initial state
 * @param {object} action - action
 * @returns {object} - currencies object
 */
function currenciesReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.currenciesList,
      };
    case ActionConstant.GET_CURRENCIES_LIST:
      return {
        ...state,
        currencies: payload || state.currencies,
      };
    case ActionConstant.SET_COUNTRY_CODE_FROM_HEADER:
      return {
        ...state,
        countryCodeFromHeader: payload,
      };
    default:
      return state;
  }
}

export default currenciesReducer;
