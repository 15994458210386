import { AppBar, IconButton, InputBase, makeStyles, Toolbar } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import MenuIcon from "@material-ui/icons/Menu";
import React from "react";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  appBarRoot: {
    height: "44px",
    zIndex: "10",
  },
  toolbarOneRoot: {
    minHeight: "44px",
    justifyContent: "space-between",
  },
  toolbarTwoRoot: {
    minHeight: "44px",
    background: "#FFFFFF",
    borderRadius: "0%",
  },
  menuButton: {
    height: "44px",
    width: "44px",
    borderRadius: "0%",
    background: theme.color?.olive,
    "& svg": {
      fontSize: "32px",
    },
  },
  searchIconBtn: {
    "& svg": {
      color: "#999999",
    },
  },
  inputRoot: {
    fontSize: "14px",
    width: "100%",
    color: "#6C6C6C",
  },
}));

/**
 * This component is used to show home page secondary header on scroll in the m-site
 *
 * @param {object} root0 props from parent
 * @param {Function} root0.openLeftMenu on click handler to open left menu
 * @param {Function} root0.openSearch on click handler to open search bar
 *
 * @returns {React.ReactElement} - renders fnp-logo component
 */
const HomeSecondaryHeader = ({ openLeftMenu, openSearch }) => {
  const classes = useStyles();

  return (
    <AppBar
      position="fixed"
      classes={{
        root: classes.appBarRoot,
      }}
    >
      <Toolbar
        disableGutters
        classes={{
          root: classes.toolbarTwoRoot,
        }}
      >
        <IconButton
          disableRipple
          onClick={openLeftMenu}
          className={classes.menuButton}
          color="inherit"
          aria-label="open left menu"
        >
          <MenuIcon />
        </IconButton>
        <IconButton disableRipple onClick={openSearch} className={classes.searchIconBtn} aria-label="open search">
          <SearchIcon />
        </IconButton>
        <InputBase
          onClick={openSearch}
          placeholder="Search flowers, cakes, gifts, etc"
          classes={{
            root: classes.inputRoot,
          }}
        />
      </Toolbar>
    </AppBar>
  );
};

HomeSecondaryHeader.propTypes = {
  openLeftMenu: PropTypes.func,
  openSearch: PropTypes.func,
};

HomeSecondaryHeader.defaultProps = {
  openLeftMenu: () => {},
  openSearch: () => {},
};

export default HomeSecondaryHeader;
