import { HYDRATE } from "next-redux-wrapper";
import APP_CONSTANTS from "../../src/action-constants/app-actions";

const initialState = {
  isCourierDateRangeEnable: "",
  courierDateRangeList: [],
};

/**
 * @param {object} state - state
 * @param {string} action - action
 * @returns {object} new state
 */
function dateRangeReducer(state = initialState, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.dateRangeSwitch,
      };
    case APP_CONSTANTS.SET_DATE_RANGE_ENABLE:
      return {
        ...state,
        isCourierDateRangeEnable: action.payload,
      };
    case APP_CONSTANTS.SET_ERROR_IN_DATE_RANGE_ENABLE:
      return {
        ...state,
        error: action.payload,
      };
    case APP_CONSTANTS.SET_DATE_RANGE_DATA:
      return {
        ...state,
        courierDateRangeList: [...state.courierDateRangeList, ...action.payload],
      };
    default:
      return state;
  }
}

export default dateRangeReducer;
