import { HYDRATE } from "next-redux-wrapper";
import APP_CONSTANTS from "../../../src/action-constants/app-actions";

const InitialState = {
  success: false,
  error: false,
  otpDetail: "",
  errorMessage: "",
  checkOtpLogin: false,
};

/**
 * This method is the reducer for actions that are specific to user
 *
 * @param {object} state - contains initial redux State
 * @param {object} action - contains actionTypes and payload information for specific action
 * @returns {object} state - the next State which needs to be dispatched to redux
 */
function sendOtpReducer(state = InitialState, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.sendOtp,
      };
    case APP_CONSTANTS.SEND_OTP:
      return {
        ...state,
        success: false,
        error: false,
      };
    case APP_CONSTANTS.SEND_OTP_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        otpDetail: action.payload.data.data,
        checkOtpLogin: action.payload.checkOtpLogin,
      };
    case APP_CONSTANTS.SEND_OTP_ERROR:
      return {
        ...state,
        success: false,
        error: true,
      };
    default:
      return state;
  }
}
export default sendOtpReducer;
