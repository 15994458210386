import voucherConstants from "../action-constants/gift-voucher-constants";
import axios from "../../app_configs/axios/base";

/**
 * This method calls API to fetch voucher details
 *
 * @returns {void} - returns nothing
 */
async function fetchVouchers() {
  try {
    const response = await axios.get("control/getGiftCoupons?allCoupons=Y");
    return response.data;
  } catch (ex) {
    return "";
  }
}
/**
 * Method to get code
 *
 * @returns {void} returns nothing
 *
 */
export const getCouponCode = () => ({
  type: voucherConstants.GET_CODE,
});

export default fetchVouchers;
