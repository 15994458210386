import { all } from "redux-saga/effects";
import watchHomePageDataSaga from "./homePageDataSaga";
import watchFusRequest from "./fusSaga";
// import watchProductCount from "./productCountSaga";
import watchGiftFinderPageRequest from "./giftFinderSaga";
import checkoutSaga from "./checkoutSaga";
import paymentSaga from "./paymentsSaga";
import currenciesSaga from "./currenciesSaga";
import { watchFetchCustomerDetailsRequest } from "./userSaga";
import watchCartSummary from "./cartSaga";
import watchRetryPayments from "./pncCartSaga";
import watchFooterSubscriptionRequest from "./desktopfooterSaga";
import watchLocationLock from "./location-lockSaga";
import watchClientSideProductListing from "./productListingSaga";
import watchAddOnProducts from "./addOnSaga";
import watchAddressBookPageRequest from "./addressBookSaga";
import watchFetchCustomerDetails from "./profileSaga";
import watchMyAccountSaga from "./myAccountSaga";
import watchTicketsRequest from "./ticketsSaga";
import watchGiftVoucher from "./giftVoucherSaga";
import watchChangePassword from "./passwordSaga";
import watchRemindersRequest from "./reminderSaga";
import watchFilterDataRequest from "./dplpFilterSaga";
import validLoginIdSaga from "./validIdSaga";
import checkLoginSaga from "./checkLoginSaga";
import createUserSaga from "./createUserSaga";
import getCsrfTokenSaga from "./csrfTokensSaga";
import sendOtpSaga from "./sendOtp";
import loginSaga from "./loginSaga";
import validateOtpSaga from "./validateOtpSaga";
import setPasswordSaga from "./setPasswordSaga";
import watchCMSPageDataSaga from "./cmsPageSaga";
import watchCommonPageDataSaga from "./common-page-data-saga";
import watchSearchRequest from "./searchSaga";
import watchPlpSaga from "./plp-saga";
import watchPDPSaga from "./pdpSaga";
import watchDateRangeRequest from "./dateRangeSaga";
import watchIsInternationalEnableRequest from "./internationalEnable";
import walletSaga from "./walletSaga";
/**
 * This method is the root of all individual sagas.
 * All the individual sagas which are added here will run in the store.
 */
export default function* rootSaga() {
  yield all([
    watchHomePageDataSaga(),
    watchFusRequest(),
    // watchProductCount(),
    watchGiftFinderPageRequest(),
    watchFetchCustomerDetailsRequest(),
    checkoutSaga(),
    paymentSaga(),
    currenciesSaga(),
    watchCartSummary(),
    watchRetryPayments(),
    watchFooterSubscriptionRequest(),
    watchLocationLock(),
    watchClientSideProductListing(),
    watchAddOnProducts(),
    watchAddressBookPageRequest(),
    watchMyAccountSaga(),
    watchFetchCustomerDetails(),
    watchTicketsRequest(),
    watchChangePassword(),
    watchRemindersRequest(),
    watchGiftVoucher(),
    watchAddOnProducts(),
    watchFilterDataRequest(),
    validLoginIdSaga(),
    checkLoginSaga(),
    createUserSaga(),
    getCsrfTokenSaga(),
    sendOtpSaga(),
    loginSaga(),
    validateOtpSaga(),
    setPasswordSaga(),
    watchCMSPageDataSaga(),
    watchCommonPageDataSaga(),
    watchSearchRequest(),
    watchPlpSaga(),
    watchPDPSaga(),
    watchDateRangeRequest(),
    watchIsInternationalEnableRequest(),
    walletSaga(),
  ]);
}
