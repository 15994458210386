import React from "react";
import PropTypes from "prop-types";
import styles from "./style.module.scss";
import { KEEP_IN_TOUCH_LABEL, COPYRIGHT_LABEL } from "../../../../common/constants";

/**
 * *This method returns desktop copyright footer jsx.
 *
 * @param {object}root0 The main parameter.
 * @param {Array}root0.socialIcons The social icons
 * @param {Array}root0.paymentGatewayItems The payment gateway icon
 * @param {Function}root0.icon function to convert svg
 * @param {string}root0.startYear When fnp started in particular geo
 * @returns {React.ReactElement} the main jsx for the copyright footer.
 */
function copyright({ socialIcons, paymentGatewayItems, icon, startYear }) {
  // Todo change to server date
  const currentYear = new Date().getFullYear();

  const footerSocialIcon = socialIcons.map((socialIcon) => (
    <a key={socialIcon.id} href={socialIcon.href} aria-label={socialIcon.ariaLabel}>
      {icon(socialIcon.id)}
    </a>
  ));

  const footerPaymentGateway = paymentGatewayItems.map((paymentGatewayItem) => (
    <React.Fragment key={paymentGatewayItem.id}>{icon(paymentGatewayItem.id)}</React.Fragment>
  ));

  return (
    <div className={styles.copyrightFooterContainer}>
      <div className={styles.copyrightFooter}>
        <span>{`© ${startYear}-${currentYear} ${COPYRIGHT_LABEL}`}</span>
        <div className={styles.socialIcons}>
          <span>{KEEP_IN_TOUCH_LABEL}</span>
          {footerSocialIcon}
        </div>
        <div className={styles.paymentGateway}>{footerPaymentGateway}</div>
      </div>
    </div>
  );
}

copyright.propTypes = {
  socialIcons: PropTypes.instanceOf(Array).isRequired,
  paymentGatewayItems: PropTypes.instanceOf(Array).isRequired,
  icon: PropTypes.func.isRequired,
  startYear: PropTypes.string.isRequired,
};

export default copyright;
