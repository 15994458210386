const desktopIndiaConfig = {
  menuItems: [
    { href: "/", name: "Home India" },
    { href: "/about", name: "About India" },
  ],
  dnsPrefetchDomains: ["assets.adobedtm.com"],
  contactUs: { description: "+91 921 242 2000", link: "+919212422000" },
  currency: "INR",
  needTodayLink: "/same-day-delivery-gifts?promo=Header_Icon",
  helpCenterLink: "/info/help-center?promo=help_hicon&cid=india",
  moreInfoDropDown: [
    {
      label: "Become a Partner",
      icon: "people",
      href: "/info/become-a-partner?promo=partner_enquiry",
    },
    {
      label: "Wedding",
      icon: "wc",
      href: "/services/weddings-n-events?promo=wedding_enquiry",
    },
    {
      label: "Media",
      icon: "movie",
    },
  ],
  accountDropDown: [
    {
      label: "Login/Register",
      icon: "account_circle",
    },
    {
      label: "My Account",
      link: "/account/my-account",
      icon: "person",
    },
    {
      label: "My Orders",
      link: "/account/my-orders",
      icon: "redeem",
    },
    {
      label: "Contact Us",
      link: "/account/my-orders",
      icon: "headset_mic",
    },
    {
      label: "FAQs",
      link: "/info/faq",
      icon: "description",
    },
  ],

  loggedInAccountDropDown: [
    {
      label: "Logout",
      link: "/control/logout?to=/",
      icon: "power_settings_new",
    },
  ],
  header: [
    {
      id: "delivery",
      heading: "Worldwide Delivery",
      subheading: "We deliver gifts to over 70 countries",
    },
    {
      id: "securePay",
      heading: "100% Safe & Secure Payments",
      subheading: "Pay using secure payment methods",
    },
    {
      id: "support",
      heading: "Dedicated Help Center",
      subheading: "Call us +91 9212422000 | 8:00AM-10:30PM",
    },
    {
      id: "app",
      heading: "Shop on the go",
      subheading: "Download mobile app",
    },
  ],
  socialIcons: [
    {
      href: "https://www.facebook.com/fnpind",
      id: "facebook",
      ariaLabel: "Visit our facebook page",
    },
    { href: "https://twitter.com/fnp_ind", id: "twitter", ariaLabel: "Visit our twitter page" },
    { href: "https://in.pinterest.com/fnpind", id: "pinterest", ariaLabel: "Visit our pinterest page" },
    {
      href: "https://www.instagram.com/fernsnpetalsindia/?hl=en",
      id: "instagram",
      ariaLabel: "Visit our instagram page",
    },
  ],

  footerBodyLinks: [
    {
      title: "Policy Info",
      links: [
        { path: "/info/terms-and-conditions", linkName: "Terms & Conditions" },
        { path: "/info/privacy-policy", linkName: "Privacy Policy" },
        { path: "/info/terms-of-use", linkName: "Terms of Use" },
        { path: "/info/disclaimer", linkName: "Disclaimer" },
      ],
    },
    {
      title: "About Company",
      links: [
        { path: "/info/about-us", linkName: "About Us" },
        { path: "/info/fnp-team", linkName: "FNP Team" },
        { path: "/info/careers", linkName: "Careers" },
        { path: "/info/testimonials", linkName: "Testimonials" },
        { path: "/info/news", linkName: "News Room" },
      ],
    },
    {
      title: "FNP business",
      links: [
        { path: "/decoration-services", linkName: "Decoration Services" },
        { path: "/corporate", linkName: "Corporate Service" },
        { path: "/info/affiliate-program", linkName: "Affiliate Program" },
        { path: "/info/retail-stores", linkName: "Retails Stores" },
        { path: "/info/franchise-programme", linkName: "Franchise" },
      ],
    },
    {
      title: "Need Help ?",
      links: [
        { path: "/info/contact-us", linkName: "Contact Us" },
        { path: "/info/faq", linkName: "FAQs" },
      ],
    },
    {
      title: " Useful Links",
      links: [
        { path: "/quotes/wishes-n-messages", linkName: "Quotes N Wishes" },
        { path: "/article/flower-astrology", linkName: "Flower Astrology" },
        { path: "/info/articles-hub", linkName: "Article Hub" },
        { path: "/article/care-guide", linkName: "Care Guide" },
        { path: "/blog/", linkName: "Blog" },
      ],
    },
  ],
  paymentGatewayItems: [
    { id: "visa" },
    { id: "mastercard" },
    { id: "paypal" },
    { id: "rupay" },
    { id: "discover" },
    { id: "amex" },
    { id: "mastero" },
    { id: "diners" },
    { id: "paytm" },
  ],
  startYear: "1994",
  isSmartLockEnabled: true,
  isOmnitureEnabled: true,
  isCdpEnabled: true,
  popularMobileCodes: [
    "+91 IND",
    "+1 USA",
    "+1-284 VGB",
    "+61 AUS",
    "+65 SGP",
    "+974 QAT",
    "+64 NZL",
    "+60 MYS",
    "+1 CAN",
    "+971 ARE",
  ],
  moreInfoLinks: [
    {
      label: "Franchise Enquiries",
      href: "/info/franchise-programme?promo=franchise_enquiry",
    },
    {
      label: "Corporate Gifts",
      href: "https://www.fnp.com/corporate?promo=corporate_enquiry",
    },
  ],
};

export default desktopIndiaConfig;
