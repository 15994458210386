import LocationLockConstants from "../action-constants/location-lock-constants";

/**
 * Action for setting delivery key
 *
 * @param {string} payload contains delivery key.
 * @returns {object} returns action
 */
export const setDeliveryKey = (payload) => ({
  type: LocationLockConstants.SET_DELIVERY_AS_KEY,
  payload,
});

/**
 * Action for setting pincode mapping request
 *
 * @returns {object} returns action
 */
export const setPinCodeMappingReq = () => ({
  type: LocationLockConstants.SET_PIN_CODE_MAPPING_REQUEST,
});

/**
 * Action for re selecting pin code
 *
 * @param {string} payload contains empty string or null
 * @returns {object} returns action
 */
export const reselectPinCode = (payload) => ({
  type: LocationLockConstants.RESET_SELECTED_PINCODE,
  payload,
});

/**
 * Action for setting selected pincode
 *
 * @param {string} payload contains selected pincode
 * @returns {object} returns action
 */
export const setSelectedPinCode = (payload) => ({
  type: LocationLockConstants.SET_SELECTED_PINCODE,
  payload,
});

/**
 * Action for setting final selected pin code
 *
 * @param {string} payload contains pin code
 * @returns {object} returns action
 */
export const setFinalSelectedPinCode = (payload) => ({
  type: LocationLockConstants.FINAL_SELECTED_PINCODE,
  payload,
});

/**
 * Action for setting final selected country
 *
 * @param {object} payload contains selected country and city
 * @returns {object} returns action
 */
export const setFinalSelectedCountry = (payload) => ({
  type: LocationLockConstants.FINAL_SELECTED_COUNTRY,
  payload,
});

/**
 * Action for showing location pop up
 *
 * @param {string} payload contains boolean value
 * @returns {object} returns action
 */
export const setShowLocationPopUp = (payload) => ({
  type: LocationLockConstants.SHOW_LOCATION_LOCK_POPUP_REQUEST,
  payload,
});

/**
 * Action for getting pin code list
 *
 * @param {string} payload contains pin code list
 * @returns {object} returns action
 */
export const getPinCodeList = (payload) => ({
  type: LocationLockConstants.GET_PINCODE_LIST_REQUEST,
  payload,
});

/**
 * Action for clearing pin code list
 *
 * @returns {object} returns action
 */
export const clearPinCodeList = () => ({
  type: LocationLockConstants.CLEAR_PIN_CODE_LIST,
});
/**
 * Action for requesting Place details
 *
 * @param {string} payload contains place details
 * @returns {object} returns action
 */
export const getPlaceDetails = (payload) => ({
  type: LocationLockConstants.GET_PLACE_DETAILS_REQUEST,
  payload,
});

/**
 * Action for setting Selected country
 *
 * @param {object} payload contains selected country
 * @returns {object} returns action
 */
export const setSelectedCountryAction = (payload) => ({
  type: LocationLockConstants.SET_SELECTED_COUNTRY,
  payload,
});

/**
 * Action for setting Selected city
 *
 * @param {object} payload contains selected city
 * @returns {object} returns action
 */
export const setSelectedCityAction = (payload) => ({
  type: LocationLockConstants.SET_SELECTED_CITY,
  payload,
});

/**
 * Action for getting country list
 *
 * @returns {object} returns action
 */
export const getCountryListRequest = () => ({
  type: LocationLockConstants.GET_COUNTRY_LIST_REQUEST,
});

/**
 * Action for getting city list
 *
 * @param {string} countryId id of selected country
 * @returns {object} returns action
 */
export const getCityListRequest = (countryId) => ({
  type: LocationLockConstants.GET_CITY_LIST_REQUEST,
  payload: countryId,
});

/**
 * Action for un setting mapped pin code.
 *
 * @returns {object} returns action
 */
export const unsetPinCodeMapped = () => ({
  type: LocationLockConstants.UNSET_IS_PIN_CODE_MAPPED,
});

/**
 * Action for showing modal or not
 *
 * @param {boolean} payload whether to show modal or not
 * @returns {object} returns action
 */
export const setShowModal = (payload) => ({
  type: LocationLockConstants.SHOW_LOCATION_LOCK_MODAL,
  payload,
});

/**
 * Action for setting auto lock trigger
 *
 * @param {string} payload whether to trigger lock or not
 * @returns {object} returns action
 */
export const setLockTrigger = (payload) => ({
  type: LocationLockConstants.SET_LOCK_TRIGGER,
  payload,
});

/**
 * Action for setting selected delivery date
 *
 * @param {string} payload contains selected delivery date
 * @returns {object} returns action
 */
export const setSelectedDeliveryDate = (payload) => ({
  type: LocationLockConstants.SET_SELECTED_DELIVERY_DATE,
  payload,
});

/**
 * Action for setting desired delivery date
 *
 * @param {string} payload contains desired delivery date
 * @returns {object} returns action
 */
export const setItemDesiredDeliveryDate = (payload) => ({
  type: LocationLockConstants.SET_ITEM_DESIRED_DELIVERY_DATE,
  payload,
});
/**
 * Action for showing tooltip
 *
 * @param {boolean} payload whether to tooltip or not
 * @returns {object} returns action
 */
export const setShowLocationTooltip = (payload) => ({
  type: LocationLockConstants.SHOW_LOCATION_TOOLTIP,
  payload,
});

/**
 * Action to set IsEnabledToSetPincodeAndDeliveryLocation flag
 *
 * @param {object} payload payload params
 * @returns {object} action
 */
export const setIsEnabledToSetPincodeAndDeliveryLocation = (payload) => ({
  type: LocationLockConstants.SET_PINCODE_AND_DELIVERY_LOCATION_ENABLE,
  payload,
});

/**
 * Action to set setAdobeAutoManualPopup
 *
 * @param {object} payload payload params
 * @returns {object} action
 */
export const setAdobeAutoManualPopup = (payload) => ({
  type: LocationLockConstants.SET_ADOBE_AUTO_MANUAL_POPUP,
  payload,
});

/**
 * Action for requesting auto gate popup data
 *
 * @returns {object} returns action
 */
export const requestAutoGatePopupConfigData = () => ({
  type: LocationLockConstants.REQUEST_AUTO_GATE_POPUP_CONFIG_DATA,
});

/**
 * Action for setting auto gate popup data
 *
 * @param {string} payload contains auto gate popup data
 * @returns {object} returns action
 */
export const setAutoGatePopupConfigData = (payload) => ({
  type: LocationLockConstants.SET_AUTO_GATE_POPUP_CONFIG_DATA,
  payload,
});

/**
 * Action creator for setting pinCode input error.
 *
 * @param {object} payload - pinCodeError and pinCodeErrorClass.
 * @returns {object} Action object with type and payload.
 */
export const setInputErrors = (payload) => ({
  type: LocationLockConstants.SET_INPUTS_ERRORS,
  payload,
});
