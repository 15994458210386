import mobileIndiaConfig from "./mobile";
import desktopIndiaConfig from "./desktop";
import geoDefault from "../default";

/**
 *
 * @param {boolean} isMobile request user agent is mobile or not
 * @returns {object} return INDIA domain configs
 */
const loadINDIAConfig = (isMobile) => {
  if (isMobile) {
    return {
      default: geoDefault,
      mobile: mobileIndiaConfig,
    };
  }
  return {
    default: geoDefault,
    desktop: desktopIndiaConfig,
  };
};

export default loadINDIAConfig;
