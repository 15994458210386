import APP_CONSTANTS from "../action-constants/app-actions";
import CommonPageActionsConstants from "../action-constants/common-page-actions-constants";
import { execDataLayer } from "../data-layer/dataLayer";
import { endpointConst } from "../data-layer/dataLayerConstants";
import { fetchContentFromCMSOnClient } from "../services/api/common/common-api";

/**
 * Action for requesting page rel alt data
 *
 * @param {string} payload contains rel alt options
 * @returns {object} returns action
 */
export const requestPageRelAltData = (payload) => ({
  type: CommonPageActionsConstants.SERVER_REQUEST_PAGE_REL_ALT_DATA,
  payload,
});

/**
 * Action for requesting page menu data
 *
 * @param {string} payload contains widget Helper data
 * @returns {object} returns action
 */
export const requestWidgetHelperData = (payload = "") => ({
  type: CommonPageActionsConstants.SERVER_REQUEST_WIDGET_HELPER_DATA,
  payload,
});

/**
 * Action for setting page menu data
 *
 * @param {string} payload contains widget helper data
 * @returns {object} returns action
 */
export const setWidgetHelperData = (payload = "") => {
  return {
    type: CommonPageActionsConstants.SERVER_SET_WIDGET_HELPER_DATA,
    payload,
  };
};

/**
 * Action for setting page meta data
 *
 * @param {string} payload contains meta data
 * @returns {object} returns action
 */
export const setPageMetaData = (payload = "") => ({
  type: CommonPageActionsConstants.SERVER_SET_PAGE_META_DATA,
  payload,
});

/**
 * Action for setting page canonical data
 *
 * @param {string} payload contains canonical data
 * @returns {object} returns action
 */
export const setCanonicalData = (payload = "") => ({
  type: CommonPageActionsConstants.SERVER_SET_PAGE_CANONICAL_DATA,
  payload,
});

/**
 * Action for setting page OG tag data
 *
 * @param {string} payload contains OG tag data
 * @returns {object} returns action
 */
export const setOgTagData = (payload = "") => ({
  type: CommonPageActionsConstants.SERVER_SET_PAGE_OG_TAG_DATA,
  payload,
});

/**
 * Action for setting page Twitter tag data
 *
 * @param {string} payload contains Twitter tag data
 * @returns {object} returns action
 */
export const setTwitterTagData = (payload = "") => ({
  type: CommonPageActionsConstants.SERVER_SET_PAGE_TWITTER_TAG_DATA,
  payload,
});

/**
 * Action for setting page Schema tag data
 *
 * @param {string} payload contains Schema tag data
 * @returns {object} returns action
 */
export const setSchemaTagData = (payload = "") => ({
  type: CommonPageActionsConstants.SERVER_SET_PAGE_SCHEMA_TAG_DATA,
  payload,
});

/**
 * Action for setting page rel alt data
 *
 * @param {string} payload contains rel alt data
 * @returns {object} returns action
 */
export const setPageRelAltData = (payload = "") => ({
  type: CommonPageActionsConstants.SERVER_SET_PAGE_REL_ALT_DATA,
  payload,
});

/**
 * Action for requesting page menu data
 *
 * @param {string} payload contains menu data
 * @returns {object} returns action
 */
export const requestPageMenuData = (payload = "") => ({
  type: CommonPageActionsConstants.SERVER_REQUEST_PAGE_MENU_DATA,
  payload,
});

/**
 * Action for setting page menu data
 *
 * @param {string} payload contains menu data
 * @returns {object} returns action
 */
export const setPageMenuData = (payload = "") => ({
  type: CommonPageActionsConstants.SERVER_SET_PAGE_MENU_DATA,
  payload,
});

/**
 * Action for requesting fnp app redirection banner data.
 *
 * @param {string} payload contains data.
 * @returns {object} returns action
 */
export const requestAppBannerData = (payload = "") => ({
  type: CommonPageActionsConstants.SERVER_REQUEST_APP_BANNER_DATA,
  payload,
});

/**
 * Action for setting fnp app redirection banner data.
 *
 * @param {string} payload contains data.
 * @returns {object} returns action
 */
export const setAppBannerData = (payload = "") => ({
  type: CommonPageActionsConstants.SERVER_SET_APP_BANNER_DATA,
  payload,
});

/**
 * Action for setting page breadcrumbs data
 *
 * @param {string} payload contains breadcrumbs data
 * @returns {object} returns action
 */
export const setPageBreadcrumbsData = (payload = "") => ({
  type: CommonPageActionsConstants.SERVER_SET_PAGE_BREADCRUMBS_DATA,
  payload,
});

/**
 * Action for setting page footer data
 *
 * @param {string} payload contains footer data
 * @returns {object} returns action
 */
export const setPageFooterData = (payload = "") => ({
  type: CommonPageActionsConstants.SERVER_SET_PAGE_FOOTER_DATA,
  payload,
});

/**
 * Action for setting page head analytics data
 *
 * @param {string} payload contains head analytics data
 * @returns {object} returns action
 */
export const setPageHeadAnalyticsData = (payload = "") => ({
  type: CommonPageActionsConstants.SERVER_SET_PAGE_HEAD_ANALYTICS_DATA,
  payload,
});

/**
 * Action for setting page body analytics data
 *
 * @param {string} payload contains body analytics data
 * @returns {object} returns action
 */
export const setPageBodyAnalyticsData = (payload = "") => ({
  type: CommonPageActionsConstants.SERVER_SET_PAGE_BODY_ANALYTICS_DATA,
  payload,
});

/**
 * Action for setting page bottom analytics data
 *
 * @param {string} payload contains bottom analytics data
 * @returns {object} returns action
 */
export const setPageBottomAnalyticsData = (payload = "") => ({
  type: CommonPageActionsConstants.SERVER_SET_PAGE_BOTTOM_ANALYTICS_DATA,
  payload,
});

/**
 * Action for setting page error data
 *
 * @param {string} payload contains menu data
 * @returns {object} returns action
 */
export const setErrorData = (payload = "") => ({
  type: CommonPageActionsConstants.SERVER_SET_ERROR_DATA,
  payload,
});

/**
 * Action for fetching currency data
 *
 * @returns {object} returns action
 */
export const fetchCurrencyData = () => ({
  type: APP_CONSTANTS.FETCH_CURRENCIES_REQUESTED,
});

/**
<<<<<<< HEAD
 * Function to get RR config from CMS
 *
 * @param {object} params params for request
 * @param {string} params.featureName name of the feature
 * @returns {object} returns action
 */
export const requestRRConfig = ({ featureName }) => ({
  type: APP_CONSTANTS.INITIATE_RR_CONFIG,
  payload: { featureName },
});

/**
 * Fail action of getting RR config
 *
 * @param {string} errorMessage represents error message
 * @returns {object} returns action
 */
export const getRRConfigError = (errorMessage) => ({
  type: APP_CONSTANTS.RR_CONFIG_FAIL,
  payload: errorMessage,
});

/**
 * Action to get widget content
 *
 * @returns {object} returns action
 */
export const getWidgetContent = () => ({
  type: APP_CONSTANTS.INITIATE_GET_WIDGET_CONTENT,
});

/**
 * Action for setting page preload data,
 *
 * @param {string} payload contains preload data
 * @returns {object} returns action
 */
export const setPagePreloadData = (payload = "") => ({
  type: CommonPageActionsConstants.SERVER_SET_PRELOAD_DATA,
  payload,
});

/**
 * Action for showing media info
 *
 * @param {string} payload contains boolean
 * @returns {object} returns action
 */
export const showMediaInfo = (payload) => ({
  type: CommonPageActionsConstants.SHOW_MEDIA_INFO,
  payload,
});

/**
 * @function apiFn wrapper function to execute addAddonsToCart from datalayer.
 * @returns {Promise<any>} Response from datalayer
 */
const apiFn = async () => {
  const res = await fetchContentFromCMSOnClient(endpointConst.configurecallandchattime);
  return res;
};

/**
 * This method fetches Timing for CALL and CHAT in my account.
 *
 * @returns {object} response
 */
export async function getCallChatTime() {
  try {
    const response = await execDataLayer(apiFn, endpointConst.configurecallandchattime);
    return response;
  } catch (ex) {
    return ex;
  }
}
