const commonSearchConstants = {
  GET_POPULAR_SEARCH_REQUEST: "GET_POPULAR_SEARCH_REQUEST",
  GET_POPULAR_SEARCH_SUCCESS: "GET_POPULAR_SEARCH_SUCCESS",
  GET_POPULAR_SEARCH_FAIL: "GET_POPULAR_SEARCH_FAIL",
  GET_SEARCH_TERM_SUGGESTION_REQUEST: "GET_SEARCH_TERM_SUGGESTION_REQUEST",
  GET_MANUAL_BOOST_REQUEST: "GET_MANUAL_BOOST_REQUEST",
  GET_MANUAL_BOOST_SUCCESS: "GET_MANUAL_BOOST_SUCCESS",
  GET_MANUAL_BOOST_FAIL: "GET_MANUAL_BOOST_FAIL",
};
export default commonSearchConstants;
