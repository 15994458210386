const Regex = {
  checkRegex: new RegExp(/^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9$&+,:;=?@#|'<>.^*()%!-_~/`{}]{6,20}$/),
  sixChar: new RegExp(/^.{6,20}$/),
  upperCase: new RegExp(/^(?=.*[A-Z]).+$/),
  lowerCase: new RegExp(/^(?=.*[a-z]).+$/),
  numeric: new RegExp(/^(?=.*[0-9]).+$/),
  checkAlphabet: new RegExp(/^[a-zA-Z ]{0,100}$/),
  alphaNumeric: /^\s*([0-9a-zA-Z]*)\s*$/,
  alphaNumericWithSpecialChar: /^[\s\d\w%$#@!+,.;:<>'"/\\|`~\-_()[\]*{}^&?]*$/,
  acceptedFileExt: /(jpg|jpeg|png|gif|txt|pdf|doc|docx|mp4|mp3)$/i,
  upiVpa: /^([A-Za-z0-9.-])+@[A-Za-z0-9]+$/,
  checkEmail: new RegExp(/^(?!['`])\s*[-+.'\w]+@[a-zA-Z0-9-.]+\.[a-zA-Z0-9]{2,3}\s*$/),
  intlPhoneNumber: /^\d{7,15}$/,
  dmstcPhoneNumber: /^\d{10}$/,
  onlyNumbers: /^[0-9]*$/,
  usaPincodePattern: /[0-9]{5}$/,
  gbrPincodePattern: /[0-9a-zA-Z\s]{6,8}$/,
  canadaPattern: /[0-9a-zA-Z\s]{6,7}$/,
  alphaNumericWithSpace: /^\s*([0-9a-zA-Z\s]*)\s*$/,
};
export default Regex;
