import queryString from "query-string";
import getConfig from "next/config";
import axios from "../../../../app_configs/axios/base";
import errorLogger from "../../../../app_configs/logger-service";
import { getCacheHeaders } from "../../../utils/common";
import gateway from "../../../../app_configs/axios/gateway";

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();
const showRequestLogs = publicRuntimeConfig.SHOW_REQUESTS_LOGS;
const desktop = typeof window !== "undefined" ? window.dSite : false;
const CONTROL_API_URL = desktop ? publicRuntimeConfig.D_CONTROL_API_URL : publicRuntimeConfig.CONTROL_API_URL;
const isServer = typeof window === "undefined";

/**
 *
 * @param {boolean} isDesktop - isDesktop flag
 * @returns {string} domain
 */
const getDeviceSpecificDomain = (isDesktop) =>
  isDesktop ? serverRuntimeConfig.D_SERVER_CONTROL_HOST : serverRuntimeConfig.SERVER_CONTROL_HOST;

const error = {
  config: {},
  response: {},
};

/**
 * This function is used to get the product details
 *
 * @param {boolean} setResHeaders response headers flag
 * @param {object} ctx context
 * @param {object} params params for api request
 * @param {boolean} isDesktop flag to check if the request is from desktop or not
 * @returns {Array} product details
 */
export const fetchproductDetails = async (setResHeaders, ctx, params, isDesktop = false) => {
  try {
    const response = await axios.get(`control/getProductDetails-rj?${queryString.stringify(params)}`, {
      baseURL: isServer ? getDeviceSpecificDomain(isDesktop) : CONTROL_API_URL,
      withCredentials: true,
      headers: {
        Cookie: ctx?.req?.headers?.cookie || "",
      },
    });
    if (setResHeaders && response?.headers) {
      const headersToSet = getCacheHeaders(response.headers);
      ctx?.res?.set(headersToSet);
    }
    return response;
  } catch (err) {
    if (err.response) return { errorStatus: err.response?.status };
    if (err.code) return err.code;
    return err;
  }
};

/**
 * This function fetches content by their content id eg:offer-content
 *
 * @param {object} params params for api request
 * @param {boolean} isDesktop flag to check if the request is from desktop or not
 * @returns {object} offer available data
 */
export async function getContentById(params, isDesktop = false) {
  try {
    const res = await axios.get(`control/getContentIdDetails-rj?${queryString.stringify(params)}`, {
      baseURL: isServer ? getDeviceSpecificDomain(isDesktop) : CONTROL_API_URL,
    });
    return res.data;
  } catch (err) {
    error.config.url = "control/getContentIdDetails-rj";
    error.response.status = `An Exception occurred while fetching contentId details => ${err}`;
    errorLogger(err, showRequestLogs);
    throw new Error(err);
  }
}

/**
 * This function fetches delivery time left
 *
 * @param {object} params request params
 * @returns {object} delivery time left data
 */
export async function getDeliveryTimeLeft(params) {
  try {
    const res = await axios.get(`control/getProductDeliveryTimeLeft?${queryString.stringify(params)}`);
    return res.data;
  } catch (err) {
    error.config.url = "control/getProductDeliveryTimeLeft";
    error.response.status = `An Exception occurred while fetching delivery time left details => ${err}`;
    errorLogger(err, showRequestLogs);
    throw new Error(err);
  }
}

/**
 * This function fetches breadcrumb
 *
 * @param {object} payload pdp breadcrumbs params.
 * @param {boolean} payload.isDesktop flag to check if the request is from desktop or not
 * @param {string} payload.productName product name .
 * @param {string} payload.productId .
 * @returns {object} rel alternate data on success and empty on exception.
 */
export async function fetchProductBreadCrumbs(payload) {
  const { productId, productName, isDesktop } = payload;
  const params = {
    currentPage: "product",
    productName,
    productId,
  };
  try {
    const res = await axios.get(`control/getBreadCrumbsDetails?${queryString.stringify(params)}`, {
      baseURL: isServer ? getDeviceSpecificDomain(isDesktop) : CONTROL_API_URL,
    });
    return res.data;
  } catch (ex) {
    throw new Error(ex);
  }
}

/** This function fetches product expiry info
 *
 * @param {string} productId .
 * @param {boolean} isDesktop flag to check if the request is from desktop or not
 * @returns {object} rel alternate data on success and empty on exception.
 */
export async function getProductExpiryInfo(productId, isDesktop = false) {
  try {
    const params = {
      productId,
    };
    const res = await axios.get(`control/getProductExpiryInfo-rj?${queryString.stringify(params)}`, {
      baseURL: isServer ? getDeviceSpecificDomain(isDesktop) : CONTROL_API_URL,
    });
    return res.data;
  } catch (ex) {
    throw new Error(ex);
  }
}

/**
 * This function fetches product review by the product id
 *
 * @param {string} productId  product Ids.
 * @param {boolean} domainId it is the domainId
 * @returns {object} rel alternate data on success and empty on exception.
 */
export async function getProductReviews(productId, domainId) {
  const endPoint = "/moody/v1/product/reviews";
  try {
    const response = await gateway.get(endPoint, {
      params: {
        productId,
        domainId,
        size: 12,
      },
    });
    return response.data;
  } catch (ex) {
    throw new Error(ex);
  }
}
