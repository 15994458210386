import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import dynamic from "next/dynamic";

import BackArrowIconBtn from "../back-arrow-icon-btn";
import CartIconBtn from "../cart-icon-btn";
import KebabMenuIconBtn from "../kebab-menu-icon-btn";
import PaginationTextInfo from "../pagination-text-info";

import { accountPagesTitle, AccountRoutes } from "../../../../../src/action-constants/my-account-constants";
import { PathPageConstants, RoutePageConstants } from "../../../../../src/utils/common";
import { checkoutHeaders } from "../../../../../src/action-constants/checkout-constants";

const FNPLogo = dynamic(() => import("../fnp-logo"), {
  ssr: false,
});

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    display: "flex",
    alignItems: "center",
    height: "48px",
  },
  appBarRoot: {
    "&.MuiPaper-root": {
      color: theme.color?.white,
      backgroundColor: theme.color?.olive,
      zIndex: "2",
    },
  },
  toolbarRoot: {
    minHeight: "48px",
  },
  title: {
    flexGrow: 1,
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "500",
  },
  marginRight: {
    marginRight: theme.spacing(3),
  },
}));

/**
 * This component is used to show header with title in the m-site
 *
 * @param {object} root0 props from parent
 * @param {string} root0.pageName current page name
 * @param {string} root0.pathName current route subpath name
 * @param {Function} root0.openRightMenu on click handler to open right menu
 * @param {Function} root0.handleBack whether to use a custom back button on click handler
 * @param {Function} root0.onBackPress on click handler to handle back press
 * @param {string} root0.headerLabel custom header label
 * @param {Function} root0.rightComponent component to align at right of header
 * @param {string} root0.catalogId current catalog id
 * @param {object} root0.titleStyle title styles
 *
 * @returns {React.ReactElement} - renders header-with-title component
 */
const HeaderWithTitle = ({
  pageName,
  pathName,
  openRightMenu,
  handleBack,
  onBackPress,
  headerLabel,
  rightComponent,
  catalogId,
  titleStyle,
}) => {
  const classes = useStyles();
  const router = useRouter();

  const userDetails = useSelector((state) => state.userDetails);

  const loggedOutCheck = !userDetails?.checkLoginInfo?.loggedin && userDetails.loginChecked;
  const routerSlug1 = router.query.slug1;

  let pageTitle;
  let showCart;
  let showKebabMenu = true;
  let paginationTextInfo;
  let pageTitleClass = classes.title;
  let showFnpLogo = false;

  if (
    [
      PathPageConstants.COUNTRY_LIST,
      PathPageConstants.SELECT_COUNTRY,
      PathPageConstants.MULTI_IMAGE_UPLOAD,
      PathPageConstants.MOBILE_SHIPPING_VIEW,
      PathPageConstants.RECIPIENT_ADDRESS,
      PathPageConstants.PLP_FILTER_VIEW,
    ].includes(pathName)
  ) {
    pageTitle = headerLabel;
    showKebabMenu = false;
    pageTitleClass += ` ${classes.marginRight}`;
  } else if (pageName === RoutePageConstants.CART_PREVIEW) {
    pageTitle = "My Cart";
  } else if (pageName === RoutePageConstants.GIFT_FINDER_PAGE) {
    pageTitle = "Perfect Gift Finder";
  } else if (pageName === RoutePageConstants.ADDONS) {
    pageTitle = "Make It Extra Special";
    showKebabMenu = false;
  } else if (pageName === RoutePageConstants.CHECKOUT_PAGE) {
    pageTitle = checkoutHeaders[routerSlug1]?.label || "Login / Register";
    showKebabMenu = false;
    paginationTextInfo = checkoutHeaders[routerSlug1]?.paginationText;

    if (paginationTextInfo === "") {
      pageTitleClass += ` ${classes.marginRight}`;
    }
  } else if (pageName === RoutePageConstants.ACCOUNT_PAGE) {
    if (loggedOutCheck) {
      pageTitle = "Login / Register";
      showKebabMenu = false;
      pageTitleClass += ` ${classes.marginRight}`;
    } else {
      pageTitle = accountPagesTitle[routerSlug1];
      if (routerSlug1 === AccountRoutes.ACCOUNT_HOME) {
        showFnpLogo = true;
      }
    }
  }

  return (
    <div className={classes.root}>
      <AppBar elevation={0} position="fixed" className={classes.appBarRoot}>
        <Toolbar className={classes.toolbarRoot}>
          <BackArrowIconBtn showFnpLogo={showFnpLogo} withTitle handleBack={handleBack} onBackPress={onBackPress} />
          {showFnpLogo && <FNPLogo catalogId={catalogId} />}
          <Typography variant="h6" className={pageTitleClass} style={titleStyle || {}}>
            {pageTitle}
          </Typography>
          {showCart && <CartIconBtn withTitle />}
          {showKebabMenu && <KebabMenuIconBtn withTitle openRightMenu={openRightMenu} />}
          {paginationTextInfo && <PaginationTextInfo textInfo={paginationTextInfo} />}
          {rightComponent && rightComponent()}
        </Toolbar>
      </AppBar>
    </div>
  );
};

HeaderWithTitle.propTypes = {
  pageName: PropTypes.string,
  pathName: PropTypes.string,
  openRightMenu: PropTypes.func,
  handleBack: PropTypes.bool,
  onBackPress: PropTypes.func,
  headerLabel: PropTypes.string,
  rightComponent: PropTypes.func,
  catalogId: PropTypes.string,
  titleStyle: PropTypes.objectOf(PropTypes.any),
};

HeaderWithTitle.defaultProps = {
  pageName: "",
  pathName: "",
  openRightMenu: () => {},
  handleBack: false,
  onBackPress: () => {},
  headerLabel: "",
  rightComponent: () => <></>,
  catalogId: "india",
  titleStyle: {},
};

export default HeaderWithTitle;
