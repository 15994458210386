import https from "https";
import getConfig from "next/config";
import axios from "./index";

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();
const isServer = typeof window === "undefined";
const isDesktop = !isServer ? window.dSite : false;
const baseApiUrl = isServer ? serverRuntimeConfig.R2_SERVER_API_GATEWAY : publicRuntimeConfig.R2_CLIENT_API_GATEWAY;
const agent = new https.Agent({
  rejectUnauthorized: false,
});

export default axios({
  baseURL: baseApiUrl,
  httpsAgent: agent,
  headers: {
    "accept-language": "en",
    "x-device-type": isDesktop ? "desktop" : "mobile",
    "x-domain": publicRuntimeConfig.DOMAIN,
  },
});
