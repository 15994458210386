import React from "react";
import { withStyles } from "@material-ui/core/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";
import styles from "../style/price-with-name-desktop.module.scss";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    marginTop: 10,
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: "0 2px 16px 0 rgb(0 0 0 / 13%)",
    zIndex: -1,
    borderRadius: 5,
    width: "250px",
    padding: "15px 15px 10px 15px",
  },
  arrow: {
    fontSize: 20,
    "&::before": {
      color: theme.palette.common.white,
      marginTop: 0,
      boxShadow: "0 2px 16px 0 rgb(0 0 0 / 13%)",
    },
  },
}))(Tooltip);

/**
 * @param {object} root0 props passed to component
 * @param {React} root0.title title as prop
 * @param {boolean} root0.isForDateRange .
 * @returns {React.Component} JSX for Tooltip component
 */
function TooltipPdp({ title, isForDateRange }) {
  const [open, setOpen] = React.useState(false);
  /**
   * To close tooltip
   */
  const handleTooltipClose = () => {
    setOpen(false);
  };

  /**
   * To open tooltip
   */
  const handleTooltipOpen = () => {
    setOpen(true);
  };
  return (
    <LightTooltip placement="bottom-start" open={open} disableHoverListener arrow title={title}>
      <InfoOutlinedIcon
        onMouseEnter={handleTooltipOpen}
        onMouseLeave={handleTooltipClose}
        className={`${styles["info-details"]} ${styles.icon} ${isForDateRange ? styles["align-bottom"] : ""}`}
      />
    </LightTooltip>
  );
}
TooltipPdp.propTypes = {
  title: PropTypes.node.isRequired,
  isForDateRange: PropTypes.bool,
};
TooltipPdp.defaultProps = {
  isForDateRange: false,
};
export default TooltipPdp;
