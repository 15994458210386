import { takeEvery, put } from "redux-saga/effects";
import { setPasswordApi } from "../../src/actions/login-actions";
import APP_CONSTANTS from "../../src/action-constants/app-actions";

/**
 * This method is yielding fus api response in saga that is retrieving cartinfo and loginInfo
 *
 * @param {object} action - contains the response payload information of cartInfo and loginInfo
 * after calling fus api
 */
function* setPassword(action) {
  try {
    const data = yield setPasswordApi(action.payload);
    if (data?.data?.isSuccess) {
      yield put({ type: APP_CONSTANTS.SET_PASSWORD_SUCCESS, payload: data });
    } else {
      yield put({ type: APP_CONSTANTS.SET_PASSWORD_ERROR, error: data?.data?.errorMsg || "" });
    }
  } catch (error) {
    yield put({ type: APP_CONSTANTS.SET_PASSWORD_ERROR, error });
  }
}

/**
 * This method is used to yield success response of CHECK_LOGIN_CART_INFO action
 *
 */
function* setPasswordSaga() {
  yield takeEvery(APP_CONSTANTS.SET_PASSWORD, setPassword);
}

export default setPasswordSaga;
