import React from "react";
import PropTypes from "prop-types";
import StarIcon from "@material-ui/icons/Star";
import { makeStyles } from "@material-ui/styles";
import { PLP } from "../../../common/constants";

const useStyles = makeStyles((theme) => ({
  checkboxLabel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "capitalize",
    padding: "10px",
    color: theme.color.moderateGrey,
    fontSize: "14px",
    "&:hover": {
      color: theme.color.orange,
    },
  },
  mCheckBoxLabel: {
    color: theme.color.darkBlack,
    padding: "15px",
    "&:hover": {
      color: theme.color.darkBlack,
    },
  },
  iconStyle: {
    fontSize: "18px",
    width: "18px",
    color: theme.color.orange,
    marginRight: "3px",
    marginLeft: "3px",
  },
}));
/**
 * This functional component is label for checkbox component for filter.
 *
 * @param {object} props - Component props
 * @param {object} props.facetOption - facetOption
 * @param {string} props.facetFieldName - name of filte
 * @param {boolean} props.isMobile device type
 * @returns {React.Component} return label for checkbox component.
 */
const CheckboxLabel = ({ facetOption, facetFieldName, isMobile = false }) => {
  const { displayName, count } = facetOption;

  const classes = useStyles();
  /**
   * this function returns checkboxLabel as per filter
   *
   * @param {string} fieldName typeof the filter
   * @returns {React.Fragment} return checkbox label.
   */
  const renderCheckboxLabel = (fieldName) => {
    if (fieldName === "rating") {
      return (
        <p className={`${classes.checkboxLabel} ${isMobile ? classes.mCheckBoxLabel : ""}`}>
          {String(displayName).toLowerCase().slice(0, 1)}
          <StarIcon className={classes.iconStyle} />
          <span data-testid="starLabel">{PLP.AND_BEYOND}</span>
        </p>
      );
    }
    if (fieldName === "listprice") {
      return (
        <p className={`${classes.checkboxLabel} ${isMobile ? classes.mCheckBoxLabel : ""}`}>
          {String(displayName).toUpperCase()}
          {count && ` (${count})`}
        </p>
      );
    }
    return (
      <p className={`${classes.checkboxLabel} ${isMobile ? classes.mCheckBoxLabel : ""}`} data-testid="label">
        {String(displayName).toLowerCase()}
        {count && ` (${count})`}
      </p>
    );
  };
  return <>{renderCheckboxLabel(facetFieldName)}</>;
};
CheckboxLabel.propTypes = {
  facetFieldName: PropTypes.string.isRequired,
  facetOption: PropTypes.shape({ displayName: PropTypes.string, count: PropTypes.number }),
  isMobile: PropTypes.bool,
};
CheckboxLabel.defaultProps = {
  facetOption: {},
  isMobile: false,
};
export default CheckboxLabel;
