import getConfig from "next/config";
import get from "lodash/get";
import axios from "../../../../app_configs/axios/base";
import errorLogger from "../../../../app_configs/logger-service";
import gateway from "../../../../app_configs/axios/gateway";
import { fetchContentFromCMSOnClient } from "../common/common-api";

const { publicRuntimeConfig } = getConfig();

const cmsAPIVersion = publicRuntimeConfig.CMS_API_VERSION;
const tiffanyAPIVersion = publicRuntimeConfig.TIFFANY_API_VERSION;

const SHOW_REQUESTS_LOGS = publicRuntimeConfig.SHOW_REQUESTS_LOGS || "Y";
const error = {
  config: {},
  response: {},
};

/**
 * This function gets the pincdoe avialables
 *
 * @returns {object} object of country list
 */
export async function getPinCodeAvialable() {
  try {
    const res = await gateway.get(`${cmsAPIVersion}global/static/pincode/availablepincodes`);
    return res?.data;
  } catch (ex) {
    error.config.url = "static/pincode/availablepincodes";
    error.response.status = `An exception occurred while fetching country list => ${ex.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return "";
  }
}
/**
 * This function gets the country list
 *
 * @returns {object} object of country list
 */
export async function getCountryList() {
  try {
    return await fetchContentFromCMSOnClient("global/static/country/supportedcityandcountry");
  } catch (ex) {
    error.config.url = "global/static/country/supportedcityandcountry";
    error.response.status = `An exception occurred while fetching country list => ${ex.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return "";
  }
}

/**
 * This function gets the city list
 *
 * @param {string} countryId Id of country
 * @returns {object} object of city list
 */
export async function getCityList(countryId) {
  try {
    const res = await gateway.get(`${tiffanyAPIVersion}cities/country/${countryId}`);
    return get(res, "data", "");
  } catch (ex) {
    error.config.url = `${tiffanyAPIVersion}cities/country/${countryId}`;
    error.response.status = `An exception occurred while fetching city list => ${ex.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return "";
  }
}

/**
 * THis function returns the pin code list as per the input value
 *
 * @param {string} code string of input value entered
 * @returns {Array} array of pin code list
 */
export async function getPinCodeList(code) {
  if (code?.availablePincodes.indexOf(code?.fetchPincode) > -1) {
    try {
      const res = await gateway.get(`${cmsAPIVersion}global/static/pincode/${code?.fetchPincode}`);
      return get(res, "data", "");
    } catch (ex) {
      error.config.url = `${cmsAPIVersion}global/static/pincode/${code?.fetchPincode}`;
      error.response.status = `An exception occurred while fetching pinCode list list => ${ex.code?.fetchPincode}`;
      errorLogger(error, SHOW_REQUESTS_LOGS);
      throw new Error(ex);
    }
  } else {
    return [];
  }
}

/**
 * This function maps the pin code as per the value
 *
 * @returns {*} .
 */
export async function getPinCode() {
  try {
    const res = await axios.get("control/jc?id=PINCODE_MAPPING");
    return get(res, "data", "");
  } catch (ex) {
    error.config.url = "control/jc?id=PINCODE_MAPPING";
    error.response.status = `An exception occurred while fetching pincode mapping list => ${ex.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    throw new Error(ex);
  }
}

/**
 * This function gets the delivery options as pet the pin code and date
 *
 * @param {string} pincode pin code entered
 * @param {string} deliveryDate selected delivery date
 * @returns {object}  object of delivery data
 */
export async function getDeliveryAreasAsKey(pincode, deliveryDate) {
  try {
    const params = { pincode };
    if (deliveryDate) params.deliveryDate = deliveryDate;
    const res = await axios.get("control/getDeliveryAreasAsKey", { params });
    return get(res, "data", "");
  } catch (ex) {
    error.config.url = "control/getDeliveryAreasAsKey";
    error.response.status = `An exception occurred while fetching Delivery area as key => ${ex.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    throw new Error(ex);
  }
}

/**
 * This function returns the pin code and place details as per the latitude and longitude
 *
 * @param {object} root .
 * @param {number} root.lat latitude
 * @param {number} root.lng longitude
 * @returns {object} object of data as per the latitude and longitude
 */
export async function getPinCodeByLocation({ lat, lng }) {
  try {
    const res = await axios.get(`control/getPinCodeByPostalAddress?latitude=${lat}&longitude=${lng}`);
    return get(res, "data", "");
  } catch (ex) {
    error.config.url = `control/getPinCodeByPostalAddress?latitude=${lat}&longitude=${lng}`;
    error.response.status = `An exception occurred while fetching pin code by place => ${ex.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    throw new Error(ex);
  }
}

/**
 * Method to fetch content from CMS on server side
 *
 * @returns {object} returns data
 */
export async function fetchAutoGatePopupConfiguration() {
  try {
    const response = await gateway.get(`${cmsAPIVersion}global/static/location-lock-config/location-lock-config-data`);
    if (response.data) return response.data;
    return "";
  } catch (err) {
    throw new Error(err.message);
  }
}
