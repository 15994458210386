import qs from "qs";
import axios from "../../../../app_configs/axios/base";

const CHARACTERS = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

/**
 * get list of all tickets
 *
 * @param {object} params - params for api request
 * @returns {object} - api response
 */
export const getMyTicketsList = (params) => {
  const { pageNo, size, subOrderId } = params;
  return axios.get(`control/myTickets?pageno=${pageNo}&size=${size}&subOrderId=${subOrderId}`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

/**
 * get details for update ticket
 *
 * @param {string} id - ticket id for api request
 * @returns {object} - api response
 */
export const getMyTicketDetails = (id) => {
  return axios.get(`control/getMyTicketDetails/?ticketId=${id}&commOf=customer`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

/**
 * get list of all orders
 *
 * @returns {object} - api response
 */
export const getUserSubOrderDetails = () => {
  return axios.get(`control/getUserSubOrderDetails?viewSize=40`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

/**
 * post update ticket details
 *
 * @param {object} params - params for api request
 * @returns {object} - api response
 */
export const updateTickets = (params) => {
  return axios.post(`control/updateTickets`, qs.stringify(params), {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

/**
 * get AWS url for image upload
 *
 * @returns {object} - api response
 */
export const getAWSPreSignedUrl = () => {
  let randomString = "";
  const date = new Date().toISOString().slice(0, 10).replace(/-/g, "");
  for (let i = 0; i < 10; i += 1) {
    randomString += CHARACTERS[Math.floor(Math.random() * CHARACTERS.length)];
  }
  return axios.get(`control/getAWSPreSignedUrl-rj?fileName=jarvis/${date}/${randomString}.png`, {});
};

/**
 * put AWS url for image upload
 *
 * @param {string} url - AWS pre signed url
 * @param {object} file - file to upload
 * @returns {object} - api response
 */
export const uploadAWSPreSignedImage = (url, file) => {
  return axios.put(url, file, {
    withCredentials: false,
    headers: {
      "Content-Type": "image/png",
    },
  });
};

/**
 * get ticket types list for create ticket
 *
 * @returns {object} - api response
 */
export const getTicketTypes = () => {
  return axios.get(`control/getTicketTypes?checkVisibility=true&group=customer`, {});
};

/**
 * post create ticket details
 *
 * @param {object} params - params for api request
 * @returns {object} - api response
 */
export const createTickets = (params) => {
  return axios.post(`control/createTickets`, qs.stringify(params), {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};
