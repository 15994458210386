import { takeEvery, put } from "redux-saga/effects";
import APP_CONSTANTS from "../../src/action-constants/app-actions";
import { sendOtpApi } from "../../src/actions/login-actions";

/**
 * This method is yielding fus api response in saga that is retrieving cartinfo and loginInfo
 *
 * @param {object} action - contains the response payload information of cartInfo and loginInfo
 * after calling fus api
 */
function* sendOtp(action) {
  try {
    const data = yield sendOtpApi(action.payload.otpData);
    if (action.payload.checkOtpLogin) {
      yield put({
        type: APP_CONSTANTS.SEND_OTP_SUCCESS,
        payload: { data, checkOtpLogin: action.payload.checkOtpLogin },
      });
    } else {
      yield put({ type: APP_CONSTANTS.SEND_OTP_SUCCESS, payload: { data } });
    }
  } catch (error) {
    yield put({ type: APP_CONSTANTS.SEND_OTP_ERROR, error });
  }
}

/**
 * This method is used to yield success response of CHECK_LOGIN_CART_INFO action
 *
 */
function* sendOtpSaga() {
  yield takeEvery(APP_CONSTANTS.SEND_OTP, sendOtp);
}

export default sendOtpSaga;
