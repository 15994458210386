/* eslint-disable import/prefer-default-export */
import getConfig from "next/config";
import errorLogger from "../../app_configs/logger-service";
import { burstCacheConfig, cacheConfig, cmsConfig } from "./dataLayerConfig";
import { cacheKeyConst } from "./dataLayerConstants";

const { publicRuntimeConfig } = getConfig();
const SHOW_REQUESTS_LOGS = publicRuntimeConfig.SHOW_REQUESTS_LOGS || "Y";
const error = {
  config: {},
  response: {},
};

/**
 * Sets a value in the local storage with a time-to-live (TTL) expiration.
 *
 * @param {string} key - The key under which to store the value.
 * @param {any} value - The value to be stored.
 * @param {number} ttl - Time-to-live in milliseconds.
 */
function setLocalStorageWithTTL(key, value, ttl) {
  const now = new Date();
  const item = { ...value, expiry: now.getTime() + ttl };
  localStorage.setItem(key, JSON.stringify(item));
}

/**
 * Retrieves a value from the local storage with time-to-live (TTL) validation.
 *
 * @param {string} key - The key to retrieve the value from.
 * @returns {any|null} - The stored value or null if not found or expired.
 */
function getLocalStorageWithTTL(key) {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item;
}

/**
 * Clears cached values from the local storage based on the burst cache configuration.
 *
 * @param {string} key - The key associated with the burst cache endpoint.
 */
function burstCache(key) {
  if (burstCacheConfig[key]) {
    burstCacheConfig[key].forEach((cacheKey) => {
      localStorage.removeItem(cacheKeyConst[cacheKey]);
    });
  }
}

/**
 * @function getCacheKeyTtl Clears cached values from the local storage based on the burst cache configuration.
 * @param {string} key - The key associated with the burst cache endpoint.
 * @returns {Number} time from ttl and from default if not find.
 */
function getCacheKeyTtl(key) {
  return cacheConfig[key].ttl || cacheConfig.default.ttl;
}

/**
 * @function getCMSData check is API from CMS or not
 * @param {string} key - The key associated with the endpoint.
 * @returns {boolean} is API end point from csm or not
 */
function getCMSData(key) {
  return cmsConfig[key] || cmsConfig.default;
}
/**
 * Executes a data layer operation, optionally caching the result in the local storage.
 * @param {Function} apiFn - The asynchronous function to retrieve data.
 * @param {String} apiEndpoint endPoint to map the cache api and burst api.
 * @returns {Promise<any>} - A Promise resolving to the data from the API or cached data.
 */
export async function execDataLayer(apiFn, apiEndpoint) {
  try {
    burstCache(apiEndpoint);
    const cacheKey = cacheKeyConst[apiEndpoint];
    let res = null;

    if (cacheKey) {
      res = getLocalStorageWithTTL(cacheKey);
      if (!res) {
        const ttl = getCacheKeyTtl(apiEndpoint);
        const isCMS = getCMSData(apiEndpoint);
        res = await apiFn();
        if ((res?.status === 200 && res?.data) || (isCMS && Object.keys(res).length)) {
          setLocalStorageWithTTL(cacheKey, res, ttl);
        }
      }
    } else {
      res = await apiFn();
    }

    return res;
  } catch (ex) {
    error.config.url = apiEndpoint;
    error.response.status = `An exception occurred while fetching earliest delivery days => ${ex}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return {};
  }
}
