import React from "react";
import PropTypes from "prop-types";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DeliveryOnCalender from "../../../mobile/pdp/product-delivery/deliver-on/calender";
import "react-day-picker/lib/style.css";
import styles from "../style/delivery-calender.module.scss";

/**
 * This method is used to render delivery calender
 *
 * @param {object} root0 it includes object from parent component
 * @param {Function} root0.onSelect on date select handler.
 * @param {Array} root0.deliveryDates on date select handler.
 * @param {Array} root0.blockedDates on date select handler.
 * @param {Function} root0.gotoPrev on date select handler.
 * @param {boolean} root0.showBackButton whether to show back button
 * @param {string} root0.itemDesiredDeliveryDate item desired delivery date.
 * @param {string} root0.itemDesiredDeliveryDatesList list of item desired delivery dates.
 * @param {string} root0.pinCode pin code.
 * @param {boolean} root0.isCourierProduct whether its a courier product.
 * @param {boolean} root0.isPersonalizedProduct whether its a personalized product.
 * @param {string} root0.selectedVariant selected variant.
 * @param {boolean} root0.isCourierDelivery whether its a courier delivery.
 * @param {boolean} root0.checkIsInternationalProduct whether its an international product,
 * @param {object} root0.productDetail product detail.
 * @param {Function} root0.getPrice function that returns price after conversion.
 * @param {string} root0.currencyCode currency code.
 * @param {boolean} root0.showMultiOrderModal multi order modal
 * @param {Function} root0.setShowMultiOrderModal function to toggle the showMultiOrderModal flag
 * @param {boolean} root0.isCheckout to check if it is in checkout-page or not
 * @param {boolean} root0.isCourierDateRangeEnable to check whether date range enable
 * @returns {React.ReactElement} jsx to render the calender.
 */
function DeliveryCalender({
  gotoPrev,
  onSelect,
  deliveryDates = [],
  blockedDates,
  onSelectRange,
  showBackButton,
  itemDesiredDeliveryDate,
  itemDesiredDeliveryDatesList,
  pinCode,
  isCourierProduct,
  isPersonalizedProduct,
  selectedVariant,
  isCourierDelivery,
  checkIsInternationalProduct,
  productDetail,
  getPrice,
  currencyCode,
  showMultiOrderModal,
  setShowMultiOrderModal,
  isCheckout,
  isCourierDateRangeEnable,
}) {
  const dates = blockedDates.length ? deliveryDates.filter((date) => blockedDates.indexOf(date) < 0) : deliveryDates;

  return (
    <div className={styles["calender-wrapper"]}>
      <div className={styles["delivery-calender"]}>
        <div className={`${styles["date-picker-heading"]} ${styles.mb10}`}>
          {showBackButton && (
            <span className={styles["back-btn"]}>
              <ArrowBackIcon onClick={() => gotoPrev()} />
            </span>
          )}
          <span className={styles["calender-title"]}>Select Delivery Date</span>
        </div>
        <DeliveryOnCalender
          isCheckout={isCheckout}
          deliveryDates={dates}
          onSelect={onSelect}
          onSelectRange={onSelectRange}
          blockedDates={blockedDates}
          itemDesiredDeliveryDate={itemDesiredDeliveryDate}
          itemDesiredDeliveryDatesList={itemDesiredDeliveryDatesList}
          pinCode={pinCode}
          isCourierProduct={isCourierProduct}
          isPersonalizedProduct={isPersonalizedProduct}
          selectedVariant={selectedVariant}
          isCourierDelivery={isCourierDelivery}
          checkIsInternationalProduct={checkIsInternationalProduct}
          productDetail={productDetail}
          getPrice={getPrice}
          currencyCode={currencyCode}
          showMultiOrderModal={showMultiOrderModal}
          setShowMultiOrderModal={setShowMultiOrderModal}
          isCourierDateRangeEnable={isCourierDateRangeEnable}
        />
      </div>
    </div>
  );
}

DeliveryCalender.propTypes = {
  gotoPrev: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  deliveryDates: PropTypes.instanceOf(Array),
  blockedDates: PropTypes.instanceOf(Array),
  onSelectRange: PropTypes.func,
  showBackButton: PropTypes.bool,
  isCourierProduct: PropTypes.bool,
  isPersonalizedProduct: PropTypes.bool,
  selectedVariant: PropTypes.objectOf(PropTypes.any),
  isCourierDelivery: PropTypes.bool,
  checkIsInternationalProduct: PropTypes.bool,
  productDetail: PropTypes.objectOf(PropTypes.any),
  itemDesiredDeliveryDate: PropTypes.string.isRequired,
  itemDesiredDeliveryDatesList: PropTypes.string,
  pinCode: PropTypes.string.isRequired,
  getPrice: PropTypes.func,
  currencyCode: PropTypes.string,
  showMultiOrderModal: PropTypes.bool,
  setShowMultiOrderModal: PropTypes.func,
  isCheckout: PropTypes.bool,
  isCourierDateRangeEnable: PropTypes.bool,
};
DeliveryCalender.defaultProps = {
  gotoPrev: () => {},
  blockedDates: [],
  deliveryDates: [],
  onSelectRange: () => {},
  showBackButton: false,
  isCheckout: false,
  isCourierDateRangeEnable: false,
  isCourierProduct: false,
  isPersonalizedProduct: false,
  isCourierDelivery: false,
  productDetail: {},
  itemDesiredDeliveryDatesList: "",
  selectedVariant: {},
  checkIsInternationalProduct: false,
  getPrice: () => {},
  currencyCode: "",
  showMultiOrderModal: false,
  setShowMultiOrderModal: () => {},
};

export default DeliveryCalender;
