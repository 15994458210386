import { HYDRATE } from "next-redux-wrapper";
import PdpPageConstants from "../../../src/action-constants/pdp-constants";

const initialState = {
  product: {},
  offers: null,
  isFetching: false,
  deliveryTimeLeft: null,
  productExpiry: {},
  pincodes: [],
  shippingDatesAndMethods: {},
  priceSurgeDetails: {},
  blockedDates: {},
  youMayAlsoLike: [],
  whatOthersAreViewing: [],
  recentProducts: [],
  productReviews: [],
  addOns: {},
  presignedUrl: null,
  sameSkuContent: {},
  earliestDeliveryDays: {},
  productPriceByDate: {},
  productPriceByPincode: {},
  currencyCode: null,
  currencyFactor: null,
  isReady: true,
  cities: [],
  internationalShippingTypes: {},
  internationalDeliveryDates: {},
  catalogLocalities: {},
  catalogDeliveryDates: {},
  shippingDetails: {},
  isLoading: false,
  breadCrumbs: {},
  isProductLoading: false,
  notFound: false,
  errorMsg: "",
  isInternationalEnable: "",
  error: "",
  deliveryDatesLoader: false,
};

/**
 * This function is a reducer for all the product detail.
 *
 * @param {object} state it represents the current state object
 * @param {object} action it represents the new action object which consists of the new payload.
 * @returns {object} Updated state object with the action object.
 */
function productDetailReducer(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.productDetail,
      };
    case PdpPageConstants.FETCH_PRODUCT_TRANSITION_SHOW:
      return {
        ...state,
        isReady: false,
      };
    case PdpPageConstants.FETCH_PRODUCT_TRANSITION_HIDE:
      return {
        ...state,
        isReady: true,
      };
    case PdpPageConstants.SET_PRODUCT:
      return {
        ...state,
        product: action.payload.results,
        isFetching: false,
      };

    case PdpPageConstants.SET_OFFERS:
      return {
        ...state,
        offers: action.payload,
        isFetching: false,
      };
    case PdpPageConstants.SET_DELIVERY_TIME_LEFT:
      return {
        ...state,
        deliveryTimeLeft: action.payload,
        isFetching: false,
      };
    case PdpPageConstants.SET_PRODUCT_EXPIRY_INFO:
      return {
        ...state,
        productExpiry: action.payload,
        isFetching: false,
      };
    case PdpPageConstants.SET_PINCODES:
      return {
        ...state,
        pincodes: action.payload,
        isFetching: false,
      };

    case PdpPageConstants.CLEAR_PINCODES:
      return {
        ...state,
        pincodes: [],
        shippingDatesAndMethods: {},
      };

    case PdpPageConstants.SHIPPING_FETCH_START:
      return {
        ...state,
        shippingDatesAndMethods: {},
        internationalShippingTypes: {},
        internationalDeliveryDates: {},
      };
    case PdpPageConstants.SET_DATES_AND_SHIPPING_METHODS:
      return {
        ...state,
        shippingDatesAndMethods: action.payload,
      };

    case PdpPageConstants.FETCH_PRICE_DETAILS_BY_DATE_START:
    case PdpPageConstants.SHIPPING_DETAILS_FETCH_START:
      return {
        ...state,
        isLoading: true,
      };
    case PdpPageConstants.FETCH_PRICE_DETAILS_BY_DATE_ERROR:
    case PdpPageConstants.SHIPPING_DETAILS_FETCH_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case PdpPageConstants.SET_SHIPPING_DETAILS:
      return {
        ...state,
        shippingDatesAndMethods: {
          ...state.shippingDatesAndMethods,
          ...action.payload,
        },
        isLoading: false,
      };

    case PdpPageConstants.SET_BLOCKED_DATES:
      return {
        ...state,
        blockedDates: action.payload,
      };
    case PdpPageConstants.SET_PRICE_SURGE:
      return {
        ...state,
        priceSurgeDetails: action.payload,
      };
    case PdpPageConstants.SET_YOU_MAY_ALSO_LIKE_PRODUCTS:
      return {
        ...state,
        youMayAlsoLike: action.payload,
      };
    case PdpPageConstants.SET_WOAV_DATA:
      return {
        ...state,
        whatOthersAreViewing: action.payload,
      };
    case PdpPageConstants.SET_RECENTLY_VIEWED_PRODUCTS:
      return {
        ...state,
        recentProducts: action.payload,
      };
    case PdpPageConstants.SET_REVIEWS:
      return {
        ...state,
        productReviews: action.payload,
      };

    case PdpPageConstants.UPDATE_PRODUCT_VARIANT:
      return {
        ...state,
        product: { ...state.product, ...action.payload },
      };

    case PdpPageConstants.SET_ADDONS:
      return {
        ...state,
        addOns: action.payload,
      };

    case PdpPageConstants.SET_PERSONALIZED_IMAGE:
      return {
        ...state,
        presignedUrl: action.payload,
      };

    case PdpPageConstants.SET_SAME_SKU_CONTENT:
      return {
        ...state,
        sameSkuContent: action.payload,
      };
    case PdpPageConstants.SET_EARLIEST_DELIVERY_DATES:
      return {
        ...state,
        earliestDeliveryDays: action.payload,
      };

    case PdpPageConstants.SET_PRICE_DETAILS_BY_DATE:
      return {
        ...state,
        productPriceByDate: action.payload,
        isLoading: false,
      };

    case PdpPageConstants.SET_PRICE_DETAILS_BY_PINCODE:
      return {
        ...state,
        productPriceByPincode: action.payload,
        isLoading: false,
      };

    case PdpPageConstants.SET_PDP_CURRENCY:
      return {
        ...state,
        currencyCode: action.payload.currencyCode,
        currencyFactor: action.payload.currencyFactor,
      };
    case PdpPageConstants.SET_INTERNATIONAL_CITIES:
      return {
        ...state,
        cities: action.payload,
      };

    case PdpPageConstants.SET_INTERNATIONAL_SHIPPING_DETAILS:
      return {
        ...state,
        internationalShippingTypes: action.payload,
      };
    case PdpPageConstants.DELIVERY_DATES_FETCH_START:
      return {
        ...state,
        deliveryDatesLoader: true,
      };
    case PdpPageConstants.DELIVERY_DATES_FETCH_FAILED:
      return {
        ...state,
        deliveryDatesLoader: false,
      };

    case PdpPageConstants.SET_INTERNATIONAL_DELIVERY_DATES:
      return {
        ...state,
        internationalDeliveryDates: action.payload,
        deliveryDatesLoader: false,
      };
    case PdpPageConstants.SET_PRODUCT_PRICE:
      return {
        ...state,
        product: {
          ...state.product,
          productPrice: {
            defaultPrice: action.payload,
            listPrice: action.payload,
            price: action.payload,
          },
        },
      };
    case PdpPageConstants.SET_CATALOG_LOCALITIES:
      return {
        ...state,
        catalogLocalities: action.payload,
      };
    case PdpPageConstants.SET_PRODUCT_BREADCRUMBS:
      return {
        ...state,
        breadCrumbs: action.payload,
      };
    case PdpPageConstants.PDP_ERROR_MSG:
      return {
        ...state,
        errorMsg: action.payload,
      };
    case PdpPageConstants.SET_IS_INTERNATIONAL_ENABLE:
      return {
        ...state,
        isInternationalEnable: action.payload,
      };
    case PdpPageConstants.SET_ERROR_IN_IS_INTERNATIONAL_ENABLE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}

export default productDetailReducer;
