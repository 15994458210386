import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import ButtonElement from "../../../../../common/button-element";
import styles from "../../../style/surge-price.module.scss";

/**
 * This functional component represents the surge modal page.
 *
 * @param {object} props props from the parent component.
 * @returns {React.Fragment} surge modal jsx for the pdp page.
 */
const SurgePrice = React.forwardRef((props, ref) => {
  const { hideSurgeModal, continueWithSurge, productDetail, getPrice, currencyCode } = props;
  const { assetsUrl } = useSelector((state) => state.appConfigs);
  const {
    productPriceByDate: { listPrice, price },
  } = productDetail;
  const {
    priceSurgeDetails: { notificationMessage },
  } = productDetail;

  return (
    <div className={styles.surgePriceContainer} ref={ref}>
      <div className={styles.innerContainer}>
        <div className={styles.priceContent}>
          <div>
            <div className={styles.textCenter}>
              <div className={styles.surgeTitle} data-testid="surge-price">
                Surge Pricing
              </div>
              <div className={styles.diffPrice}>
                <div className={styles.surgeIcon}>
                  <img src={`${assetsUrl}/assets/images/white-surge-bb.png`} alt="" />
                </div>
                <div className={styles.innerPriceChange}>
                  <div className={styles.normalPrice}>
                    <span className={styles.priceSymbol}>{currencyCode}</span>
                    <span>{getPrice(listPrice)}</span>
                  </div>
                  <div className="changePrice">
                    <span className={styles.priceSymbol}>{currencyCode}</span>
                    <span>{getPrice(price)}</span>
                  </div>
                </div>
              </div>
              <div className={styles.surgeMessage}>{notificationMessage}</div>
              <div className={styles.surgePriceButtons}>
                <ButtonElement className={styles.continueButton} onClick={continueWithSurge}>
                  Continue
                </ButtonElement>
                <ButtonElement className={styles.nonSurgeButton} onClick={hideSurgeModal}>
                  Deliver on non-surge day
                </ButtonElement>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

SurgePrice.propTypes = {
  hideSurgeModal: PropTypes.func.isRequired,
  continueWithSurge: PropTypes.func.isRequired,
  productDetail: PropTypes.objectOf(PropTypes.any).isRequired,
  getPrice: PropTypes.func.isRequired,
  currencyCode: PropTypes.string.isRequired,
};

export default SurgePrice;
