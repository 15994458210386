import { takeLatest, call, put, all } from "redux-saga/effects";
import PDP_CONSTANTS from "../../src/action-constants/pdp-constants";
import {
  setPDPError,
  setProductData,
  populateOffersData,
  populateProductBreadCrumb,
  setDeliveryTimeLeft,
  setProductExpiryInfo,
  setProductReviews,
  updateProductVariant,
  filterVariantInfo,
} from "../../src/actions/pdp-actions";
import {
  fetchproductDetails,
  getContentById,
  fetchProductBreadCrumbs,
  getDeliveryTimeLeft,
  getProductExpiryInfo,
  getProductReviews,
} from "../../src/services/api/pdp/pdp-api";
import { setSelectedVariant } from "../../src/actions/pdp-context-actions";

/**
 * This function handles the api call for Populating Bread Crumbs data for Category details and set the value in store
 *
 * @param {object} payload payload
 * @function isDesktop check is isDesktop
 */
function* getProductBreadCrumbData(payload) {
  try {
    const res = yield call(fetchProductBreadCrumbs, payload);
    if (res) {
      yield put(populateProductBreadCrumb(res.data));
    }
  } catch (error) {
    yield put(setPDPError(error.message));
  }
}

/**
 * handle product review by the product id
 *
 * @param {object} payload product reviews
 * @param {object} payload.payload payload
 */
function* handleProductReviews({ payload }) {
  const { productId, domainId } = payload;
  const VIEW_ALL = "view_all";
  const redirectionUrl = `/info/testimonials?domainId=${domainId}&pageType=product&productId=${productId}`;

  try {
    const response = yield call(getProductReviews, productId, domainId);
    if (response?.data) {
      const categoryReviews = [...response.data];
      if (response.data.length >= 12) {
        categoryReviews.push({
          categoryId: VIEW_ALL,
          redirectionUrl,
        });
      }
      yield put(setProductReviews(categoryReviews));
    }
  } catch (error) {
    yield put(setPDPError(error.message));
  }
}

/**
 * handle product Expiry Info
 *
 * @param {object} root0 action
 * @param {object} root0.payload  payload
 */
function* handleProductExpiryInfo({ payload }) {
  const { productId, isDesktop } = payload;
  try {
    const res = yield call(getProductExpiryInfo, productId, isDesktop);
    yield put(setProductExpiryInfo(res));
  } catch (error) {
    yield put(setPDPError(error.message));
  }
}

/**
 * handle delivery time left data api
 *
 * @param {object} param0 action
 * @param {object} param0.payload payload
 */
function* handleDeliveryTimeLeft({ payload }) {
  try {
    const { productId } = payload;
    const params = {
      productId,
    };
    const response = yield call(getDeliveryTimeLeft, params);
    if (response) {
      yield put(setDeliveryTimeLeft(response));
    }
  } catch (error) {
    yield put(setPDPError(error.message));
  }
}

/**
 * handle product details from API
 *
 * @param {string} action - action object
 */
function* handleProductDetails({ payload }) {
  const params = {
    catalogId: payload.catalogId,
    productUrl: payload.noHashPathParam,
    productDataExists: "",
  };
  try {
    const { setResHeaders, ctx, isDesktop, domainId } = payload;
    const response = yield call(fetchproductDetails, setResHeaders, ctx, params, isDesktop);
    if (response.status === 200) {
      if (response?.data?.data) {
        const { productId, productName, variantProducts } = response.data.data;
        let customProductId = productId;
        yield put(
          setProductData({ ...response.data.data, variantProducts: variantProducts?.length ? variantProducts : null }),
        );
        if (variantProducts?.length) {
          const haveVariant = variantProducts[0] instanceof Object;
          if (haveVariant) {
            const selectedVariant =
              variantProducts.find((variant) => {
                const key = Object.keys(variant);
                return variant[key].urlIdentifier === payload.selectedVariantURL;
              }) || variantProducts[0];
            const [title, variantInfo] = Object.entries(selectedVariant)[0];
            if (variantInfo?.productId) customProductId = variantInfo?.productId;
            yield put(setSelectedVariant({ ...variantInfo, title }));
            yield put(updateProductVariant(filterVariantInfo(variantInfo)));
          }
        }

        const promiseList = [call(handleProductExpiryInfo, { payload: { productId: customProductId, isDesktop } })];
        const totalReview = response?.data?.data?.productReviewsAndRatings?.totalReview;

        if (totalReview > 0) {
          promiseList.push(call(handleProductReviews, { payload: { productId, isDesktop, domainId } }));
        }

        if (isDesktop) {
          promiseList.push(call(getProductBreadCrumbData, { productId, productName, isDesktop }));
        }

        yield all(promiseList);
      } else {
        const ctxRes = ctx.res;
        ctxRes.statusCode = 404;
      }
    } else {
      const ctxRes = ctx.res;
      ctxRes.statusCode = response?.status || response?.errorStatus || 500;
    }
  } catch (error) {
    yield put(setPDPError(error.message));
  }
}

/**
 * @param {object} root0  props passed to the component
 * @param {object} root0.payload payload
 */
function* fetchContentId({ payload }) {
  try {
    const params = {
      contentId: payload.contentId,
    };
    const response = yield call(getContentById, params, payload.isDesktop);
    if (response) {
      yield put(populateOffersData(response));
    }
  } catch (error) {
    yield put(setPDPError(error.message));
  }
}

/**
 * Watcher saga for PDP
 */
function* watchPDPSaga() {
  yield takeLatest(PDP_CONSTANTS.SERVER_REQUEST_PRODUCT_DETAILS, handleProductDetails);
  yield takeLatest(PDP_CONSTANTS.FETCH_CONTENT_ID, fetchContentId);
  yield takeLatest(PDP_CONSTANTS.UPDATE_SET_REVIEWS, handleProductReviews);
  yield takeLatest(PDP_CONSTANTS.FETCH_PRODUCT_EXPIRY_INFO, handleProductExpiryInfo);
  yield takeLatest(PDP_CONSTANTS.GET_DELIVERY_TIME_LEFT, handleDeliveryTimeLeft);
}

export default watchPDPSaga;
