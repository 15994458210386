import queryString from "query-string";
import axios from "../../app_configs/axios/base";
/**
 * This method fetch email subscription output
 *
 * @param {string}emailId Email id to subscribe
 * @returns {string} Confirmation or fail of email subscription
 */
export default async function SubscribeNewUser(emailId) {
  const res = await axios.post(`/control/subscribe-user`, queryString.stringify({ emailId }), {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
  return res.data;
}
