import { call, put, takeEvery, select, delay } from "redux-saga/effects";

import APP_CONSTANTS from "../../src/action-constants/app-actions";
import {
  viewCartPreview,
  getPriceDetails,
  updateAddonQuantityProduct,
  deleteCartItem,
} from "../../src/actions/cartPreviewActions";
import fetchCurrencies from "../../src/actions/common-actions";

/**
 * this function yields price details for the cart items and dispatches an action for the same
 *
 * @param {object} cartSummary object of cart details
 * @param {boolean} isMobile .
 */
function* getPriceData(cartSummary, isMobile) {
  const priceDetails = yield call(getPriceDetails, cartSummary);
  yield put({ type: APP_CONSTANTS.GET_PRICE_DETAILS_SUCCESS, payload: priceDetails });
  if (!isMobile)
    yield put({
      type: APP_CONSTANTS.SET_CART_COUNT,
      payload: priceDetails?.noOfBaseItems || 0,
    });
}

/**
 * dispatches the action with payload data for reducer
 */
export function* getCartDetails() {
  try {
    const cartSummary = yield call(viewCartPreview);
    const currencies = yield select((store) => store.currenciesList.currencies);
    const isMobile = yield select((store) => store.appConfigs.isMobile);
    if (cartSummary?.items?.length > 0 && currencies.length === 0) {
      const resp = yield call(fetchCurrencies);
      yield put({ type: APP_CONSTANTS.GET_CURRENCIES_LIST, payload: resp.data.currencies });
    }
    if (cartSummary?.items?.length > 0 && !isMobile) {
      const isCourierDateRangeEnable = yield select((store) => store?.dateRangeSwitch?.isCourierDateRangeEnable || "");
      if (isCourierDateRangeEnable === "") {
        yield put({
          type: APP_CONSTANTS.GET_DATE_RANGE_ENABLE,
        });
      }
    }
    yield put({ type: APP_CONSTANTS.GET_CART_SUMMARY_INFO_SUCCESS, payload: cartSummary });
    yield put({ type: APP_CONSTANTS.GET_CART_DETAILS_FIRST_TIME, payload: true });
    yield call(getPriceData, cartSummary, isMobile);
  } catch (error) {
    yield put({ type: APP_CONSTANTS.SET_CART_ERROR_MESSAGE, payload: error });
  }
}

/**
 * Method used to update cart addon quantity. It makes api call and dispatches the action with payload data for reducer
 *
 * @param {object} action the dispatched action
 *
 */
export function* updateQuantity(action) {
  try {
    const res = yield call(updateAddonQuantityProduct, action.payload);
    if (res.data.isSuccess) {
      yield put({ type: APP_CONSTANTS.GET_CART_SUMMARY_INFO_REQUEST });
    }
  } catch (error) {
    yield put({ type: APP_CONSTANTS.SET_CART_ERROR_MESSAGE, payload: error });
  }
}

/**
 * Method used to delete item from cart. It makes api call and dispatches the action with payload data for reducer
 *
 * @param {object} action the dispatched action
 *
 */
export function* deleteItemFromCart(action) {
  try {
    const res = yield call(deleteCartItem, action.payload);
    if (res.data.isSuccess) {
      yield put({ type: APP_CONSTANTS.GET_CART_SUMMARY_INFO_REQUEST });
    }
  } catch (error) {
    yield put({ type: APP_CONSTANTS.SET_CART_ERROR_MESSAGE, payload: error });
  }
}

/**
 * Method used to show cart and notification
 *
 * @param {object} action action
 */
export function* showNewItemToCartNotification(action) {
  try {
    if (action?.payload?.getCart) {
      yield call(getCartDetails);
    }
    yield put({ type: APP_CONSTANTS.SHOW_BACKDROP_REQUEST, payload: true });
    yield put({ type: APP_CONSTANTS.SHOW_ITEM_NOTIFICATION, payload: true });
    yield delay(5000);
    yield put({ type: APP_CONSTANTS.SHOW_ITEM_NOTIFICATION, payload: false });
  } catch (error) {
    yield put({ type: APP_CONSTANTS.SET_CART_ERROR_MESSAGE, payload: error });
  }
}

/**
 * saga for fetching cart summary
 *
 */
function* watchCartSummary() {
  yield takeEvery(APP_CONSTANTS.GET_CART_SUMMARY_INFO_REQUEST, getCartDetails);
  yield takeEvery(APP_CONSTANTS.UPDATE_ADDON_QUANTITY_REQUEST, updateQuantity);
  yield takeEvery(APP_CONSTANTS.DELETE_CART_ITEM_REQUEST, deleteItemFromCart);
  yield takeEvery(APP_CONSTANTS.SHOW_CART_AND_NOTIFICATION, showNewItemToCartNotification);
}
export default watchCartSummary;
