import { call, fork, put, takeLatest } from "redux-saga/effects";
import APP_CONSTANTS from "../../src/action-constants/app-actions";
import { getSystemPropertyValue } from "../../src/actions/common-actions";
import { setDateRange, setDateRangeError } from "../../src/actions/pdp-actions";

/**
 * handle product Expiry Info
 *
 */
function* handleDateRangeEnable() {
  try {
    const payload = { resource: "fnp", name: "isEnabledShowDateRageForCourierProducts" };
    const res = yield call(getSystemPropertyValue, payload);
    if (res?.value) yield put(setDateRange(res.value));
  } catch (error) {
    yield put(setDateRangeError(error.message));
  }
}

/**
 * for calling date range sagas
 */
function* watchDateRangeRequest() {
  yield takeLatest(APP_CONSTANTS.GET_DATE_RANGE_ENABLE, handleDateRangeEnable);
}

/** This method is used to listen to respective dispatched action */
export default function* dateRangeSaga() {
  yield fork(watchDateRangeRequest);
}
