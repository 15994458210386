import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  textInfo: {
    fontSize: "16px",
    fontWeight: "500",
  },
}));

/**
 * This component is used to show pagination text on in the m-site checkout header
 *
 * @param {string} textInfo pagination text
 *
 * @returns {React.ReactElement} - renders pagination-text-info-btn component
 */
const PaginationTextInfo = ({ textInfo }) => {
  const classes = useStyles();

  return <Typography className={classes.textInfo}>{textInfo}</Typography>;
};

PaginationTextInfo.propTypes = {
  textInfo: PropTypes.string,
};

PaginationTextInfo.defaultProps = {
  textInfo: "",
};

export default PaginationTextInfo;
