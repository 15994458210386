import getConfig from "next/config";
import axios from "../../app_configs/axios/base";
import APP_CONSTANTS from "../action-constants/app-actions";
import errorLogger from "../../app_configs/logger-service";
import UserConstants from "../action-constants/user-constants";

const { publicRuntimeConfig } = getConfig();
const SHOW_REQUESTS_LOGS = publicRuntimeConfig.SHOW_REQUESTS_LOGS || "Y";
const error = {
  config: {},
  response: {},
};
/**
 * This method creates an action object for saving
 * the sticky index to the store
 *
 * @param {string} stickyIndex index of the selected action.
 * @returns {void} nothing.
 */
export const saveStickyIndex = (stickyIndex) => ({
  type: APP_CONSTANTS.SET_STICKY_INDEX,
  payload: {
    stickyIndex,
  },
});

/**
 * This method maintains name of the logged in user
 * in the store
 *
 * @param {string} name logged in user name.
 * @returns {void} nothing.
 */
export const saveUserName = (name) => ({
  type: APP_CONSTANTS.SET_USER,
  payload: {
    name,
  },
});

/**
 * fetches logged in user details
 *
 * @returns {Array} - returns the logged in user details
 */
export const getCustomerDetails = async () => {
  try {
    const response = await axios.post(`/control/getCustomerDetails-rj`);
    return response.data;
  } catch (err) {
    error.config.url = "/control/getCustomerDetails";
    error.response.status = `An exception occurred while getting customer details => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
};

/**
 * fetches logged in user details
 *
 * @param {boolean} payload flag to determine if the popup is closed
 * @returns {object} action object
 */
export const setPopUpStatus = (payload) => ({
  type: UserConstants.SET_POPUP_STATUS_FLAG,
  payload,
});

/**
 * sets current applied hash in the page
 *
 * @param {string} payload current hash applied
 * @returns {object} action object
 */
export const setCurrentHashSelected = (payload) => ({
  type: UserConstants.SET_CURRENT_HASH_SELECTED,
  payload,
});
