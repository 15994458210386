import React from "react";
import PropTypes from "prop-types";
import Image from "next/image";
import { makeStyles } from "@material-ui/styles";
import { useRouter } from "next/router";
import Grid from "@material-ui/core/Grid";
import CurrencyDetails from "./currencyDetails";

const useStyles = makeStyles((theme) => ({
  productImage: {
    width: 75,
    height: 75,
    marginRight: 12,
  },
  figure: {
    display: "flex",
  },
  pName: {
    fontSize: 14,
    overflow: "hidden",
    lineHeight: 1.4,
    display: "-webkit-box",
    color: theme.color?.blue,
    margin: "0px 0px 5px 0px",
    fontWeight: 400,
    width: "100%",
    cursor: "pointer",
    "-webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
  },
  pDescription: {
    margin: 0,
    fontSize: 13,
  },
  currencyDesc: {
    position: "absolute",
    maxWidth: "260px",
    wordBreak: "break-all",
  },
  dot: {
    display: "inline-block",
    width: 4,
    height: 4,
    background: "#ccc",
    borderRadius: "50%",
    margin: "0 10px 3px 5px",
  },

  gray: {
    color: theme.color?.lightGrey,
  },
  variantLabel: {
    color: theme.color?.lightGrey,
    textTransform: "lowercase",
  },
  fnpRed: {
    color: theme.color?.red,
  },
  price: {
    fontSize: 14,
  },
  rupee: {
    verticalAlign: "middle",
    fontFamily: "Roboto,sans-serif",
    fontSize: 16,
    "&::before": {
      content: '"\u20B9"',
    },
  },
  dotLabel: {
    margin: "0 5px 3px 5px",
  },
  quantity: { color: theme.color?.darkBlack },
  imageStyle: { flexShrink: 0 },
}));

/**
 *  Component to display Main product panel info
 *
 * @param {*} param0 all the props passed to the component.
 * @param {string} param0.giftItem It used to get name of product image.
 * @param {string} param0.currency Stores value of local currency.
 * @param {Array} param0.currencies list of currencies.
 * @returns {React.ReactElement} jsx for the main product panel info component.
 */
const MainProductPanelInfo = ({ giftItem, currency, currencies, isMobile, ...props }) => {
  const classes = useStyles(props);
  const router = useRouter();
  return (
    <Grid
      onClick={() => {
        router.push(giftItem.productUrl);
      }}
    >
      <figure className={classes.figure}>
        <div className={classes.imageStyle}>
          <Image
            src={giftItem.mainProduct.productImage}
            alt={giftItem.mainProduct.absoluteImageUrl.alt}
            className={classes.productImage}
            width={75}
            height={75}
            layout="fixed"
            unoptimized
          />
        </div>
        <figcaption style={{ paddingLeft: 12 }}>
          <h3 className={classes.pName}>{giftItem.mainProduct.productName}</h3>
          <p className={`${classes.pDescription} ${!isMobile && classes.currencyDesc}`}>
            <span className={classes.price}>
              <CurrencyDetails
                currencies={currencies}
                selectedCurrency={currency}
                price={giftItem.mainProduct.price}
                priceStyle={{ color: "#222222" }}
                priceSymbolStyles={{ margin: "3px" }}
              />
              <span> x </span>
              <span />
              <span className={classes.quantity}>
                <span>{giftItem.mainProduct.quantity}</span>
              </span>
            </span>
            <span className={`${classes.dot} ${!isMobile && classes.dotLabel}`} />
            {giftItem.mainProduct.isVariant && (
              <span className={`${classes.gray} ${!isMobile && classes.variantLabel}`}>
                {giftItem.mainProduct.variantType}
              </span>
            )}
          </p>
        </figcaption>
      </figure>
    </Grid>
  );
};

const mainProductPanelInfoPropTypes = {
  giftItem: PropTypes.objectOf(PropTypes.any).isRequired,
  currency: PropTypes.string.isRequired,
  currencies: PropTypes.arrayOf(PropTypes.object).isRequired,
  isMobile: PropTypes.bool.isRequired,
};

MainProductPanelInfo.propTypes = mainProductPanelInfoPropTypes;

export default MainProductPanelInfo;
