import { call, put, takeLatest, select } from "redux-saga/effects";
import voucherConstants from "../../src/action-constants/gift-voucher-constants";
import fetchVouchers from "../../src/actions/gift-voucher-actions";
import fetchCurrencies from "../../src/actions/common-actions";

/**
 * Method for fetching coupon codes
 *
 */
export function* getGiftVoucherData() {
  try {
    const response = yield call(fetchVouchers);
    const currencies = yield select((store) => store.currenciesList.currencies);
    if (currencies.length === 0) {
      const resp = yield call(fetchCurrencies);
      yield put({ type: voucherConstants.GET_CURRENCIES_LIST, payload: resp.data.currencies });
    }
    yield put({
      type: voucherConstants.CODE_RECEIVED,
      payload: {
        usedCoupons: response.data.relevantUsedCouponsList,
        unusedCoupons: response.data.relevantUnUsedCouponsList,
      },
    });
  } catch (ex) {
    yield put({
      type: voucherConstants.ERROR_MESSAGE,
    });
  }
}

/**
 *This method is used to fetch coupon code values
 */
function* watchGiftVoucher() {
  yield takeLatest(voucherConstants.GET_CODE, getGiftVoucherData);
}
export default watchGiftVoucher;
