import React, { memo } from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./styles/cartButton.module.scss";

const useStyles = makeStyles((theme) => ({
  continue_btn: {
    padding: "11px 16px",
    minWidth: "88px",
    minHeight: "36px",
    transition: "none !important",
    fontSize: "18px",
    fontWeight: 500,
    lineWeight: "1em",
    borderRadius: "4px",
    textTransform: "uppercase",
    width: "100%",
    height: "52px",
    backgroundColor: "#fff",
    color: "#695d5d",
    boxShadow: "0 0 10px 0 rgba(0,0,0,.15)",
    paddingBottom: "",
  },
  continue_btn_primary: {
    backgroundColor: theme.color?.orange,
    color: "#fff",
    width: "100%",
    height: "52px",
    padding: "11px 16px",
    minWidth: "88px",
    minHeight: "36px",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontSize: "18px",
    fontWeight: 500,
    lineWeight: "1em",
    borderRadius: "4px",
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: theme.color?.orange,
    },
    "& .MuiButton-label": {
      cursor: "pointer",
    },
  },
}));
/**
 * This function creates the button component for the cart preview page
 *
 * @param {boolean} loggedIn flag for user login
 * @returns {React.ReactElement} jsx for the button.
 */
function CustomButton({
  showTransition,
  buttonText,
  buttonStyle,
  buttonType,
  onClick,
  disableBtn,
  buttonTextStyle,
  transitionStyle,
  appendInputValues,
  buttonId,
  title,
  buttonTypeAttribute,
  spanId,
  dataContent,
}) {
  const classes = useStyles();
  return (
    <>
      <Button
        title={title}
        id={buttonId}
        disabled={disableBtn}
        onClick={onClick}
        variant="contained"
        className={buttonType === "primary" ? classes.continue_btn_primary : classes.continue_btn}
        style={buttonStyle}
        type={buttonTypeAttribute}
        data-content={dataContent}
        data-idforcdp="actionBtn-cdp"
        data-testid={buttonId}
      >
        {appendInputValues()}
        <>
          {showTransition ? (
            <span
              style={transitionStyle}
              className={`${styles.progress} ${styles["progress-striped"]} ${styles.active}`}
            >
              <span className={`${styles["progress-inner"]} ${styles["progress-bar"]}`} />
            </span>
          ) : (
            <span className={buttonType === "primary" ? "" : styles.shopping_progress} />
          )}
          <span
            style={buttonTextStyle}
            id={spanId}
            data-content={dataContent}
            className={buttonType === "primary" ? styles.login_content : styles.shopping_content}
          >
            {buttonText}
          </span>
        </>
      </Button>
    </>
  );
}
CustomButton.propTypes = {
  buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.any)]).isRequired,
  buttonStyle: PropTypes.objectOf(PropTypes.any),
  buttonTextStyle: PropTypes.objectOf(PropTypes.any),
  buttonType: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disableBtn: PropTypes.bool.isRequired,
  showTransition: PropTypes.bool,
  transitionStyle: PropTypes.objectOf(PropTypes.any),
  appendInputValues: PropTypes.func,
  buttonId: PropTypes.string,
  title: PropTypes.string,
  buttonTypeAttribute: PropTypes.string,
  spanId: PropTypes.string,
  dataContent: PropTypes.string,
};
CustomButton.defaultProps = {
  buttonStyle: {},
  buttonTextStyle: {},
  showTransition: true,
  transitionStyle: {},
  appendInputValues: () => {},
  buttonId: "",
  title: "",
  buttonTypeAttribute: "button",
  spanId: "",
  dataContent: "",
};
export default memo(CustomButton);
