import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import { convertPrice } from "../../../../src/utils/common";

const useStyles = makeStyles({
  rupee: {
    verticalAlign: "middle",
    fontFamily: "Roboto,sans-serif",
    fontSize: 16,
    "&::before": {
      content: '"\u20B9"',
    },
  },
});

/**
 * Component to render currency details
 *
 * @param {*} param0 props passed to component
 *  @param {string} param0.currencies List of currencies.
 * @param {string} param0.selectedCurrency string contains value of selected currency.
 * @param {string} param0.price string contains value of product price.
 * @returns {React.ReactElement} jsx for the currency details component.
 */
const CurrencyDetails = ({ currencies, selectedCurrency, price, priceStyle, priceSymbolStyles, ...props }) => {
  const classes = useStyles(props);
  const currencyDetails = convertPrice(currencies, selectedCurrency, price);
  const isCurrencyINR = selectedCurrency === "INR";
  return (
    <span style={priceStyle}>
      {isCurrencyINR ? (
        <span className={`${classes.rupee}`} style={priceSymbolStyles} />
      ) : (
        <span style={priceSymbolStyles}>{`${currencyDetails.symbol}`}</span>
      )}
      {`${currencyDetails.price}`}
    </span>
  );
};

const propTypes = {
  currencies: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedCurrency: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  priceStyle: PropTypes.objectOf(PropTypes.any),
  priceSymbolStyles: PropTypes.objectOf(PropTypes.any),
};

const defaultProps = {
  priceStyle: {},
  priceSymbolStyles: { marginRight: "3px", wordWrap: "normal" },
};
CurrencyDetails.propTypes = propTypes;
CurrencyDetails.defaultProps = defaultProps;

export default CurrencyDetails;
