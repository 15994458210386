const GiftFinderConstants = {
  REQUEST_FACET_DATA: "REQUEST_FACET_DATA",
  REQUEST_CITY_DATA: "REQUEST_CITY_DATA",
  SET_FACET_DATA: "SET_FACET_DATA",
  SET_FACET_ERROR: "FACET_DATA_ERROR",
  INITIATE_SET_CITY_DATA: "INITIATE_SET_CITY_DATA",
  SET_CITY_DATA: "SET_CITY_DATA",
  SET_CITY_ERROR: "SET_CITY_ERROR",
  CLEAR_CITY_DATA: "CLEAR_CITY_DATA",
  SET_LOCATION_DATA: "SET_LOCATION_DATA",
  CLEAR_LOCATION_DATA: "CLEAR_LOCATION_DATA",
  CLEARED_LOCATION_FIELD: "CLEARED_LOCATION_FIELD",
};
export default GiftFinderConstants;
