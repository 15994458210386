import { HYDRATE } from "next-redux-wrapper";
import APP_CONSTANTS from "../../../src/action-constants/app-actions";
import { ACTION_TYPE } from "../../../src/action-constants/my-account-constants";
import UserConstants from "../../../src/action-constants/user-constants";

const initialState = {
  name: "Guest",
  stickyIndex: 0,
  cartInfo: { cartTotalQuantity: 0, isSuccess: false },
  checkLoginInfo: { loggedin: false, isOAuthUser: false },
  customerDetails: {},
  cartId: "",
  error: "",
  loginChecked: false,
  showSmartLock: null,
  fusCompleted: false,
  isPopupOpened: false,
  currentHash: "",
  pincodeGivenByUser: null,
};

/**
 * This method is the reducer for actions that are specific to user
 *
 * @param {object} state - contains initial redux State
 * @param {object} action - contains actionTypes and payload information for specific action
 * @returns {object} state - the next State which needs to be dispatched to redux
 */
function userReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.userDetails,
      };
    case APP_CONSTANTS.SET_USER:
      return {
        ...state,
        name: payload.name,
      };
    case APP_CONSTANTS.SET_STICKY_INDEX:
      return {
        ...state,
        stickyIndex: payload.stickyIndex,
      };
    case APP_CONSTANTS.CHECK_LOGIN_CART_INFO:
      return {
        ...state,
        checkLoginInfo: payload.checkLoginInfo,
        cartInfo: payload.cartInfo,
        name: (payload.checkLoginInfo && payload.checkLoginInfo.fullname) || "Guest",
        cartId: payload.cartId,
        loginChecked: true,
        showSmartLock: !payload.checkLoginInfo.loggedin,
        pincodeGivenByUser: payload.pincodeGivenByUser,
      };
    case APP_CONSTANTS.SET_CART_COUNT:
      return {
        ...state,
        cartInfo: { ...state.cartInfo, cartTotalQuantity: payload },
      };
    case APP_CONSTANTS.LOGIN_CHECKED:
      return {
        ...state,
        loginChecked: true,
      };
    case APP_CONSTANTS.FUS_SUCCESS:
      return {
        ...state,
        fusCompleted: action.payload,
      };
    case ACTION_TYPE.LOGOUT_SUCCESS:
      return {
        ...initialState,
      };
    case ACTION_TYPE.LOGOUT_FAIL:
      return {
        ...state,
        error: payload.message,
      };
    case UserConstants.FETCH_CUSTOMER_DETAILS_SUCCESS:
      return {
        ...state,
        customerDetails: payload?.customerDetails,
      };
    case UserConstants.SET_POPUP_STATUS_FLAG:
      return {
        ...state,
        isPopupOpened: action.payload,
      };
    case UserConstants.SET_CURRENT_HASH_SELECTED:
      return {
        ...state,
        currentHash: action.payload,
      };
    default:
      return state;
  }
}

export default userReducer;
