import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";

/**
 * This is a functional component representing an button component.
 *
 * @param {object} props .
 * @returns {React.ReactElement} the JSX for the component
 */
function ButtonElement(props) {
  const { type, variant, className, children, onClick, disabled, style, testId, startIcon } = props;
  /**
   * This method handles click event.
   *
   * @param {event} e .
   */
  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <Button
      startIcon={startIcon}
      type={type}
      variant={variant}
      className={className}
      disableRipple
      onClick={handleClick}
      disabled={disabled}
      style={style}
      data-testid={testId}
    >
      {children}
    </Button>
  );
}

ButtonElement.propTypes = {
  variant: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.objectOf(PropTypes.any),
  testId: PropTypes.string,
  startIcon: PropTypes.node,
};

ButtonElement.defaultProps = {
  variant: "contained",
  type: "button",
  disabled: false,
  className: "primary",
  onClick: () => {},
  style: {},
  testId: "",
  startIcon: "",
};

export default ButtonElement;
