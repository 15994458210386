import { HYDRATE } from "next-redux-wrapper";
import commonSearchConstants from "../../../src/action-constants/common-search-constants";

const initialState = {
  popularSearches: {
    recentSearchCount: 0,
    suggestionResponse: [],
    error: "",
  },
};

/**
 * This method is the reducer for actions that are specific to App
 *
 * @param {object} state - contains initial redux State
 * @param {object} action - contains actionTypes and payload information for specific action
 * @returns {object} state - the next State which needs to be dispatched to redux
 */
function searchDataReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.searchData,
      };
    case commonSearchConstants.GET_POPULAR_SEARCH_SUCCESS:
      return {
        ...state,
        popularSearches: {
          recentSearchCount: payload.recentSearchCount,
          suggestionResponse: payload.suggestionResponse,
          error: "",
        },
      };
    case commonSearchConstants.GET_POPULAR_SEARCH_FAIL:
      return {
        ...state,
        popularSearches: {
          ...state.popularSearches,
          recentSearchCount: 0,
          suggestionResponse: [],
          error: payload,
        },
      };
    case commonSearchConstants.GET_MANUAL_BOOST_SUCCESS:
      return {
        ...state,
        popularSearches: {
          recentSearchCount: payload.recentSearchCount,
          suggestionResponse: payload.suggestionResponse,
          error: "",
        },
      };
    case commonSearchConstants.GET_MANUAL_BOOST_FAIL:
      return {
        ...state,
        popularSearches: {
          ...state.popularSearches,
          recentSearchCount: 0,
          suggestionResponse: [],
          error: payload,
        },
      };
    default:
      return state;
  }
}

export default searchDataReducer;
