/**
 * This method is used to handle toast animation
 *
 * @param {string} toastId id of the toast to be animated
 * @param {number} fadeOutTime contains additional time if required
 * @param {number} fadeOutRemoveTime contains additional time if required
 */
const animateToast = (toastId, fadeOutTime = 2000, fadeOutRemoveTime = 2500) => {
  if (document.getElementById(toastId)) document.getElementById(toastId).style.display = "block";
  setTimeout(() => {
    document.getElementById(toastId)?.classList.add("toast-fade-out");
  }, fadeOutTime);
  setTimeout(() => {
    if (document.getElementById(toastId)) {
      document.getElementById(toastId)?.classList.remove("toast-fade-out");
      document.getElementById(toastId).style.display = "none";
    }
  }, fadeOutRemoveTime);
};

export default animateToast;
