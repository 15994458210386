import { HYDRATE } from "next-redux-wrapper";
import CommonPageActionsConstants from "../../../src/action-constants/common-page-actions-constants";

const initialState = {
  pageHead: {
    preLoad: "",
    metaContent: "",
    canonical: "",
    ogTag: "",
    twitterTag: "",
    schemaTag: "",
    relAlt: "",
    headAnalytics: "",
    widgetHelper: "",
  },
  menu: "",
  breadcrumbs: [],
  bodyAnalytics: "",
  bottomAnalytics: "",
  footer: "",
  error: "",
  bannerLink: "",
  bannerImage: "",
  mediaEmail: "",
  showMediaPopup: false,
  configureCallAndChatTime: {},
  appRedirectionBannerDetails: {},
};

/**
 * Reducer for common app data
 *
 * @param {object} state state object
 * @param {object} action action object
 * @returns {object} state object
 */
const commonPageDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.commonPageData,
      };
    case CommonPageActionsConstants.SERVER_SET_PAGE_META_DATA:
      return {
        ...state,
        pageHead: {
          ...state.pageHead,
          metaContent: action.payload,
        },
      };
    case CommonPageActionsConstants.SERVER_SET_PAGE_CANONICAL_DATA:
      return {
        ...state,
        pageHead: {
          ...state.pageHead,
          canonical: action.payload,
        },
      };
    case CommonPageActionsConstants.SERVER_SET_PAGE_OG_TAG_DATA:
      return {
        ...state,
        pageHead: {
          ...state.pageHead,
          ogTag: action.payload,
        },
      };
    case CommonPageActionsConstants.SERVER_SET_PAGE_TWITTER_TAG_DATA:
      return {
        ...state,
        pageHead: {
          ...state.pageHead,
          twitterTag: action.payload,
        },
      };
    case CommonPageActionsConstants.SERVER_SET_PAGE_SCHEMA_TAG_DATA:
      return {
        ...state,
        pageHead: {
          ...state.pageHead,
          schemaTag: action.payload,
        },
      };
    case CommonPageActionsConstants.SERVER_SET_PAGE_REL_ALT_DATA:
      return {
        ...state,
        pageHead: {
          ...state.pageHead,
          relAlt: action.payload,
        },
      };
    case CommonPageActionsConstants.SERVER_SET_PAGE_MENU_DATA:
      return {
        ...state,
        menu: action.payload.menu,
        bannerLink: action.payload.bannerLink,
        bannerImage: action.payload.bannerImage,
        mediaEmail: action.payload.mediaEmail,
      };
    case CommonPageActionsConstants.SERVER_SET_PAGE_BREADCRUMBS_DATA:
      return {
        ...state,
        breadcrumbs: [...state.breadcrumbs, ...action.payload],
      };
    case CommonPageActionsConstants.SERVER_SET_PAGE_HEAD_ANALYTICS_DATA:
      return {
        ...state,
        pageHead: {
          ...state.pageHead,
          headAnalytics: action.payload,
        },
      };
    case CommonPageActionsConstants.SERVER_SET_WIDGET_HELPER_DATA:
      return {
        ...state,
        pageHead: {
          ...state.pageHead,
          widgetHelper: action.payload,
        },
      };
    case CommonPageActionsConstants.SERVER_SET_PAGE_BODY_ANALYTICS_DATA:
      return {
        ...state,
        bodyAnalytics: action.payload,
      };
    case CommonPageActionsConstants.SERVER_SET_PAGE_BOTTOM_ANALYTICS_DATA:
      return {
        ...state,
        bottomAnalytics: action.payload,
      };
    case CommonPageActionsConstants.SERVER_SET_PAGE_FOOTER_DATA:
      return {
        ...state,
        footer: action.payload,
      };
    case CommonPageActionsConstants.SERVER_SET_ERROR_DATA:
      return {
        ...state,
        error: action.payload,
      };
    case CommonPageActionsConstants.SERVER_SET_PRELOAD_DATA:
      return {
        ...state,
        pageHead: {
          ...state.pageHead,
          preLoad: action.payload,
        },
      };
    case CommonPageActionsConstants.SHOW_MEDIA_INFO:
      return {
        ...state,
        showMediaPopup: action.payload,
      };
    case CommonPageActionsConstants.GET_CALL_CHAT_TIME_SUCCESS:
      return { ...state, configureCallAndChatTime: action.payload };
    case CommonPageActionsConstants.GET_CALL_CHAT_TIME_FAIL:
      return {
        ...state,
      };
    case CommonPageActionsConstants.SERVER_SET_APP_BANNER_DATA:
      return {
        ...state,
        appRedirectionBannerDetails: action.payload,
      };
    case CommonPageActionsConstants.POST_DATA_IN_CLEVER_TAP_SUCCESS:
      return { ...state, publishDataInCleverTap: action.payload };
    case CommonPageActionsConstants.POST_DATA_IN_CLEVER_TAP_FAIL:
      return { ...state };
    default:
      return state;
  }
};

export default commonPageDataReducer;
