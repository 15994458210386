import React, { useCallback, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Card } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import FilterTypeMenu from "./filters-side-menu";
import FilterOptions from "./filter-options";
import { clearAllFilter, updateFilterDataFromUrl } from "../../../../src/utils/plp-helper";
import Loader from "../loader";
import { FACET_FIELD_NAME } from "../../../common/constants";

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    backgroundColor: theme.color.white,
    overflow: "hidden",
    height: "100%",
    marginTop: "4px",
  },
  filterDetailsContainer: {
    height: "calc(100% - 47px)",
    overflowY: "scroll",
    display: "flex",
  },
  sideMenuContainer: {
    backgroundColor: theme.color.normalGrey,
  },
  buttonContainer: {
    width: "100%",
  },
  clearBtn: {
    backgroundColor: theme.color.white,
    color: theme.color.black,
    textAlign: "center",
    padding: 10,
    fontWeight: "500",
  },
  applyBtn: {
    backgroundColor: theme.color.orange,
    color: theme.color.white,
    textAlign: "center",
    padding: 10,
    fontWeight: "500",
  },
  modalHeading: { fontWeight: 600, textAlign: "center" },
  modalSubText: {
    color: theme.color.darkGrey,
    fontSize: 12,
    textAlign: "center",
    marginTop: 5,
    marginRight: 15,
    marginLeft: 15,
  },
  modalContainer: { padding: 25 },
  discardPopupBtn: {
    padding: 7,
    border: `1px solid ${theme.color.darkGrey}`,
    borderRadius: 3,
    width: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalBtnContainer: { display: "flex", justifyContent: "space-evenly", marginTop: 25 },
  loader: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0,0,0,0.1)",
    zIndex: 2,
  },
}));
/**
 * This function renders the plp filters
 *
 * @param {*} props all props passed to the component
 * @param {Function} props.handleClose handle close prop to close filter
 * @param {Function} props.handleSelection handle selection of user
 * @returns {React.ReactElement} returns a jsx for filters
 */
const FilterBody = ({ handleClose, handleSelection }) => {
  const classes = useStyles();
  const { facetDetailList, total, isFetching } = useSelector((state) => state.productsLists);
  const [activeFilterTab, setActiveFilterTab] = useState(() =>
    facetDetailList ? facetDetailList[0]?.facetFieldName : "",
  );
  const [filterData, setFilterData] = useState({});
  const router = useRouter();

  useEffect(() => {
    const resFilters = updateFilterDataFromUrl(window?.location?.search);
    setFilterData({ ...resFilters });
  }, [router]);

  /**
   * This function handles the tab change
   *
   * @param {string} val current selected filter type
   */
  const handleFilterTypeChange = (val) => {
    setActiveFilterTab((prevVal) => (prevVal !== val ? val : prevVal));
  };

  /**
   * This function for clear all the filters for url
   *
   * @param {object} data filter data
   */
  const addFiltersInUrl = useCallback(
    (data) => {
      const currentUrlParams = new URLSearchParams(window.location.search);
      const cleanedUrlParams = clearAllFilter(currentUrlParams);
      Object.keys(data).forEach((filterType) => {
        Object.keys(data[filterType]).forEach((filterValue) => {
          cleanedUrlParams.append(`fq-${filterType}`, encodeURIComponent(filterValue));
        });
      });
      handleSelection(cleanedUrlParams);
      router.push(
        router.pathname,
        `${window?.location?.pathname}${cleanedUrlParams.toString() ? `?${cleanedUrlParams.toString()}` : ""}${
          window.location.hash
        }`,
        {
          shallow: true,
        },
      );
    },
    [handleSelection, router],
  );

  /**
   * this function updates the filter data as per the selections
   *
   * @param {string} selectedType selected type
   * @param {string} selectedValue selected value
   */
  const updateFilterData = useCallback(
    (selectedType, selectedValue) => {
      let filterObj = {};
      if (!filterData[selectedType]) {
        filterObj[selectedValue] = true;
      } else {
        filterObj = { ...filterData[selectedType] };
        if (!Object.prototype.hasOwnProperty.call(filterObj, selectedValue)) {
          filterObj[selectedValue] = true;
        } else {
          delete filterObj[selectedValue];
          if (!Object.keys(filterObj).length) {
            const tempFilterData = { ...filterData };
            delete tempFilterData[selectedType];
            addFiltersInUrl({ ...tempFilterData });
            return;
          }
        }
      }
      addFiltersInUrl({ ...filterData, [selectedType]: filterObj });
    },
    [addFiltersInUrl, filterData],
  );

  return (
    <Grid container direction="row" justify="flex-start" className={classes.filterContainer}>
      {isFetching && (
        <div className={classes.loader}>
          <Loader />
        </div>
      )}
      <Grid item xs={5} className={`${classes.filterDetailsContainer} ${classes.sideMenuContainer}`}>
        <FilterTypeMenu
          facetDetailList={facetDetailList.filter((facet) => {
            return facet?.facetFieldName !== FACET_FIELD_NAME.PRODUCT_TYPE_TAGS_RELATION;
          })}
          handleFilterTypeChange={handleFilterTypeChange}
          activeFilterTab={activeFilterTab}
          selectedFilterOptions={filterData}
        />
      </Grid>
      <Grid item xs={7} className={classes.filterDetailsContainer}>
        <FilterOptions
          facetDetailList={facetDetailList.filter((facet) => {
            return facet?.facetFieldName !== FACET_FIELD_NAME.PRODUCT_TYPE_TAGS_RELATION;
          })}
          activeFilterTab={activeFilterTab}
          updateFilterData={updateFilterData}
          selectedFilterOptions={filterData}
        />
      </Grid>
      <Card className={classes.buttonContainer} elevation={5}>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.applyBtn} onClick={handleClose}>
              {`SHOW ${total} RESULTS`}
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

FilterBody.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSelection: PropTypes.func.isRequired,
};

export default FilterBody;
