import qs from "qs";
import axios from "../../../../app_configs/axios/base";

/**
 * get list of saved addresses for particular cart index
 *
 * @param {object} params - params for api request
 * @returns {object} - api response
 */
export const getSavedAddressForPinCode = (params) => {
  const { cartIndex } = params;
  return axios.get(`control/getSavedAddressForPincode-rj?cartItemIndex=${cartIndex}`, {});
};

/**
 * post update address details
 *
 * @param {object} params - params for api request
 * @returns {object} - api response
 */
export const updateAddressForItem = (params) => {
  return axios.post("control/setAddressForItem-rj", qs.stringify(params), {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

/**
 *  get existing details to update address
 *
 * @param {object} params - params for api request
 * @returns {object} - api response
 */
export const getEditAddressForItem = (params) => {
  const { cartIndex, uniqueAddressId } = params;
  const contactIdParam = uniqueAddressId ? `&contactMechId=${uniqueAddressId}` : ``;
  return axios.get(`control/getAddressDetails-rj?cartItemIndex=${cartIndex}${contactIdParam}`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};
