import queryString from "query-string";
import getConfig from "next/config";
import axios from "../../app_configs/axios/base";
import errorLogger from "../../app_configs/logger-service";
import { paymentGateways } from "../utils/payment";
import Regex from "../../components/common/regex";
import { isMobileDevice } from "../utils/common";
import { execDataLayer } from "../data-layer/dataLayer";
import { endpointConst } from "../data-layer/dataLayerConstants";

const { publicRuntimeConfig } = getConfig();
const desktop = typeof window !== "undefined" ? window.dSite : false;
const CONTROL_API_URL = desktop ? publicRuntimeConfig.D_BASE_URL : publicRuntimeConfig.BASE_URL;

const SHOW_REQUESTS_LOGS = publicRuntimeConfig.SHOW_REQUESTS_LOGS || "Y";
const error = {
  config: {},
  response: {},
};
const isServer = typeof window === "undefined";

/**
 *
 * @param {boolean} isDesktop - isDesktop flag
 * @returns {string} domain
 */
const getDeviceSpecificDomain = (isDesktop) =>
  isDesktop ? publicRuntimeConfig.D_SERVER_CONTROL_FTS_HOST : publicRuntimeConfig.SERVER_CONTROL_FTS_HOST;

/**
 * fetches all the available currency options for paypal payment
 *
 * @returns {Array} - returns a list of all the currencies
 */
async function getPaypalCurrencies() {
  try {
    const response = await axios.get("/control/getCurrencyNamesPayPal-rj");
    return response.data;
  } catch (err) {
    error.config.url = "/control/getCurrencyNamesPayPal";
    error.response.status = `An exception occurred while fetching paypal currecies => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * fetches all the available banks for net banking
 *
 * @param {string} payload - value of gateway
 * @returns {Array} - returns a list of all the banks
 */
async function getNetBankingList(payload) {
  try {
    const apiUrl = payload === paymentGateways.PAYU ? "getNetBankingList-rj" : "getRpayNetBankingList-rj";
    const response = await axios.get(`/control/${apiUrl}`);
    return response.data;
  } catch (err) {
    error.config.url = "/control/getNetBankingList";
    error.response.status = `An exception occurred while fetching net banking banks list => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * fetches all the available wallet options
 *
 * @returns {Array} - returns a list of all the wallets available
 */
async function getWalletDetails() {
  try {
    const response = await axios.get("/control/getWalletDetails-rj");
    return response.data;
  } catch (err) {
    error.config.url = "/control/getWalletDetails";
    error.response.status = `An exception occurred while fetching wallet details => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * fetches payment options
 *
 * @param {object} payload - object with required params
 * @returns {Array} - returns payment options
 */
async function getPaymentOptionsDetails(payload) {
  try {
    const orderParams =
      !!payload?.orderId?.trim() && Regex.onlyNumbers.test(payload?.orderId?.trim())
        ? `?orderId=${payload.orderId}`
        : "";
    const pncParams = payload?.pnc === false ? `&pnc=${payload.pnc}` : "";
    const response = await axios.get(`/control/getPaymentOptionsDetails-rj${orderParams}${pncParams}`);
    return response.data;
  } catch (err) {
    error.config.url = "/control/getPaymentOptionsDetails";
    error.response.status = `An exception occurred while fetching payment options => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * fetches saved cards for logged-in user
 *
 * @param {number} partyId - returns a list of all the saved cards for logged-in user
 * @returns {Array} - returns a list of all the saved cards for logged-in user
 */
async function getUserSavedCards(partyId) {
  try {
    const response = await axios.get(`/control/getUserSavedCards-rj?partyId=${partyId}`);
    return response.data;
  } catch (err) {
    error.config.url = "/control/getUserSavedCards";
    error.response.status = `An exception occurred while fetching logged in user saved cards => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * fetches minimum EMI amount
 *
 * @returns {Array} - returns the minimum EMI amount
 */
async function getMinAmountEMI() {
  try {
    const response = await axios.get(`/control/getMinAmountEMI`);
    return response.data;
  } catch (err) {
    error.config.url = "/control/getMinAmountEMI";
    error.response.status = `An exception occurred while fetching minimum EMI amount  => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * checks entered card number's info
 *
 * @param {number} cardData - card data
 * @returns {Array} - returns the entered card number's info
 */
async function checkCardType(cardData) {
  try {
    const dataString = queryString.stringify({
      cardNumber: cardData?.cardNumber?.replace(/\s/g, ""),
      orderId: cardData?.orderId,
    });
    const response = await axios.post(`/control/checkCardType`, dataString);
    return response.data;
  } catch (err) {
    error.config.url = "/control/checkCardType";
    error.response.status = `An exception occurred while fetching card details => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * fetches EMI payment values
 *
 * @param {number} amount - total cart amount
 * @returns {Array} - returns the EMI payment values
 */
async function getEmiPaymentValues(amount) {
  try {
    const response = await axios.post(`/control/getEmiPaymentValues?amount=${amount}`);
    return response.data;
  } catch (err) {
    error.config.url = "/control/getEmiPaymentValues";
    error.response.status = `An exception occurred while fetching EMI payment values  => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * fetches thank you page info
 *
 * @param {object} payload - contains request object
 * @returns {Array} - returns the thank you page info
 */
async function getThankYouPageInfo(payload) {
  try {
    const { ctx, orderId } = payload;
    const host = isServer ? getDeviceSpecificDomain(!isMobileDevice(ctx)) : CONTROL_API_URL;
    const axiosURL = `/control/getThankYouPageInfo?orderId=${orderId}&isDRTP=Y&isRRTP=Y`;
    /**
     * @function apiFn wrapper function to execute thankyou page call from datalayer.
     * @returns response from datalayer
     */
    const apiFn = async () => {
      return axios.get(axiosURL, {
        baseURL: host,
        headers: ctx
          ? {
              Cookie: ctx.req.headers.cookie,
            }
          : {},
      });
    };
    const response = await execDataLayer(apiFn, endpointConst.thankYouPage);
    return response.data;
  } catch (err) {
    error.config.url = "/control/getThankYouPageInfo";
    error.response.status = `An exception occurred while fetching thank you page information  => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}
/**
 * fetches all reminders
 *
 * @param {number} occasionReminderId - reminder id
 * @param {boolean} upcoming - fetch all upcoming reminders flag
 * @returns {Array} - returns the all reminders
 */
async function getAllReminders(occasionReminderId, upcoming) {
  try {
    let axiosURL = `/control/getAllReminders-rj`;
    if (occasionReminderId) {
      axiosURL = `/control/getAllReminders-rj?occasionReminderId=${occasionReminderId}`;
    }
    if (upcoming) {
      axiosURL = `/control/getAllReminders-rj?viewIndex=0&viewSize=2&isApi=Y&occasionReminder=Y`;
    }
    const response = await axios.get(axiosURL);
    return response.data;
  } catch (err) {
    error.config.url = "/control/getAllReminders";
    error.response.status = `An exception occurred while fetching all reminders  => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * update reminders
 *
 * @param {object} payload - reminder data
 * @returns {Array} - returns the updated reminder data
 */
async function updateReminders(payload) {
  try {
    const { occasionReminderId = "" } = payload;
    const dataString = queryString.stringify({
      name: payload.name || "",
      occasion: payload.occasion || "",
      day: payload.day || "",
      month: payload.month || "",
      year: payload.year || "",
      occasionReminderId: occasionReminderId || "",
    });

    const axiosURL = `/control/saveReminder-rj`;
    const response = await axios.post(axiosURL, dataString);
    return response.data;
  } catch (err) {
    error.config.url = "/control/saveReminder";
    error.response.status = `An exception occurred while updating reminders  => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * delete reminders
 *
 * @param {number} occasionReminderId - reminder id
 * @returns {Array} - returns the delete reminder
 */
async function deleteReminders(occasionReminderId) {
  try {
    const dataString = queryString.stringify({
      occasionReminderId: Number(occasionReminderId),
    });
    const response = await axios.post(`/control/deleteReminder-rj`, dataString);
    return response.data;
  } catch (err) {
    error.config.url = "/control/deleteReminder";
    error.response.status = `An exception occurred while deleting reminder  => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * delete user saved card
 *
 * @param {number} partyId - party id
 * @param {string} cardToken - cardToken
 * @returns {Array} - returns the delete card
 */
async function deleteSavedCard(partyId, cardToken) {
  try {
    const response = await axios.post(`/control/deleteSavedUserCard?cardToken=${cardToken}&partyId=${partyId}`);
    return response.data;
  } catch (err) {
    error.config.url = "/control/deleteSavedUserCard";
    error.response.status = `An exception occurred while deleting user saved card  => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * makes all the api calls to collect payments data
 *
 * @param {object} payload - params for api call
 * @returns {Array} - returns all data required to process payments
 */
async function fetchPaymentMethods(payload) {
  try {
    const response = await Promise.all([getWalletDetails(), getPaymentOptionsDetails(payload)]);
    return {
      walletDetails: response[0],
      paymentDetails: response[1],
    };
  } catch (err) {
    error.config.url = "/initiatePaymentAPIs";
    error.response.status = `An exception occurred while fetching initiatePaymentAPIs=> ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * fetches thank you page detail
 *
 * @returns {Array} - returns the thank you page detail
 */
async function getThankYouPageContentDetail() {
  try {
    const response = await axios.get("/control/getContentIdDetails-rj?contentId=customizeThankYouPage");
    return response.data;
  } catch (err) {
    error.config.url = "/control/getContentIdDetails-rj?contentId=customizeThankYouPage";
    error.response.status = `An exception occurred while fetching thank you page information  => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

/**
 * save user feedback
 *
 * @param {object} payload - params for api call
 * @returns {object} - response from api
 */
async function saveUserFeedback(payload) {
  try {
    return axios.post("/control/storeUserFeedback", queryString.stringify(payload));
  } catch (err) {
    error.config.url = "/control/storeUserFeedback";
    error.response.status = `An exception occurred while saving user feedback  => ${err.code}`;
    errorLogger(error, SHOW_REQUESTS_LOGS);
    return { error: err };
  }
}

const paymentActions = {
  checkCardType,
  getUserSavedCards,
  fetchPaymentMethods,
  getEmiPaymentValues,
  getPaypalCurrencies,
  getNetBankingList,
  getWalletDetails,
  getPaymentOptionsDetails,
  getMinAmountEMI,
  getThankYouPageInfo,
  getAllReminders,
  updateReminders,
  deleteReminders,
  deleteSavedCard,
  getThankYouPageContentDetail,
  saveUserFeedback,
};

export default paymentActions;
