import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  selectedFilterCount: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "20px",
    width: "20px",
    fontSize: "12px",
    borderRadius: "50%",
    backgroundColor: theme.color.olive,
    color: theme.color.white,
    flexShrink: 0,
  },
  filterTypeCard: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 15px",
    alignItems: "center",
    fontSize: "14px",
    textTransform: "capitalize",
  },
  filterTypeCardActive: {
    backgroundColor: theme.color.white,
    borderBottom: `5px solid ${theme.color.olive}`,
    color: theme.color.green,
    fontWeight: 500,
    paddingBottom: "15px",
  },
  filterSideMenuWrapper: {
    flexGrow: 1,
  },
  filterTypeTitle: {
    lineHeight: 1.5,
  },
}));

/**
 * this function renders the side menu in filters
 *
 * @param {object} param0 - props
 * @param {Array} param0.facetDetailList filter options
 * @param {Function} param0.handleFilterTypeChange function to change the filter type tab
 * @param {string} param0.activeFilterTab current selected filter tab
 * @param {object} param0.selectedFilterOptions current selected filter options
 * @returns {React.ReactElement} returns a jsx for filters
 */
const FilterTypeMenu = ({ facetDetailList, handleFilterTypeChange, activeFilterTab, selectedFilterOptions }) => {
  const classes = useStyles();

  /**
   * this function renders the selected filter count
   *
   * @param {string} filterType -current selected filter type
   * @returns {React.ReactElement} - returns filter count jsx
   */
  const renderSelectedFilterCount = (filterType) => {
    if (Object.keys(selectedFilterOptions)?.length) {
      if (Object.prototype.hasOwnProperty.call(selectedFilterOptions, filterType))
        return (
          <span className={classes.selectedFilterCount}>{Object.keys(selectedFilterOptions[filterType])?.length}</span>
        );
    }
    return <></>;
  };

  return (
    <div aria-label="filter-side-menu" className={classes.filterSideMenuWrapper}>
      {facetDetailList.map((filters) => (
        <Grid
          key={filters?.facetFieldName}
          className={`${classes.filterTypeCard} ${
            activeFilterTab === filters?.facetFieldName ? `${classes.filterTypeCardActive} isActive` : ""
          }`}
          onClick={() => handleFilterTypeChange(filters.facetFieldName)}
        >
          <p className={classes.filterTypeTitle}>{filters?.facetFieldDisplayName?.toLowerCase()}</p>
          {renderSelectedFilterCount(filters?.facetFieldName)}
        </Grid>
      ))}
    </div>
  );
};
export default FilterTypeMenu;

FilterTypeMenu.propTypes = {
  facetDetailList: PropTypes.arrayOf(
    PropTypes.shape({
      facetFieldDisplayName: PropTypes.string,
      facetFieldName: PropTypes.string,
      facetOptions: PropTypes.arrayOf(PropTypes.any),
    }),
  ).isRequired,
  handleFilterTypeChange: PropTypes.func.isRequired,
  activeFilterTab: PropTypes.string.isRequired,
  selectedFilterOptions: PropTypes.objectOf(PropTypes.any),
};
FilterTypeMenu.defaultProps = {
  selectedFilterOptions: {},
};
