import queryString from "query-string";
import getConfig from "next/config";
import axios from "../../../../app_configs/axios/base";
import gateway from "../../../../app_configs/axios/gateway";

const { publicRuntimeConfig } = getConfig();
const columbusAPIVersion = publicRuntimeConfig.COLUMBUS_API_VERSION;
/**
 * generate search items
 *
 * @param {object} params - parameter
 * @returns {Array | string} - returns Array
 */
export async function getPopularSearches(params) {
  try {
    const response = await axios.get(`control/getPopularSearchData?${queryString.stringify(params)}`);
    return response.data.data;
  } catch (ex) {
    throw new Error(ex);
  }
}

/**
 * generate search  term suggestions
 *
 * @param {object} params - parameter
 * @returns {Array | object} - returns Array
 */
export async function getSearchTermSuggestion(params) {
  try {
    const response = await gateway.get(`${columbusAPIVersion}suggestions?${queryString.stringify(params)}`);

    return response.data;
  } catch (ex) {
    throw new Error({ ex, isSuccess: true });
  }
}

/**
 *
 * @param {object} params - parameter
 * @returns {object} - return object
 */
export async function getManualBoost(params) {
  try {
    const response = await gateway.get(`${columbusAPIVersion}popular-suggestions?${queryString.stringify(params)}`);
    return response.data;
  } catch (ex) {
    throw new Error({ ex, isSuccess: true });
  }
}

/**
 * GET Product List for given product ids
 *
 * @param {object} options - params required for fetching the products
 * @returns {object} response from productListing API
 */
export async function getProductListForGivenIds(options) {
  try {
    const response = await gateway.get(`${columbusAPIVersion}products`, {
      params: {
        ...options,
      },
    });
    return response.data;
  } catch (err) {
    return err;
  }
}
