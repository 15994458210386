import { HYDRATE } from "next-redux-wrapper";
import actionConstants from "../../src/action-constants/app-actions";

const initialState = {
  plpFilterData: "",
};

/**
 * @param {object} state - state
 * @param {string} action - action
 * @returns {object} new state
 */
function filterReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case HYDRATE:
      return {
        ...state,
        ...payload.plpFilter,
      };
    case actionConstants.GET_FILTER_DATA_SUCCESS:
      return {
        ...state,
        plpFilterData: payload,
      };
    default:
      return state;
  }
}

export default filterReducer;
