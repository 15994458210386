import { HYDRATE } from "next-redux-wrapper";
import APP_CONSTANTS from "../../../src/action-constants/app-actions";

const initialState = {
  subscribe: {
    message: "Get updates on promotions and offers coupons.",
    error: "",
    loading: false,
  },
};

/**
 *  This function is a reducer for desktop footer subscribe email.
 *
 * @param {object}state it represents the current state object
 * @param {object}action it represents the new action object which consists of the new payload.
 * @returns {object} Updated state object with the action object.
 */
function dFooterReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.dFooter,
      };
    case APP_CONSTANTS.SET_FOOTER_SUBSCRIPTION:
      return {
        ...state,
        subscribe: { ...state.subscribe, message: payload, loading: payload === "" },
      };
    case APP_CONSTANTS.FOOTER_SUBSCRIPTION_ERROR:
      return {
        ...state,
        subscribe: { ...state.subscribe, message: "invalid email address" },
      };

    default:
      return state;
  }
}

export default dFooterReducer;
