const mobileIndiaConfig = {
  menuItems: [
    { href: "/", name: "Home India" },
    { href: "/about", name: "About India" },
  ],
  footerSocialURL: [
    {
      href: "https://www.facebook.com/fnpind",
      name: "FB",
      id: "FB",
      ariaLabel: "Visit our facebook page",
    },
    { href: "https://twitter.com/fnp_ind", name: "TW", id: "TW", ariaLabel: "Visit our twitter page" },
    { href: "https://in.pinterest.com/fnpind", name: "PIN", id: "PIN", ariaLabel: "Visit our pinterest page" },
    {
      href: "https://www.instagram.com/fernsnpetalsindia",
      name: "INST",
      id: "INST",
      ariaLabel: "Visit our instagram page",
    },
  ],
  headerMenu: [
    { href: "/account/my-account", name: "Login" },
    { href: "/info/fnp-chat?param=help_nav_login", name: "Help Center" },
  ],
  footerMenu: [
    { href: "/corporate?promo=corporate_enquiry", name: "Corporate Gifts" },
    {
      href: "/info/franchise-programme?promo=franchise_enquiry",
      name: "Become a Franchise",
    },
    {
      href: "/info/become-a-partner?promo=partner_enquiry",
      name: "Become a Partner",
    },
    {
      href: "/services/weddings-n-events?promo=wedding_enquiry",
      name: "Wedding",
    },
    { href: "", name: "Media" },
  ],
  accordionData: {
    pageType: "home",
    endPoint: "control/getContentIdDetails?contentId=mobile_home_page_article",
    heading: "Why Us?",
  },
  bottomCategoryContentId: "bottom_categoryList",
  rightMenuOptions: [
    {
      name: "My Account",
      href: "/account/my-account",
      icon: "account_circle",
      id: "my-account",
    },
    {
      name: "Download App",
      href: "/info/download-app",
      icon: "file_download",
      id: "dwnld-app",
    },
    {
      name: "Corporate Gifts",
      href: "#!",
      icon: "work",
      id: "corp-gifts",
      onclick: "showCorporateGifts",
    },
    {
      name: "Get a Franchise",
      href: "/info/franchise-programme?promo=franchise_enquiry",
      icon: "store",
      id: "franchise",
    },
    {
      name: "Wedding Enquiry",
      href: "/services/weddings-n-events?promo=wedding_enquiry",
      icon: "wc",
      id: "wq",
    },
    {
      name: "Become a Partner",
      href: "/info/become-a-partner?promo=partner_enquiry",
      icon: "people",
      id: "pq",
    },
    {
      name: "Currency : INR",
      href: "#!",
      icon: "account_balance_wallet",
      id: "curr-convertor",
      onclick: "showCurrenciesList",
    },
    {
      name: "Contact Us",
      onclick: "navigateToContactUs",
      href: "#!",
      icon: "headset_mic",
      id: "contact-us",
    },
  ],
  popularCurrencies: ["INR", "USD", "EUR", "GBP", "AUD"],
  popularMobileCodes: [
    "+91 IND",
    "+1 USA",
    "+1-284 VGB",
    "+61 AUS",
    "+65 SGP",
    "+974 QAT",
    "+64 NZL",
    "+60 MYS",
    "+1 CAN",
    "+971 ARE",
  ],
  isCdpEnabled: true,
  dnsPrefetchDomains: [
    "sslwidget.criteo.com",
    "gum.criteo.com",
    "pixel.everesttech.net",
    "googleads.g.doubleclick.net",
    "dis.as.criteo.com",
    "www.googleadservices.com",
    "static.criteo.net",
    "cm.everesttech.net",
    "asia.creativecdn.com",
    "stats.g.doubleclick.net",
    "www.google.com",
    "www.everestjs.net",
    "www.googletagmanager.com",
    "www.google-analytics.com",
    "www.google.co.in",
    "assets.adobedtm.com",
  ],
  footerExtLinks: [
    { href: "/info/help-center?cid=india", name: "Help Center", id: "HC" },
    { href: "/info/franchise-programme", name: "Get Franchise", id: "FR" },
    { href: "/info/retail-stores", name: "Retail Outlets", id: "RO" },
  ],
  partnersUserAgentList: ["phonepe", "paytm"],
  isSmartLockEnabled: true,
  isOmnitureEnabled: true,
  needToShowOccasionList: true,
  needToShowBanners: true,
  contactUs: { description: "+91 921 242 2000", link: "+919212422000" },
};

export default mobileIndiaConfig;
