import { HYDRATE } from "next-redux-wrapper";
import APP_CONSTANTS from "../../../src/action-constants/app-actions";

const initialState = {
  Totalyears: [],
};

/**
 * This method is the reducer for actions that are specific to App
 *
 * @param {object} state - contains initial redux State
 * @param {object} action - contains actionTypes and payload information for specific action
 * @returns {object} state - the next State which needs to be dispatched to redux
 */
function yearsReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.totalYears,
      };
    case APP_CONSTANTS.SET_YEARS:
      return {
        ...state,
        Totalyears: payload,
      };
    default:
      return state;
  }
}

export default yearsReducer;
