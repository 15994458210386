import { HYDRATE } from "next-redux-wrapper";
import PdpPageConstants from "../../../src/action-constants/pdp-constants";

const initialPdpUserSelections = {
  countryGeoId: "",
  product_id: "",
  occasion: "",
  fnpSalesChannelEnumId: "",
  quantity: 1,
  deliveryLocation: "",
  vendorId: "",
  shippingMethodId: "",
  timeSlotId: "",
  matchedPincode: "",
  price: "",
  isbuynow: "N",
  pinCode: "",
  add_category_id: "",
  destlookup: "",
  productSequenceNumber: "",
  itemDesiredDeliveryDate: "",
  itemDesiredDeliveryDatesList: "",
  shippingCost: 0,
  add_product_id: "",
  destlookupintl: "",
  selectedCity: "",
  productType: "",
  userSelectedDelivery: null,
};
const initialState = {
  pdpUserSelections: initialPdpUserSelections,
  selectedType: "",
  selectedFlavour: "",
  flavours: [],
  selectedVariant: {},
  userPersonalization: [],
  variantList: [],
  quantityList: [],
  deliveryType: "",
  imageConfigs: [],
  multiImageUploadCompleted: false,
  uploadProgressStatus: {
    totalSize: "",
    sizeRemaining: "",
    percentComplete: "",
  },
  isProductNotDeliverable: false,
};

/**
 * This function is a reducer for all the product detail.
 *
 * @param {object} state it represents the current state object
 * @param {object} action it represents the new action object which consists of the new payload.
 * @returns {object} Updated state object with the action object.
 */
function pdpContextReducer(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.pdpContext,
      };
    case PdpPageConstants.SET_PDP_USER_SELECTIONS:
      return {
        ...state,
        pdpUserSelections: {
          ...state.pdpUserSelections,
          ...action.payload,
        },
      };

    case PdpPageConstants.SET_SELECTED_TYPE:
      return {
        ...state,
        selectedType: action.payload,
      };
    case PdpPageConstants.SET_SELECTED_FLAVOUR:
      return {
        ...state,
        selectedFlavour: action.payload,
      };
    case PdpPageConstants.SET_FLAVOURS:
      return {
        ...state,
        flavours: action.payload,
      };
    case PdpPageConstants.SET_SELECTED_VARIANT:
      return {
        ...state,
        selectedVariant: action.payload,
      };

    case PdpPageConstants.SET_USER_PERSONALIZATIONS:
      return {
        ...state,
        userPersonalization: action.payload,
      };

    case PdpPageConstants.SET_VARIANT_LIST:
      return {
        ...state,
        variantList: action.payload,
      };
    case PdpPageConstants.SET_QUANTITY_OR_TYPE_LIST:
      return {
        ...state,
        quantityList: action.payload,
      };
    case PdpPageConstants.SET_DELIVERY_TYPE:
      return {
        ...state,
        deliveryType: action.payload,
      };

    case PdpPageConstants.SET_IMAGE_CONFIGS:
      return {
        ...state,
        imageConfigs: action.payload,
      };

    case PdpPageConstants.SET_UPLOAD_PROGRESS_BAR:
      return {
        ...state,
        uploadProgressStatus: action.payload,
      };
    case PdpPageConstants.SET_MULTI_IMAGE_UPLOAD_COMPLETED:
      return {
        ...state,
        multiImageUploadCompleted: action.payload,
      };
    case PdpPageConstants.SET_IS_PRODUCT_NOT_DELIVERABLE:
      return {
        ...state,
        isProductNotDeliverable: action.payload,
      };
    case PdpPageConstants.SET_USER_SELECTED_DELIVERY:
      return {
        ...state,
        pdpUserSelections: {
          ...state.pdpUserSelections,
          userSelectedDelivery: action.payload,
        },
      };
    default:
      return state;
  }
}

export default pdpContextReducer;
