import React from "react";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { makeStyles } from "@material-ui/core/styles";
import { useRouter } from "next/router";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  backButton: {
    "&.MuiIconButton-edgeStart": {
      marginLeft: "-16px",
    },
  },
}));

/**
 * This component is used to show back arrow icon in the m-site header
 *
 * @param {object} root0 props from parent
 * @param {boolean} root0.withTitle whether page header have title or not
 * @param {boolean} root0.handleBack whether a custom onclick handler passed in onBackPress
 * @param {Function} root0.onBackPress on click handler to handle back press
 * @param {boolean} root0.showFnpLogo whether to show fnp logo or not
 *
 * @returns {React.ReactElement} - renders back-arrow-icon-btn component
 */
const BackArrowIconBtn = ({ withTitle, handleBack, onBackPress, showFnpLogo }) => {
  const router = useRouter();
  const classes = useStyles();

  /**
   *  routing which should happen on back button click
   *
   * @returns {React.ReactNode} respective back path
   */
  const backRouting = () => {
    return router.back();
  };

  return (
    <IconButton
      disableRipple
      edge={withTitle ? "start" : false}
      color="inherit"
      aria-label="back-arrow"
      onClick={handleBack ? onBackPress : () => backRouting()}
      className={showFnpLogo ? classes.backButton : {}}
    >
      <ArrowBackIcon />
    </IconButton>
  );
};

BackArrowIconBtn.propTypes = {
  withTitle: PropTypes.bool,
  handleBack: PropTypes.bool,
  onBackPress: PropTypes.func,
  showFnpLogo: PropTypes.bool,
};

BackArrowIconBtn.defaultProps = {
  withTitle: false,
  handleBack: false,
  onBackPress: () => {},
  showFnpLogo: false,
};

export default BackArrowIconBtn;
